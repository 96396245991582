import React from 'react';
import * as ROUTE from '../../routes/constant';
import { Link } from 'react-router-dom';

function settingsMenuItems(pathname = '') {
  const menuItemsArray = [
    {
      link: (
        <Link
          to={`/${ROUTE.DASHBOARD}/${ROUTE.SETTINGS}/${ROUTE.PROFILE}`}
          className={
            pathname === `/${ROUTE.DASHBOARD}/${ROUTE.SETTINGS}/${ROUTE.PROFILE}` ? 'active' : ''
          }
        >
          Enterprise Profile
        </Link>
      ),
    },
    {
      link: (
        <Link
          to={`/${ROUTE.DASHBOARD}/${ROUTE.SETTINGS}/${ROUTE.LOGIN_SECURITY}`}
          className={
            pathname === `/${ROUTE.DASHBOARD}/${ROUTE.SETTINGS}/${ROUTE.LOGIN_SECURITY}`
              ? 'active'
              : ''
          }
        >
          Login & Security
        </Link>
      ),
    },
    {
      link: (
        <Link to={`/`} className={'disable-sidebar-link-item'}>
          KYC
        </Link>
      ),
    },
    {
      link: (
        <Link to={`/`} className={'disable-sidebar-link-item'}>
          Notifications
        </Link>
      ),
    },
    {
      link: (
        <Link to={`/`} className={'disable-sidebar-link-item'}>
          Help & Support
        </Link>
      ),
    },
    {
      link: (
        <Link to={`/`} className={'disable-sidebar-link-item'}>
          Terms & Policies
        </Link>
      ),
    },
  ];

  return menuItemsArray;
}

const settingsSidebar = (pathname) => settingsMenuItems(pathname);

export default settingsSidebar;
