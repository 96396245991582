import { useState, useEffect } from 'react';
import { getCertificate } from '../components/shared/EcertificateAPIcall';

const useCertificateData = () => {
  const [certificateData, setCertificateData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCertificate();
        const certificates = response?.data?.eCertificates || [];

        const preparedItems = certificates
          .filter((item) => item.name.trim() !== '') // Filter out items with empty string names
          .map((item) => ({
            id: item.id,
            title: item.name,
            value: item.id,
          }));

        setCertificateData(preparedItems);
      } catch (error) {
        console.error('Error fetching certificates:', error);
      }
    };

    fetchData();
  }, []);

  return certificateData;
};

export default useCertificateData;
