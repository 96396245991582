import axios from 'axios';

export const getJobUsageDetails = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/jobcenter/tenant/job-usage`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response) {
      return response;
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const customBundle = async (value, id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/custom-bundle/${id}`,
      value,
      {
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
      },
    );
    if (response) {
      return response;
    }
  } catch (error) {
    throw new Error(error);
  }
};
