import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DoctButton, DoctTypography } from '@doct-react/core';
import { DoctAnimationLoading, DoctDropdownSelect, DoctModal } from '@doct-react/app';

import { setShowToasterWithParams } from '../../../featuresSlice';
import { downloadMemberDetails, uploadMemberExcel } from '../services/members.services';

import { TOST_BOTTOM_CENTER } from '../../../../constants/toasterPosition.constants';

import uploadIcon from '../../../../assets/icons/uploadIcon.svg';
import uploadIconWhite from '../../../../assets/icons/uploadIconWhite.svg';
import downloadIcon from '../../../../assets/icons/downloadIcon.svg';

const DownloadMemberDetails = ({ organizationName, refetch }) => {
  const [open, setOpen] = useState(false);

  const [openUpload, setOpenUpload] = useState(false);

  return (
    <>
      <DownloadMemberDetailModal
        organizationName={organizationName}
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
      <div className="d-flex align-items-center">
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            setOpenUpload(true);
          }}
        >
          <img src={uploadIcon} alt="Upload Icon" className="mx-2" />
          <DoctTypography variant="subtitle2" className="text-grey-600">
            Upload
          </DoctTypography>
        </div>
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() => {
            setOpen(true);
          }}
        >
          <img src={downloadIcon} alt="Upload Icon" className="mx-2" />
          <DoctTypography variant="subtitle2" className="text-grey-600">
            Download
          </DoctTypography>
        </div>
      </div>

      <UploadExcelModal
        open={openUpload}
        refetch={refetch}
        handleClose={() => {
          setOpenUpload(false);
        }}
      />
    </>
  );
};

export default DownloadMemberDetails;

const DownloadMemberDetailModal = ({ open, handleClose, organizationName }) => {
  const [selectedFormat, setSelectedFormat] = useState('');
  const [downloadFormat, setDownloadFormat] = useState();
  const [isDownloadFieldEmpty, setIsDownloadFieldEmpty] = useState(false);

  useEffect(() => {
    if (downloadFormat != null) {
      setIsDownloadFieldEmpty(false);
    }
  }, [downloadFormat]);

  return (
    <DoctModal
      open={open}
      iconName=""
      title="Download"
      showFooter={false}
      handleClose={handleClose}
      width={360}
      className="disabled_modal_outside_click"
    >
      <DoctTypography variant="subtitle2" className="text-grey-600">
        Download in PDF or Excel format as per your requirement.
      </DoctTypography>

      <div className="mt-2">
        <DoctDropdownSelect
          label="Format"
          width={328}
          value={selectedFormat}
          handleChange={(item) => {
            setSelectedFormat(item.target.value);

            if (item.target.value == 'Excel Sheet') {
              setDownloadFormat('ExcelSheet');
            }
          }}
          menuItems={[{ title: 'Excel Sheet', value: 'Excel Sheet' }]}
        />
      </div>
      {isDownloadFieldEmpty && <p className="custom-validation-error">Its required</p>}

      <DoctButton
        icon="downloadOne"
        size="medium"
        text="Download"
        className="float-right mt-5"
        onButtonClickHandler={() => {
          if (downloadFormat == null) {
            setIsDownloadFieldEmpty(true);
          } else {
            downloadMemberDetails(downloadFormat, organizationName).then(() => {
              handleClose();
            });
          }
        }}
      />
    </DoctModal>
  );
};

const UploadExcelModal = ({ open, handleClose, refetch }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const uploadFiles = () => {
    document.getElementById('selectField').click();
  };

  return (
    <>
      <input
        type="file"
        id="selectField"
        name="profileFile"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        hidden
        onChange={(e) => {
          setLoading(true);
          uploadMemberExcel(e.target.files[0])
            .then(() => {
              handleClose();
              dispatch(
                setShowToasterWithParams({
                  variant: 'neutral',
                  position: TOST_BOTTOM_CENTER,
                  message: 'Sheet Uploaded successfully!',
                }),
              );
              refetch();
            })
            .catch((error) => {
              if (error?.message === 'Request failed with status code 400') {
                dispatch(
                  setShowToasterWithParams({
                    variant: 'neutral',
                    position: TOST_BOTTOM_CENTER,
                    message: 'Invalid excel data!',
                  }),
                );
              } else if (error.response?.data?.Title) {
                dispatch(
                  setShowToasterWithParams({
                    variant: 'danger',
                    position: TOST_BOTTOM_CENTER,
                    message: error.response?.data?.Title,
                  }),
                );
              } else {
                dispatch(
                  setShowToasterWithParams({
                    variant: 'neutral',
                    position: TOST_BOTTOM_CENTER,
                    message: 'Oops! something went wrong',
                  }),
                );
              }
            })
            .finally(() => {
              setLoading(false);
              e.target.value = '';
            });
        }}
      />
      <DoctModal
        open={open}
        handleClose={() => {
          handleClose();
        }}
        iconName=""
        showFooter={false}
        title="Upload"
        width={360}
        className="disabled_modal_outside_click"
      >
        <DoctTypography variant="body2" className="text-grey-600">
          Fill in your existing members&apos; data as shown in the sample in excel format.
        </DoctTypography>

        <DoctTypography variant="body2" className="text-grey-600 font-italic">
          Please note that all mandatory columns must be filled or your data to be uploaded properly
          otherwise you might get an error while uploading.
        </DoctTypography>

        <div className="d-flex align-items-center justify-content-end">
          <a href={'/static/sample-excel-file.xlsx'} download>
            <DoctButton
              text="Sample Excel"
              icon="downloadOne"
              variant="text"
              type="inverse"
              size="medium"
            />
          </a>
          <div
            className="d-flex align-items-center justify-content-center cursor-pointer upload-excel-modal-btn"
            onClick={(e) => {
              e.preventDefault();
              uploadFiles();
            }}
          >
            <img src={uploadIconWhite} alt="Upload Icon" className="mx-2" />
            <DoctTypography variant="subtitle2" className="text-white">
              Upload
            </DoctTypography>
          </div>
        </div>
      </DoctModal>
      {loading && (
        <div className="full-page-loader">
          <DoctAnimationLoading />
        </div>
      )}
    </>
  );
};
