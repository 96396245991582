import { useState } from 'react';

import React from 'react';

const DragAndDropFileUploder = ({ children, handleUpload }) => {
  const [, setFileNameError] = useState(false);
  const [, setFileExtensionError] = useState(false);

  const handleFileDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setFileNameError(false);
    setFileExtensionError(false);

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const fileExtension = files[0]?.name?.toString()?.split('.')[1];
      if (['pdf'].includes(fileExtension?.toLowerCase())) {
        const uploadedFile = files;
        handleUpload(uploadedFile);
      } else {
        const uploadedFile = files;
        handleUpload(uploadedFile);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <div onDrop={handleFileDrop} onDragOver={handleDragOver}>
      {children}
    </div>
  );
};

export default DragAndDropFileUploder;
