import React from 'react';
import * as ROUTE from '../../routes/constant';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setJobPostActiveTabIndex } from '../featuresSlice';

const recruiterDashboardMenuItems = (pathname = '') => {
  const dispatch = useDispatch();

  const menuItemsArray = [
    {
      link: (
        <Link
          to={`/${ROUTE.DASHBOARD}/${ROUTE.RECRUITER}/${ROUTE.POSTING}`}
          className={
            pathname === `/${ROUTE.DASHBOARD}/${ROUTE.RECRUITER}/${ROUTE.POSTING}` ? 'active' : ''
          }
          onClick={() => {
            dispatch(setJobPostActiveTabIndex(0));
          }}
        >
          Job Posts
        </Link>
      ),
    },
    {
      link: (
        <Link
          to={`/${ROUTE.DASHBOARD}/${ROUTE.RECRUITER}/${ROUTE.JOB_APPLICANTS}`}
          className={
            pathname === `/${ROUTE.DASHBOARD}/${ROUTE.RECRUITER}/${ROUTE.JOB_APPLICANTS}`
              ? 'active'
              : ''
          }
        >
          Job Applicants
        </Link>
      ),
    },
  ];

  return menuItemsArray;
};

const recruiterPageSidebar = (pathname) => recruiterDashboardMenuItems(pathname);

export default recruiterPageSidebar;
