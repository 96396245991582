import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serialize } from 'object-to-formdata';
import { CHANGE_PASSWORD, SYSTEM_CENTER_API_ROUTE, USER } from '../../api/apiEndpoints';

export const appApi = createApi({
  reducerPath: 'appApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = getState().app.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => `${process.env.REACT_APP_BASE_API_URL}/systemcenter/user`,
      providesTags: ['User'],
    }),
    updateProfile: builder.mutation({
      query: (body) => {
        return {
          url: `${process.env.REACT_APP_BASE_API_URL}${SYSTEM_CENTER_API_ROUTE}/${USER}`,
          method: 'PUT',
          body: serialize(body),
        };
      },
      invalidatesTags: ['User'],
    }),
    changePassword: builder.mutation({
      query: (body) => {
        return {
          url: `${process.env.REACT_APP_BASE_API_URL}${SYSTEM_CENTER_API_ROUTE}/${USER}/${CHANGE_PASSWORD}`,
          method: 'PUT',
          body,
        };
      },
    }),
  }),
});

export const { useGetUserQuery, useUpdateProfileMutation, useChangePasswordMutation } = appApi;
