import React, { useEffect, useState } from 'react';
import { DoctTypography } from '@doct-react/core';
import UploadCertificateHeaderText from './shared/UploadCertificateHeaderText';
import { getCertificate } from './shared/EcertificateAPIcall';
import { useLocation, useNavigate } from 'react-router';
import { Toaster } from '../../../shared';
import { DoctAnimationLoading } from '@doct-react/app';
import HoriZontalRow from './shared/HoriZontalRow';
import usePdfToImageConverter from '../hooks/usePDFToImageConverter';
import { useCertificateImage } from '../hooks/useCertificateImage';
import { resetEcertificateState, setAllCertificateData } from '../slices/eCertificateSlice';
import { useDispatch } from 'react-redux';
import * as ROUTE from '../../../routes/constant';
import '../components/shared/styles.scss';

const GenerateECertificateBox = React.lazy(
  () => import('./featAllECertificate/GenerateECertificateBox'),
);
const UploadCertificate = React.lazy(() => import('./featAllECertificate/UploadCertificate'));
const CertificateCard = React.lazy(() => import('./featAllECertificate/CertificateCard'));

const AllECertificate = () => {
  const [showUploadCertificate, setShowUploadCertificate] = useState(false);
  const [certificateData, setCertificateData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showTost, setShowTost] = useState(false);
  const [text, setText] = useState('');
  const location = useLocation();
  const [isLoad, setIsLoad] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isError, setIsError] = useState(false);
  const [deleteToast, setDeleteToast] = useState(false);
  const { setHtmlCertificate } = useCertificateImage();
  const { images, loading, setImages, convertPdfToImages } = usePdfToImageConverter(setSearchText);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [disableSaveAndAssign, setDisableSaveAndAssign] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleUploadButtonClick = () => {
    setShowUploadCertificate(true);
  };

  const handleClose = () => {
    setShowUploadCertificate(false);
    setSearchText('');
    setImages([]);
    if (totalRecords > 0) {
      navigate(
        `/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_E_CERTIFICATES}?=showAllCertificates`,
      );
    } else {
      navigate(`/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_E_CERTIFICATES}`);
    }
  };

  useEffect(() => {
    if (location?.search?.includes('upload-certificate=true')) {
      setShowUploadCertificate(true);
      setTimeout(() => {
        dispatch(resetEcertificateState());
        setImages([]);
        setHtmlCertificate('');
      }, 0);
      setTimeout(() => {
        dispatch(resetEcertificateState());
        setImages([]);
        setHtmlCertificate('');
      }, 0);
    }
  }, [location?.search?.includes('upload-certificate=true')]);
  useEffect(() => {
    setIsLoad(true);
    getCertificate()
      .then((res) => {
        setCertificateData(res?.data?.eCertificates);
        dispatch(setAllCertificateData(res?.data?.eCertificates));
      })
      .finally(() => {
        setIsLoad(false);
      });
  }, [location]);

  const GetCertificate = async () => {
    await getCertificate().then((res) => {
      setCertificateData(res?.data?.eCertificates);
      setTotalRecords(res?.data?.totalRecords);
    });
  };

  useEffect(() => {
    GetCertificate();
  }, [location]);

  {
    loading && (
      <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
        <DoctAnimationLoading />
      </div>
    );
  }

  if (location?.search?.includes('?=showAllCertificates')) {
    return (
      <>
        {deleteToast && (
          <div className="position-fixed tost-container-top-right">
            <Toaster
              variant={'positive'}
              text={'Your Certificate Deleted SuccessFully!'}
              onPressedClose={() => setDeleteToast(false)}
            />
          </div>
        )}
        <React.Suspense
          fallback={
            <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
              <DoctAnimationLoading />
            </div>
          }
        >
          {isLoad ? (
            <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
              <DoctAnimationLoading />
            </div>
          ) : (
            <>
              <DoctTypography variant="subtitle2" className="text-grey-800 mb-0">
                All E-Certificates
              </DoctTypography>
              <HoriZontalRow className="mb-0" />
              {showTost && (
                <div className="position-fixed tost-container-top-right">
                  <Toaster
                    variant={'error'}
                    text={text}
                    onPressedClose={() => setShowTost(false)}
                  />
                </div>
              )}
              <div className="all-e-certificate-scrollbar">
                <DoctTypography variant="subtitle3" className="text-grey-600 pt-1">
                  {totalRecords} CERTIFICATE
                </DoctTypography>
                {certificateData.map((certificate, index) => (
                  <CertificateCard
                    setCertificateData={setCertificateData}
                    certificateData={certificateData}
                    key={certificate.id ?? index}
                    Certificate={certificate}
                    setShowTost={setShowTost}
                    setText={setText}
                    setTotalRecords={setTotalRecords}
                    totalRecords={totalRecords}
                    setIsLoad={setIsLoad}
                    setDeleteToast={setDeleteToast}
                    deleteToast={deleteToast}
                    GetCertificate={GetCertificate}
                  />
                ))}
              </div>
            </>
          )}
        </React.Suspense>
      </>
    );
  }

  return (
    <main className="bg-grey-200">
      <div>
        <React.Suspense
          fallback={
            <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
              <DoctAnimationLoading />
            </div>
          }
        >
          {showUploadCertificate || location?.search?.includes('?=certificateID') ? (
            images?.length > 0 || location?.search?.includes('?=certificateID') ? (
              <UploadCertificateHeaderText
                isUploaded
                iconName="arrowBack"
                title={searchText}
                subText="Certificate File Name"
                leftIconOnClick={handleClose}
                setIsLoad={setIsLoad}
                disableSaveBtn={disableSaveBtn}
                setDisableSaveBtn={setDisableSaveBtn}
                isLoad={isLoad}
                disableSaveAndAssign={disableSaveAndAssign}
                setDisableSaveAndAssign={setDisableSaveAndAssign}
                handleClose={handleClose}
                setSearchText={setSearchText}
                setIsError={setIsError}
                isEdit={location?.search?.includes('?=certificateID')}
                certificateID={location?.search?.split('?=certificateID')[1]}
                searchText={searchText}
              />
            ) : (
              <UploadCertificateHeaderText
                iconName="close"
                title="Upload Certificate Design"
                setIsLoad={setIsLoad}
                leftIconOnClick={handleClose}
              />
            )
          ) : (
            ''
          )}
        </React.Suspense>
      </div>
      {showUploadCertificate || location?.search?.split('?=certificateID')[1] ? (
        <React.Suspense
          fallback={
            <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
              <DoctAnimationLoading />
            </div>
          }
        >
          {isLoad ? (
            <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
              <DoctAnimationLoading />
            </div>
          ) : (
            <UploadCertificate
              certificateID={location?.search?.split('?=certificateID')[1]}
              setSearchText={setSearchText}
              searchText={searchText}
              isError={isError}
              setIsError={setIsError}
              images={images}
              loading={loading}
              convertPdfToImages={convertPdfToImages}
            />
          )}
        </React.Suspense>
      ) : (
        <React.Suspense
          fallback={
            <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
              <DoctAnimationLoading />
            </div>
          }
        >
          <GenerateECertificateBox onUploadButtonClick={handleUploadButtonClick} />
        </React.Suspense>
      )}
    </main>
  );
};
export default AllECertificate;
