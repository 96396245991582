import React, { useState, useCallback } from 'react';
import { DoctButton, DoctTypography } from '@doct-react/core';
import ImageUploadIllustrator from '../../../assets/images/image-input.svg';
import style from './ImageInput.module.scss';

const styleObj = {
  image_input: `${style.image_input} ${style.image_input_fixed_hight}`,
  image_input_initial: style.image_input_initial,
  previewImage: style.image_input_preview,
  label: style.image_input_label,
  label_fixed_hight: style.fixed_area,
  btn_area: style.image_input_btn_area,
  input_while_preview_mode: style.input_while_preview_mode,
};

function Preview({ src, onButtonClickHandler }) {
  return (
    <>
      <img src={src} alt="Cover Image" className={styleObj.previewImage} />
      <span className={styleObj.btn_area}>
        <DoctButton
          text="Remove/Change"
          variant="contained"
          type="inverse"
          size="medium"
          onButtonClickHandler={(e) => {
            e.preventDefault();
            onButtonClickHandler();
          }}
        />
      </span>
    </>
  );
}

function StaticArea({ infoOne, infoTwo }) {
  return (
    <>
      <img
        src={ImageUploadIllustrator}
        alt="illustrator"
        loading="lazy"
        width={'73px'}
        height={'66px'}
      />
      {infoOne && (
        <DoctTypography variant="body2" className="mb-12px">
          {infoOne}
        </DoctTypography>
      )}
      <span className="pointer-event-none">
        <DoctButton
          text="Upload Cover"
          size="medium"
          type="semantic-info"
          onButtonClickHandler={(e) => {
            e.stopPropagation();
          }}
        />
      </span>
      {infoTwo && (
        <DoctTypography variant="body2" className="text-grey-600 mt-4 pt-1">
          {infoTwo}
        </DoctTypography>
      )}
    </>
  );
}

export default function ImageInput({
  name,
  id = 'coverPhoto',
  className = '',
  infoOne = 'Upload 1920px by 1280px image for best fit.',
  infoTwo = 'JPEG, JPG, PNG upto 10 MB size',
  imageUrl = '',
  maxFileSize = null,
  onChange = () => {},
  accept = '.png, .jpg, .jpeg',
  allowedExtensions = `(${accept?.replaceAll(',', '|').replaceAll(' ', '')})`,
  onMaxFileSizeExceed = () => {},
  onNotAllowedExtension = () => {},
}) {
  const [file, setFile] = useState(null);

  const onButtonClickHandler = () => {
    setFile(null);
    onChange({ file: null });
  };

  const checkTypeOfImageSrc = useCallback(() => {
    if (imageUrl) return imageUrl;
    return URL.createObjectURL(file);
  }, [file, imageUrl]);

  return (
    <div className={`${styleObj.image_input} ${file ? '' : styleObj.image_input_initial}`}>
      {(file || imageUrl) && (
        <Preview src={checkTypeOfImageSrc()} onButtonClickHandler={onButtonClickHandler} />
      )}
      <label
        htmlFor={id}
        className={`cursor-pointer ${file || imageUrl ? styleObj.input_while_preview_mode : ''} ${
          styleObj.label
        } ${className}`}
      >
        <StaticArea {...{ infoOne, infoTwo }} />
        <input
          hidden
          type="file"
          id={id}
          name={name}
          accept={accept}
          onChange={(e) => {
            // mismatch file size condition
            if (maxFileSize) {
              if (e.target.files.length > 0) {
                const fileSize = e.target.files[0].size;
                const fileMb = fileSize / 1024 ** 2;
                if (fileMb > maxFileSize) {
                  onMaxFileSizeExceed();
                  return;
                }
              }
            }
            // mismatch file type condition
            if (e.target.value) {
              if (!new RegExp(allowedExtensions).test(e.target.value?.toLocaleLowerCase())) {
                onNotAllowedExtension();
                return;
              }
            }

            setFile(e.target.files[0]);
            onChange({ file: e.target.files[0] });
          }}
        ></input>
      </label>
    </div>
  );
}
