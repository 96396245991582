import * as ROUTE from '../../../routes/constant';

const EventAbstractActionBar = {
  [ROUTE.MANAGE_EVENTS_ABSTRACTS]: {
    pathname: ROUTE.MANAGE_EVENTS_ABSTRACTS,
    title: 'Events Dashboard',
    navigateButtonLink: '/create',
    buttonText: 'New',
    buttonIcon: 'plus',
    isButtonDisabled: true,
  },
};

export default EventAbstractActionBar;
