import React from 'react';
import { DoctButton, DoctIcon, DoctTypography } from '@doct-react/core';
import { DoctModal } from '@doct-react/app';
import { CircularProgress } from '@mui/material';

export default function AssignCertificatePopUp({
  firstButtonText,
  middleButtonText,
  lastButtonText,
  open,
  handleManualPopUpClose,
  handleExcelPopUpClose,
  assignTo,
  handleUploadExcelEmailShare,
  handleUploadExcelWillDoLater,
  handleUploadExcelFileShare,
  emailLoading,
  pdfLoading,
  generateCertificateForExcel,
  laterLoading,
  shareButtonHide,
}) {
  return (
    <DoctModal
      open={open}
      showFooter={false}
      className="white-body-modal modal-hidden disable_modal_outside_click hide-modal-header doct-modal-extra-padding"
    >
      <div
        onClick={handleManualPopUpClose || handleExcelPopUpClose}
        className="float-right mt-n2 mr-n2"
      >
        <DoctIcon name="close" height="24" width="24" className="mt-n1 mr-n1 cursor-pointer" />
      </div>
      <DoctTypography variant="h6" className="text-success font-weight-medium">
        E-Certificate Generated Successfully!
      </DoctTypography>
      <DoctTypography variant="body2" className="text-grey-600 mb-4">
        {assignTo ? (
          <span>
            Assigned to <span className="font-weight-bold text-grey-800">{assignTo}</span>
          </span>
        ) : (
          ''
        )}
      </DoctTypography>
      {emailLoading ? (
        <div className="d-flex justify-content-around loader-outer-line align-items-center select-file-button">
          <CircularProgress size={20} color="inherit" className="text-white" />
        </div>
      ) : (
        <DoctButton
          className="w-100 select-file-button"
          text={firstButtonText}
          variant="contained"
          size="medium"
          onButtonClickHandler={handleUploadExcelEmailShare}
          disabled={emailLoading}
        />
      )}
      {pdfLoading ? (
        <div
          className="d-flex justify-content-around loader-outer-line align-items-center mt-2"
          style={{ border: '2px solid rgba(0, 0, 0, 0.1)' }}
        >
          <CircularProgress size={20} color="inherit" className="text-grey-600" />
        </div>
      ) : (
        <DoctButton
          className={`text-grey-600 w-100 mt-2 ${shareButtonHide && 'share-button-hide'}`}
          text={middleButtonText}
          variant="outlined"
          size="medium"
          disabled={pdfLoading}
          onButtonClickHandler={handleUploadExcelFileShare}
        />
      )}

      {laterLoading ? (
        <div className="d-flex justify-content-around loader-outer-line align-items-center will-do-later-button mt-2">
          <CircularProgress size={20} color="inherit" className="text-grey-600" />
        </div>
      ) : (
        <DoctButton
          className="text-grey-600 w-100 mt-2 will-do-later-button"
          text={lastButtonText}
          variant="contained"
          size="medium"
          onButtonClickHandler={() => {
            if (generateCertificateForExcel) {
              return;
            }
            handleUploadExcelWillDoLater();
          }}
          disabled={laterLoading}
        />
      )}
    </DoctModal>
  );
}
