import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useFormCommon from '../../../../shared/form/useForm.Common';
import FormAddDesignation from './Form.AddEditDesignation';

import {
  selectCommitteeMembers,
  selectShowDesignationModal,
  selectSubCommitteeId,
  setSubCommitteeId,
  setDesignationId,
  setCommitteeMembers,
  toggleDesignationModal,
  resetState,
  selectIsAddOrUpdating,
} from '../slice/committee.slice';
import { postDesignation, putDesignation } from '../services/designation.services';

function AddEditDesignationWrapper({
  defaultValues = {},
  modalContainer: ModalContainer,
  id,
  primaryBtnText,
}) {
  const dispatch = useDispatch();

  const {
    formProps: { handleSubmit, control, errors, setValue, watch, getValues },
  } = useFormCommon({ defaultValues });

  const [isMembersEmpty, setIsMembersEmpty] = useState(false);

  const showDesignationModal = useSelector(selectShowDesignationModal);
  const selectedCommitteeMembers = useSelector(selectCommitteeMembers);
  const subCommitteeId = useSelector(selectSubCommitteeId);
  // const loaderForApiCall = useSelector(selectLoaderInApiCall);
  const isAddOrUpdating = useSelector(selectIsAddOrUpdating);

  const formProps = { control, isErrors: errors, setValue, watch, getValues };

  useEffect(() => {
    if (selectedCommitteeMembers?.length > 0) {
      setIsMembersEmpty(false);
    }
  }, [selectedCommitteeMembers]);

  const onSubmitForm = handleSubmit((values) => {
    if (selectedCommitteeMembers?.length == 0) {
      setIsMembersEmpty(true);
    } else {
      const transformValue = { ...values };
      transformValue.memberIds = selectedCommitteeMembers
        ?.map(({ id }) => id)
        .join()
        .split(',');
      transformValue.subCommitteeId = subCommitteeId;
      if (id) {
        dispatch(putDesignation({ id, data: transformValue, handleModalClose }));
      } else {
        dispatch(postDesignation({ data: transformValue, handleModalClose }));
      }
    }
  });

  const handleModalClose = () => {
    if (showDesignationModal) {
      dispatch(toggleDesignationModal());
      dispatch(resetState());
      dispatch(setCommitteeMembers([]));
      dispatch(setSubCommitteeId(null));
      dispatch(setDesignationId(null));
    }
  };

  const modalProps = { onSubmitForm, handleModalClose, primaryBtnText };

  return (
    <ModalContainer
      {...modalProps}
      isOpen={showDesignationModal}
      isPrimaryBtnDisable={isAddOrUpdating}
    >
      <FormAddDesignation formProps={formProps} isMembersEmpty={isMembersEmpty} />
    </ModalContainer>
  );
}

export default AddEditDesignationWrapper;
