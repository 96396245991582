import { DoctTextField } from '@doct-react/app';
import { DoctCol, DoctRow, DoctTypography } from '@doct-react/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../../../../../../../components';
import qs from 'qs';

const UsingViewContactDetails = ({ control, errors, setValue, watch, isEditTimeEmailValue }) => {
  const user = useSelector(userSelector);
  const watchMobileNumber = watch('viewContactMobileNumber');
  const watchTelephoneNumber = watch('telephoneNumber');

  useEffect(() => {
    if (user?.tenant?.email && !isEditTimeEmailValue) {
      setValue('viewContactEmail', user?.tenant?.email);
    }
    if (user?.mobileNumber && !isEditTimeEmailValue) {
      setValue('viewContactMobileNumber', user?.mobileNumber?.split('-')[1]);
    }
  }, [qs.stringify(user), isEditTimeEmailValue]);

  return (
    <div className="p-4">
      <DoctTypography variant="textLabel1" fontWeight="medium">
        Mobile
      </DoctTypography>

      <DoctRow>
        <DoctCol sm={2}>
          <DoctTextField
            name={'code'}
            id={'code'}
            label={'Code'}
            validationRules={{}}
            control={control}
            isErrors={errors}
            defaultValue={'+91'}
            disabled
            variant="filled"
            showStar={false}
          />
        </DoctCol>
        <DoctCol sm={10}>
          <DoctTextField
            name={'viewContactMobileNumber'}
            id={'viewContactMobileNumber'}
            label={'Add mobile number'}
            validationRules={{
              required: !watchTelephoneNumber ? 'it is required' : false,
              minLength: {
                value: 10,
                message: 'Mobile No is not valid',
              },
              maxLength: {
                value: 10,
                message: 'Mobile No is not valid',
              },
              pattern: {
                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                message: 'Mobile No is not valid',
              },
            }}
            defaultValue={user?.mobileNumber?.split('-')[1]}
            control={control}
            isErrors={errors}
            showStar={false}
          />
        </DoctCol>
      </DoctRow>

      <DoctTypography variant="textLabel1" fontWeight="medium">
        Telephone
      </DoctTypography>

      <DoctRow>
        <DoctCol sm={2}>
          <DoctTextField
            name={'code'}
            id={'code'}
            label={'Code'}
            validationRules={{}}
            control={control}
            isErrors={errors}
            showStar={false}
            defaultValue={'+91'}
            disabled
            variant="filled"
          />
        </DoctCol>
        <DoctCol sm={10}>
          <DoctTextField
            name={'telephoneNumber'}
            id={'telephoneNumber'}
            label={'Add Telephone number'}
            validationRules={{
              required: !watchMobileNumber ? 'it is required' : false,
              minLength: {
                value: 10,
                message: 'Telephone number is not valid',
              },
              maxLength: {
                value: 15,
                message: 'Telephone number is not valid',
              },
              pattern: {
                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                message: 'Telephone number is not valid',
              },
            }}
            control={control}
            isErrors={errors}
            showStar={false}
          />
        </DoctCol>
      </DoctRow>

      <DoctTypography variant="textLabel1" fontWeight="medium">
        Email
      </DoctTypography>

      <DoctTextField
        name={'viewContactEmail'}
        id={'viewContactEmail'}
        label={'Email address'}
        validationRules={{
          required: 'it is required',
          pattern: {
            value: /^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Email is not valid',
          },
        }}
        defaultValue={user?.tenant?.email}
        control={control}
        isErrors={errors}
        showStar={false}
      />
    </div>
  );
};

export default UsingViewContactDetails;
