import React from 'react';
import { DoctIcon, DoctTypography } from '@doct-react/core';

export function ActionRowLayout({ onClick = () => {}, title, rightArea, showCursor = true }) {
  return (
    <div
      className={`bg-white ${showCursor ? `cursor-pointer` : ''} common-box-pannel px-20px mb-2px`}
      onClick={onClick ? onClick : () => {}}
    >
      {title}
      <span className="ml-auto">{rightArea}</span>
    </div>
  );
}

export function ExpandableAreaHeader({ title, onClick, isExpanded }) {
  if (isExpanded) return null;
  return (
    <ActionRowLayout
      onClick={() => onClick(title)}
      title={
        <DoctTypography variant="textLabel1" fontWeight="medium" className="my-0">
          {title}
        </DoctTypography>
      }
      rightArea={<DoctIcon width="24" height="24" name="right" className="ml-auto text-grey-400" />}
    ></ActionRowLayout>
  );
}

export function ExpandableAreaBody({ children, onClick }) {
  return (
    <div
      onClick={() => {
        onClick(null);
      }}
    >
      {children}
    </div>
  );
}
