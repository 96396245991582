import React from 'react';
import { Dropdown } from '../../../../shared';
import { DoctButton } from '@doct-react/core';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import * as ROUTE from '../../../../routes/constant';

export default function NewECertificateActionBtn() {
  const location = useLocation();
  const currentRoute = location.pathname + location.search;

  const isButtonEnabled =
    currentRoute === `/dashboard/e-certificate/all-e-certificates?=showAllCertificates`;

  return (
    <Dropdown className="ml-auto">
      {isButtonEnabled ? (
        <Link
          to={`/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_E_CERTIFICATES}?upload-certificate=true`}
        >
          <DoctButton variant="contained" text={`New E- Certificate`} size="medium" icon={`plus`} />
        </Link>
      ) : (
        <DoctButton
          variant="contained"
          text={`New E- Certificate`}
          size="medium"
          icon={`plus`}
          disabled
        />
      )}
    </Dropdown>
  );
}
