import React from 'react';
import { Link } from 'react-router-dom';

import {
  AvatarProfileDropdown,
  LayoutProfileDropdown,
  MenuItemProfileDropdown,
  UserAvatarAndInfo,
} from './components';
import { PROFILE, SETTINGS } from '../../../routes/constant';

export default React.forwardRef(function ProfileDropdown({ user, onMenuItemClick }, ref) {
  const { name, tenant, userNumber } = user || {};
  const { organizationName, organizationLogoUrl } = tenant || {};

  return (
    <LayoutProfileDropdown ref={ref}>
      <UserAvatarAndInfo organization={organizationName} admin={name} id={userNumber}>
        <AvatarProfileDropdown src={organizationLogoUrl} />
      </UserAvatarAndInfo>
      <MenuItemProfileDropdown
        title={'Account settings'}
        icon={'accountSettings'}
        element={Link}
        link={`${SETTINGS}/${PROFILE}`}
        showRightIcon
        onMenuItemClick={onMenuItemClick}
      />
      <MenuItemProfileDropdown
        title={'Log out'}
        icon={'logout'}
        onMenuItemClick={onMenuItemClick}
      />
    </LayoutProfileDropdown>
  );
});
