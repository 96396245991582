import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createEventSteps, freeEventSteps, Header, StepsContainer } from './components';
import {
  selectBasicInfoDetails,
  selectCreateEventResponse,
  selectCurrentStep,
  setStepsFormData,
} from './createEvent.slice';

import './CreateEvent.scss';
import { scrollToTop } from '../../../utils/scrollToTop';
import { useEffect } from 'react';

export default function CreateEvent() {
  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const basicInfoDetails = useSelector(selectBasicInfoDetails);
  const apiResponseData = useSelector(selectCreateEventResponse);

  dispatch(setStepsFormData({}));

  useEffect(() => {
    scrollToTop();
  }, [currentStep]);

  const checkPaymentType =
    apiResponseData?.eventPaymentType === 'Free' || basicInfoDetails?.registrationType === 'Free';

  return (
    <>
      <Header />
      <main className="bg-grey-200 pt-3x vh-without-header">
        <StepsContainer
          steps={checkPaymentType ? freeEventSteps : createEventSteps}
          currentStep={currentStep}
        />
      </main>
    </>
  );
}
