import React from 'react';
import AddNewMemberFormFooter from './AddNewMemberFormFooter';
import AddNewMemberFormHeader from './AddNewMemberFormHeader';

const AddNewMemberFormBodyContainer = ({ children, setStatus, newJobLoading }) => {
  return (
    <>
      <AddNewMemberFormHeader setStatus={setStatus} newJobLoading={newJobLoading} /> {children}{' '}
      <AddNewMemberFormFooter newJobLoading={newJobLoading} />
    </>
  );
};

export default AddNewMemberFormBodyContainer;
