import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NETWORK_REQUEST_ERROR_MSG } from '../constants/constants';
import { TOST_BOTTOM_CENTER } from '../constants/toasterPosition.constants';
import { setShowToasterWithParams } from '../features';

export default function useNotifySuccessFailed({
  isSuccess,
  onSuccessHandler = () => {},
  successNotifyMsg = 'Changes saved successfully!',
  errorNotifyMsg = NETWORK_REQUEST_ERROR_MSG,
  isError,
  onErrorHandler = () => {},
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccess) {
      onSuccessHandler();
      dispatch(
        setShowToasterWithParams({
          variant: 'neutral',
          position: TOST_BOTTOM_CENTER,
          message: successNotifyMsg,
        }),
      );
    }
    if (isError) {
      onErrorHandler();
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_BOTTOM_CENTER,
          message: errorNotifyMsg,
        }),
      );
    }
  }, [isSuccess, isError]);
}
