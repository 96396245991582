export const privacyPolicyEnterprise = [
  {
    heading: 'Privacy Policy',
  },
  {
    content: `The Docthub Enterprise application provides a dashboard for
        organizations and businesses to connect and publish their services
        or information such as job vacancies and courses on Docthub user
        platforms. It also allows the use of business tools and software
        developed by Docthub. The Docthub Enterprise is operated by
        Docthub Health Tech Private Limited.`,
  },
  {
    content: ` The organization and businesses need to create an Enterprise
    Account on Docthub Enterprise website (
    <span className="text-black">domain </span>
    <a href="https://enterprise.docthub.com/">
      https://enterprise.docthub.com/
    </a>
    ) and further access all services through the same account and
    manage it through Enterprise Dashboard. Such account users are
    referred hereafter as “Enterprise User”.`,
  },
  {
    content: `This privacy policy describes how Docthub Enterprise and its
    affiliates (collectively “Docthub”) collect and process your
    personal and/or organizational information through all Docthub
    websites, products, devices, services, online marketplace,
    software and applications that together referred hereafter as
    “Docthub Services”. We (Docthub Enterprise) know that you
    (Enterprise Users) care about how your information is being used
    and shared, we appreciate your trust and ensure that we will do it
    carefully and sensibly.`,
  },
  {
    content: ` By using Docthub Services you agree to our use of your personal or
    organizational information (including sensitive personal
    information) in accordance with this Privacy Policy, as may be
    amended from time to time by us at our discretion. You also agree
    and consent to us collecting, storing, processing, transferring,
    and sharing your personal and/or organizational information
    (including sensitive personal information) with third parties or
    service providers for the purposes set out in this Privacy Policy.`,
  },
  {
    content: ` Personal information subject to this Privacy Policy will be
    collected and retained by Docthub, with a registered office at
    202, GNFC Info Tower, Pakwan Cross Road, SG Highway, Ahmedabad,
    Gujarat, 380054, India.`,
  },
  {
    title: ` What Personal/ Organizational information about Enterprise Users
    does Docthub collect?`,
  },
  {
    content: ` Docthub collects your personal and organizational information in order to provide and continually improve our products and services.`,
  },
  {
    title: `Information that you provide`,
  },
  {
    content: ` We receive and store any information that you provide in relation to Docthub Services. You can choose not to provide certain types of information, which may affect your experience of using Docthub Services. Here are the types of personal information we collect`,
  },
  {
    content: `Your name`,
  },
  {
    content: `Designation/ Position in your organization`,
  },
  {
    content: `Organization Name`,
  },
  {
    content: `Organization Location: City, State, Country`,
  },
  {
    content: `Mobile number`,
  },
  {
    content: `Business email address`,
  },
  {
    content: `Postal address`,
  },
  {
    content: `Business KYC: Taxation ID, Company registration, Utility bill`,
  },
  {
    content: `Organization Logo image and cover image`,
  },
  {
    title: `Auto Collection of information:`,
  },
  {
    content: `We automatically collect and store certain types of information about your use of Docthub Services, including information about your interaction with consent and services available through Docthub. Like many other websites, we use cookies and other unique identifiers and we obtain certain types of information when your web browser or device accesses Docthub Services and other consent served by or on behalf of Docthub on other websites.`,
  },
  {
    title: `Information from other sources:`,
  },
  {
    content: `We might receive information about you from other sources, such as updated address, organization logo, email address, information about your services from your website, digital media or our affiliates, which we use to collect our records and deliver you a better business solution.`,
  },
  {
    title: `Cookies and other identifiers:`,
  },
  {
    content: `We use cookies and other identifiers to enable our systems to recognize your browser or device and to provide and improve Docthub Services to you. For more information about cookies and how we use them, please read our Cookies Policy.`,
  },
  {
    title: `Does Docthub share my personal information?`,
  },
  {
    content: `Information about our users is an important part of our business and we do not sell our users’ personal information. We share your personal information only in the below described manner for Docthub and its subsidiaries.`,
  },
  {
    content: `<span class="text-black"><b>Third-party service providers: </b></span> We integrate other digital platforms into Docthub Services to offer comprehensive solutions for our users and give easily accessible services. For example, payment gateway integration for purchasing subscriptions, products or services on Docthub Services. Such third-party service providers have access to personal information needed to perform their functions, but may not use it for other purposes. Further, they must process the personal information in accordance with applicable law.`,
  },
  {
    content: `<span class="text-black"><b>Business transfers: </b></span> As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, customer information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Policy (unless, of course, the customer consents otherwise). Also, in the unlikely event the Docthub or any of its affiliates, or substantially all of their assets are acquired, customer information will be one of the transferred assets.`,
  },
  {
    content: `<span class="text-black text-grey-800"><b>Protection of Docthub and others: </b></span> We release accounts and other personal information when we believe it is appropriate to comply with the law; enforce or apply our Terms and Conditions and other agreements; or protect the rights, property, or safety of Docthub, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and risk reduction.`,
  },
  {
    content: `<span class="text-black"><b>Other than as set out above, you will receive notice when personal
    information about you might be shared with third parties, and you
    will have an opportunity to choose not to share the information.</b></span>`,
  },
  {
    title: `How secure is my information?`,
  },
  {
    content: `<span class="text-black"><b>We design our systems with your security and privacy in mind.</b></span>`,
  },
  {
    content: `We work to protect the security of your personal and organizational information during transmission by using encryption protocols and software.`,
  },
  {
    content: `We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, processing, and disclosure of personal customer information. Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you.`,
  },
  {
    content: `Our services have security features to protect your data against unauthorized access or any adversity.`,
  },
  {
    content: `It is important for you to protect your data against unauthorized access to your password and hence be sure to sign off when finished using a shared computer.`,
  },
  {
    title: `Social Media:`,
  },
  {
    title: `Docthub operates channels, pages and accounts on some social media
    platforms to inform, assist and engage with customers. Docthub
    monitors and records comments and posts made on these channels to
    improve its products and services.`,
  },
  {
    content: `Please note that you must not communicate the following information to Docthub through such social media sites:`,
  },
  {
    content: `Sensitive personal data including;`,
  },
  {
    content: `Special categories of personal data meaning any information revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person's sex life or sexual orientation and`,
  },
  {
    content: `Other sensitive personal data such as criminal convictions and offences and national identification number: - Excessive, Inappropriate, Offensive or Defamatory content.`,
  },
  {
    content: `Docthub is not responsible for any information posted on those sites other than the information posted by Docthub Channel. Docthub is only responsible for its own use of the Personal Information received through such sites.`,
  },
  {
    title: `Retention of Personal Information:`,
  },
  {
    content: `Your Personal Information will not be retained by Docthub any longer than it is necessary for the purposes for which the personal information is processed and/or in accordance with legal, regulatory, contractual or statutory obligations as applicable. At the expiry of such periods, your personal information will be deleted or archived in compliance with applicable laws.`,
  },
  {
    title: `Children:`,
  },
  {
    content: `To use the Docthub Services, you agree that you must be the minimum age (described in this paragraph below) or older.`,
  },
  {
    content: `The minimum age for these purposes shall be eighteen (18), however if local laws require that you must be older for Docthub to be able to lawfully provide the services over the Platform to you then that older age shall apply as the applicable minimum age.`,
  },
  {
    content: `If you are under the age of 18 or the age of majority in your jurisdiction, you must use the Docthub Services under the supervision of your parent, legal guardian or responsible adult.`,
  },
  {
    title: `Confidentiality and security:`,
  },
  {
    content: `The security and confidentiality of your personal information is important to us and Docthub has invested significant resources to protect the safekeeping of the confidentiality of your personal data. When using external service providers acting as processors, we require that they adhere to the same standards as Docthub does. Regardless of where your personal information is transferred or stored, we take all necessary steps to ensure that your personal data is kept secure.`,
  },
  {
    content: `We seek to ensure compliance with the requirements of the Information Technology Act of India and the rules made to ensure the protection and preservation of your privacy. We have physical, electronic, and procedural safeguards that comply with the laws prevalent in India to protect your personal information. By accepting the terms of this Privacy Policy, you agree that the standards and practices being implemented by us, are reasonable and sufficient for the protection of your Personal Information.`,
  },
  {
    title: `Changes to this Privacy Policy:`,
  },
  {
    title: `Docthub reserves the right to update, change or modify this privacy
    policy at any time. The Privacy Policy shall come to effect from the
    date of publication of such update, change or modification.`,
  },
  {
    title: `Data protection officer/Grievance officer:`,
  },
  {
    title: `In case you have any complaints and/or grievances in relation to the
    processing of your personal information you can send your complaints
    via email to our grievance officer:`,
  },
  {
    title: `Address:`,
  },
  {
    title: `Contact Us`,
  },
  {
    content: `If you have any questions about this Privacy Policy, You can contact us:`,
  },
  {
    content: `By visiting this page on our website: <a href="https://www.docthub.com/contact-us"> https://www.docthub.com/contact-us </a>`,
  },
  {
    content: `By sending us an email: <a href="mailto:office@docthub.com "> office@docthub.com </a>`,
  },
];
