import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@mui/material/Skeleton';

function SkeletonCardView1() {
  return (
    <>
      <Box
        sx={{ bgcolor: 'grey.200' }}
        variant="rect"
        width="99%"
        height={130}
        className="mt-1 border-radius"
      >
        <div className="d-flex justify-content-between">
          <Skeleton
            sx={{ bgcolor: 'grey.300' }}
            variant="h1"
            width="30%"
            height={20}
            className="mt-2 border-radius ml-3"
          />
          <Skeleton
            sx={{ bgcolor: 'grey.300' }}
            variant="h1"
            width="30%"
            height={20}
            className="mt-2 border-radius mr-3"
          />
        </div>
        <Skeleton
          sx={{ bgcolor: 'grey.300' }}
          variant="h1"
          width="88%"
          height={20}
          className="mt-2 border-radius ml-3"
        />
        <Skeleton
          sx={{ bgcolor: 'grey.300' }}
          variant="h1"
          width="88%"
          height={20}
          className="mt-2 border-radius ml-3"
        />
        <div className="d-flex justify-content-between">
          <Skeleton
            sx={{ bgcolor: 'grey.300' }}
            variant="h1"
            width="43%"
            height={20}
            className="mt-2 border-radius ml-3"
          />
          <Skeleton
            sx={{ bgcolor: 'grey.300' }}
            variant="h1"
            width="43%"
            height={20}
            className="mt-2 border-radius mr-3"
          />
        </div>
      </Box>
      <Box
        sx={{ bgcolor: 'grey.200' }}
        variant="rect"
        width="99%"
        height={130}
        className="p-2 mt-1 border-radius"
      >
        <Skeleton
          sx={{ bgcolor: 'grey.300' }}
          variant="h1"
          width="30%"
          height={20}
          className="pt-1 border-radius ml-3"
        />
        <div className="d-flex justify-content-center">
          <Skeleton
            sx={{ bgcolor: 'grey.300' }}
            variant="h1"
            width="50%"
            height={20}
            className="mt-3 border-radius ml-3"
          />
        </div>
        <Skeleton
          sx={{ bgcolor: 'grey.300' }}
          variant="h1"
          width="88%"
          height={20}
          className="mt-4 border-radius ml-3"
        />
      </Box>
    </>
  );
}

export default SkeletonCardView1;
