import { DoctAutoComplete, DoctDatePickerV2, DoctTextField } from '@doct-react/app';
import { DoctTypography, DoctButton } from '@doct-react/core';
import React, { useEffect, useState } from 'react';
import uploadProfileImg from '../../../../../assets/images/photo-upload.svg';
import '../../addNewMember.scss';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const BasicInformation = ({
  control,
  errors,
  register,
  profileUrlOnEdit,
  setDeleteProfileImage,
  setValue,
}) => {
  const [userImgSrc, setUserImgSrc] = useState(uploadProfileImg);

  useEffect(() => {
    if (profileUrlOnEdit != null) {
      setUserImgSrc(profileUrlOnEdit);
    }
  }, [profileUrlOnEdit]);

  const uploadFiles = () => {
    document.getElementById('selectField').click();
  };
  return (
    <div className="my-2">
      <DoctTypography variant="h6" className="basic_info_title_border p-3 text-grey-500 bg-white">
        Basic Information
      </DoctTypography>
      <div className="bg-grey-100 basic_info_contents d-flex align-items-center justify-content-center">
        <div className="form_container_body mt-3">
          <div className="cursor-pointer d-flex align-items-center">
            <img src={userImgSrc} className="user_profiile_img" alt="user-profile-image" />

            <div className="mx-3">
              {userImgSrc != uploadProfileImg ? (
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setUserImgSrc(uploadProfileImg);
                    setDeleteProfileImage(true);
                    setValue('profileFile', null);
                  }}
                >
                  <DoctButton text="Remove" variant="outlined" size="medium" />
                </span>
              ) : (
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setUserImgSrc(uploadProfileImg);
                    uploadFiles();
                  }}
                >
                  <DoctButton text="Edit" variant="outlined" size="medium" />
                </span>
              )}
            </div>

            <input
              type="file"
              id="selectField"
              ref={register}
              name="profileFile"
              accept="image/*"
              hidden
              onChange={(e) => {
                e.preventDefault();
                setUserImgSrc(URL.createObjectURL(e.target.files[0]));
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
          </div>

          <div className="py-2">
            <DoctTypography variant="subtitle2">Full Name *</DoctTypography>
            <DoctTextField
              label="Full Name"
              control={control}
              showStar={false}
              id="fullName"
              name="fullName"
              isErrors={errors}
              validationRules={{ required: "It's Required Field" }}
            />
          </div>

          <div className="py-2 d-flex align-items-center justify-content-between">
            <div className="w-50">
              <DoctTypography variant="subtitle2">Birth Date *</DoctTypography>
              <DoctDatePickerV2
                inputProps={{
                  label: 'Birth Date',
                  id: 'birthDate',
                  dateFormat: 'dd MMM yyyy',
                  autoComplete: 'off',
                }}
                control={control}
                isErrors={errors}
                showStar={false}
                name="birthDate"
              />
            </div>

            <div className="w-50 px-3">
              <DoctTypography variant="subtitle2">Gender *</DoctTypography>
              <DoctAutoComplete
                label="Select"
                isErrors={errors}
                id="gender"
                name="gender"
                variant="standard"
                control={control}
                validationRules={{ required: "It's Required Field" }}
                options={[
                  { label: 'Male', value: 'Male' },
                  { label: 'Female', value: 'Female' },
                ]}
              />
            </div>
          </div>

          <DoctTypography variant="subtitle2">Professional Title *</DoctTypography>
          <div className="py-2">
            <DoctTextField
              label="Professional Title"
              showStar={false}
              control={control}
              id="professionalTitle"
              name="professionalTitle"
              isErrors={errors}
              validationRules={{ required: "It's Required Field" }}
            />
          </div>

          <DoctTypography variant="subtitle2">Bio</DoctTypography>

          <div className="py-2">
            <Controller
              rules={{}}
              render={(field) => {
                return (
                  <TextField
                    variant="standard"
                    multiline
                    rows={2}
                    maxRows={4}
                    {...field}
                    label={'Bio'}
                    className={`doct-input doct-text-area${
                      field?.value ? ' doct-input-focused' : ''
                    }`}
                  />
                );
              }}
              name={'bio'}
              control={control}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;
