export default function transformFormData(obj) {
  const joinAddress = obj.address.split(',');
  return {
    ...obj,
    address: {
      addressLine1: joinAddress[0] || '',
      addressLine2: joinAddress[1] || '',
      landmark: joinAddress.slice(2).join(', ') || '', // Spread the remaining elements into landmark
    },
    city: obj?.city?.label,
    state: obj?.state?.label,
    country: obj?.country?.label,
    pinCode: obj?.pincode,
  };
}
