import React from 'react';
import { useDispatch } from 'react-redux';
import { DoctActionMenu, DoctAnimationLoading } from '@doct-react/app';
import { DoctButton, DoctTypography } from '@doct-react/core';

import { setArchiveId, setModalTitle, toggleArchiveModal } from '../../slice/committee.slice';
import ListingSubCommittee from '../Listing.SubCommittee';
import ListDesignation from '../List.Designation';

function ViewArchiveCard({
  options,
  setIsArchiveView,
  storeArchiveIdWithName,
  designationData,
  isDesignationLoading,
  setIsModalOpen,
}) {
  const dispatch = useDispatch();

  if (isDesignationLoading) {
    return (
      <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
        <DoctAnimationLoading />
      </div>
    );
  }
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <DoctButton
            type="inverse"
            variant="text"
            className="pl-1"
            icon="arrowBack"
            onButtonClickHandler={() => {
              setIsArchiveView(false);
            }}
          />
          <DoctTypography variant="subtitle2" fontWeight="medium">
            {storeArchiveIdWithName?.name}
          </DoctTypography>
        </div>
        <DoctActionMenu
          options={options}
          className="more_action_menu mr-1"
          onClick={(item) => {
            if (item.title == 'Rename Archive') {
              dispatch(setModalTitle('Rename Archive'));
              dispatch(setArchiveId(storeArchiveIdWithName.id));
              dispatch(toggleArchiveModal());
            }
            if (item.title == 'Delete Archive') {
              setIsModalOpen(true);
            }
          }}
        />
      </div>
      {designationData?.designations?.length > 0 && (
        <ListDesignation designations={designationData?.designations} />
      )}

      {designationData?.subCommittees?.length > 0 && (
        <ListingSubCommittee subCommittees={designationData?.subCommittees} />
      )}
    </>
  );
}

export default ViewArchiveCard;
