import { EMAIL_VALIDATION } from '../../../../constants/constants';

const fieldProfileSetting = {
  fullName: {
    id: 'name',
    name: 'name',
    label: 'Full name',
    apiValueAccessBy: 'name',
    validationRules: {
      minLength: {
        value: 3,
        message: 'Enter name at least 3 character long',
      },
      maxLength: {
        value: 100,
        message: 'Max 100 character allowed',
      },
      required: "It's Required Field",
    },
  },
  designation: {
    id: 'professionalTitle',
    name: 'professionalTitle',
    label: 'Designation',
    apiValueAccessBy: 'professionalTitle',
    validationRules: {},
  },
  gender: {
    id: 'gender',
    name: 'gender',
    options: [
      { value: 'Male', label: 'Male' },
      { value: 'Female', label: 'Female' },
    ],
    validationRules: {
      required: "It's Required Field",
    },
  },
  country: {
    id: 'country',
    name: 'country',
    validationRules: {},
  },
  state: {
    id: 'state',
    name: 'state',
    validationRules: {},
  },
  city: {
    id: 'city',
    name: 'city',
    validationRules: {},
  },
  postalCode: {
    id: 'pinCode',
    name: 'pinCode',
    label: 'Postal Code',
    validationRules: {
      pattern: {
        value: /^(0|[1-9]\d*)(\.\d+)?$/,
        message: 'Please enter number only',
      },
    },
    inComponentName: 'pincode',
  },
  address: {
    id: 'address',
    name: 'address',
    label: 'Address',
    validationRules: {},
  },
  email: {
    id: 'email',
    name: 'email',
    label: 'Email address',
    validationRules: {
      required: "It's Required Field",
      pattern: {
        value: EMAIL_VALIDATION,
        message: 'Entered value does not match email format',
      },
    },
    showStar: true,
  },
  mobileNumber: {
    id: 'mobileNumber',
    name: 'mobileNumber',
    label: 'Enter mobile number',
    validationRules: {
      required: "It's Required Field",
      pattern: {
        value: /^[0-9]+$/,
        message: 'Please enter number only',
      },
      maxLength: {
        value: 10,
        message: 'Number is not valid',
      },

      minLength: {
        value: 10,
        message: 'Number is not valid',
      },
    },
    showStar: true,
  },
  telephoneNumberCode: {
    id: 'telephoneNumberCode',
    name: 'telephoneNumberCode',
    label: 'STD Code',
    validationRules: {
      required: "It's Required Field",
      maxLength: {
        value: 6,
        message: 'Max 6 Characters',
      },
      pattern: {
        value: /^[0-9]+$/,
        message: 'Please enter number only',
      },
    },
    showStar: true,
  },
  telephoneNumber: {
    id: 'telephoneNumber',
    name: 'telephoneNumber',
    label: 'Enter telephone number',
    validationRules: {
      required: "It's Required Field",
      maxLength: {
        value: 15,
        message: 'Max 15 Characters',
      },
      pattern: {
        value: /^[0-9]+$/,
        message: 'Please enter number only',
      },
    },
    showStar: true,
  },
  currentPassword: {
    id: 'currentPassword',
    name: 'currentPassword',
    label: 'Enter current password',
  },
  newPassword: {
    id: 'newPassword',
    name: 'newPassword',
    label: 'Create Password',
  },
  confirmPassword: {
    id: 'confirmPassword',
    name: 'confirmPassword',
    label: 'Confirm Password',
  },
};

export default fieldProfileSetting;
