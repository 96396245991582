import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#242424',
    width: '290px',
    fontSize: '14px',
    padding: '16px',
    fontWeight: 'normal',
    border: '1px solid rgba(0,0,0,0.06)',
    boxShadow: '0px 1px 2px #00000008',
    lineHight: '24px',
  },
}));

export default LightTooltip;
