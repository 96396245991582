import React, { useEffect, useState } from 'react';
import InstituteCoursesListingCard from './components/InstituteCoursesListingCard';
import usePrepareInstituteTabList from './hooks/usePrepareTabs';
import {
  DoctAnimationLoading,
  DoctFreeSoloSearchInput,
  DoctTabContent,
  DoctTabWrapper,
} from '@doct-react/app';
import { DoctIcon, DoctTypography } from '@doct-react/core';
import { useGetCoursesQuery } from './services/course.service';
import OnNoCourseAdded from './components/OnNoCourseAdded/OnNoCourseAdded';
import NoDataFound from '../../../shared/ui/NoDataFound';
import LoadMore from '../../RecruiterDashboard/JobListing/components/LoadMore';
import NoSearchFound from '../../../shared/components/NoSearchFound';
import { useDispatch, useSelector } from 'react-redux';
import {
  courseListingActiveTabIndexState,
  setCourseListingsActiveTabIndex,
} from '../../featuresSlice';

const InstituteCoursesListing = () => {
  const dispatch = useDispatch();

  const tabOptionsArray = usePrepareInstituteTabList();

  const [searchText, setSearchText] = useState();

  const {
    data: allCourseDataFromApi,
    isFetching: allCourseDataFromApiFetching,
    refetch,
  } = useGetCoursesQuery({ searchText: searchText });

  const [allCoursesData, setAllCoursesData] = useState();
  // const [draftCourseData, setDraftCourseData] = useState();
  // const [publishedCourseData, setPublishedCourseData] = useState();
  // const [unPublishedCourseData, setUnPublishedCourseData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [jobsData, setJobsData] = useState(0);
  const [jobsAllData, setJobsAllData] = useState(7);
  // const [pendingApprovalCourseData, setPendingApprovalCourseData] = useState();
  // const [rejectedCourseData, setRejectedCourseData] = useState();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setAllCoursesData(allCourseDataFromApi?.tenantCourse);
  }, [allCourseDataFromApi]);

  const courseListingActiveTabIndex = useSelector(courseListingActiveTabIndexState);

  const handleChange = (_, newValue) => {
    dispatch(setCourseListingsActiveTabIndex(newValue));
  };

  return (
    <>
      <DoctFreeSoloSearchInput
        name="searchCourses"
        onInputChangeHandler={(val) => {
          setCourseListingsActiveTabIndex(0);
          setSearchText(val);
        }}
        placeholder="Course Title, Course Type, Specialty"
      />

      <DoctTabWrapper
        value={courseListingActiveTabIndex}
        handleChange={handleChange}
        tabOptions={tabOptionsArray}
      />

      {!allCourseDataFromApiFetching ? (
        <DoctTabContent value={courseListingActiveTabIndex} index={0}>
          <DoctTypography variant="textLabel2" className="text-grey-500">
            {allCourseDataFromApi?.totalRecords > 0 &&
              `${allCourseDataFromApi?.totalRecords} COURSES`}
          </DoctTypography>
          {allCoursesData?.length ? (
            allCoursesData?.slice(jobsData, jobsAllData).map((courseDetail, index) => {
              return <InstituteCoursesListingCard key={index} courseDetails={courseDetail} />;
            })
          ) : searchText && allCoursesData?.length <= 0 ? (
            <NoSearchFound
              positionClassName="not_course_found_position"
              description="No courses found to show here."
            />
          ) : (
            <OnNoCourseAdded />
          )}
          <LoadMore
            allJobsData={allCoursesData}
            setJobsAllData={setJobsAllData}
            jobsAllData={jobsAllData}
          />
        </DoctTabContent>
      ) : (
        <div className="page-content-loader">
          <DoctAnimationLoading />
        </div>
      )}

      {!allCourseDataFromApiFetching ? (
        <>
          <DoctTabContent value={courseListingActiveTabIndex} index={1}>
            <DoctTypography variant="textLabel2" className="text-grey-500">
              {allCourseDataFromApi?.totalRecords > 0 && searchText && !allCoursesData?.length
                ? ''
                : allCoursesData?.filter((item) => item?.status === 'Published')?.length
                ? `${
                    allCoursesData?.filter((item) => item?.status === 'Published')?.length
                  } COURSES`
                : ''}
            </DoctTypography>

            {allCoursesData?.filter((item) => item?.status === 'Published')?.length ? (
              allCoursesData
                ?.filter((item) => item.status === 'Published')
                .map((courseDetail, index) => {
                  return <InstituteCoursesListingCard key={index} courseDetails={courseDetail} />;
                })
            ) : !allCoursesData?.length ? (
              searchText && allCoursesData?.length <= 0 ? (
                <NoSearchFound
                  positionClassName="not_course_found_position"
                  description="No courses found to show here."
                />
              ) : (
                <OnNoCourseAdded />
              )
            ) : (
              <NoDataFound />
            )}
          </DoctTabContent>
        </>
      ) : (
        ''
      )}

      {!allCourseDataFromApiFetching ? (
        <>
          <DoctTabContent value={courseListingActiveTabIndex} index={2}>
            <DoctTypography variant="textLabel2" className="text-grey-500">
              {allCourseDataFromApi?.totalRecords > 0 && searchText && !allCoursesData?.length
                ? ''
                : allCoursesData?.filter((item) => item?.status === 'ActivationRequested')?.length
                ? `${
                    allCoursesData?.filter((item) => item?.status === 'ActivationRequested')?.length
                  } COURSES`
                : ''}
            </DoctTypography>

            {allCoursesData?.filter((item) => item?.status === 'ActivationRequested')?.length ? (
              allCoursesData
                .filter((item) => item?.status === 'ActivationRequested')
                .map((courseDetail, index) => {
                  return <InstituteCoursesListingCard key={index} courseDetails={courseDetail} />;
                })
            ) : !allCoursesData?.length ? (
              searchText && allCoursesData?.length <= 0 ? (
                <NoSearchFound
                  positionClassName="not_course_found_position"
                  description="No courses found to show here."
                />
              ) : (
                <OnNoCourseAdded />
              )
            ) : (
              <NoDataFound />
            )}
          </DoctTabContent>
        </>
      ) : (
        ''
      )}

      {!allCourseDataFromApiFetching ? (
        <>
          <DoctTabContent value={courseListingActiveTabIndex} index={3}>
            <DoctTypography variant="textLabel2" className="text-grey-500">
              {allCourseDataFromApi?.totalRecords > 0 && searchText && !allCoursesData?.length
                ? ''
                : allCoursesData?.filter((item) => item?.status === 'Draft')?.length
                ? `${allCoursesData?.filter((item) => item?.status === 'Draft')?.length} COURSES`
                : ''}
            </DoctTypography>

            {allCoursesData?.filter((item) => item?.status === 'Draft')?.length ? (
              allCoursesData
                .filter((item) => item?.status === 'Draft')
                .map((courseDetail, index) => {
                  return <InstituteCoursesListingCard key={index} courseDetails={courseDetail} />;
                })
            ) : !allCoursesData?.length ? (
              searchText && !allCoursesData?.length ? (
                <NoSearchFound
                  positionClassName="not_course_found_position"
                  description="No courses found to show here."
                />
              ) : (
                <OnNoCourseAdded />
              )
            ) : (
              <NoDataFound />
            )}
          </DoctTabContent>
        </>
      ) : (
        ''
      )}

      {!allCourseDataFromApiFetching ? (
        <>
          <DoctTabContent value={courseListingActiveTabIndex} index={4}>
            <DoctTypography variant="textLabel2" className="text-grey-500">
              {allCourseDataFromApi?.totalRecords > 0 && searchText && !allCoursesData?.length
                ? ''
                : allCoursesData?.filter((item) => item?.status === 'UnPublished')?.length
                ? `${
                    allCoursesData?.filter((item) => item?.status === 'UnPublished')?.length
                  } COURSES`
                : ''}
            </DoctTypography>

            {allCoursesData?.filter((item) => item?.status === 'UnPublished')?.length ? (
              allCoursesData
                .filter((item) => item?.status === 'UnPublished')
                ?.map((courseDetail, index) => {
                  return <InstituteCoursesListingCard key={index} courseDetails={courseDetail} />;
                })
            ) : !allCoursesData?.length ? (
              searchText && !allCoursesData?.length ? (
                <NoSearchFound
                  positionClassName="not_course_found_position"
                  description="No courses found to show here."
                />
              ) : (
                <OnNoCourseAdded />
              )
            ) : (
              <NoDataFound />
            )}
          </DoctTabContent>
        </>
      ) : (
        ''
      )}

      {!allCourseDataFromApiFetching ? (
        <DoctTabContent value={courseListingActiveTabIndex} index={5}>
          {allCourseDataFromApi?.totalRecords > 0 && (
            <DoctTypography
              variant="textLabel2"
              className={`${
                searchText && !allCoursesData?.length
                  ? ''
                  : allCoursesData?.filter((item) => item?.status === 'Rejected')?.length
                  ? 'bg-grey-300 py-2 border-radius d-flex align-items-center'
                  : ''
              }`}
              fontWeight="regular"
            >
              {searchText && !allCoursesData?.length ? (
                ''
              ) : allCoursesData?.filter((item) => item?.status === 'Rejected')?.length ? (
                <DoctIcon name="infoOutline" width="15" className="mx-2 p-0" />
              ) : (
                ''
              )}

              {searchText && !allCoursesData?.length
                ? ''
                : allCoursesData?.filter((item) => item?.status === 'Rejected')?.length
                ? 'Rejected courses will stay here for 30 days, then deleted automatically.'
                : ''}
            </DoctTypography>
          )}
          <DoctTypography variant="textLabel2" className="text-grey-500">
            {allCourseDataFromApi?.totalRecords > 0 &&
              `${
                allCoursesData?.filter((item) => item?.status === 'Rejected')?.length
                  ? allCoursesData?.filter((item) => item?.status === 'Rejected')?.length
                  : ''
              } ${
                allCoursesData?.filter((item) => item?.status === 'Rejected')?.length
                  ? 'COURSES'
                  : ''
              }`}
          </DoctTypography>

          {allCoursesData?.filter((item) => item?.status === 'Rejected')?.length ? (
            allCoursesData
              .filter((item) => item?.status === 'Rejected')
              ?.map((courseDetail, index) => {
                return <InstituteCoursesListingCard key={index} courseDetails={courseDetail} />;
              })
          ) : !allCoursesData?.length ? (
            searchText && !allCoursesData?.length ? (
              <NoSearchFound
                positionClassName="not_course_found_position"
                description="No courses found to show here."
              />
            ) : (
              <OnNoCourseAdded />
            )
          ) : (
            <NoDataFound />
          )}
        </DoctTabContent>
      ) : (
        ''
      )}
    </>
  );
};

export default InstituteCoursesListing;
