import { createSlice } from '@reduxjs/toolkit';
import { fetchJobById, postJobInquiry } from '../services/manageJob.services';

const initialState = {
  isLoadingFetchJobDetails: false,
  isLoadingPostJobInquiry: false,
  rawFormValues: {},
  modifiedFormValues: {},
  apiData: {
    experience: '',
  },
  educationArray: {},
  keySkills: {},
  isButtonTextChange: false,
  isCancelJob: false,
  isDeleteJob: false,
};

export const manageJobSlice = createSlice({
  name: 'manageJob',
  initialState,
  reducers: {
    setRawFormValues: (state, action) => {
      state.rawFormValues = action.payload;
    },
    setModifiedValues: (state, action) => {
      state.modifiedFormValues = action.payload;
    },
    setApiData: (state, action) => {
      state.apiData = action.payload;
    },
    setEducationArray: (state, action) => {
      state.educationArray = action.payload;
    },
    setKeySkills: (state, action) => {
      state.keySkills = action.payload;
    },
    setCancelJob: (state, action) => {
      state.isCancelJob = action.payload;
    },
    setDeleteJob: (state, action) => {
      state.isDeleteJob = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJobById.pending, (state) => {
      state.isLoadingFetchJobDetails = true;
    });
    builder.addCase(fetchJobById.fulfilled, (state, action) => {
      state.isLoadingFetchJobDetails = false;
      state.apiData = action.payload;
    });
    builder.addCase(fetchJobById.rejected, (state) => {
      state.isLoadingFetchJobDetails = false;
    });
    builder.addCase(postJobInquiry.pending, (state) => {
      state.isLoadingPostJobInquiry = true;
    });
    builder.addCase(postJobInquiry.fulfilled, (state) => {
      state.isLoadingPostJobInquiry = false;
      state.isButtonTextChange = true;
    });
    builder.addCase(postJobInquiry.rejected, (state) => {
      state.isLoadingPostJobInquiry = false;
      state.isButtonTextChange = false;
    });
  },
});

export const {
  setRawFormValues,
  setModifiedValues,
  setApiData,
  setEducationArray,
  setKeySkills,
  setCancelJob,
  setDeleteJob,
} = manageJobSlice.actions;

export const selectJobRawFormValues = (state) => state['manageJob'].rawFormValues;
export const selectModifiedJobFormValues = (state) => state['manageJob'].modifiedFormValues;
export const selectEducationArray = (state) => state['manageJob'].educationArray;
export const selectKeySkills = (state) => state['manageJob'].keySkills;
export const selectCancelJob = (state) => state['manageJob'].isCancelJob;
export const selectDeleteJob = (state) => state['manageJob'].isDeleteJob;
export const selectApiData = (state) => state['manageJob'].apiData;
export const selectIsLoadingPostJobInquiry = (state) => state['manageJob'].isLoadingPostJobInquiry;
export const selectIsLoadingFetchJobDetails = (state) =>
  state['manageJob'].isLoadingFetchJobDetails;
export const selectIsButtonTextChange = (state) => state['manageJob'].isButtonTextChange;
