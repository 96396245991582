export const courseApplicantTableColumn = [
  {
    key: 'userName',
    title: 'APPLICANT',
  },
  {
    key: 'mobileNumber',
    title: 'MOBILE',
  },
  {
    key: 'actionType',
    title: 'ACTION TYPE',
  },
  {
    key: 'buttonStatus',
    title: 'STATUS',
  },
  {
    key: 'action',
    title: '',
  },
];
