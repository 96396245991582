import { DoctTypography } from '@doct-react/core';
import React from 'react';

export default function FreePostChip() {
  return (
    <>
      <DoctTypography
        variant="textLabel2"
        fontWeight="bold"
        className="text-grey-600 free-post-chip-border px-1 m-0 mr-2"
      >
        FREE POST
      </DoctTypography>
    </>
  );
}
