import axios from 'axios';
import { ENTERPRISE_BASE_API_ROUTE } from '../../../../api/apiEndpoints';

export const getCertificate = async () => {
  const url = `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}/tenant/ecertificates`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.info(error);
  }
};

export const deleteCertificate = async (id) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}/tenant/ecertificates/${id}`;

  try {
    const response = await axios.delete(url);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCerificateImage = async (id) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}/tenant/ecertificates/${id}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.info(error);
  }
};

export const getAwardeePreviewImage = async (id) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}/tenant/ecertificate/awardee/${id}`;
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.info(error);
  }
};
