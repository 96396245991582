import React from 'react';
import { DoctCol, DoctContainer, DoctRow } from '@doct-react/core';
import { useSelector } from 'react-redux';
import { userSelector } from '../../components';
import {
  AddMenu,
  CourseQuickAccessWrapper,
  EventQuickAccessWrapper,
  JobQuickAccessWrapper,
  MembershipQuickAccessWrapper,
  ECertificaterAccessWrapper,
  Sidebar,
  UserInfo,
} from './components';
import './QuickAccess.scss';

export default function QuickAccess() {
  const user = useSelector(userSelector);

  const { name, tenant, userNumber } = user || {};

  const { organizationName } = tenant || {};

  const userInfo = {
    name,
    organizationName,
    userNumber,
  };

  return (
    <main className="bg-grey-200 container-height-without-header pt-4">
      <DoctContainer>
        <DoctRow>
          <DoctCol md={3}>
            <AddMenu />
            <Sidebar className={'mt-4'} />
          </DoctCol>
          <DoctCol md={9}>
            <UserInfo userInfo={userInfo} className={'mb-4'} />
            <DoctRow>
              <DoctCol sm={4}>
                <EventQuickAccessWrapper />
              </DoctCol>
              <DoctCol sm={4}>
                <JobQuickAccessWrapper />
              </DoctCol>
              <DoctCol sm={4}>
                <CourseQuickAccessWrapper />
              </DoctCol>
              <DoctCol sm={4}>
                <MembershipQuickAccessWrapper />
              </DoctCol>
              <DoctCol sm={4}>
                <ECertificaterAccessWrapper />
              </DoctCol>
            </DoctRow>
          </DoctCol>
        </DoctRow>
      </DoctContainer>
    </main>
  );
}
