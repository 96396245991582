import React from 'react';
import { DoctCol, DoctIcon, DoctRow, DoctTypography } from '@doct-react/core';
import documentIcon from '../../../../assets/icons/institute-dashboard/document.svg';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';

const ApplicantDocumnetsCards = ({ attachment, i }) => {
  return (
    <div className="bg-white m-1 px-2 d-flex align-items-center justify-content-between">
      <img src={documentIcon} />
      <DoctTypography variant="textLabel2" className="text-grey-800">
        Document {i + 1}
      </DoctTypography>

      <a href={attachment} rel="noreferrer" target="_blank">
        <DoctTypography variant="textLabel2" className="text-primary">
          VIEW
        </DoctTypography>
      </a>
    </div>
  );
};

const ApplicantCollpsable = ({ column }) => {
  const {
    applicantId,
    userName,
    gender,
    city,
    mobileNumber,
    email,
    attachments,
    latestActionOfApplicant,
    actionTypes,
    educations,
    viewContactCount,
    brochureDownloadCount,
    instituteWebsiteCount,
    sendInquiryCount,
  } = column;

  dayjs.extend(utcPlugin);

  const {
    latestBrochureDate,
    isBrochureDownloaded,
    isViewContactDetail,
    latestViewContactDate,
    isSendInquiry,
    latestSendInquiryDate,
    isInstituteWebsite,
    latestInstituteWebsite,
  } = actionTypes;

  const latestActionDateWithOffset = dayjs(latestActionOfApplicant?.latestActionDate).add(
    330,
    'minutes',
  );
  return (
    <>
      {latestActionOfApplicant?.latestActionDate && (
        <div className="d-flex align-items-center bg-light-info mx-n4 border-radius-tr border-radius-tl position-relative">
          <DoctIcon name="flash" width="16" height="16" fill="#4389FF" />
          <DoctTypography variant="textLabel2" className="text-black">
            Applicant has {latestActionOfApplicant?.latestActionName} on{' '}
            {dayjs(latestActionOfApplicant?.latestActionDate).format('D MMM YYYY')} at{' '}
            {dayjs(latestActionDateWithOffset).utc().utcOffset(330).format('LT')}
          </DoctTypography>
        </div>
      )}

      <DoctRow>
        <DoctCol sm={4}>
          <div>
            <DoctIcon name="infoOutline" fill="grey" width="16" />
            {applicantId && (
              <DoctTypography variant="textLabel2" className="text-grey-600">
                Applicant ID : {applicantId}
              </DoctTypography>
            )}
            <DoctTypography variant="textLabel2" className="text-grey-600">
              Full Name : {userName}
            </DoctTypography>
            <DoctTypography variant="textLabel2" className="text-grey-600">
              Gender : {gender ? gender : '-'}
            </DoctTypography>
            <DoctTypography variant="textLabel2" className="text-grey-600">
              Current Location : {city ? city : '-'}
            </DoctTypography>
            <DoctTypography variant="textLabel2" className="text-grey-400 mt-4">
              Application History :
            </DoctTypography>
            {isBrochureDownloaded && (
              <DoctTypography variant="textLabel2" className="text-grey-600">
                Brochure Downloaded {`(${brochureDownloadCount})`} :{' '}
                {dayjs(latestBrochureDate).format('DD/MM/YYYY')}
              </DoctTypography>
            )}
            {isViewContactDetail && (
              <DoctTypography variant="textLabel2" className="text-grey-600">
                View Contact Detail {`(${viewContactCount})`} :
                {dayjs(latestViewContactDate).format('DD/MM/YYYY')}
              </DoctTypography>
            )}
            {isSendInquiry && (
              <DoctTypography variant="textLabel2" className="text-grey-600">
                Applied Directly {`(${sendInquiryCount})`} :
                {dayjs(latestSendInquiryDate).format('DD/MM/YYYY')}
              </DoctTypography>
            )}

            {isInstituteWebsite && (
              <DoctTypography variant="textLabel2" className="text-grey-600">
                Apply To Website {`(${instituteWebsiteCount})`} :
                {dayjs(latestInstituteWebsite).format('DD/MM/YYYY')}
              </DoctTypography>
            )}

            {/* {actionName && dayjs(latestActionDate).format('DD/MM/YYYY') !== '01/01/1901' && (
              <DoctTypography variant="textLabel2" className="text-grey-600">
                {actionName}{' '}
                {`${actionName === 'Apply To Website' ? `(${instituteWebsiteCount})` : ''}`} :{' '}
                {dayjs(latestActionDate).format('DD/MM/YYYY')}
              </DoctTypography>
            )} */}
          </div>
        </DoctCol>
        {educations?.length > 0 && (
          <DoctCol sm={4}>
            <div className={'collapsible_content_container px-3 h-100'}>
              <DoctIcon name="degreeCollegeOutline" fill="grey" width="16" height="16" />
              {educations?.map((education) => (
                <>
                  <div className="my-3">
                    <DoctTypography variant="textLabel2" className="text-grey-600 my-1">
                      {education?.organization}
                    </DoctTypography>

                    <DoctTypography variant="textLabel2" className="text-grey-600 my-1">
                      {education?.degree}
                    </DoctTypography>
                    <DoctTypography variant="textLabel2" className="text-grey-600 m-0">
                      {education?.startYear} -{' '}
                      {education?.endYear ? education?.endYear : 'Till Date'}
                    </DoctTypography>
                  </div>
                </>
              ))}
            </div>
          </DoctCol>
        )}
        <DoctCol sm={4}>
          <div className={'collapsible_content_container px-3'}>
            <DoctIcon name="assignToOutline" fill="grey" width="16" />
            <DoctTypography variant="textLabel2" className="text-grey-600">
              Mobile: {mobileNumber}
            </DoctTypography>
            {/* <DoctTypography variant="textLabel2" className="text-grey-600">
              Whatsapp: {mobileNumber}
            </DoctTypography> */}
            <DoctTypography variant="textLabel2" className="text-grey-600">
              Email: {email}
            </DoctTypography>
          </div>
        </DoctCol>
        <DoctCol sm={8}>
          {attachments?.length > 0 && (
            <DoctTypography variant="textLabel1" fontWeight="bold" className="text-grey-800">
              Documents
            </DoctTypography>
          )}

          <div className="d-flex flex-wrap">
            {attachments?.length > 0 &&
              attachments?.map((attachment, i) => {
                return (
                  <div className="w-50" key={i}>
                    <ApplicantDocumnetsCards i={i} attachment={attachment} />
                  </div>
                );
              })}
          </div>
        </DoctCol>
      </DoctRow>
    </>
  );
};

export default ApplicantCollpsable;
