import { DoctActionMenu } from '@doct-react/app';
import React from 'react';

export default function ActionMenu({ options, menuAction }) {
  return (
    <DoctActionMenu
      btnType="secondary"
      className={`rotate90 transparent-action-menu`}
      options={options}
      onClick={menuAction}
    />
  );
}
