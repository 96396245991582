import React from 'react';
import { DoctIcon } from '@doct-react/core';

import * as ROUTE from '../../../../routes/constant';
import EventDynamicCard from './EventDynamicCard';
import SkeletonCardView1 from '../../../../shared/ui/SkeletonCard/SkeletonCardView1';

const EventsCard = ({ eventData, navigate, isLoading }) => {
  const { activeEventSummaryDto } = eventData || {};

  if (isLoading) {
    return <SkeletonCardView1 />;
  }

  return (
    <>
      {(activeEventSummaryDto?.length > 0 || eventData?.draftEvents) && !isLoading && (
        <>
          <span
            onClick={() => {
              navigate(`${ROUTE.PATH_CREATE_EVENT}`);
            }}
            className="cursor-pointer"
          >
            <DoctIcon
              name="plus"
              width="24"
              height="24"
              fill="#00A0C0"
              className="mr-4 position-absolute right-0 negative-margin-for-btn"
            />
          </span>
          <EventDynamicCard eventData={eventData} />
        </>
      )}
    </>
  );
};

export default EventsCard;
