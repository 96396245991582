import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserQuery } from '../../../../../components';
import { useGetCountryQuery } from '../../../../../hooks/useLocation/useLocations.services';
import { ProfileDataBox, ProfileDataBoxContentTitleDescription } from '../../../../../shared';
import { selectIsModalOpenOrganization, toggleOrganizationModal } from '../../slice/profile.slice';
import ActionMenu from '../ActionMenu';
import EditModal from '../EditModal';
import EditLocationDetailsProfile from './EditLocationDetailsProfile';
import useHookDefaultValue from './useFormDefaultValue';

export default function LocationDetailsProfile() {
  const dispatch = useDispatch();
  const { data: userData } = useGetUserQuery('');

  const { country, state, city, pinCode, address } = userData || {};

  const { prepareFormValue } = useHookDefaultValue();

  const isModalOpen = useSelector(selectIsModalOpenOrganization);
  const { data: locationData, isLoading: isLocationApiLoading } = useGetCountryQuery('', {
    skip: !isModalOpen,
  });

  const [, setPreparingDefaultVal] = useState(false);

  const menuAction = useCallback((item) => {
    if (item.title == 'Edit') {
      setPreparingDefaultVal(true);
      handleOpenModal();
    }
  }, []);

  const handleOpenModal = useCallback(() => {
    dispatch(
      toggleOrganizationModal({
        modalTitle: 'Edit Organization Location Details',
        isModalOpenOrganization: true,
      }),
    );
  }, []);

  const handleModalClose = useCallback(() => {
    dispatch(toggleOrganizationModal({ modalTitle: null, isModalOpenOrganization: false }));
  }, []);

  return (
    <>
      <ProfileDataBox
        heading={'Organization Location Details'}
        actionMenu={
          <ActionMenu options={[{ title: 'Edit' }]} menuAction={(item) => menuAction(item)} />
        }
      >
        <ProfileDataBoxContentTitleDescription title={'Country:'} description={country || '-'} />
        <ProfileDataBoxContentTitleDescription title={'State:'} description={state || '-'} />
        <ProfileDataBoxContentTitleDescription title={'City:'} description={city || '-'} />
        <ProfileDataBoxContentTitleDescription
          title={'Postal Code:'}
          description={pinCode || '-'}
        />
        <ProfileDataBoxContentTitleDescription
          title={'Address:'}
          description={
            (address?.addressLine1 ? address.addressLine1 : '') +
              (address?.addressLine2 && address.addressLine1 ? ', ' : '') +
              (address?.addressLine2 ? address.addressLine2 : '') +
              (address?.landMark && (address?.addressLine1 || address?.addressLine2) ? ', ' : '') +
              (address?.landMark ? address.landMark : '') || '-'
          }
        />
      </ProfileDataBox>
      {isModalOpen && (
        <EditLocationDetailsProfile
          isLocationApiLoading={isLocationApiLoading}
          defaultValues={isLocationApiLoading ? null : prepareFormValue(userData, locationData)}
          userData={userData}
          modalComponent={EditModal}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
}
