/* eslint-disable prettier/prettier */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { JOBS, JOB_APPLICANT } from '../../../../api/apiEndpoints';
import * as API_ENDPOINTS from '../../../../api/apiEndpoints';

export const recruiterApplicantApi = createApi({
  reducerPath: 'recruiterApplicantApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = getState().app.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: ['Applicants'],

  endpoints: (builder) => ({
    getApplicants: builder.query({
      query: ({ jobId, status, searchText }) => {
        try {
          return jobId
            ? `${process.env.REACT_APP_BASE_API_URL}/${API_ENDPOINTS.JOB_CENTER}${
                API_ENDPOINTS.BUSINESS_BASE_API_ROUTE
              }/${API_ENDPOINTS.JOBS}/${
                typeof jobId === 'number' ? jobId : jobId?.split('&')[0]
              }/${JOB_APPLICANT}${status ? `?status=${status}` : ''}${
                searchText ? `?searchText=${searchText}` : ''
              }`
            : '';
        } catch (error) {
          return error;
        }
      },
      providesTags: ['Applicants'],
    }),

    updateStatus: builder.mutation({
      query: ({ id, userId, updatedStatus }) => ({
        url: `${process.env.REACT_APP_BASE_API_URL}/${API_ENDPOINTS.JOB_CENTER}${API_ENDPOINTS.BUSINESS_BASE_API_ROUTE}/${JOBS}/${id}/${JOB_APPLICANT}/${userId}/change-status`,
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(updatedStatus),
      }),

      invalidatesTags: ['Applicants'],
    }),
  }),
});

export const { useGetApplicantsQuery, useUpdateStatusMutation } = recruiterApplicantApi;
