import { DoctTypography } from '@doct-react/core';
import React, { useState } from 'react';
import ScheduleSessionCard from './ScheduleSessionCard';
import dayjs from 'dayjs';
import qs from 'qs';

import { useEffect } from 'react';
import {
  prepareUploadedFile,
  scheduleFromStartDateEndDate,
} from '../../../../../../../../helper/helperFunction';
import { DoctFileUpload, DoctForm, DoctModal } from '@doct-react/app';
import ManageScheduleSession from './ManageScheduleSession';
import {
  fetchEventById,
  saveEmptyScheduleDate,
  saveScheduleDetails,
  selectBasicInfoDetails,
  selectCreateEventResponse,
  selectIsDateChanged,
  selectScheduleAndSpeakersDetails,
} from '../../../../../createEvent.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormGroup from '../../../../../../../../shared/FormGroup';
import { setShowToasterWithParams } from '../../../../../../../featuresSlice';
import { TOST_TOP_RIGHT } from '../../../../../../../../constants/toasterPosition.constants';

const LocalizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(LocalizedFormat);
// const START_DATE = '2022-08-01T10:00:00'; // static data will replace with API data
// const END_DATE = '2022-08-05T15:00:00'; // static data will replace with API data

export default function Schedule({
  uploadedFiles,
  setUploadedFiles,
  resetScheduleDocumentUrl,
  setResetScheduleDocumentUrl,
  setDeleteScheduleDocumentUrl,
}) {
  const BasicInfoDetails = useSelector(selectBasicInfoDetails);
  const speakersAndSchedule = useSelector(selectScheduleAndSpeakersDetails);
  const apiResponseData = useSelector(selectCreateEventResponse);
  const checkDateIsChanged = useSelector(selectIsDateChanged);

  const dispatch = useDispatch();
  const { id } = useParams();

  const [schedules, setSchedules] = useState([]);
  const [schedulesWithSlots, setSchedulesWithSlots] = useState({});

  const [defaultTimeValue, setDefaultTimeValue] = useState();
  const [selectedTimeValue, setSelectedTimeValue] = useState(false);

  useEffect(() => {
    if (id == undefined) return;
    dispatch(fetchEventById(id));
  }, [id]);

  useEffect(() => {
    if (Object.keys(BasicInfoDetails).length) {
      const START_DATE = BasicInfoDetails?.startDate;
      const END_DATE = BasicInfoDetails?.endDate;
      const prepareScheduleArray = scheduleFromStartDateEndDate(START_DATE, END_DATE);
      setSchedules(prepareScheduleArray);
    }
  }, [BasicInfoDetails]);

  useEffect(() => {
    if (!schedules?.length) return;

    const scheduleDefaultData = {};
    const newObjForSchedulesWithSlots = {};

    schedules.map((obj) => {
      scheduleDefaultData[obj?.date] = {
        scheduleSlots: [],
      };
    });

    setSchedulesWithSlots({ ...scheduleDefaultData });

    if (checkDateIsChanged) {
      dispatch(saveScheduleDetails(scheduleDefaultData));
    } else {
      if (speakersAndSchedule?.schedules?.length >= 1) {
        speakersAndSchedule?.schedules?.map((item) => {
          newObjForSchedulesWithSlots[dayjs(item.date).format('dddd, D MMMM YYYY')] = {};
          newObjForSchedulesWithSlots[dayjs(item.date).format('dddd, D MMMM YYYY')].ScheduleSlots =
            item?.scheduleSlots;
          setSchedulesWithSlots({ ...scheduleDefaultData, ...newObjForSchedulesWithSlots });
          dispatch(saveScheduleDetails({ ...scheduleDefaultData, ...newObjForSchedulesWithSlots }));
        });
      } else if (Object.keys(apiResponseData).length) {
        apiResponseData?.schedules?.map((item) => {
          newObjForSchedulesWithSlots[dayjs(item.date).format('dddd, D MMMM YYYY')] = {};
          newObjForSchedulesWithSlots[dayjs(item.date).format('dddd, D MMMM YYYY')].ScheduleSlots =
            item.scheduleSlots;
        });

        setSchedulesWithSlots({ ...scheduleDefaultData, ...newObjForSchedulesWithSlots });
        dispatch(saveScheduleDetails({ ...scheduleDefaultData, ...newObjForSchedulesWithSlots }));

        if (apiResponseData?.scheduleDocumentUrl || apiResponseData?.scheduleDocumentUrl === null) {
          setResetScheduleDocumentUrl(apiResponseData?.scheduleDocumentUrl);
        }
      } else {
        dispatch(saveScheduleDetails(scheduleDefaultData));
      }
    }
  }, [speakersAndSchedule, apiResponseData, schedules]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keyOfDay, setKeyOfDay] = useState(null);
  const [nameOfDay, setNameOfDay] = useState(null);
  const [editSession, setEditSession] = useState(null);
  const [isOpenModalOfFileLimit, setIsOpenModalOfFileLimit] = useState(false);

  const manageActionHandler = () => {
    reset({});
    setIsModalOpen(!isModalOpen);
  };

  const deleteSessionHandler = (day, sessionIndex) => {
    let obj = qs.parse(qs.stringify(schedulesWithSlots));
    obj[day].ScheduleSlots.splice(sessionIndex, 1);
    setSchedulesWithSlots(obj);
  };

  useEffect(() => {
    if (!schedules?.length) return;
    const scheduleDefaultData = {};
    schedules.map((obj) => {
      scheduleDefaultData[obj?.date] = {
        scheduleSlots: [],
      };
    });
    dispatch(saveScheduleDetails({ ...scheduleDefaultData, ...schedulesWithSlots }));
  }, [schedulesWithSlots]);

  const { handleSubmit, control, touched, errors, watch, register, reset } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });

  useEffect(() => {
    if (!editSession) return;
    if (!Object.keys(editSession).length) return;
    manageActionHandler();
    setDefaultTimeValue(editSession);
    setSelectedTimeValue(true);
    reset(editSession);
  }, [qs.stringify(editSession)]);

  const handleFormSubmit = handleSubmit((values) => {
    values.from = dayjs(values.from).format('YYYY-MM-DDTHH:mm:ss');
    values.to = dayjs(values.to).format('YYYY-MM-DDTHH:mm:ss');
    const prevValue = schedulesWithSlots[keyOfDay]?.ScheduleSlots || [];
    let obj = {};
    let updatedSession = [];

    if (editSession?.index + 1) {
      let editedValues = [...prevValue];
      editedValues[editSession.index] = values;
      updatedSession = editedValues;
    } else {
      updatedSession = [...prevValue, values];
    }
    obj = {
      [keyOfDay]: {
        ScheduleSlots: [...updatedSession],
      },
    };

    setSchedulesWithSlots({ ...schedulesWithSlots, ...obj });
    dispatch(saveScheduleDetails({ ...schedulesWithSlots, ...obj }));
    setIsModalOpen(false);
    setEditSession(null);
    setKeyOfDay(null);
    manageActionHandler();
  });

  useEffect(() => {
    if (schedules?.length) {
      dispatch(saveEmptyScheduleDate(schedules));
    }
  }, [schedules]);

  const formProps = {
    handleFormSubmit,
    handleSubmit,
    control,
    touched,
    errors,
    watch,
    register,
    defaultTimeValue,
    selectedTimeValue,
  };

  return (
    <>
      <div className="schedule_event_section bg-grey-100 border-radius">
        <div className="px-4 d-flex justify-content-between align-items-center">
          <div>
            <DoctTypography variant="h6" className="text-grey-800 pt-3 speaker_presenter_text">
              Schedule
            </DoctTypography>
            <DoctTypography
              variant="textLabel1"
              className="text-grey-400 mt-1 speaker_presenter_text"
            >
              {schedules?.length} days event
            </DoctTypography>
            <div className="horizontal_line_grey_schedule position-relative mt-3 mb-1 ml-5"></div>
            <FormGroup className="" title="">
              <DoctFileUpload
                uploadTitle="Upload detailed schedule/ itinerary here."
                uploadMaxFilesMessage="Upload document in PDF, DOC, JPG, JPEG, PNG formats up to 5 MB size."
                maxFiles={1}
                uploadedFiles={prepareUploadedFile(
                  resetScheduleDocumentUrl && resetScheduleDocumentUrl,
                  uploadedFiles && uploadedFiles,
                )}
                setUploadedFiles={(uploadedFile) => {
                  if (!uploadedFile?.length) {
                    setResetScheduleDocumentUrl(null);
                    setDeleteScheduleDocumentUrl(true);
                    setUploadedFiles(null);
                  } else {
                    const fileExtension = uploadedFile[0]?.name?.toString()?.split('.')[1];
                    if (
                      !['jpeg', 'jpg', 'png', 'pdf', 'docx'].includes(fileExtension.toLowerCase())
                    ) {
                      dispatch(
                        setShowToasterWithParams({
                          variant: 'danger',
                          position: TOST_TOP_RIGHT,
                          message: 'Invalid File Type!',
                        }),
                      );
                      return;
                    }
                    setUploadedFiles(uploadedFile);
                  }
                }}
                accept=".jpeg, .jpg, .png, .pdf, .docx"
                maxFileSizeInMb={5}
              />
            </FormGroup>
            <div className="horizontal_line_grey_schedule position-relative mt-3 mb-4 ml-5"></div>
            <DoctTypography variant="body2" className="text-grey-600 m-0 speaker_presenter_text">
              Add sessions as per your plan for the event day.
            </DoctTypography>
          </div>
        </div>
        <div className="px-1 pt-3 pb-1">
          {schedules.map((item) => {
            return (
              <ScheduleSessionCard
                key={item?.key}
                day={item.day}
                date={item.date}
                manageActionHandler={manageActionHandler}
                keyOfDay={item.date}
                setNameOfDay={setNameOfDay}
                setKeyOfDay={setKeyOfDay}
                sessions={schedulesWithSlots[item?.date]?.ScheduleSlots || []}
                setEditSession={setEditSession}
                deleteSessionHandler={deleteSessionHandler}
              />
            );
          })}
        </div>
      </div>
      <DoctModal
        iconName={''}
        primaryBtnText={'Save'}
        open={isModalOpen}
        className={'disable_modal_outside_click'}
        handlePrimaryButtonClick={() => {
          handleFormSubmit();
          setSelectedTimeValue(false);
        }}
        title={`Add Session to ${nameOfDay}`}
        width={360}
        handleClose={() => {
          setSelectedTimeValue(false);
          manageActionHandler();
          setEditSession(null);
          setKeyOfDay(null);
        }}
      >
        <ManageScheduleSession {...formProps} />
      </DoctModal>
      <DoctModal
        showFooter={false}
        open={isOpenModalOfFileLimit}
        handleClose={() => setIsOpenModalOfFileLimit(false)}
      >
        <div>You can upload 1 document only</div>
      </DoctModal>
    </>
  );
}
