import React from 'react';
import { DoctModal } from '@doct-react/app';

export default function InfoModalWrapper({ isOpenModal, setIsOpenModal }) {
  return (
    <>
      {isOpenModal && (
        <DoctModal
          width={345}
          title="Attention"
          className="disable_modal_outside_click doct-modal-extra-padding"
          showFooter={false}
          open={isOpenModal}
          handleClose={() => {
            setIsOpenModal(false);
          }}
        >
          Your Account Status is Pending, Kindly Active your Account..
        </DoctModal>
      )}
    </>
  );
}
