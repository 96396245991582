import React from 'react';
import { DoctButton, DoctTypography } from '@doct-react/core';
import NoSubscription from '../../assets/images/Data-Not-Found.webp';
import CommonSubscriptionCard from './CommonSubscriptionCard/CommonSubscriptionCard';
import { useGetMySubscriptionsQuery } from './API/subscriptionApi';
import { DoctAnimationLoading } from '@doct-react/app';
import useSendSubscription from './hooks/useSendSubscription';
import { DASHBOARD } from '../../routes/constant';
import dayjs from 'dayjs';
import style from './SubscriptionModal.module.scss';

export default function MySubscriptionsLayout() {
  const { data: myPackages, isLoading } = useGetMySubscriptionsQuery();
  const { navigate } = useSendSubscription();

  if (isLoading) {
    return (
      <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
        <DoctAnimationLoading />
      </div>
    );
  }

  return myPackages?.length > 0 ? (
    <>
      {myPackages?.map((subscriptionPlan) => {
        const startDate = dayjs(subscriptionPlan?.startDate);
        const expiryDate = dayjs(subscriptionPlan?.expiryDate);
        // const currentDate = dayjs();

        // const isExpired = expiryDate.isBefore(currentDate);
        const formattedStartDate = startDate.format('D MMM YYYY');
        const formattedExpiryDate = expiryDate.format('D MMM YYYY');

        const date =
          subscriptionPlan?.planName === 'Institute Free Plan'
            ? formattedStartDate
            : `${formattedStartDate} to ${formattedExpiryDate}`;
        return (
          <CommonSubscriptionCard
            key={subscriptionPlan?.id}
            date={date}
            packageName={subscriptionPlan?.packageName}
            cardTitle={subscriptionPlan?.packageName}
            planName={subscriptionPlan?.planName}
            yearValidity={subscriptionPlan?.validity}
            amount={subscriptionPlan?.totalAmount}
            isExpired={formattedExpiryDate}
            orderId={subscriptionPlan?.orderId}
            status={subscriptionPlan?.status}
          />
        );
      })}
    </>
  ) : (
    <div
      className={`${style['no-subscription-screen']} d-flex align-items-center justify-content-center text-center`}
    >
      <div className="d-block">
        <img
          src={NoSubscription}
          alt="subscribe"
          width={200}
          height={200}
          className={`${style['no-subscription-img']}`}
        />
        <DoctTypography variant="subtitle1" className="text-grey-600 mb-0">
          No subscriptions
        </DoctTypography>
        <DoctTypography variant="subtitle2" className="text-grey-600 mt-2 font-weight-regular">
          You have not purchased any packages yet.
        </DoctTypography>
        <div className="d-flex justify-content-center">
          <DoctButton
            text="View Packages"
            variant="outlined"
            className={`${style['view-packages-button']}`}
            size="medium"
            onButtonClickHandler={() =>
              navigate(`/${DASHBOARD}/billing-subscription/subscription/all-packages`)
            }
          />
        </div>
      </div>
    </div>
  );
}
