import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectArchiveModal,
  selectShowDesignationModal,
  selectSubCommitteeModal,
} from './slice/committee.slice';

import { AddEditDesignation } from './AddEditDesignation';
import { AddEditSubCommittee } from './AddEditSubCommittee';
import { AddEditArchive } from './AddEditArchive';

import DefaultStateCommittee from './components/DefaultState.Committee';

export default function CommitteeContainer() {
  const showDesignationModal = useSelector(selectShowDesignationModal);
  const subCommitteeModal = useSelector(selectSubCommitteeModal);
  const showArchiveModal = useSelector(selectArchiveModal);
  return (
    <>
      <DefaultStateCommittee />
      {showDesignationModal && <AddEditDesignation />}
      {subCommitteeModal && <AddEditSubCommittee />}
      {showArchiveModal && <AddEditArchive />}
    </>
  );
}
