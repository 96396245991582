import { useCallback } from 'react';
import fieldProfileSetting from '../field.profileSettings';

export default function useHookDefaultValue() {
  const prepareFormValue = useCallback((data) => {
    return {
      [fieldProfileSetting.fullName.name]: data?.[fieldProfileSetting.fullName.apiValueAccessBy],
      [fieldProfileSetting.designation.name]:
        data?.[fieldProfileSetting.designation.apiValueAccessBy] || '',
    };
  }, []);

  return { prepareFormValue };
}
