import React, { useEffect, useState } from 'react';
import { DoctAutoComplete, DoctChip, DoctFileUpload, DoctModal } from '@doct-react/app';
import { DoctCol, DoctIconButton, DoctRow, DoctTypography } from '@doct-react/core';
import { useDispatch } from 'react-redux';
import useFieldOptions from '../../../../../../hooks/useFieldOptions/useFieldOptions';
import TextEditor from '../../../../../../shared/form/TextEditor/TextEditor';
import { prepareUploadedFile } from '../../../../../../helper/helperFunction';
import { setShowToasterWithParams } from '../../../../../featuresSlice';
import { TOST_TOP_RIGHT } from '../../../../../../constants/toasterPosition.constants';

const AdditionalInfo = ({
  control,
  error,
  watch,
  minimumEducationArr,
  setMinimumEducationArr,
  setMinimumEducationIdArr,
  minimumEducationIdArr,
  uploadedBrochure,
  setUploadedBrochure,
  setValue,
  setResetBrochureUrl,
  resetBrochureUrl,
  setDeleteBrochureUrl,
  courseIsFetching,
}) => {
  const dispatch = useDispatch();
  const { optionsArray: minimumEducationOptions } = useFieldOptions({
    apiRoute: '/qualifications/names',
  });

  const [isOpenModalOfFileLimit, setIsOpenModalOfFileLimit] = useState(false);

  const watchMinmumEducation = watch('minimumEducation');

  const addMinimumEducationToArr = () => {
    if (watchMinmumEducation && !minimumEducationArr?.includes(watchMinmumEducation)) {
      setMinimumEducationArr((prevState) => [...prevState, watchMinmumEducation]);
      setMinimumEducationIdArr((prevState) => [...prevState, watchMinmumEducation?.id]);
    }
  };

  const removeEducationFromArr = (educationToRemove) => {
    setMinimumEducationArr(
      minimumEducationArr?.filter((existingEducation) => existingEducation != educationToRemove),
    );
    setMinimumEducationIdArr(
      minimumEducationIdArr?.filter(
        (existingEducationId) => existingEducationId != educationToRemove?.id,
      ),
    );
  };

  const disableSelectedEducationArr = (qualification) => {
    return !!minimumEducationArr.find((element) => element?.value === qualification?.value);
  };

  useEffect(() => {
    if (courseIsFetching) {
      setValue('minimumEducation', null);
    }
    if (watchMinmumEducation?.length) {
      setValue('minimumEducation', null);
    }
  }, [courseIsFetching, watchMinmumEducation]);

  return (
    <>
      <div className="input-column">
        <DoctTypography variant="subtitle2">Minimum Education (optional)</DoctTypography>

        <DoctRow>
          <DoctCol sm={11}>
            <DoctAutoComplete
              control={control}
              isErrors={error}
              id={'minimumEducation'}
              name={'minimumEducation'}
              label={'Select'}
              options={minimumEducationOptions}
              variant="standard"
              getOptionDisabled={(option) => {
                return disableSelectedEducationArr(option);
              }}
            />
          </DoctCol>

          <DoctCol sm={1}>
            <DoctIconButton
              onButtonClickHandler={(e) => {
                e.preventDefault();
                addMinimumEducationToArr();
                setValue('minimumEducation', '');
              }}
              icon="check"
              size="medium"
            />
          </DoctCol>

          {minimumEducationArr?.length > 0 &&
            minimumEducationArr?.map((education, i) => {
              return (
                <div key={i} className={`m-2 text-truncate-chip-width`}>
                  <DoctChip
                    title={education?.label}
                    onCloseHandler={() => {
                      removeEducationFromArr(education);
                    }}
                  />
                </div>
              );
            })}
        </DoctRow>
      </div>

      <div className="input-column">
        <DoctTypography variant="subtitle2">Eligibility Criteria (optional)</DoctTypography>
        <span className="text_editor_course">
          <TextEditor name="eligibility" placeholder="Define Criteria" control={control} />
        </span>
      </div>

      <div className="input-column">
        <DoctTypography variant="subtitle2">Course Overview (optional)</DoctTypography>
        <span className="text_editor_course">
          <TextEditor name="overview" placeholder="Course Overview" control={control} />
        </span>
      </div>

      <div className="input-column">
        <DoctTypography variant="subtitle2">Admission Process (optional)</DoctTypography>
        <span className="text_editor_course">
          <TextEditor name="admissionProcess" placeholder="Define Process" control={control} />
        </span>
      </div>

      <div className="input-column">
        <DoctTypography variant="subtitle2">Upload Brochure (optional)</DoctTypography>
        <DoctFileUpload
          uploadTitle="You can upload course details brochure for download."
          uploadMaxFilesMessage="Upload document in JPG, JPEG, PNG, PDF, DOC formats up to 5 MB size."
          maxFiles={1}
          uploadedFiles={prepareUploadedFile(
            resetBrochureUrl && resetBrochureUrl,
            uploadedBrochure && uploadedBrochure,
          )}
          setUploadedFiles={(uploadedFile) => {
            if (!uploadedFile?.length) {
              setResetBrochureUrl(null);
              setDeleteBrochureUrl(true);
              setUploadedBrochure(null);
            } else {
              const fileExtension = uploadedFile[0]?.name?.toString()?.split('.')[1];
              if (!['jpeg', 'jpg', 'png', 'pdf', 'docx'].includes(fileExtension.toLowerCase())) {
                dispatch(
                  setShowToasterWithParams({
                    variant: 'danger',
                    position: TOST_TOP_RIGHT,
                    message: 'Invalid File Type!',
                  }),
                );
                return;
              }
              setUploadedBrochure(uploadedFile);
            }
          }}
          accept=".pdf, .png, .jpg, .jpeg, .docx"
          maxFileSizeInMb={5}
        />
      </div>
      <DoctModal
        showFooter={false}
        open={isOpenModalOfFileLimit}
        handleClose={() => setIsOpenModalOfFileLimit(false)}
      >
        <div>You can upload 1 document only</div>
      </DoctModal>
    </>
  );
};

export default AdditionalInfo;
