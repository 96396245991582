import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnterpriseFormLayout } from '../../../../layout';
import CloseFormModal from '../../../../shared/ui/CloseFormModal';
import EnterpriseFormCard from '../../../../shared/ui/EnterpriseSectionCard/EnterpriseForm.card';
import { PreviewCourse } from '../PreviewCourse';
import { useTenantCourses } from './hook/useTenantCourses';
import './manageCourse.styles.scss';
import * as ROUTE from '../../../../routes/constant';
import {
  setAffiliation,
  setBrochureUrl,
  setEditTimeApplyType,
  setFaculties,
  setIdStoreAtTime,
  setInstituteCourseFormValues,
  setIntakes,
  setRawFormValues,
  setSelectMinimumEducation,
} from './ManageCourseSlice';
import { useDispatch } from 'react-redux';

const ManageCourseForm = () => {
  const {
    courseFormParts,
    handleCourseFormSubmit,
    isPreview,
    courseDetails: courseDetailFromState,
    handleOnPreviewApproved,
    setIsPreview,
    isAdding,
    isUpdatingCourse,
    errorMessage,
    rawFormValues,
    setFromUpperBtnClicked,
    setIsEditTimeEmailValue,
  } = useTenantCourses();
  const [openExitModal, setOpenExitModal] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <EnterpriseFormLayout
      formHeaderSecondaryButtonClickHandler={() => {
        handleCourseFormSubmit({ status: 'Draft' });
      }}
      closeHandler={() => {
        setOpenExitModal(true);
      }}
      handleSubmit={() => {
        if (!isPreview) {
          handleCourseFormSubmit();
          setFromUpperBtnClicked(true);
        } else {
          handleOnPreviewApproved();
        }
      }}
      formSecondaryBtnText={'Save Draft'}
      formTitle="List a Course"
      formDisclaimer={"By submitting course, you are agree to Docthub's Policy and Terms of use."}
      formPrimaryBtnText={!isPreview ? 'Preview & Post' : 'Submit Post'}
      primaryButtonType={isPreview ? 'semantic-success' : 'semantic-info'}
      showFormFooterSecondaryBtn={isPreview ? true : false}
      formFooterSecondaryBtnText="Back to Edit"
      formFooterSecondaryBtnType={'secondary'}
      formFooterSecondaryBtnClickHandler={() => {
        setFromUpperBtnClicked(false);
        setIsPreview(false);
      }}
      isAdding={isAdding || isUpdatingCourse}
      isUpdatingCourse={isUpdatingCourse}
      errorMessage={errorMessage}
    >
      <div className={isPreview ? 'd-none' : ''}>
        {courseFormParts.map((formPart, i) => {
          return <EnterpriseFormCard key={i} {...formPart} />;
        })}
      </div>
      <div className={isPreview ? '' : 'd-none'}>
        <PreviewCourse
          courseDetails={courseDetailFromState}
          addBgGrey={false}
          rawFormValues={rawFormValues}
        />
      </div>
      <CloseModal />
    </EnterpriseFormLayout>
  );

  function CloseModal() {
    return (
      <CloseFormModal
        open={openExitModal}
        handleClose={() => {
          setOpenExitModal(false);
        }}
        onConfirmExit={() => {
          dispatch(setRawFormValues(null));
          dispatch(setInstituteCourseFormValues({}));
          dispatch(setAffiliation(null));
          dispatch(setIntakes(null));
          dispatch(setSelectMinimumEducation(null));
          dispatch(setFaculties(null));
          dispatch(setBrochureUrl(null));
          dispatch(setIdStoreAtTime(null));
          dispatch(setEditTimeApplyType(null));
          setIsEditTimeEmailValue(false);
          navigate(`/${ROUTE.DASHBOARD}/${ROUTE.INSTITUTE}/${ROUTE.INSTITUTE_COURSES}`);
        }}
      />
    );
  }
};

export default ManageCourseForm;
