import * as ROUTE from '../../../routes/constant';

const profileActionBarData = {
  [`/${ROUTE.DASHBOARD}/${ROUTE.SETTINGS}/${ROUTE.PROFILE}`]: {
    pathname: `/${ROUTE.DASHBOARD}/${ROUTE.SETTINGS}/${ROUTE.PROFILE}`,
    title: 'Account Settings',
    navigateButtonLink: '/create',
    buttonIcon: 'plus',
    isButtonDisabled: true,
  },
};

export default profileActionBarData;
