import React, { useEffect, useState } from 'react';
import { DoctAnimationLoading } from '@doct-react/app';

import ArchiveCard from './components/Card.Committee/ArchiveCard';
import ViewArchiveCard from './components/Card.Committee/ViewArchiveCard';
import DeleteCommittee from './components/Common/Delete.Committee';

import { useDeleteArchiveMutation, useGetArchivesQuery } from './services/archive.services';
import { useGetDesignationsQuery } from './services/designation.services';
import { setShowToasterWithParams } from '../../featuresSlice';
import { TOST_BOTTOM_CENTER } from '../../../constants/toasterPosition.constants';
import { useDispatch } from 'react-redux';

const options = [
  {
    title: 'Rename Archive',
  },
  {
    title: 'Delete Archive',
    className: 'text-danger',
  },
];

export default function ArchivesCommittee() {
  const { data: archiveData, isLoading } = useGetArchivesQuery('', {
    refetchOnMountOrArgChange: true,
  });

  const dispatch = useDispatch();

  const [deleteArchive] = useDeleteArchiveMutation();

  const [isArchiveView, setIsArchiveView] = useState(false);
  const [storeArchiveIdWithName, setStoreArchiveIdWithName] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: designationData, isLoading: isDesignationLoading } = useGetDesignationsQuery(
    {
      archiveId: storeArchiveIdWithName?.id,
    },
    { refetchOnMountOrArgChange: true, skip: !storeArchiveIdWithName?.id },
  );

  useEffect(() => {
    if (archiveData?.length && !isLoading) {
      archiveData?.map((archive) => {
        if (archive.id == storeArchiveIdWithName.id) {
          setStoreArchiveIdWithName({ id: archive.id, name: archive.title });
        }
      });
    }
  }, [archiveData, isLoading]);

  if (isLoading) {
    return (
      <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
        <DoctAnimationLoading />
      </div>
    );
  }

  return (
    <>
      {isArchiveView ? (
        <ViewArchiveCard
          options={options}
          setIsArchiveView={setIsArchiveView}
          storeArchiveIdWithName={storeArchiveIdWithName}
          designationData={designationData}
          isDesignationLoading={isDesignationLoading}
          setIsModalOpen={setIsModalOpen}
        />
      ) : (
        <>
          {archiveData?.map((item, index) => (
            <ArchiveCard
              archive={item}
              key={index}
              options={options}
              setIsArchiveView={setIsArchiveView}
              setStoreArchiveIdWithName={setStoreArchiveIdWithName}
              setIsModalOpen={setIsModalOpen}
            />
          ))}
        </>
      )}
      <DeleteCommittee
        open={isModalOpen}
        primaryBtnText={'Yes, Delete'}
        featureText={'Archive'}
        name={storeArchiveIdWithName?.name}
        handleClose={() => {
          setStoreArchiveIdWithName({});
          setIsModalOpen(false);
        }}
        handlePrimaryButtonClick={() => {
          deleteArchive({ id: storeArchiveIdWithName?.id });
          dispatch(
            setShowToasterWithParams({
              variant: 'neutral',
              position: TOST_BOTTOM_CENTER,
              message: 'Archive Deleted Successfully',
            }),
          );
          setIsModalOpen(false);
          setIsArchiveView(false);
        }}
      />
    </>
  );
}
