import React from 'react';

export function FieldWrapper({ children }) {
  return <div className="form_el">{children}</div>;
}

export function InputColumn({ children, className = '' }) {
  return <div className={`input-column ${className}`}>{children}</div>;
}

export function InputColumnTiny({ children }) {
  return <div className="input-column-tiny">{children}</div>;
}
