import { DoctButton, DoctContainer, DoctIconButton, DoctTypography } from '@doct-react/core';
import React from 'react';

const FormHeader = ({
  formTitle,
  formSecondaryBtnText,
  formHeaderSecondaryButtonClickHandler = () => {},
  isAdding,
  showSecondaryHeaderBtn,
  iconName,
  closeHandler = () => {},
}) => {
  return (
    <div className="bg-grey-100 enterprise_form_header_container p-2">
      <DoctContainer>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-start">
            <DoctIconButton
              onButtonClickHandler={(e) => {
                e.preventDefault();
                closeHandler();
              }}
              icon={iconName ? iconName : 'close'}
              size="large"
              variant="text"
              type="secondary"
            />
            <DoctTypography variant="textLabel1" className="font-weight-bold mx-1">
              Subscription: {formTitle}
            </DoctTypography>
          </div>

          {showSecondaryHeaderBtn === false ? (
            ''
          ) : (
            <div className={!formSecondaryBtnText && 'd-none'}>
              <DoctButton
                onButtonClickHandler={(e) => {
                  e.preventDefault();
                  formHeaderSecondaryButtonClickHandler();
                }}
                variant="contained"
                size="medium"
                text={formSecondaryBtnText}
                disabled={isAdding}
                icon="downloadOne"
                iconPosition="left"
                className="invoice-button-bg"
              />
            </div>
          )}
        </div>
      </DoctContainer>
    </div>
  );
};

export default FormHeader;
