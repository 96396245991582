import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Toaster } from '../shared';
import { setShowToasterWithParams, showToasterWithPropsState } from '../features';

import { TOST_BOTTOM_CENTER, TOST_TOP_RIGHT } from '../constants/toasterPosition.constants.js';

function ToastWrapper() {
  const dispatch = useDispatch();

  const showToasterWithProps = useSelector(showToasterWithPropsState);

  const [classes, setClasses] = useState();

  const { variant, position, message } = showToasterWithProps;

  const getClassName = useCallback(() => {
    switch (position) {
      case TOST_TOP_RIGHT:
        setClasses('top_right');
        break;

      case TOST_BOTTOM_CENTER:
        setClasses('top_bottom');
        break;

      default:
        setClasses('top_right');
        break;
    }
  }, [position]);

  const onPressedClose = useCallback(() => {
    dispatch(setShowToasterWithParams(null));
  }, []);

  useLayoutEffect(() => {
    getClassName();
  }, [position]);

  if (!showToasterWithProps?.message) return null;

  setTimeout(() => {
    dispatch(setShowToasterWithParams(null));
  }, 3000);

  return (
    <div className={`position-fixed tost-container ${classes}`}>
      <Toaster
        variant={variant || 'neutral'}
        text={message || ''}
        onPressedClose={onPressedClose}
      />
    </div>
  );
}

export default ToastWrapper;
