import React from 'react';
import { DoctContainer, DoctTypography } from '@doct-react/core';
import useFindQualificationNames from '../utils/findQualificationNames';

const JobDetail = ({ jobPreviewDetail = {} }) => {
  const {
    description,
    qualificationIds,
    keySkills,
    benefits,
    organizationContactAndAddressDetails,
    aboutOrganization,
  } = jobPreviewDetail;
  const { findQualificationNames } = useFindQualificationNames();

  return (
    <>
      <div className="my-3">
        <DoctContainer>
          <div className="bg-white border-radius text-word-break">
            <div className="p-3">
              {description && (
                <>
                  <div className="my-3">
                    <DoctTypography variant="subtitle1">Description</DoctTypography>
                    <DoctTypography variant="body2">
                      <div
                        dangerouslySetInnerHTML={{ __html: description }}
                        className="preview_page_contents"
                      />
                    </DoctTypography>
                    <div className="section_divider"></div>
                  </div>
                </>
              )}

              {qualificationIds?.length > 0 && (
                <>
                  <div className="section_divider"></div>
                  <div className="my-3">
                    <DoctTypography variant="subtitle1">Preferred Education</DoctTypography>
                    <DoctTypography variant="textLabel1">
                      <>
                        {qualificationIds?.map((qualificationId, i) => (
                          <>
                            <DoctTypography
                              key={i}
                              variant="input"
                              className="doct-outline-pills mx-1 d-inline-block mb-1"
                            >
                              {findQualificationNames(qualificationId)}
                            </DoctTypography>
                          </>
                        ))}
                      </>
                    </DoctTypography>
                    <div className="section_divider"></div>
                  </div>
                </>
              )}

              {keySkills?.length > 0 && (
                <div className="my-3">
                  <DoctTypography variant="subtitle1">Key Skills</DoctTypography>
                  {keySkills?.map((skill, i) => (
                    <DoctTypography
                      key={i}
                      variant="input"
                      className="doct-outline-pills mx-1 d-inline-block mb-1"
                    >
                      {skill}
                    </DoctTypography>
                  ))}
                  <div className="section_divider mt-3"></div>
                </div>
              )}

              {benefits && (
                <>
                  <DoctTypography variant="subtitle1">Benefits</DoctTypography>
                  <DoctTypography variant="body2">
                    <div dangerouslySetInnerHTML={{ __html: benefits }} />
                  </DoctTypography>
                </>
              )}
            </div>
          </div>
        </DoctContainer>
      </div>

      <div className="my-3 pb-5 mb-5">
        <DoctContainer>
          <div
            className={
              aboutOrganization || organizationContactAndAddressDetails
                ? `bg-white border-radius`
                : ''
            }
          >
            <div className="p-3">
              {aboutOrganization && (
                <>
                  <DoctTypography variant="subtitle1">About Organization</DoctTypography>
                  <DoctTypography variant="body2" className="text-grey-600">
                    <div dangerouslySetInnerHTML={{ __html: aboutOrganization }} />
                  </DoctTypography>
                  <div className="section_divider"></div>
                </>
              )}

              {organizationContactAndAddressDetails && (
                <>
                  <DoctTypography variant="subtitle1">Contact Info</DoctTypography>
                  <DoctTypography variant="body2" className="text-grey-600">
                    <div className="d-flex">
                      Address : &nbsp; &nbsp;
                      <div
                        dangerouslySetInnerHTML={{ __html: organizationContactAndAddressDetails }}
                      />
                    </div>
                  </DoctTypography>
                </>
              )}
            </div>
          </div>
        </DoctContainer>
      </div>
    </>
  );
};

export default JobDetail;
