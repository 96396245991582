import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useFormCommon from '../../../../shared/form/useForm.Common';
import { postArchive, putArchive } from '../services/archive.services';
import {
  resetState,
  selectArchiveModal,
  setArchiveId,
  toggleArchiveModal,
  selectIsAddOrUpdating,
} from '../slice/committee.slice';

import FormAddEditArchive from './Form.AddEditArchive';

function AddEditArchiveWrapper({
  defaultValues = {},
  modalContainer: ModalContainer,
  id,
  primaryBtnText,
}) {
  const dispatch = useDispatch();

  const showArchiveModal = useSelector(selectArchiveModal);
  // const loaderForApiCall = useSelector(selectLoaderInApiCall);
  const isAddOrUpdating = useSelector(selectIsAddOrUpdating);

  const {
    formProps: { handleSubmit, control, errors, setValue, watch, getValues },
  } = useFormCommon({ defaultValues });

  const formProps = { control, isErrors: errors, setValue, watch, getValues };

  const onSubmitForm = handleSubmit((values) => {
    if (id) {
      dispatch(putArchive({ id, data: values, handleModalClose }));
    } else {
      dispatch(postArchive({ data: values, handleModalClose }));
    }
  });

  const handleModalClose = useCallback(() => {
    if (showArchiveModal) {
      dispatch(toggleArchiveModal());
      dispatch(resetState());
      dispatch(setArchiveId(null));
    }
  }, []);

  const modalProps = { onSubmitForm, handleModalClose, primaryBtnText };

  return (
    <ModalContainer {...modalProps} isOpen={showArchiveModal} isPrimaryBtnDisable={isAddOrUpdating}>
      <FormAddEditArchive formProps={formProps} />
    </ModalContainer>
  );
}

export default AddEditArchiveWrapper;
