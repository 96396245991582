import { DoctButton } from '@doct-react/core';
import React from 'react';

const AddNewMemberFormFooter = ({ newJobLoading }) => {
  return (
    <DoctButton
      className="float-right my-3 mx-3"
      size="medium"
      text="Submit"
      disabled={newJobLoading}
    />
  );
};

export default AddNewMemberFormFooter;
