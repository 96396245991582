import React from 'react';

import { DoctTextField } from '@doct-react/app';
import { FormElWithHeading } from '../../../../shared';
import fieldCommittee from '../components/Common/field.Committee';
import CommitteeMembers from './Members.Committee';

export default function FormAddDesignation({ formProps, isMembersEmpty }) {
  return (
    <>
      <FormElWithHeading heading={fieldCommittee.designationName.label}>
        <DoctTextField
          {...formProps}
          name={fieldCommittee.designationName.keyOfGetApi}
          label="For example: General Manager"
          validationRules={fieldCommittee.designationName.validationRules}
          id={fieldCommittee.designationName.name}
          showStar={false}
        />
      </FormElWithHeading>
      <CommitteeMembers isMembersEmpty={isMembersEmpty} />
    </>
  );
}
