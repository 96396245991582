import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useFieldOptions from '../../../../../../hooks/useFieldOptions/useFieldOptions';
import {
  selectRawAffiliation,
  selectRawFaculties,
  selectRawintakes,
  selectRawSelectMinimumEducation,
} from '../../ManageCourseSlice';
import { returnTransformedResetObject } from '../getResetObj';

const useResetOnEdit = ({
  courseDataById,
  setIntakesArr,
  setFacultiesArr,
  setMinimumEducationArr,
  setAffiliations,
  setCurrentApplyType,
}) => {
  const resetFromObjOnEdit = { ...courseDataById };

  const { optionsArray: minimumEducationData } = useFieldOptions({
    apiRoute: '/qualifications/names',
  });

  const { optionsArray: affiliationData } = useFieldOptions({
    courseCenterRoutes: '/affiliations',
  });
  const selectAffiliation = useSelector(selectRawAffiliation);
  const selectIntakes = useSelector(selectRawintakes);
  const selectMinimumEducation = useSelector(selectRawSelectMinimumEducation);
  const selectFaculties = useSelector(selectRawFaculties);

  useEffect(() => {
    if (selectFaculties?.length > 0) {
      setFacultiesArr(selectFaculties);
    } else if (courseDataById?.faculties) {
      setFacultiesArr(courseDataById?.faculties);
    } else {
      setFacultiesArr([]);
    }
    if (selectIntakes?.length > 0) {
      setIntakesArr(selectIntakes);
    } else if (courseDataById?.intakes) {
      setIntakesArr(courseDataById?.intakes);
    } else {
      setIntakesArr([]);
    }
    if (courseDataById?.applyType) {
      setCurrentApplyType(courseDataById?.applyType);
    }
    if (selectMinimumEducation?.length > 0) {
      setMinimumEducationArr(selectMinimumEducation);
    } else if (minimumEducationData && courseDataById?.qualificationIds) {
      const educationChipsData = [];
      courseDataById?.qualificationIds?.map((qualificationId) => {
        educationChipsData.push(
          minimumEducationData.find((education) => {
            return education?.id == qualificationId;
          }),
        );

        if (educationChipsData[0] != undefined) {
          setMinimumEducationArr(educationChipsData);
        }
      });
    } else {
      setMinimumEducationArr([]);
    }
    if (selectAffiliation?.length > 0) {
      setAffiliations(selectAffiliation);
    } else if (affiliationData && courseDataById?.affiliationIds) {
      const affiliationChipData = [];
      courseDataById?.affiliationIds?.map((affiliationId) => {
        const findData = affiliationData.find((affiliation) => {
          return affiliation?.id == affiliationId;
        });

        affiliationChipData.push(findData);

        if (affiliationChipData[0] != undefined) {
          setAffiliations(affiliationChipData);
        }
      });
    } else {
      setAffiliations([]);
    }
  }, [courseDataById, minimumEducationData, affiliationData]);

  if (courseDataById) {
    resetFromObjOnEdit['courseTitleId'] = returnTransformedResetObject({
      resultObj: courseDataById,
      deepKey: 'courseTitle',
    });

    resetFromObjOnEdit['courseTypeId'] = returnTransformedResetObject({
      resultObj: courseDataById,
      deepKey: 'courseType',
    });

    resetFromObjOnEdit['specialtyId'] = returnTransformedResetObject({
      resultObj: courseDataById,
      deepKey: 'specialty',
    });

    if (courseDataById?.durationType) {
      resetFromObjOnEdit['durationType'] = returnTransformedResetObject({
        resultObj: courseDataById,
        deepKey: 'durationType',
      });
    }
    if (courseDataById?.feesType) {
      resetFromObjOnEdit['feesType'] = returnTransformedResetObject({
        resultObj: courseDataById,
        deepKey: 'feesType',
      });
    }

    if (courseDataById.accomodation) {
      resetFromObjOnEdit['accomodation'] = {
        label: courseDataById.accomodation?.replace(/([A-Z])/g, ' $1').trim(),
        value: courseDataById.accomodation,
      };
    }

    resetFromObjOnEdit['disciplineId'] = {
      label: courseDataById?.specialty?.discipline,
      id: courseDataById?.specialty?.disciplineId,
      value: courseDataById?.specialty?.disciplineId,
    };

    resetFromObjOnEdit['intakes'] = null;
  }

  return { resetFromObjOnEdit };
};

export default useResetOnEdit;
