import * as ROUTE from '../../../routes/constant';

const activityLogActionBar = {
  [`/${ROUTE.DASHBOARD}/${ROUTE.MANAGE_MEMBERSHIPS}/${ROUTE.ACTIVITY_LOG}`]: {
    pathname: `${ROUTE.ACTIVITY_LOG}/${ROUTE.ADD_ROUTE}`,
    title: 'Memberships Management',
    buttonText: 'New Activity',
    buttonIcon: 'plus',
    isButtonDisabled: false,
    navigateButtonLink: `/${ROUTE.DASHBOARD}/${ROUTE.MANAGE_MEMBERSHIPS}/${ROUTE.ACTIVITY_LOG}/${ROUTE.ADD_ROUTE}`,
  },
};

export default activityLogActionBar;
