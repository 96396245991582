import React, { useState } from 'react';
import { DoctModal } from '@doct-react/app';
import { DoctButton, DoctTypography } from '@doct-react/core';
import { useNavigationActivityLog } from '../hooks';
import { useNotifySuccessFailed } from '../../../../hooks';
import LayoutLoading from '../../../../layout/Layout.loading';
import NoDataFound from '../../../../shared/ui/NoDataFound';
import { DetailsActivityLog } from './DetailsActivityLog';
import { CardActivityLog } from '.';
import { useDeleteActivityLogMutation } from '../services/activityLog.services';

export default function ListingActivityLog({ isLoading, activities }) {
  const [selectedActivity, setSelectedActivity] = useState(null);

  const { navigateToEdit } = useNavigationActivityLog();

  const [deleteActivityLog, { isLoading: isLoadingDeleteActivityLog, isSuccess, isError }] =
    useDeleteActivityLogMutation();

  const initDeleteActivityLogMutation = (id) => {
    deleteActivityLog({ id });
  };

  useNotifySuccessFailed({
    isSuccess,
    isError,
    successNotifyMsg: 'Activity Deleted Successfully',
    onSuccessHandler: () => {
      setSelectedActivity(null);
    },
  });

  const cardMenuOptions = ({ title }, id, activity) => {
    switch (title) {
      case 'Edit':
        navigateToEdit(id);
        break;
      case 'Delete':
        setSelectedActivity({ id, modalName: 'DELETE', activity });
        break;
      case 'View':
        setSelectedActivity({ activity, modalName: 'VIEW' });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <LayoutLoading isLoading={isLoading}>
        <>
          {activities &&
            activities?.map((activity) => {
              return (
                <CardActivityLog
                  activity={activity}
                  key={activity?.id}
                  cardMenuOptions={cardMenuOptions}
                />
              );
            })}
          {!activities?.length && <NoDataFound />}
        </>
      </LayoutLoading>
      <DoctModal
        title={selectedActivity?.activity?.title}
        width={572}
        showFooter={false}
        className="disable_modal_outside_click"
        open={selectedActivity?.modalName == 'VIEW'}
        handleClose={() => setSelectedActivity(null)}
      >
        <DetailsActivityLog
          activity={selectedActivity?.activity}
          cardMenuOptions={cardMenuOptions}
        />
      </DoctModal>
      <DoctModal
        width={328}
        open={selectedActivity?.modalName == 'DELETE'}
        showFooter={false}
        className="hide-modal-header white-body-modal"
      >
        <div className="m-2">
          <DoctTypography variant="h6" className="mt-0 mb-12px">
            Are you sure to delete this activity?
          </DoctTypography>
          <DoctTypography variant="body2" className="text-grey-600 mt-0 mb-4">
            {`Know that activity “${selectedActivity?.activity?.title}” will be deleted, you cannot undo this
              action.`}
          </DoctTypography>

          <div className="d-flex justify-content-end">
            <DoctButton
              variant="contained"
              type="secondary"
              text="Cancel"
              size="medium"
              className="mr-2"
              onButtonClickHandler={() => setSelectedActivity(null)}
            />
            <DoctButton
              variant="contained"
              type="semantic-danger"
              text="Yes, Delete"
              size="medium"
              disabled={isLoadingDeleteActivityLog}
              onButtonClickHandler={() => initDeleteActivityLogMutation(selectedActivity?.id)}
            />
          </div>
        </div>
      </DoctModal>
    </>
  );
}
