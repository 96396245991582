import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useGetAffiliationAndCourseTitleDropDownOptionsQuery,
  useGetDropDownOptionsQuery,
} from './useFieldOption.service';

const useFieldOptions = ({
  apiRoute,
  searchText,
  optionObjectKey = 'label',
  courseCenterRoutes,
}) => {
  const [data, setData] = useState([]);

  const { data: masterDataDropDownOptions } = useGetDropDownOptionsQuery({ apiRoute, searchText });

  const { data: affiliationAndCourseTitleOptions, refetch } =
    useGetAffiliationAndCourseTitleDropDownOptionsQuery({
      courseCenterRoutes,
      searchText,
    });

  useEffect(() => {
    if (masterDataDropDownOptions) {
      setData(masterDataDropDownOptions);
    }
  }, [masterDataDropDownOptions]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (affiliationAndCourseTitleOptions) {
      setData(affiliationAndCourseTitleOptions);
    }
  }, [affiliationAndCourseTitleOptions]);

  const [optionsArray, setOptionsArray] = useState([]);

  const mutateOptions = useCallback(() => {
    data?.map((option) => {
      setOptionsArray((prevState) => [
        ...prevState,
        { [optionObjectKey]: option?.name, id: option?.id, value: option?.id },
      ]);
    });
  }, [data, searchText]);

  useMemo(() => {
    setOptionsArray([]);
    mutateOptions();
  }, [data, searchText]);

  return { optionsArray };
};

export default useFieldOptions;
