import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CommitteeModal from '../components/CommitteeModal';
import { useResetSubCommitteeAndArchiveForm } from '../hooks/committeeResetHookForm';

import AddEditSubCommitteeWrapper from './AddEditSubCommitteeWrapper';

import {
  selectSubCommitteeResponseById,
  selectLoaderInApiCall,
  selectSubCommitteeId,
  selectIsAddOrUpdating,
} from '../slice/committee.slice';
import { getSubCommitteeById } from '../services/subCommittee.services';

function EditSubCommittee({ id, primaryBtnText }) {
  const dispatch = useDispatch();
  const subCommitteeResponse = useSelector(selectSubCommitteeResponseById);
  const isLoading = useSelector(selectLoaderInApiCall);

  const { prepareApiToFormData } = useResetSubCommitteeAndArchiveForm();

  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(getSubCommitteeById(id));
    }
  }, [id]);

  useEffect(() => {
    if (!subCommitteeResponse) return;

    const handleDefaultValue = async () => {
      const apiToFormData = await prepareApiToFormData(subCommitteeResponse);
      setDefaultValues(apiToFormData);
    };
    handleDefaultValue();
  }, [subCommitteeResponse]);

  if (!isLoading && defaultValues) {
    return (
      <AddEditSubCommitteeWrapper
        modalContainer={CommitteeModal}
        defaultValues={defaultValues}
        id={id}
        primaryBtnText={primaryBtnText}
      />
    );
  }

  return (
    <CommitteeModal
      isLoading={isLoading}
      isOpen={true}
      primaryBtnText={primaryBtnText}
      isPrimaryBtnDisable={isLoading}
    />
  );
}

export default function AddEditSubCommittee() {
  const subCommitteeId = useSelector(selectSubCommitteeId);
  const primaryBtnText = 'Save';
  const isAddOrUpdating = useSelector(selectIsAddOrUpdating);

  if (subCommitteeId) {
    return <EditSubCommittee id={subCommitteeId} primaryBtnText={primaryBtnText} />;
  }
  return (
    <AddEditSubCommitteeWrapper
      modalContainer={CommitteeModal}
      primaryBtnText={primaryBtnText}
      isAddOrUpdating={isAddOrUpdating}
    />
  );
}
