import { DoctModal } from '@doct-react/app';
import { DoctButton, DoctTypography } from '@doct-react/core';
import React from 'react';

export default function StartAssigningPopUp({ open, handlePass }) {
  return (
    <DoctModal
      open={open}
      showFooter={false}
      className="white-body-modal modal-hidden disable_modal_outside_click hide-modal-header doct-modal-extra-padding"
    >
      <DoctTypography variant="h6" className="font-weight-bold">
        Your Certificate is Ready! Start Assigning to Awardees.
      </DoctTypography>
      <DoctTypography variant="body2" className="text-grey-600">
        There are multiple ways to assign this certificate to awardees.
      </DoctTypography>
      <DoctButton
        className="text-white float-right mt-1 bg-active-green"
        text="Okay"
        variant="contained"
        size="medium"
        onButtonClickHandler={handlePass}
      />
    </DoctModal>
  );
}
