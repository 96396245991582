import React, { useState } from 'react';
import './Accordion.scss';
import { DoctIcon, DoctTypography } from '@doct-react/core';

const Accordion = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`accordion ${expanded ? 'expanded' : ''}`}>
      <div onClick={handleToggle}>
        <DoctTypography
          variant="subtitle2"
          className={`m-0 text-grey-600 accordion-title d-flex align-items-center justify-content-between m-0 ${
            expanded && 'pb-0'
          }`}
        >
          {title}
          <DoctIcon
            name="down"
            fill="#AAAAAA"
            width="24"
            height="24"
            className={`expand-icon ${expanded ? 'expanded' : ''}`}
          ></DoctIcon>
        </DoctTypography>
      </div>
      {expanded && (
        <div className="accordion-subtitle-padding d-flex align-items-center">{children}</div>
      )}
    </div>
  );
};

export default Accordion;
