const fieldAddEditActivityLog = {
  title: {
    name: 'Title',
    label: 'Add title',
    keyOfGetApi: 'title',
    validationRules: {
      required: "It's Required Field",
    },
  },
  startDate: {
    name: 'StartDate',
    label: 'Date of Activity',
    keyOfGetApi: 'startDate',
    validationRules: {
      required: "It's Required Field",
    },
  },
  endDate: {
    name: 'EndDate',
    label: 'Date of Activity',
    keyOfGetApi: 'endDate',
    validationRules: {
      required: "It's Required Field",
    },
  },
  highlights: {
    label: 'Highlights (optional)',
    validationRules: {},
    name: 'Highlights',
    keyOfGetApi: 'highlights',
  },
  memberIds: {
    name: 'MemberIds',
  },
  supportedBy: {
    name: 'SupportedBy',
    validationRules: {},
    label: 'Supported by (optional)',
    keyOfGetApi: 'supportedBy',
  },
  coverImage: {
    validationRules: {},
    name: 'CoverImage',
    accessBy: 'coverImage',
  },
  coverImageUrl: {
    validationRules: {},
    name: 'CoverImageUrl',
    keyOfGetApi: 'coverImageUrl',
  },
  deleteCoverImage: {
    validationRules: {},
    name: 'DeleteCoverImage',
  },
  organizingTeam: {
    name: 'OrganizingTeam',
    validationRules: {},
    label: 'Organizing Team (optional)',
    accessBy: 'organizingTeam',
    keyOfGetApi: 'members',
  },
};

export default fieldAddEditActivityLog;
