import { DoctForm } from '@doct-react/app';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEventById,
  saveApiData,
  saveBasicInfoDetails,
  saveCapacityUsed,
  saveMaxAttendees,
  saveSaveAsDraft,
  selectAttendeeValue,
  selectBasicInfoDetails,
  selectCreateEventResponse,
  selectCurrentStep,
  selectResponseData,
  selectSaveAsDraftClicked,
  selectUpdateEventLoader,
  setCurrentStep,
  setDefaultState,
  setStepsFormData,
} from '../../../createEvent.slice';
import stepsName from '../stepsName';
import dayjs from 'dayjs';

import { useNavigate, useParams } from 'react-router-dom';
import { postCreateEvent, updateCreateEvent } from '../../../services/CreateEventServices';
import {
  getSpecialtyNames,
  getSubjectTagsNames,
} from '../Registration/services/EventRegistrationSectionService';
import { EventStatus } from '../../../../../../helper/enums/eventStatus';

const defaultValue = {
  country: {
    label: 'India',
    value: 1,
  },
};

export default function useFormBasicInfo() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const BasicInfoDetails = useSelector(selectBasicInfoDetails);
  const apiResponseData = useSelector(selectCreateEventResponse);
  const createdEventResponse = useSelector(selectResponseData);
  const loading = useSelector(selectUpdateEventLoader);
  const draftBtnClicked = useSelector(selectSaveAsDraftClicked);
  const navigate = useNavigate();
  const defaultmaxAttendees = useSelector(selectAttendeeValue);

  const [Participants, setParticipants] = useState('Public');
  const [numberOfGathering, setNumberOfGathering] = useState('Limited');
  const [registrationType, setRegistrationType] = useState('Paid');
  const [locationValue, setLocationValue] = useState(null);
  const [successfullHandleSubmit, setSuccessfullHandleSubmit] = useState(false);
  const [checkStartDate, setCheckStartDate] = useState(false);
  const [showTost, setShowTost] = useState(false);
  const [registrationProcessForReset, setRegistrationProcessForReset] = useState(null);

  const [specialityArray, setSpecialityArray] = useState([]);
  const [specialtyDataOption, setSpecialtyDataOption] = useState();
  const [subjectTagsArray, setSubjectTagsArray] = useState([]);
  const [subjectTagsDataOption, setSubjectTagsDataOption] = useState();

  const selectedSpecialityId = [];
  const selectedSubjectTagId = [];

  useEffect(() => {
    getSpecialtyNames().then((data) => {
      setSpecialtyDataOption(data);
    });

    getSubjectTagsNames().then((data) => {
      setSubjectTagsDataOption(data);
    });
  }, []);

  // select id of selectedSpeciality by name

  const findSpecialityByName = (selectedSpeciality) => {
    const foundSpeciality = specialtyDataOption?.filter((speciality) => {
      return speciality?.title == selectedSpeciality;
    });

    foundSpeciality?.map((item) => {
      selectedSpecialityId.push(item?.id);
    });
  };

  // select id of subjectTags by name

  const findSubjectTagsByName = (selectedSubjectTag) => {
    const foundSubjectTag = subjectTagsDataOption?.filter((subjectTag) => {
      return subjectTag?.title == selectedSubjectTag;
    });

    foundSubjectTag?.map((item) => {
      selectedSubjectTagId.push(item?.id);
    });
  };

  const { handleSubmit, control, errors, formState, reset, touched, watch, setValue } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });

  useEffect(() => {
    if (draftBtnClicked == true) {
      if (successfullHandleSubmit) {
        if (!loading) {
          dispatch(saveSaveAsDraft(false));
          dispatch(setDefaultState());
          navigate(-1);
        }
      } else {
        handleFormSubmit();
      }
    }
  }, [draftBtnClicked, successfullHandleSubmit, loading]);

  useMemo(() => {
    if (Object.keys(errors)?.length) {
      dispatch(saveSaveAsDraft(false));
    }
  }, [Object.keys(errors)?.length]);

  useEffect(() => {
    if (apiResponseData?.maxAttendees != defaultmaxAttendees) {
      const sum = apiResponseData?.tickets?.reduce(function (prev, current) {
        return prev + +current.numberOfSeats;
      }, 0);
      dispatch(saveCapacityUsed(sum));
      dispatch(saveMaxAttendees(apiResponseData.maxAttendees));
    }
  }, [apiResponseData]);

  useEffect(() => {
    if (Object.keys(BasicInfoDetails).length) {
      const values = { ...BasicInfoDetails };
      if (values.venueAddress) {
        values.addressLine1 = values.venueAddress;
      }
      if (values.type) {
        values.eventType = {
          label: values.type?.replace(/([A-Z])/g, ' $1').trim(),
          value: values.type,
        };
      }
      if (values.registrationProcess) {
        setRegistrationProcessForReset(values?.registrationProcess);
        values.onlineRegistrationthroughDocthubPortal = values?.registrationProcess;
      }
      if (values.startDate) {
        values.StartTime = values?.startDate;
        values.startDate = new Date(values?.startDate);
      }
      if (values.endDate) {
        values.EndTime = values?.endDate;
        values.endDate = new Date(values?.endDate);
      }
      const locationObj = {
        state: { label: values.state.label, value: values.state.value },
        city: { label: values.city.label, value: values.city.value },
      };
      setLocationValue(locationObj);
      if (values.registrationType) {
        setRegistrationType(values.registrationType);
      }
      if (values.numberOfGathering) {
        setNumberOfGathering(values.numberOfGathering);
      }
      if (values.participant) {
        setParticipants(values.participant);
      }
      if (values.maxAttendees) {
        values.maxAttendees = defaultmaxAttendees;
      }
      reset({ ...defaultValue, ...values });
    } else if (Object.keys(apiResponseData).length) {
      if (id == undefined) return;
      const apiValues = { ...apiResponseData };
      if (apiValues.venue) {
        apiValues.addressLine1 = apiValues.venue.addressLine1;
        apiValues.postalCode = apiValues.venue.pincode;
      }
      apiValues.state = { label: apiValues.venue.state, value: apiValues.stateId };
      apiValues.city = { label: apiValues.venue.city, value: apiValues.cityId };
      if (apiValues.type) {
        apiValues.eventType = {
          label: apiValues.type?.replace(/([A-Z])/g, ' $1').trim(),
          value: apiValues.type,
        };
      }
      if (apiValues.startDate) {
        apiValues.StartTime = apiValues?.startDate;
        apiValues.startDate = new Date(apiValues.startDate);
      }

      if (apiValues.endDate) {
        apiValues.EndTime = apiValues?.endDate;
        apiValues.endDate = new Date(apiValues.endDate);
      }
      if (apiValues.maxAttendees) {
        apiValues.maxAttendees = apiValues.maxAttendees
          ? apiValues.maxAttendees
          : defaultmaxAttendees;
      }
      if (apiValues.eventPaymentType) {
        setRegistrationType(apiValues.eventPaymentType);
      }
      if (apiValues.numberOfGathering) {
        setNumberOfGathering(apiValues.numberOfGathering);
      }
      if (apiValues.participant) {
        setParticipants(apiValues.participant);
      }

      reset({ ...defaultValue, ...apiValues });
      dispatch(saveApiData(apiValues));
    } else {
      reset(defaultValue);
    }
  }, [BasicInfoDetails, apiResponseData, defaultmaxAttendees]);

  useEffect(() => {
    if (Object.keys(apiResponseData)?.length) {
      if (apiResponseData?.specialities?.length) {
        setSpecialityArray(apiResponseData.specialities);
      }
      if (apiResponseData?.tags?.length) {
        setSubjectTagsArray(apiResponseData.tags);
      }
    }
  }, [apiResponseData]);

  useEffect(() => {
    if (id == undefined) return;
    dispatch(fetchEventById(id));
    dispatch(setDefaultState());
  }, [id]);

  const handleFormSubmit = handleSubmit((values) => {
    if (checkStartDate) {
      setShowTost(true);
    } else {
      specialityArray?.map((item) => {
        findSpecialityByName(item);
      });

      subjectTagsArray?.map((item) => {
        findSubjectTagsByName(item);
      });
      values.type = values.eventType?.value;
      delete values.eventType;
      values.registrationType = registrationType;
      values.participant = Participants;
      values.numberOfGathering = numberOfGathering;
      if (numberOfGathering == 'Unlimited') {
        values.maxAttendees = '';
      } else {
        values.maxAttendees = Number(values.maxAttendees);
      }
      if (values.startDate) {
        if (values.StartTime) {
          values.startDate =
            dayjs(values.startDate).format('YYYY-MM-DDTHH:mm:ss').split('T')[0] +
            'T' +
            dayjs(values.StartTime).format('YYYY-MM-DDTHH:mm:ss').split('T')[1];
        } else {
          values.startDate = dayjs(values.startDate).format('YYYY-MM-DDTHH:mm:ss');
        }
      }

      if (values.endDate) {
        if (values.EndTime) {
          values.endDate =
            dayjs(values.endDate).format('YYYY-MM-DDTHH:mm:ss').split('T')[0] +
            'T' +
            dayjs(values.EndTime).format('YYYY-MM-DDTHH:mm:ss').split('T')[1];
        } else {
          values.endDate = dayjs(values.endDate).format('YYYY-MM-DDTHH:mm:ss');
        }
      }
      if (values.onlineRegistrationthroughDocthubPortal) {
        values.registrationProcess = 'OnlineRegistrationThroughDocthub';
      } else if (apiResponseData.registrationProcess && !registrationProcessForReset) {
        values.registrationProcess = apiResponseData.registrationProcess;
      } else {
        // values.registrationProcess = null;  // due to BE mapping error commented this code after dicussion with Akshara
      }
      delete values.offlineAndComplimentaryRegistration;
      // delete values.onlineRegistrationthroughDocthubPortal;
      values.countryId = values.country.value;
      values.stateId = values.state.value;
      values.cityId = values.city.value;
      values.venueAddress = values.addressLine1;
      delete values.addressLine1;
      values.IsPartneredEvent = true;
      values.status = EventStatus.DRAFT;

      const omitNullVal = {};

      Object.keys(values).map((key) => {
        if (values[key]) {
          omitNullVal[key] = values[key];
        }
      });

      values.specialitiesArray = specialityArray;
      values.subjectTagsArray = subjectTagsArray;

      values.specialtyIds = [...new Set(selectedSpecialityId)];
      values.subjectTagIds = [...new Set(selectedSubjectTagId)];

      dispatch(saveMaxAttendees(values.maxAttendees));

      dispatch(saveBasicInfoDetails(values));
      if (id) {
        values.Id = id;
        dispatch(updateCreateEvent(values.Id)); // PUT Call
      } else if (createdEventResponse.id) {
        values.Id = createdEventResponse.id;
        dispatch(updateCreateEvent(values.Id)); // PUT Call
      } else {
        dispatch(postCreateEvent()); // POST Call
      }
      setSuccessfullHandleSubmit(true);

      if (draftBtnClicked == false) {
        if (loading == false) {
          dispatch(setCurrentStep(currentStep + 1));
          dispatch(setStepsFormData({ [stepsName.basicInfo.name]: values }));
        }
      }
    }
  });

  return {
    handleFormSubmit,
    control,
    errors,
    formState,
    reset,
    touched,
    watch,
    setValue,
    setParticipants,
    Participants,
    setNumberOfGathering,
    numberOfGathering,
    registrationType,
    setRegistrationType,
    locationValue,
    setCheckStartDate,
    checkStartDate,
    setShowTost,
    showTost,
  };
}
