import React from 'react';
import { EditmembershipLayout } from './EditmembershipLayout';
import '../newMembership.scss';
import { useEditMembershipForm } from './components/hooks/useForm.EditMembership';
import EditMembeshipForm from './components/EditMembeshipForm';
import SuccessModal from './components/SuccessModal';
import FailureModal from './components/FailureModal';

export const Editmembership = () => {
  const {
    errors,
    control,
    handleFormSubmit,
    isSuccessFull,
    setIsSuccessFull,
    isEditing,
    error,
    setIsError,
  } = useEditMembershipForm();

  return (
    <form onSubmit={handleFormSubmit}>
      <FailureModal setIsError={error} setError={setIsError} />
      <SuccessModal isSuccess={isSuccessFull} setIsSuccessFull={setIsSuccessFull} />
      <EditmembershipLayout isEditing={isEditing}>
        <div className="bg-grey-100">
          <EditMembeshipForm control={control} errors={errors} />
        </div>
      </EditmembershipLayout>
    </form>
  );
};
