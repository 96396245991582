import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  certificateData: {
    images: [],
    text: '',
    currentPosition: null,
    inputSize: null,
    sampleText: '{{sample text}}',
    fontSize: null,
  },
  isSavedCertificate: false,
  trackPreviousUrlOfCertificate: '',
  awardeeData: null,
  allCertificateData: {},
  typeOfCertificate: '',
  mailSentForAwardee: false,
};

export const eCertificateSlice = createSlice({
  name: 'e-certificate',
  initialState,
  reducers: {
    updateCertificateDataForHTML: (state, action) => {
      state.certificateData = action.payload;
    },
    updateIsSavedCertificate: (state, action) => {
      state.isSavedCertificate = action.payload;
    },
    setTrackPreviousUrl: (state, action) => {
      state.trackPreviousUrlOfCertificate = action.payload;
    },
    setAwardeeData: (state, action) => {
      state.awardeeData = action.payload;
    },
    setAllCertificateData: (state, action) => {
      state.allCertificateData = action.payload;
    },
    setTypeOfCertificate: (state, action) => {
      state.typeOfCertificate = action.payload;
    },
    setMailSentForAwardee: (state, action) => {
      state.mailSentForAwardee = action.payload;
    },
    resetEcertificateState: (state) => {
      state.certificateData = {
        images: [],
        text: '',
        currentPosition: null,
        sampleText: '{{sample text}}',
        fontSize: null,
      };
      state.isSavedCertificate = false;
      state.trackPreviousUrlOfCertificate = '';
      state.awardeeData = null;
      state.allCertificateData = {};
      state.typeOfCertificate = '';
    },
  },
});

// Action creator is generated for the case reducer function
export const {
  updateCertificateDataForHTML,
  updateIsSavedCertificate,
  setTrackPreviousUrl,
  setAwardeeData,
  setAllCertificateData,
  setTypeOfCertificate,
  resetEcertificateState,
  setMailSentForAwardee,
} = eCertificateSlice.actions;

export default eCertificateSlice;
