import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setCommitteeMemberOptions } from '../../slice/committee.slice';

export function useCommitteeMemberOptionEffect({ data, isLoading }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoading) return;
    dispatch(
      setCommitteeMemberOptions(
        data.map(({ fullName, id }) => {
          return { title: fullName, id };
        }),
      ),
    );
  }, [isLoading]);
}
