import axios from 'axios';
import { createHtmlContent } from '../../../helper/helperFunction';
import * as API_ENDPOINT from '../../../../src/api/apiEndpoints';

export const uploadCertificateImage = async (base64ImageData) => {
  try {
    const base64Image = base64ImageData.replace(/^data:image\/(png|jpeg);base64,/, '');
    const byteArray = new Uint8Array(
      atob(base64Image)
        .split('')
        .map((char) => char.charCodeAt(0)),
    );
    const certificateFile = new File([byteArray], 'certificate.jpg', { type: 'image/jpeg' });
    const formData = new FormData();
    formData.append('certificate', certificateFile);
    const certificateUploadEndpoint = `${process.env.REACT_APP_BASE_API_URL}/enterprisecenter/tenant/ecertificates/upload-certificate`;
    const uploadResponse = await axios.post(certificateUploadEndpoint, formData);
    return uploadResponse;
  } catch (error) {
    throw new Error('Failed to upload certificate image: ' + error.message);
  }
};

export const sendHtmlContentToApi = async (certificateUrl, certificateData, type) => {
  try {
    const secondApiEndpoint = `${process.env.REACT_APP_BASE_API_URL}/enterprisecenter/tenant/ecertificates`;
    const htmlContent = await createHtmlContent(
      certificateData.currentPosition,
      certificateData.sampleText,
      certificateData.fontSize,
    );
    const payload = {
      name: certificateData.text,
      certificate: htmlContent,
      certificateUrl: certificateUrl,
      type: type,
      xPosition: certificateData?.currentPosition?.x,
      yPosition: certificateData?.currentPosition?.y,
      height: certificateData?.inputSize?.height,
      width: certificateData?.inputSize?.width,
      // fontSize: certificateData.fontSize,
    };

    const secondResponse = await axios.post(secondApiEndpoint, payload);
    return secondResponse;
  } catch (error) {
    throw new Error('Failed to send HTML content to API: ' + error.message);
  }
};

export const sendEditedHtmlContentToApi = async (certificateUrl, certificateData, id, type) => {
  try {
    const secondApiEndpoint = `${process.env.REACT_APP_BASE_API_URL}/enterprisecenter/tenant/ecertificates/${id}`;
    const htmlContent = await createHtmlContent(
      certificateData.currentPosition,
      certificateData.sampleText,
      certificateData.fontSize,
    );
    const payload = {
      name: certificateData.text,
      certificate: htmlContent,
      certificateUrl: certificateUrl,
      type,
      xPosition: certificateData?.currentPosition?.x,
      yPosition: certificateData?.currentPosition?.y,
      height: certificateData?.inputSize?.height,
      width: certificateData?.inputSize?.width,
      // fontSize: certificateData.fontSize,
    };
    const secondResponse = await axios.put(secondApiEndpoint, payload);
    if (Object.keys(secondResponse || {})?.length) {
      window.location.replace(
        window.origin + '/dashboard/e-certificate/all-e-certificates?=showAllCertificates',
      );
    }
    return secondResponse;
  } catch (error) {
    throw new Error('Failed to send HTML content to API: ' + error.message);
  }
};

export const handleSaveCertificatesAPI = async (eCertificateData) => {
  if (!eCertificateData || !eCertificateData.certificateData) {
    console.error('No certificate data available.');
    return;
  }

  try {
    const base64ImageData = eCertificateData.certificateData.images[0];
    const uploadResponse = await uploadCertificateImage(base64ImageData);

    if (uploadResponse && uploadResponse.status === 200) {
      const certificateUrl = uploadResponse.data.certificateUrl;
      const secondResponse = await sendHtmlContentToApi(
        certificateUrl,
        eCertificateData.certificateData,
        eCertificateData.typeOfCertificate,
      );
      return secondResponse;
    } else {
      console.error('Failed to upload certificate image. Status:', uploadResponse.status);
      return uploadResponse.status;
    }
  } catch (error) {
    console.error('An error occurred:', error);
    return error;
  }
};

export const handleEditCertificatesAPI = async (eCertificateData, id) => {
  const eCertificateID = id?.split('=')[1];
  if (!eCertificateData) {
    console.error('No certificate data available.');
    return;
  }

  try {
    const secondResponse = await sendEditedHtmlContentToApi(
      eCertificateData?.images[0]?.images || eCertificateData.images,
      eCertificateData,
      eCertificateID,
      eCertificateData?.typeOfCertificate,
    );
    return secondResponse;
  } catch (error) {
    console.error('An error occurred:', error);
    return error;
  }
};

// API CALLS POST AWARDEE

export const sendMannualAwardees = async (payload, id, isPreview) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/enterprisecenter/tenant/ecertificate/${id}/awardees?isPreview=${isPreview}`,
      payload,
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error('Error fetching awardees:', error);
    throw error;
  }
};

export async function sendPayloadMannualAwardee(certificateID, uploadType, token, awardeeID) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}${API_ENDPOINT.ENTERPRISE_BASE_API_ROUTE}${API_ENDPOINT.BUSINESS_BASE_API_ROUTE}/ecertificate/${certificateID}/awardees/${awardeeID}`,
      {
        method: 'PUT',
        headers: {
          accept: 'text/plain',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailStaus: uploadType }),
      },
    );

    if (!response.ok) {
      throw new Error('Failed to send payload');
    }

    if (response?.ok) {
      return response?.ok;
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}

export async function sendMailForAwardee(certificateID, uploadType, token, awardeeID) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}${API_ENDPOINT.ENTERPRISE_BASE_API_ROUTE}${API_ENDPOINT.BUSINESS_BASE_API_ROUTE}/ecertificate/${certificateID}/awardees/${awardeeID}`,
      {
        method: 'PUT',
        headers: {
          accept: 'text/plain',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailStaus: uploadType }),
      },
    );

    if (!response.ok) {
      throw new Error('Failed to send payload');
    }
    return response;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}

export const sendAllAwardees = async (file, id) => {
  try {
    if (!id) {
      throw new Error('ID is required');
    }
    if (!file || !file.length) {
      throw new Error('No file uploaded');
    }

    const formData = new FormData();
    formData.append('uploadAwardees', file[0]);

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/enterprisecenter/tenant/ecertificate/${id}/excel-awardees-count`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch (error) {
    // Handle error
    return error.response.data;
  }
};

export const uploadExcelAwardees = async (awardeesData, eCertificateId, later) => {
  try {
    // TODO: temp commented
    // if (
    //   !awardeesData ||
    //   !eCertificateId ||
    //   !Array.isArray(awardeesData) ||
    //   awardeesData?.length === 0
    // ) {
    //   throw new Error('Invalid input data');
    // }

    const payload = awardeesData?.map((awardee) => ({
      ...awardee,
    }));

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/enterprisecenter/tenant/ecertificate/${eCertificateId}/upload-excel-awardees?awardeeActionType=${later}`,
      payload,
    );

    return response?.data;
  } catch (error) {
    // Handle error
    console.error('Error uploading awardees:', error.message);
    throw error;
  }
};
