export const disclaimerPolicyEnterprise = [
  {
    heading: 'Disclaimer Policy',
  },

  {
    content: `Docthub Health Tech Private Limited is a company registered
      under the Companies Act, 2013 of India, which runs the platform
      "Docthub.com" and offers multiple applications for healthcare
      professionals, students, organizations, and others.`,
  },
  {
    content: `Docthub.com is a healthcare career and professional upgrade
    platform with a web application and a mobile app on Android and
    iOS. It operates different products like Courses, Jobs, Events,
    where it aggregates different healthcare organizations globally.`,
  },
  {
    content: `Docthub Enterprise application provides a dashboard for
    organizations and businesses to connect and publish services or
    information such as job vacancies and offered courses on Docthub
    user platforms. It also allows the use of business tools and
    software developed by Docthub. Docthub Enterprise is operated by
    Docthub Health Tech Private Limited.`,
  },
  {
    content: `The organization and businesses need to create an Enterprise
    Account on Docthub Enterprise website (
    <span className="text-black"> domain: </span>
    <a href="https://enterprise.docthub.com/">
      https://enterprise.docthub.com/
    </a>
    ) and further access all services through the same account and
    manage it through Enterprise Dashboard. Such account users are
    referred hereafter as “Enterprise User”.`,
  },
  {
    content: `This privacy policy describes how Docthub Enterprise and its affiliates (collectively “Docthub”) collect and process your personal and/or organizational information through all Docthub websites, products, devices, services, online marketplace, software and applications that together referred hereafter as “Docthub Services”. We (Docthub Enterprise) know that you (Enterprise Users) care about how your information is being used and shared, we appreciate your trust and ensure that we will do it carefully and sensibly.`,
  },
  {
    content: `All the information collected and used will be sourced information from the respective authority or organization or institute’s websites, publications, newspaper, public notifications, social media pages and other reliable sources available in the public domain. While every effort has been made to ensure that the information or content on Docthub.com is comprehensive, accurate, and up-to-date, Docthub does not take any responsibility for the content. Information provided, may become obsolete partially or completely due to time lapse, unedited, incomplete or incorrect altogether, even after sincere efforts of updating on regular intervals to ensure highest quality. As the purpose of the content used on the website is only for the reference purposes, the users are advised to refer to the respective organization website in case there is any kind of doubt and if possible, inform us to do the necessary changes to make Docthub.com a considerably useful website for its visitors and users. `,
  },
  {
    content: `However, if you found any inappropriate or wrong information or data on the website, inform us by clicking at “Contact Us” on the section for rectification/ updating the same.`,
  },
  {
    title: `No Warranty:`,
  },
  {
    content: `To the extent permitted under law, Docthub and its Associates (include professionals, organization representatives, creators, contractors that Docthub works with to provide the services`,
  },
  {
    content: `<span class="text-black"><b>(a) All implied warranties and representations, including those relating to merchantability, suitableness for a particular purpose, accuracy, and non-infringement, are disclaimed</b></span>`,
  },
  {
    content: `<span class="text-black"><b>(b) Does not guarantee that the services will work without errors or interruption</b></span>`,
  },
  {
    content: `<span class="text-black"><b>(c) Provide the services including content and information, on “as is” and “as available” basis.</b></span>`,
  },
  {
    content: `Some laws do not allow particular disclaimers, so some or all of these disclaimers may not apply to you.`,
  },
  {
    title: ` Exclusion of Liability:`,
  },
  {
    content: `To the extent allowed under law (Unless Docthub has entered into a separate written agreement that overrides this contract), Docthub and its associates (including professionals, organization representatives, creators, contractors that Docthub works with to provide the services) shall not be liable to you or others for any indirect, special, incidental, consequential or punitive damages, or any loss of data, opportunities, reputation, profits or revenues, related to the services like offensive or defamatory statements, downtime or loss, use of, or changes to, your information or content.`,
  },
  {
    content: `The liability of Docthub and its associates (including professionals, organization representatives, creators, contractors that Docthub works with to provide the services) in any case shall not exceed the less of; (a) Five times your most recent premium service fee or (b) USD $ 1000 in the aggregate for all claims.`,
  },
  {
    content: `This limitation of liability is part of the bargain between you and Docthub, and shall apply to all claims of liability like warranty, negligence, contract, tort, law, and if Docthub or its associates were informed of the possibility of any such damage the failure of these remedies may deliver the intended result. There may be laws that prohibit certain disclaimers, so some or all of these disclaimers may not apply to you.`,
  },
  {
    title: `CONTACTS`,
  },
  {
    content: `If you have any questions about this Privacy Policy, You can contact us:`,
  },
  {
    content: `By visiting this page on our website: <a href="https://www.docthub.com/contact-us"> https://www.docthub.com/contact-us </a>`,
  },
  {
    content: `By sending us an email: <a href="mailto:office@docthub.com"> office@docthub.com </a>`,
  },
];
