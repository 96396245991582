import { useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Custom hook to send a request for institute subscription information.
 * @returns {function} A function to send the request.
 */
export default function useSendRequestInfoInstituteSubscription() {
  const accessToken = useSelector((state) => state?.app?.token);
  const [requestSent, setRequestSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);
  const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/request-info`;

  /**
   * Sends a POST request to request institute subscription information.
   * @async
   */
  const sendRequest = async () => {
    try {
      setSending(true);
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        setRequestSent(true);
      } else {
        return error;
      }
    } catch (error) {
      setError(true);
    } finally {
      setSending(false);
    }
  };

  return { sendRequest, requestSent, setRequestSent, sending, error, setError };
}
