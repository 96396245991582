import { DoctForm, DoctModal } from '@doct-react/app';
import { DoctButton, DoctIcon, DoctTypography } from '@doct-react/core';
import React, { useEffect, useState } from 'react';
import CertificateFileNamePopUp from './PopUps/CertificateFileNamePopUp';
import { useDispatch, useSelector } from 'react-redux';
import { handleEditCertificatesAPI, handleSaveCertificatesAPI } from '../../APIs/eCertificate';
import { Toaster } from '../../../../shared';
import {
  updateCertificateDataForHTML,
  updateIsSavedCertificate,
} from '../../slices/eCertificateSlice';
import { useNavigate } from 'react-router-dom';
import { useCertificateImage } from '../../hooks/useCertificateImage';
import { useGetCertificateByIDQuery } from '../services/eCertificateApi';
import StartAssigningPopUp from './PopUps/StartAssigningPopUp';
import HoriZontalRow from './HoriZontalRow';

const UploadCertificateHeaderText = ({
  setIsLoad,
  iconName,
  leftIconOnClick,
  title,
  subText,
  setSearchText,
  searchText,
  handleClose,
  isEdit,
  certificateID,
  isUploaded,
  setDisableSaveBtn,
  disableSaveBtn,
  disableSaveAndAssign,
  setDisableSaveAndAssign,
}) => {
  const [openEditMenu, setOpenEditMenu] = useState(false);
  const { eCertificateData } = useSelector((state) => state) ?? {};
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [saveAndAssignClick, setSaveAndAssignClick] = useState(false);
  const typeOfCertificate = useSelector((state) => state?.eCertificateData?.typeOfCertificate);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { data } = useGetCertificateByIDQuery(certificateID?.split('=')[1]);
  const [onSaveClicked, setOnSaveClicked] = useState(false);
  const [onSaveAssignAwardeeClicked, setOnSaveAssignAwardeeClicked] = useState(false);

  const { certificateImage, setHtmlCertificate } = useCertificateImage();

  useEffect(() => {
    if (data?.certificate) {
      setHtmlCertificate(data?.certificate);
    }
  }, [data?.certificate]);
  const { control, errors, touched, handleSubmit, setValue } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const handleFormSubmit = handleSubmit((values) => {
    setSearchText(values?.searchText);
    dispatch(
      updateCertificateDataForHTML({
        images: eCertificateData?.certificateData?.images,
        text: values?.searchText,
        currentPosition: eCertificateData?.certificateData?.currentPosition,
        inputSize: eCertificateData?.certificateData?.inputSize,
        sampleText: eCertificateData?.certificateData?.sampleText,
        fontSize: eCertificateData?.certificateData?.fontSize,
      }),
    );
  });

  const handleRedirectToAllECertificates = () => {
    navigate('?=showAllCertificates', { replace: true });
  };

  const handleSaveAndAssignAwardee = async () => {
    setDisableSaveBtn(true);
    const newObjForCertificate = {
      ...eCertificateData,
      type: typeOfCertificate,
    };
    setOnSaveAssignAwardeeClicked(true);
    const response = await handleSaveCertificatesAPI(newObjForCertificate);
    setOnSaveAssignAwardeeClicked(false);
    if (response.status === 201) {
      await setSaveAndAssignClick(true);
      await setIsLoad(false);
      await setDisableSaveBtn(false);
    }
  };

  useEffect(() => {
    if (showSuccessMsg) {
      setTimeout(async () => {
        setShowSuccessMsg((prev) => !prev);
        dispatch(updateIsSavedCertificate(true));
        navigate('?=showAllCertificates', { replace: true });
        setTimeout(() => {
          handleClose();
        }, 1000);
      }, 1000);
    }
  }, [showSuccessMsg]);

  const handleAssignAwardee = () => {
    navigate('/dashboard/e-certificate/all-awardees-list');
  };

  return (
    <>
      {showSuccessMsg && (
        <div className="position-fixed tost-container-top-right">
          <Toaster
            variant={'positive'}
            text={'Your Certificate Created SuccessFully!'}
            onPressedClose={() => {
              setShowSuccessMsg(false);
              dispatch(updateIsSavedCertificate(true));
              navigate('?=showAllCertificates', { replace: true });
            }}
          />
        </div>
      )}
      <div className="d-flex align-items-center mb-n1">
        {!isEdit && (
          <span
            onClick={
              eCertificateData?.allCertificateData?.length &&
              eCertificateData?.allCertificateData[0]?.length &&
              eCertificateData?.allCertificateData[0]?.id
                ? handleRedirectToAllECertificates
                : leftIconOnClick
            }
            className="d-flex"
          >
            <DoctIcon
              name={iconName}
              height="24"
              width="24"
              fill="#8E8E8E"
              className="mr-3 cursor-pointer"
            />
          </span>
        )}

        <div>
          {subText && (
            <DoctTypography className={`m-0 text-grey-600`} variant="textLabel1">
              {subText}
            </DoctTypography>
          )}
          <div className="d-flex">
            <DoctTypography
              variant="subtitle2"
              className={`max-text-width d-flex m-0 text-grey-800 text-word-break`}
            >
              {title ? title : data?.name}
            </DoctTypography>
            {isUploaded && (
              <DoctButton
                variant="text"
                text="EDIT"
                size="small"
                className="edit-button-text"
                onButtonClickHandler={() => setOpenEditMenu(true)}
              />
            )}
          </div>
        </div>
        {isUploaded && (
          <div className="d-flex ml-auto">
            <DoctButton
              variant="contained"
              text="Save Certificate"
              className={
                !onSaveClicked
                  ? 'save-certificate-button-bg mr-2'
                  : 'on-click-save-certificate-button-bg mr-2'
              }
              size="medium"
              onButtonClickHandler={async () => {
                setIsLoad(true);
                setDisableSaveAndAssign(true);
                if (isEdit) {
                  const updateCertificateObj = {
                    ...(eCertificateData?.certificateData || {}),
                  };
                  const updatedObj = {
                    ...updateCertificateObj,
                    images: certificateImage,
                    text: searchText ? searchText : data.name,
                    type: typeOfCertificate,
                  };
                  try {
                    const response = await handleEditCertificatesAPI(updatedObj, certificateID);
                    if (response?.status === 201) {
                      await setShowSuccessMsg(true);
                      await setIsLoad(false);
                    }
                    return;
                  } catch (e) {
                    debugger;
                  }
                }
                setOnSaveClicked(true);
                const newObjForCertificate = {
                  ...eCertificateData,
                  type: typeOfCertificate,
                };
                const response = await handleSaveCertificatesAPI(newObjForCertificate);
                setOnSaveClicked(false);
                if (response.status === 201) {
                  await setShowSuccessMsg(true);
                  await setIsLoad(false);
                  await setDisableSaveAndAssign(false);
                  setOnSaveClicked(false);
                }
                await navigate('?=savedOrEdited');
              }}
              disabled={onSaveClicked || disableSaveBtn}
            />
            {isEdit ? (
              <DoctButton
                variant="outlined"
                text="Discard Changes"
                className=""
                size="medium"
                onButtonClickHandler={() => {
                  navigate('?=showAllCertificates', { replace: true });
                }}
              />
            ) : (
              <DoctButton
                variant="contained"
                text="Save & Assign Awardee"
                className="select-file-button"
                size="medium"
                onButtonClickHandler={handleSaveAndAssignAwardee}
                disabled={onSaveAssignAwardeeClicked || disableSaveAndAssign}
              />
            )}
          </div>
        )}
      </div>
      <HoriZontalRow dotted className="mb-3" />
      <DoctModal
        iconName={''}
        primaryBtnText="Save"
        secondaryBtnText="Cancel"
        open={openEditMenu}
        handlePrimaryButtonClick={() => {
          handleFormSubmit();
          setOpenEditMenu(false);
        }}
        isPrimaryBtnDisable={Object.keys(errors ?? {}).length > 0 ? true : false}
        handleSecondaryButtonClick={() => {
          setOpenEditMenu(false);
        }}
        handleClose={() => setOpenEditMenu(false)}
        title="Edit Certificate File Name"
        handleIconButtonClick={() => {}}
        width={360}
        className="disabled_modal_outside_click"
      >
        <CertificateFileNamePopUp
          searchText={searchText}
          handleSubmit={handleFormSubmit}
          control={control}
          errors={errors}
          touched={touched}
          setValue={setValue}
          editCertificateName={data?.name}
          setSearchText={setSearchText}
        />
      </DoctModal>

      <StartAssigningPopUp open={saveAndAssignClick} handlePass={handleAssignAwardee} />
    </>
  );
};

export default UploadCertificateHeaderText;
