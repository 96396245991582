import { DoctForm } from '@doct-react/app';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchEventById,
  saveApiData,
  saveSaveAsDraft,
  saveTermsAndPoliciesDetails,
  selectCreateEventResponse,
  selectCurrentStep,
  selectResponseData,
  selectSaveAsDraftClicked,
  selectTermsAndPoliciesDetails,
  selectUpdateEventLoader,
  setCurrentStep,
  setDefaultState,
  setStepsFormData,
} from '../../../createEvent.slice';
import { updateCreateEvent } from '../../../services/CreateEventServices';
import { useNavigate } from 'react-router-dom';
import stepsName from '../stepsName';

const formNameWithDefaultProps = (control) => {
  return {
    termsAndCondition: {
      name: 'metaData.termsAndCondition',
      control,
      placeholder: `Describe terms and conditions (optional)`,
    },
    cancellationAndRefundPolicy: {
      name: 'metaData.cancellationPolicy',
      control,
      placeholder: `Describe policies here (optional)`,
    },
    remarks: {
      name: 'metaData.remarks',
      control,
      placeholder: `Describe (optional)`,
    },
  };
};

export default function useFormTermsPolicies() {
  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const teamsAndPolicies = useSelector(selectTermsAndPoliciesDetails);
  const createdEventResponse = useSelector(selectResponseData);
  const loading = useSelector(selectUpdateEventLoader);
  const apiResponseData = useSelector(selectCreateEventResponse);
  const { id } = useParams();
  const draftBtnClicked = useSelector(selectSaveAsDraftClicked);
  const navigate = useNavigate();

  const [successfullHandleSubmit, setSuccessfullHandleSubmit] = useState(false);

  useEffect(() => {
    if (id == undefined) return;
    dispatch(fetchEventById(id));
  }, [id]);

  const { handleSubmit, control, errors, formState, reset, touched, watch, setValue } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });

  useEffect(() => {
    if (draftBtnClicked == true) {
      if (successfullHandleSubmit) {
        if (!loading) {
          dispatch(saveSaveAsDraft(false));
          dispatch(setDefaultState());
          navigate(-1);
        }
      } else {
        handleFormSubmit();
      }
    }
  }, [draftBtnClicked, successfullHandleSubmit, loading]);

  useMemo(() => {
    if (Object.keys(errors)?.length) {
      dispatch(saveSaveAsDraft(false));
    }
  }, [Object.keys(errors)?.length]);

  useEffect(() => {
    if (Object.keys(teamsAndPolicies).length) {
      reset({ ...teamsAndPolicies });
    } else if (Object.keys(apiResponseData).length) {
      if (id == undefined) return;
      const value = { ...apiResponseData };
      value['metaData.termsAndCondition'] = value.metaData?.termsAndCondition;
      value['metaData.cancellationPolicy'] = value.metaData?.cancellationPolicy;
      value['metaData.remarks'] = value.metaData?.remarks;
      reset({ ...value });
    }
  }, [teamsAndPolicies, apiResponseData]);

  const handleFormSubmit = handleSubmit((values) => {
    const { termsAndCondition, cancellationPolicy } = values?.metaData || {};

    if (termsAndCondition === '<p><br></p>') {
      values.metaData = { ...values.metaData, termsAndCondition: null };
    }
    if (cancellationPolicy === '<p><br></p>') {
      values.metaData = { ...values.metaData, cancellationPolicy: null };
    }
    if (createdEventResponse?.id) {
      values.Id = createdEventResponse.id;
    }
    if (id) {
      values.Id = id;
      dispatch(saveApiData({ ...apiResponseData, ...values }));
    }
    dispatch(saveTermsAndPoliciesDetails(values));
    dispatch(updateCreateEvent(values.Id)); // PUT API Call
    setSuccessfullHandleSubmit(true);
    if (draftBtnClicked == false) {
      if (loading == false) {
        dispatch(setCurrentStep(currentStep + 1));
        dispatch(setStepsFormData({ [stepsName.sponsorsAndExhibitors.name]: values }));
      }
    }
  });

  return {
    formName: formNameWithDefaultProps(control, errors),
    handleFormSubmit,
    control,
    errors,
    formState,
    reset,
    touched,
    watch,
    setValue,
  };
}
