import { DoctTextArea, DoctTextField } from '@doct-react/app';
import React, { useEffect } from 'react';
import { useState } from 'react';
import UploadImage from '../../../../../../../shared/ui/UploadImage/UploadImage';
import image from '../../../../../../../assets/icons/photo-upload.svg';

function ManageOrganizerModel({
  control,
  errors,
  touched,
  register,
  members,
  selectedRecord,
  setValue,
}) {
  const [photoSrc, setPhotoSrc] = useState(null);

  useEffect(() => {
    if (selectedRecord != null) {
      if (
        members[selectedRecord]?.pictureFile?.length > 0 ||
        members[selectedRecord]?.pictureFile
      ) {
        if (members[selectedRecord]?.pictureFile?.length > 0) {
          setPhotoSrc(window.URL.createObjectURL(members[selectedRecord].pictureFile[0]));
        } else if (members[selectedRecord]?.pictureFile?.type) {
          setPhotoSrc(window.URL.createObjectURL(members[selectedRecord]?.pictureFile));
        }
      } else {
        setPhotoSrc(members[selectedRecord]?.pictureUrl);
      }
    }
  }, []);

  return (
    <>
      <UploadImage
        register={register}
        id={`userProfilePhoto`}
        setPhotoSrc={setPhotoSrc}
        photoSrc={photoSrc || image}
        setValue={setValue}
        text="Add Photo"
        name="pictureFile"
      />
      <DoctTextField
        showStar={false}
        className="mt-2 mb-2"
        name="fullName"
        label="Full Name"
        id="fullName"
        control={control}
        isErrors={errors}
        defaultValue=""
        validationRules={{
          required: "It's Required Field",
        }}
        touched={touched}
      />
      <DoctTextArea
        name="professionalTitle"
        label="Professional Title"
        id="professionalTitle"
        showStar={false}
        control={control}
        isErrors={errors}
        validationRules={{
          required: "It's Required Field",
          maxLength: {
            value: 1000,
            message: 'Max 1000 Characters',
          },
        }}
      />
    </>
  );
}

export default ManageOrganizerModel;
