/* eslint-disable prettier/prettier */
import React from 'react'
import * as ROUTE from '../../../routes/constant';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setECertificatesActiveTabIndex } from '../../featuresSlice';
import { useEffect, useState } from 'react';
import axios from 'axios';

const eCertificateSideBar = (pathname = '') => {
  const [totalRecords, setTotalRecords] = useState(0);
  const dispatch = useDispatch();
  const allCertificateData =
    useSelector((state) => state?.eCertificateData?.allCertificateData) ?? {};

  useEffect(() => {
    async function eCertificateData() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/enterprisecenter/tenant/ecertificates`,
        );
        setTotalRecords(res?.data?.totalRecords);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    eCertificateData();
  });

  const menuItemsArray = [
    {
      link:
        totalRecords === 0 ? (
          <Link
            to={`/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_E_CERTIFICATES}`}
            className={
              pathname === `/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_E_CERTIFICATES}`
                ? 'active'
                : ''
            }
            onClick={() => {
              dispatch(setECertificatesActiveTabIndex(0));
            }}
          >
            All E-Certificates
          </Link>
        ) : (
          <Link
            to={`/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_E_CERTIFICATES}?=${ROUTE.SHOW_ALL_E_CERTIFICATES}`}
            className={
              pathname === `/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_E_CERTIFICATES}`
                ? 'active'
                : ''
            }
            onClick={() => {
              dispatch(setECertificatesActiveTabIndex(0));
            }}
          >
            All E-Certificates
          </Link>
        ),
    },

    {
      link:
        allCertificateData?.length > 0 ? (
          <Link
            to={`/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_AWARDEES_LIST}`}
            className={
              pathname === `/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_AWARDEES_LIST}`
                ? 'active'
                : ''
            }
          >
            Awardees List
          </Link>
        ) : (
          <Link className="disabled-link pointer-event-none">Awardees List</Link>
        ),
    },

    // {
    //   link: (
    //     <Link
    //       to={`/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_DETAILS}`}
    //       className={
    //         pathname ===
    //         `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_DETAILS}`
    //           ? 'active'
    //           : ''
    //       }
    //     >
    //       Billing Details
    //     </Link>
    //   ),
    // },
  ];
  return menuItemsArray;
};

const eCeritificatesSidebar = (pathname) => eCertificateSideBar(pathname);

export default eCeritificatesSidebar;
