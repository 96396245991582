import React from 'react';
import { ChangeMobileNumber, ChangeTelephone } from './components';

export default function ChangePhoneNumber() {
  return (
    <>
      <ChangeMobileNumber />
      <ChangeTelephone />
    </>
  );
}
