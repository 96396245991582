import { useState, useEffect } from 'react';

export function useCertificateImage() {
  const [htmlCertificate, setHtmlCertificate] = useState('');
  const [certificateImage, setCertificateImage] = useState(null);

  useEffect(() => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlCertificate;

    // Find the <img> tag within the parsed HTML
    const imgElement = tempDiv.querySelector('img');

    if (imgElement) {
      const imageUrl = imgElement.src || imgElement.getAttribute('src');
      if (imageUrl.startsWith('data:image')) {
        // If the src attribute is a data URL
        setCertificateImage(imageUrl);
      } else {
        // If the src attribute is a URL, create an Image element to load it
        const image = new Image();
        image.onload = () => {
          setCertificateImage(image.src);
        };
        image.src = imageUrl;
      }
    }

    return () => {
      setCertificateImage(null);
    };
  }, [htmlCertificate]);

  return { certificateImage, htmlCertificate, setHtmlCertificate };
}
