import React from 'react';
import Helmet from 'react-helmet';

const defaultSEO = {
  title: 'Post a Job - Create an Event - List a Course all at one Place | Docthub.com',
  description:
    'Now Recruiters can post a job, Associations can create an event and Institutes can list courses. Explore India’s No.1 Healthcare Portal Docthub.com',
};

export default function PageSEO({
  title,
  ogTitle = title,
  description,
  ogDescription = description,
  canonicalUrl,
}) {
  return (
    <Helmet>
      <title key="title">{title || defaultSEO.title}</title>
      <meta property="og:title" content={ogTitle || defaultSEO.title} key="ogTitle" />
      <meta
        name="description"
        content={description || defaultSEO.description}
        key="metaDescription"
      />
      <meta
        property="og:description"
        content={ogDescription || defaultSEO.description}
        key="ogDescription"
      />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Helmet>
  );
}
