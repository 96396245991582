import React from 'react';
import { DoctAnimationLoading } from '@doct-react/app';
import PropTypes from 'prop-types';
export default function LayoutLoading({ isLoading, children = null }) {
  if (isLoading) {
    return (
      <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
        <DoctAnimationLoading />
      </div>
    );
  }
  return children;
}

LayoutLoading.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
};
