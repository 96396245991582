import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  featuresSlice,
  eventApi,
  offlineRegistrationReducer,
  registrationsApi,
  attendeesApi,
  manageRegistrationSlice,
  complementaryRegistrationSlice,
  manageCourseApi,
  courseApi,
  courseApplicantApi,
  createEventSlice,
  activityLogSlice,
  profileSlice,
  committeeSlice,
  designationApi,
  subCommitteeApi,
  archiveApi,
  activityLogApi,
} from './features';

import { appApi, appSlice } from './components/App';
import { membersApi } from './features/ManageMemberships/ManageDirectory/services/members.services';
import { membershipsApi } from './features/ManageMemberships/AllMemberships/services/memberships.services';
import { useLocationSlice } from './hooks';
import { locationPlacesApi } from './hooks/useLocation';
import { manageCourseSlice } from './features/InstituteDashboard/Courses/ManageCourse/ManageCourseSlice';
import { fieldOptions } from './hooks/useFieldOptions/useFieldOption.service';
import { manageJobSlice } from './features/RecruiterDashboard/ManageJob/slice/manageJobSlice';
import { jobsApi } from './features/RecruiterDashboard/JobListing/services/jobs.services';
import { manageJobsApi } from './features/RecruiterDashboard/ManageJob/services/manageJob.services';
import { recruiterApplicantApi } from './features/RecruiterDashboard/JobApplicants/service/recruiterApplicants.service';
import { quickAccessApi } from './features/QuickAccess/services/quickAccess.services';
import subscriptionFormSlice from './features/SubscriptionModal/SubsciptionServicesSlice';
import { subscriptionApi } from './features/SubscriptionModal/API/subscriptionApi';
import subscriptionSlice from './features/SubscriptionModal/API/subscriptionApiSlice';
import eCertificateSlice from './features/ECertificates/slices/eCertificateSlice';
import { eCertificateApi } from './features/ECertificates/components/services/eCertificateApi';
export const store = configureStore({
  reducer: {
    features: featuresSlice.reducer,
    app: appSlice.reducer,
    manageCourse: manageCourseSlice.reducer,
    manageJob: manageJobSlice.reducer,
    subscriptionData: subscriptionSlice.reducer,
    eCertificateData: eCertificateSlice.reducer,
    [membersApi.reducerPath]: membersApi.reducer,
    [membershipsApi.reducerPath]: membershipsApi.reducer,
    [appApi.reducerPath]: appApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [registrationsApi.reducerPath]: registrationsApi.reducer,
    [attendeesApi.reducerPath]: attendeesApi.reducer,
    [locationPlacesApi.reducerPath]: locationPlacesApi.reducer,
    [fieldOptions.reducerPath]: fieldOptions.reducer,
    [manageCourseApi.reducerPath]: manageCourseApi.reducer,
    [manageJobsApi.reducerPath]: manageJobsApi.reducer,
    [courseApi.reducerPath]: courseApi.reducer,
    [jobsApi.reducerPath]: jobsApi.reducer,
    [courseApplicantApi.reducerPath]: courseApplicantApi.reducer,
    [recruiterApplicantApi.reducerPath]: recruiterApplicantApi.reducer,
    offlineRegistration: offlineRegistrationReducer.reducer,
    complementaryRegistration: complementaryRegistrationSlice.reducer,
    manageRegistration: manageRegistrationSlice.reducer,
    locations: useLocationSlice.reducer,
    createEvent: createEventSlice.reducer,
    activityLog: activityLogSlice.reducer,
    [activityLogApi.reducerPath]: activityLogApi.reducer,
    profile: profileSlice.reducer,
    committee: committeeSlice.reducer,
    [designationApi.reducerPath]: designationApi.reducer,
    [subCommitteeApi.reducerPath]: subCommitteeApi.reducer,
    [archiveApi.reducerPath]: archiveApi.reducer,
    [quickAccessApi.reducerPath]: quickAccessApi.reducer,
    subscriptionForm: subscriptionFormSlice.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [eCertificateApi.reducerPath]: eCertificateApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      appApi.middleware,
      eventApi.middleware,
      locationPlacesApi.middleware,
      fieldOptions.middleware,
      attendeesApi.middleware,
      registrationsApi.middleware,
      courseApi.middleware,
      courseApplicantApi.middleware,
      jobsApi.middleware,
      recruiterApplicantApi.middleware,
      recruiterApplicantApi.middleware,
      manageCourseApi.middleware,
      membersApi.middleware,
      membershipsApi.middleware,
      manageJobsApi.middleware,
      activityLogApi.middleware,
      designationApi.middleware,
      subCommitteeApi.middleware,
      archiveApi.middleware,
      quickAccessApi.middleware,
      subscriptionApi.middleware,
      eCertificateApi.middleware,
    ),
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);
