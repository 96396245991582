import React from 'react';

import {
  LightTooltip,
  ProfileDataBox,
  ProfileDataBoxContentTitleDescription,
} from '../../../../../shared';
import InfoIconWithTooltip from '../InfoIconWithTooltip';
import { useGetUserQuery } from '../../../../../components';

export default function EmailPhoneProfile() {
  const { data: userData } = useGetUserQuery('');

  const { email, mobileNumber, telephoneNumber } = userData || {};

  return (
    <ProfileDataBox
      heading={'Email & Phone'}
      actionMenu={
        <LightTooltip
          title={
            <span>
              To edit phone information go to <b> “Login & Security”</b> tab.
            </span>
          }
          arrow
        >
          <InfoIconWithTooltip />
        </LightTooltip>
      }
    >
      <ProfileDataBoxContentTitleDescription title={'Email:'} description={email || '-'} />
      <ProfileDataBoxContentTitleDescription
        title={'Mobile:'}
        description={mobileNumber?.replace('-', ' ') || '-'}
      />
      <ProfileDataBoxContentTitleDescription
        title={'Telephone:'}
        description={telephoneNumber?.replace('-', ' ') || '-'}
      />
    </ProfileDataBox>
  );
}
