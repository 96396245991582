import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENTERPRISE_BASE_API_ROUTE } from '../../../../api/apiEndpoints';
export const eCertificateApi = createApi({
  reducerPath: 'eCertificateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().app.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['eCertificates'],
  endpoints: (builder) => ({
    fetchAwardees: builder.query({
      query: (obj) => {
        const { certificateID, searchText, filterProps, awardeeDataPageSize } = obj;
        let url = `${ENTERPRISE_BASE_API_ROUTE}/tenant/ecertificate/${certificateID}/awardees`;
        const queryParams = [];
        if (filterProps) {
          for (const key in filterProps) {
            if (typeof filterProps[key] !== 'undefined') {
              // eslint-disable-next-line no-prototype-builtins
              if (filterProps.hasOwnProperty(key)) {
                queryParams.push(`${key}=${encodeURIComponent(filterProps[key])}`);
              }
            }
          }
        }
        if (searchText) {
          queryParams.push(`SearchText=${encodeURIComponent(searchText)}`);
        }
        if (awardeeDataPageSize) {
          queryParams.push(`PageNumber=${encodeURIComponent(1)}`);
          queryParams.push(`PageSize=${encodeURIComponent(awardeeDataPageSize)}`);
        }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
        return url;
      },
    }),
    getCertificateByID: builder.query({
      query: (certificateID) => {
        let url = `${ENTERPRISE_BASE_API_ROUTE}/tenant/ecertificates/${certificateID}`;
        return url;
      },
    }),
    sendPendingCertificate: builder.mutation({
      query: (obj) => ({
        url: `${ENTERPRISE_BASE_API_ROUTE}/tenant/ecertificate/${obj.id}/send-pending-awardees-certificates`,
        method: 'POST',
        body: obj,
      }),
    }),
  }),
});

export const {
  useFetchAwardeesQuery,
  useGetCertificateByIDQuery,
  useSendPendingCertificateMutation,
} = eCertificateApi;
