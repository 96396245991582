import React from 'react';

import { DoctTextField } from '@doct-react/app';

import { FormElWithHeading } from '../../../../shared';

import fieldCommittee from '../components/Common/field.Committee';

function FormAddEditArchive({ formProps }) {
  return (
    <>
      <FormElWithHeading heading={fieldCommittee.archive.label}>
        <DoctTextField
          {...formProps}
          name={fieldCommittee.archive.keyOfGetApi}
          label="Enter title"
          validationRules={fieldCommittee.archive.validationRules}
          id={fieldCommittee.archive.name}
          showStar={false}
        />
      </FormElWithHeading>
    </>
  );
}

export default FormAddEditArchive;
