import { useForm } from 'react-hook-form';
import {
  sendMannualAwardees,
  sendAllAwardees,
  sendPayloadMannualAwardee,
  uploadExcelAwardees,
} from '../../APIs/eCertificate'; // Assuming this is the correct import path
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setMailSentForAwardee, setTrackPreviousUrl } from '../../slices/eCertificateSlice';
import useToggle from '../useToggle';

const useAwardeeForm = (certificateID, id, sendEmailPopUpHandleClose, toggleNewAwardee) => {
  const { handleSubmit, control, errors, formState, reset, touched, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });
  const { token } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const [selectExcelFileResponse, setSelectExcelFileResponse] = useState([]);
  const [mannualResponse, setMannualResponse] = useState({});
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [showToaster, setShowToaster] = useState(false);
  const [awardeeID, setAwardeeID] = useState('');
  const [isPreview, setIsPreview] = useState(false);
  const [awardeeImgData, setAwardeeImgData] = useState(null);
  const [awardeeImgName, setAwardeeImgName] = useState(null);
  const [open, toggle] = useToggle();
  const [emailLoading, setEmailLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [laterLoading, setLaterLoading] = useState(false);

  const handleUploadForMannual = (uploadType) => {
    const loadingSetter = getLoadingSetter(uploadType);
    loadingSetter(true);

    const response = sendPayloadMannualAwardee(certificateID || id, uploadType, token, awardeeID);

    response
      .then((res) => {
        if (res) {
          if (uploadType === 'SendEmail') {
            dispatch(setMailSentForAwardee(true));
          }
          if (typeof handleClose === 'function') {
            sendEmailPopUpHandleClose();
          }
          navigate(`/dashboard/e-certificate/all-awardees-list?id=${certificateID || id}`);
          setShowToaster(false);
          toggleNewAwardee(false);
          dispatch(setTrackPreviousUrl(''));
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        loadingSetter(false);
      });
  };

  const handleUpload = (uploadType) => {
    const loadingSetter = getLoadingSetter(uploadType);
    loadingSetter(true);
    const response = uploadExcelAwardees(
      selectExcelFileResponse?.awardeeExcel,
      certificateID || id,
      uploadType,
    );
    response
      .then(() => {
        if (uploadType === 'SendEmail') {
          dispatch(setMailSentForAwardee(true));
        }
        if (typeof handleClose === 'function') {
          sendEmailPopUpHandleClose();
        }
        navigate(`/dashboard/e-certificate/all-awardees-list?id=${certificateID || id}`);
        setOpenModal(false);
        toggleNewAwardee(false);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const getLoadingSetter = (uploadType) => {
    switch (uploadType) {
      case 'SendEmail':
        return setEmailLoading;
      case 'ShareEmail':
        return setPdfLoading;
      case 'WillDoLater':
        return setLaterLoading;
      default:
        return () => {};
    }
  };

  const onSubmit = (data, actionType) => {
    const payload = {
      name: data?.name,
      email: data?.email,
      mobileNumber: data?.mobileNumber || null,
      certificateUrl: data?.certificateUrl,
    };
    sendMannualAwardees(payload, certificateID || id, actionType === 'preview' ? true : false)
      .then((data) => {
        setAwardeeID(data?.id);
        setMannualResponse(data);
        if (actionType === 'preview') {
          setAwardeeImgData(data?.certificate);
          setAwardeeImgName(data?.name);
          setIsPreview(true);
          toggle();
        } else {
          setShowToaster(true);
          setIsPreview(false);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return {
    handleSubmit,
    control,
    errors,
    formState,
    reset,
    touched,
    watch,
    setValue,
    onSubmit,
    sendAllAwardees,
    setSelectExcelFileResponse,
    selectExcelFileResponse,
    openModal,
    setOpenModal,
    showToaster,
    setShowToaster,
    mannualResponse,
    setMannualResponse,
    handleUpload,
    handleUploadForMannual,
    emailLoading,
    pdfLoading,
    laterLoading,
    isPreview,
    setIsPreview,
    awardeeImgData,
    setAwardeeImgData,
    awardeeImgName,
    setAwardeeImgName,
    open,
    toggle,
  };
};

export default useAwardeeForm;
