/* eslint-disable no-unused-vars */
import { useForm, Controller } from 'react-hook-form';
import { useFetchAwardeesQuery } from '../../components/services/eCertificateApi';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import { formatDate } from '../../../../helper/helperFunction';
export default function useAwardeesListingFilterForm(certificateID) {
  const {
    control,
    handleSubmit,
    formState: { errors, touched },
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });
  const navigate = useNavigate();
  const [dateError, setDateError] = useState(false);

  const location = useLocation();
  const [filterProps, setFilterProps] = useState({});
  const [filterData, setFilterData] = useState({});
  const {
    data: awardeeData,
    error,
    isLoading,
    refetch,
  } = useFetchAwardeesQuery({
    certificateID,
    searchText: '',
    filterProps,
  });

  const onSubmit = (data) => {
    setFilterData(data);
    if (data.fromDate == undefined || data.toDate == '' || data.fromDate == '') {
      setDateError(true);
      return;
    } else if (data.toDate == undefined || data.toDate == '' || data.fromDate == '') {
      setDateError(true);
      return;
    }

    const transFormObj = {
      EmailStatus: data?.emailPending ? 'Pending' : data?.emailSent ? 'Sent' : undefined,
      FromDate: formatDate(data?.fromDate),
      ToDate: formatDate(data?.toDate),
    };

    // Filter out properties with null values
    const filteredTransFormObj = Object.fromEntries(
      Object.entries(transFormObj).filter(([key, value]) => value !== null),
    );

    setFilterProps(filteredTransFormObj);
    navigate(location.pathname + '?id=' + certificateID, {
      state: filteredTransFormObj,
    });
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    reset,
    setValue,
    Controller,
    touched,
    dateError,
    filterProps,
    setDateError,
    filterData,
    watch,
    setFilterData,
    setFilterProps,
  };
}
