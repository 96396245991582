import { DoctAnimationLoading, DoctDropdownSelect } from '@doct-react/app';
import React, { useEffect, useState } from 'react';
import { EventStatus } from '../../../helper/enums/eventStatus';
import { transformMenuItems } from '../../../helper/helperFunction';
import { useGetAllEventsQuery } from '../ManageEvents';
import AbstractFileUpload from './AbstractFileUpload';

function Abstract() {
  const [initiallySelectedEvents, setInitiallySelectedEvents] = useState([]);
  const [dropdownValue, setDropdownValue] = useState('');

  const { data: allEventsData } = useGetAllEventsQuery({
    Status: [
      EventStatus.ACTIVE,
      EventStatus.CLOSED,
      EventStatus.DRAFT,
      EventStatus.ACTIVATIONREQUESTED,
    ],
  });
  const { events = [] } = allEventsData || {};

  const dropdownMenuItemsArray = transformMenuItems(events, 'code', 'name', 'id') || [];

  useEffect(() => {
    setInitiallySelectedEvents(dropdownMenuItemsArray[0]);
  }, [events?.length]);

  useEffect(() => {
    setDropdownValue(initiallySelectedEvents?.value);
  }, [initiallySelectedEvents]);

  const handleChange = (event) => {
    setDropdownValue(event.target.value);
  };
  if (!dropdownValue) {
    return (
      <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
        <DoctAnimationLoading />
      </div>
    );
  }

  return (
    <>
      <div className="w-25">
        <div className="dropdown-select dropdown-select-dashed">
          <DoctDropdownSelect
            value={dropdownValue}
            handleChange={handleChange}
            menuItems={dropdownMenuItemsArray}
          />
        </div>
      </div>
      <AbstractFileUpload dropdownValue={dropdownValue} />
    </>
  );
}

export default Abstract;
