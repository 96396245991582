import React, { useEffect, useState } from 'react';
import { DoctButton, DoctContainer, DoctIcon, DoctTypography } from '@doct-react/core';
import UnlimitedChip from '../CommonChip/UnlimitedChip';
import INRAmount from './INRAmount';
import { useNavigate } from 'react-router-dom';
import * as ROUTE from '../../../routes/constant';
import TopRightStar from '../../../assets/images/TopRightStar.svg';
import { useDispatch } from 'react-redux';
import { setChoosedPlan } from '../API/subscriptionApiSlice';
// import DOMPurify from 'dompurify';
import { encryptData } from '../../../helper/helperFunction';
import { useGetMySubscriptionsQuery } from '../API/subscriptionApi';
import { useGetApplicantUsageQuery } from '../../InstituteDashboard/Applicants/services/courseApplicant.services';
import style from '../SubscriptionModal.module.scss';

export default function PackageBox(props) {
  const {
    name,
    duration,
    durationType,
    subscriptionPlanFeature,
    discountAmount,
    totalAmount,
    id,
    netAmount,
    discountPercent,
    isGSTApplicable,
    saveUpTo,
  } = props;
  const navigate = useNavigate();
  const { data: myPackages } = useGetMySubscriptionsQuery();
  const { data: allApplicantUsage } = useGetApplicantUsageQuery();
  const [planName, setPlanName] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof myPackages !== 'undefined' && myPackages.length > 0) {
      setPlanName(myPackages[0]?.planName);
    }
  }, [myPackages?.length]);

  // const sanitizedSubscriptionPlanFeatureOtherFeatures = DOMPurify.sanitize(
  //   subscriptionPlanFeature?.otherFeatures,
  // );
  // const planId = useSelector((state) => state?.subscriptionData?.choosedPlan?.id);

  const planData = {
    id,
    name,
    totalAmount,
    saveUpTo,
  };

  const storePlanId = () => {
    const encryptedData = encryptData(planData);
    localStorage.setItem('encryptedPlanData', encryptedData);
  };

  return (
    <>
      <DoctContainer>
        <>
          <div
            className={`${style['package-box-body']} ${
              name === 'Institute Premium Plan'
                ? `bg-info text-white`
                : `bg-white text-grey-800 ${style['package-card-border']}`
            } position-relative`}
          >
            <img
              src={TopRightStar}
              alt="TopRightStar"
              className="position-absolute right-0 mt-2 mr-2"
            />
            <div className={`${style['card-title-padding']} text-truncate-two-lines`}>
              <DoctTypography variant="h5" className="font-weight-bold">
                {name}
              </DoctTypography>
            </div>
            <DoctTypography
              variant="subtitle3"
              className={`${name === 'Free' && 'pb-3'} m-0 mb-3 pl-4 ml-1`}
            >
              {duration > 0
                ? `${
                    duration === 1 && durationType !== 'Month'
                      ? `1 Year`
                      : `${duration === 12 ? '1 Year' : duration + 'Month'}`
                  }`
                : ''}
            </DoctTypography>
            <div className="d-flex align-items-center mb-2 pb-1">
              <DoctIcon
                width="10"
                name="check"
                className="ml-3 mr-2"
                fill={name === 'Institute Premium Plan' ? '#FFFFFF' : '#707070'}
              />
              <UnlimitedChip
                blackChipText={
                  subscriptionPlanFeature?.courseMode == 'Unlimited'
                    ? subscriptionPlanFeature?.courseMode
                    : subscriptionPlanFeature?.cousreListing
                }
              />
              <DoctTypography variant="subtitle2" className="m-0">
                <div
                  className={`${style['figure-media-margin']}`}
                  dangerouslySetInnerHTML={{
                    __html: subscriptionPlanFeature?.courseMode && 'Course Listing',
                  }}
                />
              </DoctTypography>
            </div>
            {name === 'Institute Free Plan' ? (
              ''
            ) : (
              <>
                <div className="d-flex align-items-center mb-2 pb-1">
                  <DoctIcon
                    width="10"
                    name="check"
                    className="ml-3 mr-2"
                    fill={name === 'Institute Premium Plan' ? '#FFFFFF' : '#707070'}
                  />
                  <UnlimitedChip
                    blackChipText={
                      subscriptionPlanFeature?.courseApplicantMode == 'Unlimited'
                        ? subscriptionPlanFeature?.courseApplicantMode
                        : subscriptionPlanFeature?.courseApllicantViews
                    }
                  />
                  <DoctTypography variant="subtitle2" className="m-0">
                    {subscriptionPlanFeature?.courseApplicantMode && 'Course Applicants views'}
                  </DoctTypography>
                </div>
                {/* <div className="d-flex align-items-center mb-2 pb-1">
                  <DoctIcon
                    width="10"
                    name="check"
                    className="ml-3 mr-2"
                    fill={name === 'Institute Premium Plan' ? '#FFFFFF' : '#707070'}
                  />
                  <UnlimitedChip
                    blackChipText={
                      subscriptionPlanFeature?.jobMode == 'Unlimited'
                        ? subscriptionPlanFeature?.jobMode
                        : subscriptionPlanFeature?.jobPosting
                    }
                  />{' '}
                  <DoctTypography variant="subtitle2" className="m-0">
                    {subscriptionPlanFeature?.jobMode && 'Jobs Posting'}
                  </DoctTypography>
                </div>
                <div className="d-flex align-items-center mb-2 pb-1">
                  <DoctIcon
                    width="10"
                    name="check"
                    className="ml-3 mr-2"
                    fill={name === 'Institute Premium Plan' ? '#FFFFFF' : '#707070'}
                  />
                  <UnlimitedChip
                    blackChipText={
                      subscriptionPlanFeature?.eventMode == 'Unlimited'
                        ? subscriptionPlanFeature?.eventMode
                        : subscriptionPlanFeature?.eventListing
                    }
                  />
                  <DoctTypography variant="subtitle2" className="m-0">
                    {subscriptionPlanFeature?.eventMode && 'Events Listing'}
                  </DoctTypography>
                </div> */}
              </>
            )}

            {/* <>
              <div className="d-flex align-items-center mb-2 pb-1">
                <DoctIcon
                  width="10"
                  name="check"
                  className="ml-3 mr-2"
                  fill={name === 'Institute Premium Plan' ? '#FFFFFF' : '#707070'}
                />
                <div
                  className={`${
                    name === 'Institute Free Plan'
                      ? `${style['content-card-scrollbar-free-plan']}`
                      : `${style['content-card-scrollbar']}`
                  } ${style['figure-media-margin']} ${style['subscription-card-list-ul']} ml-4`}
                  dangerouslySetInnerHTML={{
                    __html: sanitizedSubscriptionPlanFeatureOtherFeatures,
                  }}
                />
              </div>
            </> */}

            <div className={`${style['plan-Btn-padding']} position-absolute ml-4 pl-1`}>
              {name === 'Institute Free Plan' ? (
                ''
              ) : (
                <INRAmount
                  isGSTApplicable={isGSTApplicable}
                  subscriptionChipText={
                    discountPercent > 0
                      ? saveUpTo
                        ? `Save up to ${discountPercent} % OFF`
                        : `${discountPercent} % OFF`
                      : ''
                  }
                  MainAmount={netAmount}
                  DiscountedAmount={totalAmount}
                  className={discountAmount ? 'd-flex' : 'd-none'}
                  textGrey={name === 'Institute Premium Plan' ? false : true}
                />
              )}
              <DoctButton
                className={
                  name === 'Institute Premium Plan' &&
                  `${style['view-packages-button4']} text-grey-600`
                }
                text={`${
                  name === 'Institute Free Plan' &&
                  planName === 'Institute Free Plan' &&
                  allApplicantUsage?.isPremium === 'true'
                    ? 'Current Plan'
                    : 'Choose Plan'
                }`}
                variant="contained"
                size="medium"
                onButtonClickHandler={() => {
                  // dispatch(resetForm());
                  // dispatch(resetBillingDetails());
                  dispatch(
                    setChoosedPlan({
                      name,
                      totalAmount,
                      id,
                    }),
                  );
                  storePlanId();
                  navigate(
                    `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO}`,
                  );
                }}
                disabled={name === 'Institute Free Plan' && planName === 'Institute Free Plan'}
              />
            </div>
          </div>
        </>
      </DoctContainer>
    </>
  );
}
