import React, { useRef } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import { useOnClickOutside } from '../Dropdown/Dropdown';

export default function DropdownShareButton({ setSharePopupOpen, ahref }) {
  const ref = useRef();

  useOnClickOutside(ref, () => {
    if (setSharePopupOpen) {
      setSharePopupOpen(false);
    }
  });
  const handleShare = (e) => {
    e.preventDefault();

    // const ahref = window.location.href;
    const encodedAhref = encodeURIComponent(ahref);
    var link;

    switch (e.currentTarget.id) {
      case 'facebook':
        link = `https://www.facebook.com/sharer/sharer.php?u=${ahref}`;
        open(link);
        break;

      case 'twitter':
        link = `https://twitter.com/intent/tweet?url=${encodedAhref}`;
        open(link);
        break;

      case 'whatsapp':
        link = `https://api.whatsapp.com/send?text=${encodedAhref}`;
        open(link);
        break;

      case 'instagram':
        link = `https://www.instagram.com/?url=${encodedAhref}`;
        open(link);
        break;

      case 'linkedin':
        link = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedAhref}`;
        open(link);
        break;

      case 'email':
        link = `mailto:?subject=Check%20out%20this%20article&body=${encodedAhref}`;
        open(link);
        break;

      default:
        break;
    }
  };

  const open = (socialLink) => {
    window.open(socialLink, '_blank');
  };

  return (
    <div className="share_options_menu position-absolute social-share-menu" ref={ref}>
      <ListItem button id="whatsapp" onClick={handleShare}>
        <ListItemIcon>
          <WhatsAppIcon style={{ color: '#25D366' }} />
        </ListItemIcon>
        <ListItemText primary="WhatsApp" />
      </ListItem>
      <ListItem button id="facebook" onClick={handleShare}>
        <ListItemIcon>
          <FacebookIcon style={{ color: '#1877F2' }} />
        </ListItemIcon>
        <ListItemText primary="Facebook" />
      </ListItem>
      <ListItem button id="twitter" onClick={handleShare}>
        <ListItemIcon>
          <TwitterIcon style={{ color: '#1DA1F2' }} />
        </ListItemIcon>
        <ListItemText primary="Twitter" />
      </ListItem>
      <ListItem button id="linkedin" onClick={handleShare}>
        <ListItemIcon>
          <LinkedInIcon style={{ color: '#0A66C2' }} />
        </ListItemIcon>
        <ListItemText primary="Linkedin" />
      </ListItem>
      <ListItem button id="instagram" onClick={handleShare}>
        <ListItemIcon>
          <InstagramIcon />
        </ListItemIcon>
        <ListItemText primary="Instagram" />
      </ListItem>
      <div className="divider-line" />
      <ListItem button id="email" onClick={handleShare}>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary="Other Email" />
      </ListItem>
    </div>
  );
}
