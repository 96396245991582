import React from 'react';

import ListDesignation from './List.Designation';
import ListingSubCommittee from './Listing.SubCommittee';

function ListCommittees({ designationData }) {
  return (
    <>
      <div className="mt-3">
        {designationData?.designations?.length > 0 && (
          <ListDesignation designations={designationData?.designations} />
        )}

        {designationData?.subCommittees?.length > 0 && (
          <ListingSubCommittee subCommittees={designationData?.subCommittees} />
        )}
      </div>
    </>
  );
}

export default ListCommittees;
