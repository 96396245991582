import React, { useState } from 'react';
import QuickCardHighted from '../QuickCard/QuickCardHighted';
import { useGetCourseQuickAccessQuery } from '../../services/quickAccess.services';
import CoursesEmptyStateImage from '../../../../assets/images/empty-state-courses.png';
import * as ROUTE from '../../../../routes/constant';
import { useNavigate } from 'react-router';
import UserAppCard from '../QuickCard/UserAppCard';
import EmptyStateCard from '../QuickCard/EmptyStateCard';

function CourseQuickAccessWrapper() {
  const { data: courseData, isLoading } = useGetCourseQuickAccessQuery('', {
    refetchOnMountOrArgChange: true,
  });

  const {
    appliedToWebsiteCounts,
    draftCourses,
    openedForApplyCounts,
    totalCourses,
    totalNewApplicants,
    newApplicants,
  } = courseData || {};

  const [loadRecord, setLoadRecord] = useState(1);

  const handleNextData = () => {
    setLoadRecord(loadRecord + 1);
  };

  const handlePreviewData = () => {
    setLoadRecord(loadRecord - 1);
  };

  const navigate = useNavigate();

  return (
    <QuickCardHighted title="Courses">
      {(totalCourses && !isLoading) || isLoading ? (
        <UserAppCard
          status={'Courses'}
          totalRecords={totalCourses}
          firstSubHeadingCount={openedForApplyCounts}
          secondSubHeadingCount={appliedToWebsiteCounts}
          thirdSubHeadingCount={draftCourses}
          newApplicantCount={totalNewApplicants}
          newApplicants={newApplicants}
          navigate={navigate}
          handleNextData={handleNextData}
          handlePreviewData={handlePreviewData}
          loadRecord={loadRecord}
          isLoading={isLoading}
        />
      ) : (
        <EmptyStateCard
          icon={<img src={CoursesEmptyStateImage} alt="Add your first Course Now!" />}
          title={'Add your first Course Now!'}
          btnText="List a Course"
          bgcolor="courses-btn-bgcolor"
          onPressFunction={() => {
            navigate(`/${ROUTE.DASHBOARD}/${ROUTE.INSTITIUTE}/${ROUTE.INSTITUTE_ADD_COURSE}`);
          }}
        />
      )}
    </QuickCardHighted>
  );
}

export default CourseQuickAccessWrapper;
