import React from 'react';
import * as ROUTE from '../../../routes/constant';
import NewECertificateActionBtn from './shared/NewECertificateActionBtn';

const allECertificateActionBar = {
  [`/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_E_CERTIFICATES}`]: {
    pathname: `/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_E_CERTIFICATES}`,
    title: 'E-Certificates',
    // navigateButtonLink: '/dashboard/e-certificate/all-e-certificates',
    buttonText: 'New E- Certificate',
    buttonIcon: 'plus',
    isButtonDisabled: false,
    btnComponent: <NewECertificateActionBtn />,
  },
};

export default allECertificateActionBar;
