import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@mui/material/Skeleton';

function SmallCardSkeletonView() {
  return (
    <Box width={330} marginRight={0.5}>
      <Box
        sx={{ bgcolor: 'grey.200' }}
        variant="rect"
        width="81%"
        height={100}
        className="mt-1 border-radius"
      >
        <div className="align-items-center d-flex">
          <Skeleton
            sx={{ bgcolor: 'grey.300' }}
            variant="h1"
            width="30%"
            height={50}
            className="mt-4 border-radius ml-3"
          />
          <Skeleton
            sx={{ bgcolor: 'grey.300' }}
            variant="h1"
            width="70%"
            height={20}
            className="mt-4 border-radius mr-2 ml-2"
          />
        </div>
      </Box>
    </Box>
  );
}

export default SmallCardSkeletonView;
