import React from 'react';
import SubscriptionsPackages from './CoursePackages';
// import JobPackages from './JobPackages';

const Subscriptions = () => {
  return (
    <div>
      <SubscriptionsPackages />
      {/* <JobPackages /> */}
    </div>
  );
};

export default Subscriptions;
