import React from 'react';
import { NewMemberShipCreationLayout } from './NewMemberShipCreationLayout';
import NewMembershipform from './components/NewMembershipform';
import '../newMembership.scss';
import { useMemberShipForm } from './components/hooks/useForm.CreateMembershipFrom';
import SuccessModal from './components/SuccessModal';
import FailureModal from './components/FailureModal';

export const NewMemberShipPlanCreationForm = () => {
  const {
    errors,
    control,
    handleFormSubmit,
    isSuccessFull,
    setIsSuccessFull,
    newMembershipLoading,
    error,
    setError,
  } = useMemberShipForm();

  return (
    <form onSubmit={handleFormSubmit}>
      <SuccessModal isSuccess={isSuccessFull} setIsSuccessFull={setIsSuccessFull} />
      <FailureModal isError={error} setIsError={setError} />
      <NewMemberShipCreationLayout newMembershipLoading={newMembershipLoading}>
        <div className="bg-grey-100">
          <NewMembershipform
            control={control}
            errors={errors}
            newMembershipLoading={newMembershipLoading}
          />
        </div>
      </NewMemberShipCreationLayout>
    </form>
  );
};
