import React from 'react';
import { DoctButton, DoctTypography } from '@doct-react/core';
import { useDispatch } from 'react-redux';

import { setModalTitle, toggleSubCommitteeModal } from '../../slice/committee.slice';

import CreateCommittee from '../../../../../assets/images/committee/create-committee.svg';

const CreateSubCommittee = () => {
  const dispatch = useDispatch();

  return (
    <div className="text-center">
      <img src={CreateCommittee} alt="Add Designation" />
      <DoctTypography variant="subtitle1" fontWeight="medium" className="text-grey-600">
        Create Sub-Committee
      </DoctTypography>
      <DoctTypography variant="body2" fontWeight="regular" className="text-grey-600">
        Add different types of committees, wings,
        <br /> sections, verticals, and departments as per <br /> your structure.
      </DoctTypography>
      <div className="d-flex justify-content-center">
        <DoctButton
          text="Create"
          icon="plus"
          iconPosition="left"
          onButtonClickHandler={() => {
            dispatch(setModalTitle('Create Sub-Committee'));
            dispatch(toggleSubCommitteeModal());
          }}
        />
      </div>
    </div>
  );
};

export default CreateSubCommittee;
