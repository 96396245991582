import React, { useEffect, useState } from 'react';
// import { userSelector } from '../../../../components';
import { useSelector } from 'react-redux';
import JobSubscriptionUsageCard, {
  FreeJobGreenCard,
  FreeLimitUsed,
  PlanLimitedUsed,
} from './JobSubscriptionUsageCard/JobSubscriptionUsageCard';
import { getJobUsageDetails } from '../../../SubscriptionModal/helper/getJobUsage';
import { selectCancelJob, setDeleteJob } from '../../ManageJob/slice/manageJobSlice';

const JobApplicantUsage = () => {
  const [jobApplicantData, setJobApplicantData] = useState();
  const cancelJobPosting = useSelector(selectCancelJob);
  const deletJobPosting = useSelector(setDeleteJob);

  const token = useSelector((state) => state.app?.token);

  const type = useSelector((state) => state?.app?.user?.tenant?.type);

  const isPostJobType = type === 'PostAJob';

  useEffect(() => {
    const fetchJobUsageDetails = () => {
      const response = getJobUsageDetails(token);
      response.then((data) => {
        localStorage.setItem('jobUsage', JSON.stringify(data?.data ?? {}));
        setJobApplicantData(data?.data);
      });
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchJobUsageDetails();
      }
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Fetch job usage details initially
    fetchJobUsageDetails();

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [token, cancelJobPosting, deletJobPosting]);

  //TEMP: Comment code for User
  // const user = useSelector(userSelector);
  return (
    <div>
      {
        // Whatever the status of Tenant user, Usage Box should be display
        // user?.tenant?.status === 'Pending' ? (
        //   ''
        // ) :
        isPostJobType && (
          <>
            {jobApplicantData?.planName !== 'Recruiter Free Plan' &&
              jobApplicantData?.isLimitExceed === false && (
                <JobSubscriptionUsageCard
                  jobPostedCount={jobApplicantData?.jobPostedCount}
                  totalJobListingLimit={jobApplicantData?.totalJobListingLimit}
                  planName={jobApplicantData?.planName}
                  pendingApprovalCounts={jobApplicantData?.pendingApprovalCounts}
                />
              )}
            {jobApplicantData?.planName === 'Recruiter Free Plan' &&
              jobApplicantData?.isLimitExceed === true && (
                <FreeLimitUsed
                  jobPostedCount={jobApplicantData?.jobPostedCount}
                  totalJobListingLimit={jobApplicantData?.totalJobListingLimit}
                />
              )}
            {jobApplicantData?.isLimitExceed &&
              jobApplicantData?.validity === null &&
              jobApplicantData?.planName !== 'Recruiter Free Plan' && (
                <PlanLimitedUsed
                  jobPostedCount={jobApplicantData?.jobPostedCount}
                  totalJobListingLimit={jobApplicantData?.totalJobListingLimit}
                  pendingApprovalCounts={jobApplicantData?.pendingApprovalCounts}
                  planName={jobApplicantData?.planName}
                />
              )}
            {jobApplicantData?.planName === 'Recruiter Free Plan' &&
              jobApplicantData?.pendingApprovalCounts === 0 &&
              !jobApplicantData?.isLimitExceed && (
                <FreeJobGreenCard
                  jobPostedCount={jobApplicantData?.jobPostedCount}
                  totalJobListingLimit={jobApplicantData?.totalJobListingLimit}
                />
              )}
          </>
        )
        // ) : (
        //   ''
        // )
      }
    </div>
  );
};

export default JobApplicantUsage;
