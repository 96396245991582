import { useCallback } from 'react';
import fieldProfileSetting from '../field.profileSettings';

export default function useHookDefaultValue() {
  const prepareFormValue = useCallback((data, locationData) => {
    const country = locationData?.find(({ name }) => {
      return name == data?.[fieldProfileSetting.country.name];
    });

    const state = country?.states?.find(({ name }) => {
      return name == data?.[fieldProfileSetting.state.name];
    });

    const city = state?.cities?.find(({ name }) => {
      return name == data?.[fieldProfileSetting.city.name];
    });
    const joinAddress = Object.values(data?.[fieldProfileSetting.address.name] || {})
      .filter((value) => value !== null)
      .join(', ');

    return {
      [fieldProfileSetting.postalCode.inComponentName]: data?.[fieldProfileSetting.postalCode.name],
      [fieldProfileSetting.address.name]: joinAddress || '',
      [fieldProfileSetting.country.name]: country
        ? {
            label: data?.[fieldProfileSetting.country.name],
            value: country?.id,
          }
        : {
            label: 'India',
            value: 1,
          },
      [fieldProfileSetting.state.name]: state
        ? {
            label: data?.[fieldProfileSetting.state.name],
            value: state?.id || '',
          }
        : null,
      [fieldProfileSetting.city.name]: city
        ? {
            label: data?.[fieldProfileSetting.city.name],
            value: city?.id || '',
          }
        : null,
    };
  }, []);

  return { prepareFormValue };
}
