/* eslint-disable prettier/prettier */
import axios from 'axios';

const BASE_URL = 'https://api.dochub.in/coursecenter/tenant/courses';

export default async function APICourseApplicantReport(id, searchText, status, isViewedApplicants) {
  const urlForAPI = `${BASE_URL}/${id}/course-applicant-report?downloadtype=ExcelSheet${
    status ? `&Status=${status}` : ''
  }${searchText ? `&SearchText=${searchText}` : ''}${
    isViewedApplicants ? `&IsViewedApplicants=true` : ''
  }`;

  try {
    const fileName = 'CourseApplicantReport.csv';
    const response = await axios.get(urlForAPI, { responseType: 'blob' });

    const contentType = response.headers['content-type'];
    if (contentType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      throw new Error('Invalid content type');
    }

    const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return response;
  } catch (error) {
    console.error('Error while downloading file:', error);
  }
}
