import * as ROUTE from '../../../routes/constant';

const billingDetailsLayoutPageActionBar = {
  [`/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_DETAILS}`]: {
    pathname: `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_DETAILS}`,
    title: 'Billing & Subscriptions',
    isButtonDisabled: true,
  },
};

export default billingDetailsLayoutPageActionBar;
