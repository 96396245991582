import React, { useState } from 'react';
import { DoctTypography } from '@doct-react/core';

import { ListingActivityLog, SearchActivityLog } from './components';

import { useGetActivitiesQuery } from './services/activityLog.services';

export default function ActivityLog() {
  const [debouncedSearchVal, setDebouncedSearchVal] = useState('');

  const { data, isLoading } = useGetActivitiesQuery({
    queryParams: { searchText: debouncedSearchVal },
  });

  const { activities, totalRecords } = data || {};

  return (
    <>
      <div className={!totalRecords && !debouncedSearchVal ? 'visibility-hidden' : ''}>
        <SearchActivityLog setDebouncedSearchVal={setDebouncedSearchVal} />
      </div>
      <div className="panel-height-4x d-flex align-items-center">
        <DoctTypography
          variant="body3"
          letterSpacing={'0.48'}
          textTransform="uppercase"
          className="text-grey-600 my-0"
        >
          <span className={!totalRecords ? 'visibility-hidden' : ''}>
            {totalRecords || 0} ACTIVITIES
          </span>
        </DoctTypography>
      </div>
      {<ListingActivityLog isLoading={isLoading} activities={activities} />}
    </>
  );
}
