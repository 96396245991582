/* eslint-disable prettier/prettier */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as API_ENDPOINTS from '../../../../api/apiEndpoints';

export const courseApplicantApi = createApi({
  reducerPath: 'courseApplicantApi',

  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = getState().app.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['CourseApplicant'],

  // endpoints: (builder) => ({
  //   getApplicantData: builder.query({
  //     query: ({ id, searchText, status }) => {
  //       return id
  //         ? `${process.env.REACT_APP_BASE_API_URL}/${API_ENDPOINTS.COURSE_CENTER}${
  //             API_ENDPOINTS.BUSINESS_BASE_API_ROUTE
  //           }/${API_ENDPOINTS.COURSES}/${id?.includes('All Courses') ? 0 : id}/applicants?${
  //             searchText ? `&searchText=${searchText}` : ''
  //           }${status ? `&status=${status}` : ''}`
  //         : '';
  //     },
  //     providesTags: ['CourseApplicant'],
  //   }),

  endpoints: (builder) => ({
    getApplicantData: builder.query({
      query: ({ id, searchText, status }) => {
        return id
          ? `${process.env.REACT_APP_BASE_API_URL}/${API_ENDPOINTS.COURSE_CENTER}${
              API_ENDPOINTS.BUSINESS_BASE_API_ROUTE
            }/${API_ENDPOINTS.COURSES}/${id?.includes('All Courses') ? 0 : id}/applicants?${
              searchText ? `&searchText=${searchText}` : ''
            }${status ? `&status=${status}` : ''}`
          : '';
      },
      providesTags: ['CourseApplicant'],
    }),

    updateCourseApplicantStatus: builder.mutation({
      query: ({ userId, status, courseId }) => ({
        url: `${process.env.REACT_APP_BASE_API_URL}/${API_ENDPOINTS.COURSE_CENTER}/${
          API_ENDPOINTS.COURSE_APPLICATIONS
        }/${courseId === 'All Courses' ? 0 : courseId}/${API_ENDPOINTS.CHANGE_COURSE_STATUS}`,
        method: 'PUT',
        body: { status, userId },
      }),
      invalidatesTags: ['subscription-usage'],
    }),
    getApplicantUsage: builder.query({
      query: () => {
        return `${process.env.REACT_APP_BASE_API_URL}/${API_ENDPOINTS.COURSE_CENTER}${API_ENDPOINTS.BUSINESS_BASE_API_ROUTE}/subscription-usage`;
      },
      providesTags: ['subscription-usage'],
    }),
  }),
});

export const {
  useGetApplicantDataQuery,
  useUpdateCourseApplicantStatusMutation,
  useGetApplicantUsageQuery,
  // useGetAplicantViewUsageQuery,
} = courseApplicantApi;
