import React from 'react';
import * as ROUTE from '../../routes/constant';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSubscriptionActiveTabIndex } from '../featuresSlice';

const billingSubscriptionMenuItems = (pathname = '') => {
  const dispatch = useDispatch();

  const menuItemsArray = [
    {
      link: (
        <Link
          to={`/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}`}
          className={
            pathname ===
            `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}`
              ? 'active'
              : ''
          }
          onClick={() => {
            dispatch(setSubscriptionActiveTabIndex(0));
          }}
        >
          Subscriptions
        </Link>
      ),
    },
    {
      link: (
        <Link
          to={`/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_DETAILS}`}
          className={
            pathname ===
            `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_DETAILS}`
              ? 'active'
              : ''
          }
        >
          Billing Details
        </Link>
      ),
    },
  ];
  return menuItemsArray;
};

const billingSubscriptionSidebar = (pathname) => billingSubscriptionMenuItems(pathname);

export default billingSubscriptionSidebar;
