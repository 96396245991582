import { DoctButton, DoctContainer, DoctTypography } from '@doct-react/core';
import React from 'react';
import courseSuccessTick from '../../../../../../assets/icons/institute-dashboard/course_success_tick.svg';
import CounsellingCTA from '../static/CounsellingCta/CounsellingCTA';
import * as ROUTE from '../../../../../../routes/constant';
import { useNavigate } from 'react-router-dom';
import FooterCopyRight from '../../../../../../layout/components/Footer.CopyRight';
import '../static/CounsellingCta/CounsellingCta.scss';

const CourseSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-grey-200 py-1 container-height-without-header">
        <DoctContainer>
          <div className="my-4">
            <div className="w-100 text-center">
              <div className="w-100 mx-auto border-radius-12">
                <div className="bg-white border-radius-12 p-3">
                  <div className="background_container border-radius-12  p-3">
                    <img src={courseSuccessTick} className="mt-4" />
                    <DoctTypography variant="h4" className="mt-2">
                      Your course has been submitted successfully.
                    </DoctTypography>
                    <DoctTypography variant="body2" fontWeight="regular" className="text-grey-600">
                      Upon approval, your course will be published on our platform and you will
                      receive a confirmation email. <br /> Once your course goes live, you will
                      start receiving applications and inquiries from students.
                    </DoctTypography>

                    <div className="my-4 d-flex align-items-center justify-content-center">
                      <DoctButton
                        onButtonClickHandler={() => {
                          navigate(
                            `/${ROUTE.DASHBOARD}/${ROUTE.INSTITUTE}/${ROUTE.INSTITUTE_COURSES}`,
                          );
                        }}
                        variant="outlined"
                        text="Go to Dashboard"
                        size="medium"
                        className="m-1"
                      />
                      <DoctButton
                        onButtonClickHandler={() => {
                          navigate(
                            `/${ROUTE.DASHBOARD}/${ROUTE.INSTITUTE}/${ROUTE.INSTITUTE_ADD_COURSE}`,
                          );
                        }}
                        text="List more Courses"
                        size="medium"
                        className="m-1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CounsellingCTA />
          </div>
        </DoctContainer>
      </div>
      <FooterCopyRight />
    </>
  );
};

export default CourseSuccessPage;
