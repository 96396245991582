import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BUSINESS_BASE_API_ROUTE,
  COURSES,
  COURSE_CENTER,
  ECERTIFICATEs,
  ENTERPRISE_BASE_API_ROUTE,
  EVENT,
  EVENT_BASE_API_ROUTE,
  JOBS,
  JOB_CENTER,
  MEMBERSHIP,
  SUMMARY,
} from '../../../api/apiEndpoints';

export const quickAccessApi = createApi({
  reducerPath: 'quickAccessApi',

  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = getState().app.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: ['QuickAccess'],

  endpoints: (builder) => ({
    getEventQuickAccess: builder.query({
      query: () =>
        `${process.env.REACT_APP_BASE_API_URL}${EVENT_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${EVENT}/${SUMMARY}`,
    }),

    getJobQuickAccess: builder.query({
      query: () =>
        `${process.env.REACT_APP_BASE_API_URL}/${JOB_CENTER}${BUSINESS_BASE_API_ROUTE}/${JOBS}/${SUMMARY}`,
    }),

    getCourseQuickAccess: builder.query({
      query: () =>
        `${process.env.REACT_APP_BASE_API_URL}/${COURSE_CENTER}${BUSINESS_BASE_API_ROUTE}/${COURSES}/${SUMMARY}`,
    }),

    getMembershipQuickAccess: builder.query({
      query: () =>
        `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${MEMBERSHIP}/${SUMMARY}`,
    }),
    getEcertificateAwardeesQuickAccess: builder.query({
      query: () =>
        `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${ECERTIFICATEs}/${SUMMARY}`,
    }),
  }),
});

export const {
  useGetEventQuickAccessQuery,
  useGetJobQuickAccessQuery,
  useGetCourseQuickAccessQuery,
  useGetMembershipQuickAccessQuery,
  useGetEcertificateAwardeesQuickAccessQuery,
} = quickAccessApi;
