export const contacts = {
  enterprise: {
    email: 'enterprise@docthub.com',
    mobile: '+91 9090902626',
  },
  events: {
    email: 'events@docthub.com',
    mobile: '+91 8320875943',
    label: 'Events Helpline',
  },
  recruiter: {
    email: 'jobs@docthub.com',
    mobile: '+91 9510403130',
    label: 'Recruiter Helpline',
  },
  institute: {
    email: 'courses@docthub.com',
    mobile: '+91 9510202289',
    label: 'Institute Helpline',
  },
  'manage-memberships': {
    email: 'events@docthub.com',
    mobile: '+91 8320875943',
    label: 'Memberships Helpline',
  },
};
