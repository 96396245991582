import { DoctAnimationLoading } from '@doct-react/app';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { EnterpriseFormLayout } from '../../../layout';
import CloseFormModal from '../../../shared/ui/CloseFormModal';
import EnterpriseFormCard from '../../../shared/ui/EnterpriseSectionCard/EnterpriseForm.card';
import { PreviewJob } from '../PreviewJob';
import useTenantJobs from './hooks/useTenantJobs';
import {
  selectApiData,
  selectIsLoadingFetchJobDetails,
  setApiData,
  setEducationArray,
  setKeySkills,
  setRawFormValues,
} from './slice/manageJobSlice';
import * as ROUTE from '../../../routes/constant';
import useQueryHooks from '../../../hooks/useQueryHooks';
import { fetchJobById } from './services/manageJob.services';
import InfoModalWrapper from '../../../components/InfoModalWrapper';

const RecruiterPostAJob = () => {
  const { pathname } = location;
  const jobId = pathname.replace(/[^0-9]/g, '');
  const type = useSelector((state) => state?.app?.user?.tenant?.status);
  const isAccountPending = type === 'Pending';
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const { id, ...query } = useQueryHooks();

  const isLoadingFetchJobDetails = useSelector(selectIsLoadingFetchJobDetails);
  const dataFromApi = useSelector(selectApiData);

  useEffect(() => {
    if (jobId || query?.duplicateId) {
      dispatch(fetchJobById(jobId || query?.duplicateId));
    }
    return () => {
      dispatch(setApiData({}));
    };
  }, []);

  const {
    formParts,
    handleOnPreviewApproved,
    handleJobFormSubmit,
    isPreview,
    setIsPreview,
    modifiedJobFormValues,
    isJobPostLoading,
    isJobEditLoading,
  } = useTenantJobs();
  const [openExitModal, setOpenExitModal] = useState(false);
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleSaveDraft = async () => {
    await handleJobFormSubmit({ status: 'Draft' });
  };

  function CloseModal() {
    return (
      <CloseFormModal
        open={openExitModal}
        clasname="disable_modal_outside_click"
        handleClose={(flag) => {
          // dispatch(setRawFormValues(null));
          // dispatch(setEducationArray(null));
          // dispatch(setKeySkills(null));
          setOpenExitModal(false);
          flag === 'Discard' && navigate(-1, { replace: true });
        }}
        onConfirmExit={() => {
          setOpenExitModal(false);
          dispatch(setRawFormValues(null));
          dispatch(setEducationArray(null));
          dispatch(setKeySkills(null));
          navigate(`/${ROUTE.DASHBOARD}/${ROUTE.RECRUITER}/${ROUTE.POSTING}`);
        }}
        discard="Discard"
        saveDraft="Save Draft"
        handleSaveAsDraft={handleSaveDraft}
      />
    );
  }

  if (isLoadingFetchJobDetails) {
    return <DoctAnimationLoading />;
  }

  if (
    (jobId || query?.duplicateId) &&
    (!!dataFromApi && !!Object.keys(dataFromApi || {}))?.length
  ) {
    return null;
  }

  // Refactored code for better code readabity

  const handleClose = () => {
    setOpenExitModal(true);
  };

  const handleBackToEdit = () => {
    setIsPreview(false);
  };

  const handleSubmit = async () => {
    try {
      // TODO: temp commented
      // const totalActiveJobs = parseInt(localStorage.getItem('totalActiveJobs'), 10) || 0;
      const jobUsage = JSON.parse(localStorage.getItem('jobUsage')) || {};
      const allowToPostADraftJob = jobUsage.isFreeValidityExpired || jobUsage.isLimitExceed;
      if (allowToPostADraftJob && isPreview) {
        {
          isAccountPending
            ? setIsOpenModal(true)
            : await handleJobFormSubmit({
                status: 'Draft',
                allowToPostADraftJob: allowToPostADraftJob,
                state: {
                  backFromSubscription: '/dashboard/recruiter/posting',
                },
              });
        }

        return;
      } else if (!isPreview) {
        await handleJobFormSubmit();
      } else {
        handleOnPreviewApproved();
      }
    } catch (error) {
      console.error('Error during handleSubmit:', error);
      // Handle the error appropriately here
    }
  };

  return (
    <EnterpriseFormLayout
      formHeaderSecondaryButtonClickHandler={handleSaveDraft}
      closeHandler={handleClose}
      formFooterSecondaryBtnClickHandler={handleBackToEdit}
      showFormFooterSecondaryBtn={isPreview}
      handleSubmit={handleSubmit}
      formFooterSecondaryBtnText="Back to Edit"
      formSecondaryBtnText="Save Draft"
      formTitle="Post a Job"
      formDisclaimer="By posting a job, you agree to Docthub's Policy and Terms of use."
      formPrimaryBtnText={!isPreview ? 'Preview & Post' : 'Submit Post'}
      primaryButtonType={!isPreview ? 'semantic-info' : 'semantic-success'}
      formFooterSecondaryBtnType="secondary"
      isAdding={isJobPostLoading || isJobEditLoading}
    >
      <>
        {!isPreview &&
          formParts.map((formPart, i) => {
            return <EnterpriseFormCard key={i} {...formPart} />;
          })}
        {isPreview && <PreviewJob jobDetails={modifiedJobFormValues} />}
      </>
      <CloseModal />
      <InfoModalWrapper isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </EnterpriseFormLayout>
  );
};

export default RecruiterPostAJob;
