import React, { useEffect, useState } from 'react';
import { checkKeyDownAndPreventDefault } from '../../helper/helperFunction';
import { Toaster } from '../../shared';
import { FormFooter, FormHeader } from './components';
import './enterpriseForm.scss';
import { useLocation } from 'react-router-dom';

const EnterpriseFormLayout = ({
  children,
  formSecondaryBtnText,
  formTitle,
  formDisclaimer,
  formPrimaryBtnText,
  handleSubmit,
  primaryButtonType,
  closeHandler,
  formFooterSecondaryBtnText,
  showFormFooterSecondaryBtn,
  formFooterSecondaryBtnType,
  formFooterSecondaryBtnClickHandler,
  formHeaderSecondaryButtonClickHandler,
  isAdding,
  errorMessage,
  showSecondaryHeaderBtn,
  iconName,
  isConfirmationScreen,
  isRequired,
  isFooterShow,
}) => {
  const [showTost, setShowTost] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      setShowTost(true);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (showTost) {
      setTimeout(() => {
        onTostCloseHandler();
      }, 2000);
    }
  }, [showTost]);

  const onTostCloseHandler = () => {
    setShowTost(false);
  };

  const location = useLocation();

  return (
    <form
      className={`bg-grey-200 ${
        location?.pathname === '/dashboard/subscription/all-packages/billing-info/order-summary' &&
        'confirmation-screen-height'
      }`}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      onKeyDown={(e) => checkKeyDownAndPreventDefault(e)}
      autoComplete="off"
      noValidate={isRequired}
    >
      {showTost && (
        <div className="position-fixed tost-container">
          <Toaster variant={'error'} text={errorMessage} onPressedClose={onTostCloseHandler} />
        </div>
      )}
      <FormHeader
        formHeaderSecondaryButtonClickHandler={formHeaderSecondaryButtonClickHandler}
        closeHandler={closeHandler}
        formSecondaryBtnText={formSecondaryBtnText}
        formTitle={formTitle}
        isAdding={isAdding}
        showSecondaryHeaderBtn={showSecondaryHeaderBtn}
        iconName={iconName}
      />
      {children}
      <FormFooter
        formFooterSecondaryBtnClickHandler={formFooterSecondaryBtnClickHandler}
        formFooterSecondaryBtnText={formFooterSecondaryBtnText}
        showFormFooterSecondaryBtn={showFormFooterSecondaryBtn}
        formFooterSecondaryBtnType={formFooterSecondaryBtnType}
        btnType={primaryButtonType}
        formDisclaimer={formDisclaimer}
        formPrimaryBtnText={formPrimaryBtnText}
        isAdding={isAdding}
        isFooterShow={isFooterShow}
        isConfirmationScreen={isConfirmationScreen}
      />
    </form>
  );
};

export default EnterpriseFormLayout;
