import React, { useEffect, useState } from 'react';
import { Dropdown } from '../../../../shared';
import { DoctButton } from '@doct-react/core';
import AssignNewAwardeePopUp from './PopUps/AssignNewAwardeePopUp';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getCertificate } from './EcertificateAPIcall';

export default function AddAwardeeActionBtn() {
  const [newAwardeePopUp, setNewAwardeePopUp] = useState(false);
  const [certificateData, setCertificateData] = useState([]);

  let certificateName;

  const location = useLocation();
  const awardeeData = useSelector((state) => state?.eCertificateData?.awardeeData) ?? {};

  useEffect(() => {
    getCertificate().then((res) => {
      setCertificateData(res?.data?.eCertificates);
    });
  }, []);

  const handleAssignAwardee = () => {
    setNewAwardeePopUp(true);
  };

  const awardeeClose = () => {
    setNewAwardeePopUp(false);
  };
  const sendEmailPopUpHandleClose = () => {
    setNewAwardeePopUp(true);
  };

  const certificateID = location?.search?.includes('id')
    ? location?.search?.split('=')[1]
    : certificateData[0]?.id;

  if (certificateID && certificateData?.length > 0) {
    const certificateIdInNumber = Number(certificateID);
    const filteredData = certificateData.filter((item) => item.id === certificateIdInNumber);
    certificateName = filteredData[0]?.name;
  }

  return (
    <>
      <Dropdown className="ml-auto">
        <DoctButton
          variant="contained"
          text={`Add Awardee`}
          size="medium"
          icon={`plus`}
          onButtonClickHandler={handleAssignAwardee}
          disabled={awardeeData?.totalRecords <= 0 ? true : false}
        />
      </Dropdown>

      {newAwardeePopUp && (
        <AssignNewAwardeePopUp
          isPopUp
          open={newAwardeePopUp}
          handleClose={awardeeClose}
          sendEmailPopUpHandleClose={sendEmailPopUpHandleClose}
          name={certificateName}
          id={certificateID}
        />
      )}
    </>
  );
}
