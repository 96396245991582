import React from 'react';
import EditMemberFormFooter from './EditMemberFormFooter';
import EditMemberFormHeader from './EditMemberFormHeader';

const EditMemberFormBodyContainer = ({ children, setStatus, editMemberLoading }) => {
  return (
    <>
      <EditMemberFormHeader setStatus={setStatus} editMemberLoading={editMemberLoading} />{' '}
      {children} <EditMemberFormFooter editMemberLoading={editMemberLoading} />
    </>
  );
};

export default EditMemberFormBodyContainer;
