import * as ROUTE from '../../routes/constant';

const subscriptionLayoutPageActionBar = {
  [`/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}`]: {
    pathname: `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}`,
    title: 'Billing & Subscriptions',
    isButtonDisabled: true,
  },
};

export default subscriptionLayoutPageActionBar;
