import { createSlice } from '@reduxjs/toolkit';

const sliceName = 'features';

const initialState = {
  manageEventsActiveTabIndex: 0,
  jobPostActiveTabIndex: 0,
  courseListingActiveTabIndex: 0,
  showToasterWithProps: {},
  membershipActiveTabIndex: 0,
  eCertificatesActiveTabIndex: 0,
};

export const featuresSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setManageEventsActiveTabIndex: (state, action) => {
      state.manageEventsActiveTabIndex = action.payload;
    },

    setCourseListingsActiveTabIndex: (state, action) => {
      state.courseListingActiveTabIndex = action.payload;
    },

    setJobPostActiveTabIndex: (state, action) => {
      state.manageEventsActiveTabIndex = action.payload;
    },

    setShowToasterWithParams: (state, action) => {
      const { variant, position, message } = action?.payload || {};
      state.showToasterWithProps = { variant, position, message };
    },

    setMembershipActiveTabIndex: (state, action) => {
      state.membershipActiveTabIndex = action.payload;
    },

    setSubscriptionActiveTabIndex: (state, action) => {
      state.membershipActiveTabIndex = action.payload;
    },
    setECertificatesActiveTabIndex: (state, action) => {
      state.eCertificatesActiveTabIndex = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setManageEventsActiveTabIndex,
  setShowToasterWithParams,
  setJobPostActiveTabIndex,
  setCourseListingsActiveTabIndex,
  setMembershipActiveTabIndex,
  setSubscriptionActiveTabIndex,
  setECertificatesActiveTabIndex,
} = featuresSlice.actions;

export const mangeEventsActiveTabIndexState = (state) =>
  state[sliceName].manageEventsActiveTabIndex;

export const jobPostActiveTabIndexState = (state) => state[sliceName].manageEventsActiveTabIndex;
export const courseListingActiveTabIndexState = (state) =>
  state[sliceName].courseListingActiveTabIndex;

export const showToasterWithPropsState = (state) => state[sliceName].showToasterWithProps;

export default featuresSlice.reducer;
