import React from 'react';
import { DoctIcon } from '@doct-react/core';

const InfoIconWithTooltip = React.forwardRef(function InfoIconWithTooltip(props, ref) {
  return (
    <span {...props} ref={ref}>
      <DoctIcon width="24" height="24" name="infoOutline" className="text-grey-400" />
    </span>
  );
});

export default InfoIconWithTooltip;
