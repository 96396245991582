import React, { useEffect } from 'react';
import { DoctTabWrapper, DoctTabContent } from '@doct-react/app';
import LayoutLoading from '../../../layout/Layout.loading';
import CardEvent from './components/Card.Event';
import { EventStatus } from '../../../helper/enums/eventStatus';
import { useGetEventsQuery } from './services/events.services';
import NoDataFound from '../../../shared/ui/NoDataFound';
import usePrepareEventTabList from './hooks/usePrepareTab';
import { mangeEventsActiveTabIndexState, setManageEventsActiveTabIndex } from '../../featuresSlice';
import { useDispatch, useSelector } from 'react-redux';
import './Events.scss';

function ListingOfCard({ isLoading, events }) {
  return (
    <LayoutLoading isLoading={isLoading}>
      <>
        {!events?.length && !isLoading && <NoDataFound />}
        {events?.map(
          ({
            endDate,
            name,
            status,
            type,
            id,
            code,
            image,
            rejectionReason,
            availableTicket,
            maxAttendees,
            startDate,
            modifiedDate,
            numberOfGathering,
          }) => {
            return (
              <CardEvent
                key={name}
                type={type}
                status={status}
                name={name}
                endDate={endDate}
                image={{ coverImageUrl: image.coverImageUrl ? image.coverImageUrl : '' }}
                id={id}
                code={code}
                rejectionReason={rejectionReason}
                availableTicket={availableTicket}
                maxAttendees={maxAttendees}
                startDate={startDate}
                modifiedDate={modifiedDate}
                numberOfGathering={numberOfGathering}
              />
            );
          },
        )}
      </>
    </LayoutLoading>
  );
}

export default function MangeEvents() {
  const dispatch = useDispatch();

  const { data, isLoading, refetch: allEventRefetch } = useGetEventsQuery('');
  const { data: dataActive, refetch: activeEventRefetch } = useGetEventsQuery({
    status: EventStatus.ACTIVE,
  });
  const { data: dataClosed, refetch: closedEventRefetch } = useGetEventsQuery({
    status: EventStatus.CLOSED,
  });
  const { data: dataDraft, refetch: draftEventRefetch } = useGetEventsQuery({
    status: EventStatus.DRAFT,
  });
  const { data: dataCompleted, refetch: completedEventRefetch } = useGetEventsQuery({
    status: EventStatus.COMPLETED,
  });
  const { data: dataActivationRequested, refetch: activationRequestedEventRefetch } =
    useGetEventsQuery({
      status: EventStatus.ACTIVATIONREQUESTED,
    });
  const { data: dataRejected, refetch: rejectedEventRefetch } = useGetEventsQuery({
    status: EventStatus.REJECTED,
  });

  const { events } = data || {};
  const { events: eventActive } = dataActive || {};
  const { events: eventDraft } = dataDraft || {};
  const { events: eventClosed } = dataClosed || {};
  const { events: eventCompleted } = dataCompleted || {};
  const { events: eventActivationRequested } = dataActivationRequested || {};
  const { events: eventRejected } = dataRejected || {};

  const mangeEventsActiveTabIndex = useSelector(mangeEventsActiveTabIndexState);

  const tabOptionsArray = usePrepareEventTabList();

  const handleChange = (_, newValue) => {
    dispatch(setManageEventsActiveTabIndex(newValue));
  };

  useEffect(() => {
    allEventRefetch();
    activeEventRefetch();
    closedEventRefetch();
    draftEventRefetch();
    completedEventRefetch();
    activationRequestedEventRefetch();
    rejectedEventRefetch();
  }, []);

  return (
    <>
      <DoctTabWrapper
        value={mangeEventsActiveTabIndex}
        handleChange={handleChange}
        tabOptions={tabOptionsArray}
        indicatorColor="primary"
      />
      <div className="mt-4"></div>
      {mangeEventsActiveTabIndex == 0 && (
        <DoctTabContent value={mangeEventsActiveTabIndex} index={0}>
          <div className="manage_page__card_grid mx-auto">
            <ListingOfCard events={events} isLoading={isLoading} />
          </div>
        </DoctTabContent>
      )}
      {mangeEventsActiveTabIndex == 1 && (
        <DoctTabContent value={mangeEventsActiveTabIndex} index={1}>
          <div className="manage_page__card_grid mx-auto">
            <ListingOfCard events={eventActive} isLoading={isLoading} />
          </div>
        </DoctTabContent>
      )}
      {mangeEventsActiveTabIndex == 2 && (
        <DoctTabContent value={mangeEventsActiveTabIndex} index={2}>
          <div className="manage_page__card_grid mx-auto">
            <ListingOfCard events={eventClosed} isLoading={isLoading} />
          </div>
        </DoctTabContent>
      )}
      {mangeEventsActiveTabIndex == 3 && (
        <DoctTabContent value={mangeEventsActiveTabIndex} index={3}>
          <div className="manage_page__card_grid mx-auto">
            <ListingOfCard events={eventDraft} isLoading={isLoading} />
          </div>
        </DoctTabContent>
      )}
      {mangeEventsActiveTabIndex == 4 && (
        <DoctTabContent value={mangeEventsActiveTabIndex} index={4}>
          <div className="manage_page__card_grid mx-auto">
            <ListingOfCard events={eventCompleted} isLoading={isLoading} />
          </div>
        </DoctTabContent>
      )}
      {mangeEventsActiveTabIndex == 5 && (
        <DoctTabContent value={mangeEventsActiveTabIndex} index={5}>
          <div className="manage_page__card_grid mx-auto">
            <ListingOfCard events={eventActivationRequested} isLoading={isLoading} />
          </div>
        </DoctTabContent>
      )}
      {mangeEventsActiveTabIndex == 6 && (
        <DoctTabContent value={mangeEventsActiveTabIndex} index={6}>
          <div className="manage_page__card_grid mx-auto">
            <ListingOfCard events={eventRejected} isLoading={isLoading} />
          </div>
        </DoctTabContent>
      )}
    </>
  );
}
