import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteSubCommittee } from '../services/subCommittee.services';

import ConferenceCommittee from './Card.Committee/ConferenceCommittee';
import DeleteCommittee from './Common/Delete.Committee';

function ListingSubCommittee({ subCommittees }) {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subCommitteeIdAndName, setSubCommitteeIdAndName] = useState({});

  return (
    <div>
      {subCommittees?.map((item, index) => {
        return (
          <ConferenceCommittee
            subCommitteesRecords={item}
            key={index}
            setIsModalOpen={setIsModalOpen}
            setSubCommitteeIdAndName={setSubCommitteeIdAndName}
          />
        );
      })}
      <DeleteCommittee
        open={isModalOpen}
        primaryBtnText={'Yes, Delete'}
        featureText={'Sub-Committee'}
        name={subCommitteeIdAndName?.name}
        handleClose={() => {
          setSubCommitteeIdAndName({});
          setIsModalOpen(false);
        }}
        handlePrimaryButtonClick={() => {
          dispatch(deleteSubCommittee(subCommitteeIdAndName?.id));
          setIsModalOpen(false);
          setSubCommitteeIdAndName({});
        }}
      />
    </div>
  );
}

export default ListingSubCommittee;
