import React from 'react';
import { useDispatch } from 'react-redux';
import { DoctAnimationLoading } from '@doct-react/app';
import { DoctButton, DoctCol, DoctRow } from '@doct-react/core';

import ListCommittees from './components/List.Committees';
import AddDesignation from './components/Card.Committee/AddDesignation';
import CreateSubCommittee from './components/Card.Committee/CreateSubCommittee';

import { setModalTitle, toggleArchiveModal } from './slice/committee.slice';
import { useGetDesignationsQuery } from './services/designation.services';

function DesignationCommittee() {
  const dispatch = useDispatch();
  const { data: designationData, isLoading } = useGetDesignationsQuery(
    { archiveId: '' },
    { refetchOnMountOrArgChange: true },
  );

  const checkArchive =
    designationData?.designations?.length > 0 || designationData?.subCommittees?.length > 0;

  if (isLoading) {
    return (
      <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
        <DoctAnimationLoading />
      </div>
    );
  }

  return (
    <div>
      {checkArchive && (
        <DoctButton
          text="Archive"
          size="medium"
          icon="archive"
          onButtonClickHandler={() => {
            dispatch(setModalTitle('Archive Current Committee'));
            dispatch(toggleArchiveModal());
          }}
          type="inverse"
          variant="text"
          className="btn-archives position-absolute mr-3"
        />
      )}
      {designationData?.designations?.length > 0 || designationData?.subCommittees?.length > 0 ? (
        <ListCommittees designationData={designationData} />
      ) : (
        <div className="current-committee-screen d-flex justify-content-center align-items-center">
          <DoctRow>
            <div className="d-flex justify-content-between">
              <DoctCol xs={6} md={12} lg={5}>
                <AddDesignation />
              </DoctCol>

              <DoctCol xs={6} md={12} lg={5}>
                <CreateSubCommittee />
              </DoctCol>
            </div>
          </DoctRow>
        </div>
      )}
    </div>
  );
}

export default DesignationCommittee;
