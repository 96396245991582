import React from 'react';
import { DoctIcon, DoctTypography } from '@doct-react/core';

export default function MenuItemProfileDropdown({
  title,
  icon,
  link,
  showRightIcon,
  element: El = 'div',
  onMenuItemClick = () => {},
}) {
  return (
    <El
      onClick={() => {
        onMenuItemClick(title);
      }}
      to={link}
      className="d-flex panel-height-5x align-items-center px-3 border-radius cursor-pointer text-grey-600"
    >
      <DoctIcon name={icon} width={24} height={24} />
      <DoctTypography variant="textLabel1" className="ml-12px">
        {title}
      </DoctTypography>
      {showRightIcon && (
        <DoctIcon width="24" height="24" name="right" className="ml-auto text-grey-400" />
      )}
    </El>
  );
}
