import React, { useState } from 'react';

import { DoctTypography } from '@doct-react/core';
import { Radio } from '@mui/material';

import UsingApplyNow from './components/options/UsingApplyNow';
import UsingViewContactDetails from './components/options/UsingViewContactDetails';
import UsingInstituteWebsite from './components/options/UsingInstituteWebsite';
import { DoctModal } from '@doct-react/app';
import { DASHBOARD } from '../../../../../../../routes/constant';
import { useGetApplicantUsageQuery } from '../../../../../Applicants/services/courseApplicant.services';

const SelectApplyingOptions = ({
  setCurrentApplyType,
  control,
  error,
  watch,
  setValue,
  isEditTimeEmailValue,
  activeApplyType,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: allApplicantUsage } = useGetApplicantUsageQuery();

  const [shouldSelectRadioButton, setShouldSelectRadioButton] = useState(true);

  const handleRadioChange = (value) => {
    setCurrentApplyType(value);
  };

  const handleRadioChangeInstituteBtn = (applyType) => {
    if (applyType === 1) {
      // Check if isPremium is false
      if (!allApplicantUsage.isPremium) {
        setIsModalOpen(true);
        setShouldSelectRadioButton(false);
      } else {
        setIsModalOpen(false);
        setCurrentApplyType(applyType);
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const redirectLink = () => {
    window.location.href = `/${DASHBOARD}/billing-subscription/subscription/all-packages`;
  };
  return (
    <>
      <DoctTypography variant="subtitle1" className="text-grey-800">
        How you wants Applicants to Apply for this Course?
      </DoctTypography>

      {/* For Send Inquiry */}
      <div className="py-2 my-2 bg-white ">
        <div className="d-flex remove_hover_on_radio_button">
          <Radio
            checked={activeApplyType === 0}
            onChange={() => handleRadioChange(0)}
            name="sendInquiry"
            inputProps={{ 'aria-label': 'A' }}
            disabled={false}
          />
          <div className="d-flex align-items-center justify-content-between px-2 border-radius cursor-pointer">
            <div>
              <DoctTypography variant="textLabel1" fontWeight="medium" className="mx-0 my-1">
                Using “Send Inquiry” button
              </DoctTypography>
              <DoctTypography
                variant="body3"
                fontWeight="medium"
                className="text-grey-600 mx-0 my-1"
              >
                Students can apply directly from course detail page through this button.
              </DoctTypography>
            </div>
          </div>
        </div>
        {activeApplyType === 0 && (
          <div className="bg-grey-100  mx-2 border-radius">
            <UsingApplyNow
              control={control}
              errors={error}
              setValue={setValue}
              isEditTimeEmailValue={isEditTimeEmailValue}
              watch={watch}
            />
          </div>
        )}
      </div>

      {/* For View Contact Details */}
      <div className="py-2 my-2 bg-white ">
        <div className="d-flex remove_hover_on_radio_button">
          <Radio
            checked={true} // Always select id === 2
            onChange={handleRadioChange}
            name="sendInquiry"
            inputProps={{ 'aria-label': 'A' }}
            disabled={true}
          />
          <div className="d-flex align-items-center justify-content-between px-2 border-radius cursor-pointer">
            <div>
              <DoctTypography variant="textLabel1" fontWeight="medium" className="mx-0 my-1">
                Using “View Contact Details” button
              </DoctTypography>
              <DoctTypography
                variant="body3"
                fontWeight="medium"
                className="text-grey-600 mx-0 my-1"
              >
                Get listed on Docthub Course Listing Platform & user will see “View Contact Details“
                for inquiry.
              </DoctTypography>
            </div>
          </div>
        </div>
        <div className="bg-grey-100  mx-2 border-radius">
          <UsingViewContactDetails
            control={control}
            errors={error}
            setValue={setValue}
            isEditTimeEmailValue={isEditTimeEmailValue}
            watch={watch}
          />
        </div>
      </div>

      {/* For Institute Website */}
      <div className="py-2 my-2 bg-white ">
        <div className="d-flex remove_hover_on_radio_button">
          <Radio
            checked={shouldSelectRadioButton && activeApplyType === 1}
            onChange={() => handleRadioChangeInstituteBtn(1)}
            name="sendInquiry"
            inputProps={{ 'aria-label': 'A' }}
            disabled={false}
          />
          <div className="d-flex align-items-center justify-content-between px-2 border-radius cursor-pointer">
            <div>
              <DoctTypography variant="textLabel1" fontWeight="medium" className="mx-0 my-1">
                Apply from your “Institute Website”
              </DoctTypography>
              <DoctTypography
                variant="body3"
                fontWeight="medium"
                className="text-grey-600 mx-0 my-1"
              >
                Get listed on docthub course listing platform & user will be directed for Apply to
                your website.
              </DoctTypography>
            </div>
          </div>
        </div>
        {activeApplyType === 1 && (
          <div className="bg-grey-100  mx-2 border-radius">
            <UsingInstituteWebsite control={control} errors={error} />
          </div>
        )}
      </div>
      {isModalOpen && (
        <DoctModal
          open={isModalOpen}
          iconName=""
          showFooter={true}
          handleSecondaryButtonClick={closeModal}
          handlePrimaryButtonClick={redirectLink}
          handleClose={closeModal}
        >
          <DoctTypography variant="h6" fontWeight="bold" className="mx-0 my-1">
            To use this feature please buy our Premium Plan
          </DoctTypography>
        </DoctModal>
      )}
    </>
  );
};

export default SelectApplyingOptions;
