import React, { useEffect, useRef, useState } from 'react';
import imageSquare from '../../../assets/images/Create Events Form/USER PROFILE.svg';
import './Uploadsquareimg.scss';
import { useDispatch } from 'react-redux';
import { setShowToasterWithParams } from '../../../features';
import { TOST_TOP_RIGHT } from '../../../constants/toasterPosition.constants';

function UploadImage({
  user = { image: '' },
  id,
  register,
  photoSrc,
  setPhotoSrc,
  text,
  name,
  setValue,
}) {
  const [toasterMessage, setToasterMessage] = useState(false);
  const [toasterMessageForInvalidFile, setToasterMessageForInvalidFile] = useState(false);
  const [erorFormSubmit, setErrorFormSubmit] = useState(null);
  const labelRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user.image) {
      setPhotoSrc(user.image);
    } else {
      setPhotoSrc(imageSquare);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setToasterMessage(false);
    }, 2000);
  }, [toasterMessage]);

  useEffect(() => {
    setTimeout(() => {
      setToasterMessageForInvalidFile(false);
    }, 2000);
  }, [toasterMessageForInvalidFile]);

  const onTostCloseHandler = () => {
    setToasterMessage(false);
    setErrorFormSubmit(null);
  };
  useEffect(() => {
    if (toasterMessage) {
      setTimeout(() => {
        onTostCloseHandler();
      }, 2000);
    }
  }, [toasterMessage]);

  useEffect(() => {
    toasterMessage &&
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_TOP_RIGHT,
          message: erorFormSubmit?.Title || 'Maximum allowed size is 5 MB',
        }),
      );
  }, [toasterMessage]);
  useEffect(() => {
    toasterMessageForInvalidFile &&
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_TOP_RIGHT,
          message: erorFormSubmit?.Title || 'Invalid File Type !',
        }),
      );
  }, [toasterMessageForInvalidFile]);

  if (user.image) {
    return (
      <div className="mb-4 mt-2">
        <div className="profile_photo_square">
          <img src={photoSrc} alt="image" />
        </div>
        <label className="cursor-pointer ml-4" htmlFor={id}>
          <div>
            <input
              id={id}
              type="file"
              hidden
              name={name}
              ref={register}
              onChange={(e) => {
                setPhotoSrc(null);
                if (e.target.files[0]?.size >= 2097152) {
                  setToasterMessage(true);
                  if (setValue) setValue(name, null);
                } else {
                  setPhotoSrc(URL.createObjectURL(e.target.files[0]));
                }
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
              accept="image/*"
            />
          </div>
          <div className="upload_btn doct-button doct-medium-button align-items-center justify-content-center bg-info text-white text-center px-2 py-2 mt-3">
            {text}
          </div>
        </label>
      </div>
    );
  }

  return (
    <>
      <label className="cursor-pointer  mb-4" ref={labelRef}>
        <div>
          <input
            id={id}
            type="file"
            hidden
            name={name}
            ref={register}
            onChange={(e) => {
              setPhotoSrc(null);
              const fileExtension = e?.target?.files[0]?.name?.toString()?.split('.')[1];
              if (!['jpeg', 'jpg', 'png'].includes(fileExtension.toLowerCase())) {
                setToasterMessageForInvalidFile(true);
                if (setValue) setValue(name, null);
                return;
              }
              if (e.target.files[0]?.size >= 2097152) {
                setToasterMessage(true);
                if (setValue) setValue(name, null);
                setPhotoSrc(imageSquare);
              } else {
                if (e?.target?.files?.length != 0) {
                  setPhotoSrc(URL.createObjectURL(e.target.files[0]));
                } else {
                  setPhotoSrc(imageSquare);
                }
              }
            }}
            onClick={(event) => {
              event.target.value = null;
            }}
            accept="image/*"
          />
        </div>
      </label>
      <div className="profile_photo_square">
        <img
          src={photoSrc || imageSquare}
          alt="Upload"
          onClick={() => {
            labelRef.current.click();
          }}
        />
      </div>
      <div
        className="upload_btn doct-button doct-medium-button mt-3 align-items-center justify-content-center bg-info text-white text-center px-2 py-2"
        onClick={() => {
          labelRef.current.click();
        }}
      >
        {text}
      </div>
    </>
  );
}

export default UploadImage;
