import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import useSendSubscription from '../hooks/useSendSubscription';
import FormFooterPaymentHeading from './FormFooterPaymentHeading';
import * as ROUTE from '../../../routes/constant';
import { useDispatch, useSelector } from 'react-redux';
import { setBillingDetails } from '../API/subscriptionApiSlice';
import { useGetAdditionalInformationQuery } from '../API/subscriptionApi';
import { Toaster } from '../../../shared';
import EnterpriseFormCard from '../../../shared/ui/EnterpriseSectionCard/EnterpriseForm.card';
import { EnterpriseFormLayout } from '../../../layout';
import { decryptData, encryptData } from '../../../helper/helperFunction';

const Form = () => {
  const [showTost, setShowTost] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { choosedPlan } = useSelector((state) => state.subscriptionData);
  const billingDetails = useSelector((state) => state?.subscriptionData?.billingDetails);
  const { data: billingInfo } = useGetAdditionalInformationQuery();

  const [storedPlanId, setStoredPlanId] = useState('');
  const [planTotalAmount, setPlanTotalAmount] = useState('');
  const [storedPlanName, setStoredPlanName] = useState('');

  useEffect(() => {
    const encryptedData = localStorage.getItem('encryptedPlanData');
    if (encryptedData) {
      const decryptedData = decryptData(encryptedData);
      setStoredPlanId(decryptedData.id);
      setPlanTotalAmount(decryptedData.totalAmount);
      setStoredPlanName(decryptedData.name);
    }
  }, [choosedPlan]);

  const { handleSubmit, formParts, navigate, submitError } = useSendSubscription(storedPlanId);
  const prepDatas = useSelector((state) => state?.subscriptionData?.prepData);
  // const planId = useSelector((state) => state?.subscriptionData?.choosedPlan?.id);
  const sucessDetails = useSelector((state) => state?.subscriptionData?.setSuccessFormDetails);
  const amountDetails = useSelector((state) => state?.subscriptionData?.amountDetails);
  // const planName = useSelector((state) => state?.subscriptionData?.choosedPlan?.name);

  useEffect(() => {
    if (!id === ':id' || !location.pathname.includes('/billing-info/')) {
      const successDetailsJSON = JSON.stringify(sucessDetails);
      const encryptedSucessDetails = encryptData(successDetailsJSON);
      localStorage.setItem('successDetails', encryptedSucessDetails);

      const amountDetailsJSON = JSON.stringify(amountDetails);
      const encryptedamountDetails = encryptData(amountDetailsJSON);
      localStorage.setItem('amountDetails', encryptedamountDetails);

      const prepDatasString = JSON.stringify(prepDatas);
      const encryptedprepDatas = encryptData(prepDatasString);
      localStorage.setItem('prepDatas', encryptedprepDatas);
    }
  }, [sucessDetails, amountDetails, prepDatas]);

  const onTostCloseHandler = () => {
    setShowTost((prev) => !prev);
  };

  useEffect(() => {
    if (submitError) {
      setShowTost(true);
    }
  }, [submitError]);

  useEffect(() => {
    if (showTost) {
      setTimeout(() => {
        onTostCloseHandler();
      }, 2000);
    }
  }, [showTost]);

  useEffect(() => {
    if (Object.keys(billingDetails || {}).length <= 0) {
      dispatch(setBillingDetails(billingInfo));
    }
  }, [billingInfo, billingDetails]);
  const { id } = useParams();

  const FormDisclaimer = () => {
    return <FormFooterPaymentHeading amount={planTotalAmount} gst="+GST" currency="INR" />;
  };
  // const itemsToRemove = ['encryptedPlanData', 'successDetails', 'amountDetails', 'prepDatas'];
  // const removeStorageData = () => {
  //   itemsToRemove.forEach((item) => {
  //     localStorage.removeItem(item);
  //   });
  // };

  return (
    <>
      {showTost && (
        <div className="position-fixed tost-container">
          <Toaster variant={'error'} text={submitError} onPressedClose={onTostCloseHandler} />
        </div>
      )}
      <EnterpriseFormLayout
        formDisclaimer={
          id === ':id' || location.pathname.includes('/billing-info/') ? '' : <FormDisclaimer />
        }
        formPrimaryBtnText={
          id === ':id' || location.pathname.includes('/billing-info/') ? 'Save & Close' : 'Continue'
        }
        isAdding={false}
        primaryButtonType={'primary'}
        showFormFooterSecondaryBtn={
          id === ':id' || location.pathname.includes('/billing-info/') ? false : true
        }
        formFooterSecondaryBtnText={'Back'}
        formTitle={storedPlanName}
        closeHandler={() => {
          // dispatch(resetBillingDetails());
          if (!location.pathname.includes('billing-info-recruiter')) {
            localStorage.clear();
          }
          return navigate(
            `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_DETAILS}`,
          );
        }}
        handleSubmit={handleSubmit}
        showSecondaryHeaderBtn={false}
        formFooterSecondaryBtnClickHandler={() => {
          if (location.pathname.includes('billing-info-recruiter')) {
            return navigate(
              `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${id}`,
            );
          }
          localStorage.clear();
          navigate(
            `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}`,
          );
        }}
      >
        <div className="bg-grey-200 py-3">
          {formParts.map((formPart, i) => {
            return <EnterpriseFormCard key={i} {...formPart} />;
          })}
        </div>
      </EnterpriseFormLayout>
    </>
  );
};

export default Form;
