import React from 'react';
import ManageCancellationOrder from './ManageCancellation';
import { useCancellationOrderMutation } from './manageRegistration.services';

function CancellationOrder({ orderData, openExitModal, setOpenExitModal }) {
  const [cancellationOrder] = useCancellationOrderMutation();
  const { orderId, eventId } = orderData;
  return (
    <ManageCancellationOrder
      open={openExitModal}
      handleClose={() => {
        setOpenExitModal(false);
      }}
      onConfirmExit={() => {
        cancellationOrder({ eventId, orderId });
        setOpenExitModal(false);
      }}
    />
  );
}

export default CancellationOrder;
