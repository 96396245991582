/* eslint-disable prettier/prettier */
import axios from 'axios';
import * as API_ENDPOINT from '../../../api/apiEndpoints';

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}${API_ENDPOINT.ENTERPRISE_BASE_API_ROUTE}${API_ENDPOINT.BUSINESS_BASE_API_ROUTE}/ecertificate`;

export default async function APIIndividualJPEGDownload(id, name) {
  try {
    const urlForAPI = `${BASE_URL}/awardee/${id}`;
    const fileName = `${name}.jpeg`;

    const response = await axios.post(urlForAPI, {}, { responseType: 'arraybuffer' });
    const contentType = response.headers['content-type'];

    const blob = new Blob([response.data], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error while downloading file:', error);
  }
}
