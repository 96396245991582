import { DoctDropdownSelect } from '@doct-react/app';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import DropDownMenuItem from './DropdownMenuItem';
import '../../../components/JobCustomTableStyles.scss';

const JobCustomTableJobSelectionDropDown = ({ jobsData, jobIdVal, setJobIdVal, urlId }) => {
  const [jobDataMenu, setJobDataMenu] = useState([]);
  const navigate = useNavigate();
  const selected = window.location.href.split('?')[1];
  const selected2 = window.location.href.split('?')[2];

  useEffect(() => {
    if (selected2) {
      window.location.href = '';
    }
    setJobIdVal(selected?.split('=')[1] || urlId);
  }, [selected]);

  useEffect(() => {
    if (!jobIdVal && urlId) {
      navigate(`?jobId=${urlId}`);
    }
  }, [selected, urlId]);

  useEffect(() => {
    if (jobsData) {
      jobsData?.map((jobData) => {
        setJobDataMenu((prevState) => [
          ...prevState,
          {
            title: <DropDownMenuItem {...jobData} />,
            value: jobData?.id,
          },
        ]);
      });
    }
  }, [jobsData]);
  return (
    <div className="dropdown-select dropdown-select-dashed">
      {jobsData?.length > 0 && (
        <>
          {jobIdVal != 'undefined' && (
            <DoctDropdownSelect
              value={jobIdVal?.toString()?.split('&')[0] || jobsData[0]?.id}
              handleChange={(e) => {
                setJobIdVal(e.target?.value);
                e?.target.value ? navigate(`?jobId=${e?.target?.value}`) : '';
              }}
              menuItems={jobDataMenu}
            />
          )}
        </>
      )}
    </div>
  );
};
export default JobCustomTableJobSelectionDropDown;
