import { AuthenticationContext } from '@axa-fr/react-oidc-context';
import jwt_decode from 'jwt-decode';
import React, { useContext, useLayoutEffect, useState } from 'react';
import './LandingPageHeader.scss';
import * as ROUTE from '../../../routes/constant';
import { DoctTopBarBranding } from '@doct-react/app';
import { appTokenState, setToken, useGetUserQuery } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { CONTINUE_URL } from '../../../constants/constants';
const LinkAsNavItem = ({ children, ...props }) => {
  return (
    <a to={props.href} {...props} target="_blank">
      {children}
    </a>
  );
};

const LandingPageHeader = ({ enterpriseHeader }) => {
  const dispatch = useDispatch();
  const appToken = useSelector(appTokenState);

  const context = useContext(AuthenticationContext);
  const { oidcUser, login, logout } = context;
  // eslint-disable-next-line no-unused-vars
  const [isBusinessUser, setIsBusinessUser] = useState(true);
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const user = useSelector(userSelector);
  const { data: userData } = useGetUserQuery('', {
    skip: !appToken,
  });

  // const handleAvatarClick = () => {
  //   setIsDrawerOpen(true);
  // };

  useLayoutEffect(() => {
    if (!oidcUser?.access_token) return;
    const { tenantId, tenantType } = jwt_decode(oidcUser?.access_token);
    if (tenantId && tenantType) {
      window.location.pathname = `${ROUTE.DASHBOARD}`;
      setIsBusinessUser(true);
    } else {
      dispatch(setToken(oidcUser.access_token));
      setIsBusinessUser(false);
    }
  }, [oidcUser?.access_token]);

  const userMenu = {
    userDetails: userData || {},
    userMenuLinks: [
      {
        userLinkHeading: '',
        links: [
          {
            link: (
              <a className="doct-user-link" href="https://accounts.docthub.com/profile">
                My Profile
              </a>
            ),
          },
        ],
      },
      {
        userLinkHeading: 'Saved',
        links: [
          {
            link: (
              <a className="doct-user-link" href="https://accounts.docthub.com/profile">
                My Profile
              </a>
            ),
          },
          {
            link: (
              <a className="doct-user-link" href="https://accounts.docthub.com/profile">
                My Profile
              </a>
            ),
          },
          {
            link: (
              <a className="doct-user-link" href="https://accounts.docthub.com/profile">
                My Profile
              </a>
            ),
          },
        ],
      },
    ],
  };

  const handleLogin = () => {
    login(process.env.IDENTITY_ID);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <DoctTopBarBranding
      enterprise
      handleLoginRedirection={handleLogin}
      handleLogout={handleLogout}
      userMenu={userMenu}
      onCreateAccountPressed={() => {
        enterpriseHeader
          ? (window.location.href = `${process.env.REACT_APP_ACCOUNT_APP_INDIVIDUAL_LINK}&${CONTINUE_URL}=${process.env.REACT_APP_ENTERPRISE_APP_LINK}`)
          : (window.location.href = `${process.env.REACT_APP_ACCOUNT_APP_BUSINESS_LINK}&${CONTINUE_URL}=${process.env.REACT_APP_ENTERPRISE_APP_LINK}`);
      }}
      navLinks={[
        {
          name: 'Home',
          href: '/',
          component: LinkAsNavItem,
        },
        {
          name: 'Recruiter',
          href: `${process.env.REACT_APP_DOCTHUB_WEB_APP_LINK}/post-job`,
          component: LinkAsNavItem,
        },
        {
          name: 'Institutes',
          href: `${process.env.REACT_APP_DOCTHUB_WEB_APP_LINK}/publish-course`,
          component: LinkAsNavItem,
        },
        {
          name: 'Events',
          href: `${process.env.REACT_APP_DOCTHUB_WEB_APP_LINK}/create-an-event`,
          component: LinkAsNavItem,
        },
      ]}
    />
  );
};

export default LandingPageHeader;
