/* eslint-disable prettier/prettier */
import axios from 'axios';
import * as API_ENDPOINT from '../../../../src/api/apiEndpoints';

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}${API_ENDPOINT.ENTERPRISE_BASE_API_ROUTE}${API_ENDPOINT.BUSINESS_BASE_API_ROUTE}/ecertificate`;

export default async function APIAllPdfDownload(individualCertificateID, searchText, filterValues) {
  try {
    const query = new URLSearchParams({
      ['SearchText']: filterValues?.searchText === ' ' ? '' : filterValues?.searchText,
      ['EmailStatus']: filterValues?.EmailStatus === undefined ? '' : filterValues?.EmailStatus,
      ['FromDate']: filterValues?.FromDate === undefined ? '' : filterValues?.FromDate,
      ['ToDate']: filterValues?.ToDate === undefined ? '' : filterValues?.ToDate,
    }).toString();

    const urlForAPI = `${BASE_URL}/${individualCertificateID}/download-awardees-certificates?${query}`;
    const fileName = 'MultipleCertificates.zip';

    const response = await axios.post(urlForAPI, filterValues, { responseType: 'blob' });
    const contentType = response.headers['content-type'];

    if (contentType !== 'application/zip') {
      throw new Error('Invalid content type');
    }

    const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return response;
  } catch (error) {
    console.error('Error while downloading file:', error);
  }
}

export async function APIAllPdfDownloadForPopup(individualCertificateID, searchText, filterValues) {
  try {
    const urlForAPI = `${BASE_URL}/${individualCertificateID}/download-awardees-certificates${
      filterValues?.searchText ? `?searchText=${filterValues?.searchText}` : ''
    }${filterValues?.searchText ? `&` : `?`}PageNumber=1&PageSize=5000`;
    const fileName = 'MultipleCertificates.zip';

    const response = await axios.post(urlForAPI, filterValues, { responseType: 'blob' });
    const contentType = response.headers['content-type'];

    if (contentType !== 'application/zip') {
      throw new Error('Invalid content type');
    }

    const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return response;
  } catch (error) {
    console.error('Error while downloading file:', error);
  }
}
