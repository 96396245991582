import React from 'react';
import JobBasicInfo from '../components/JobFormParts/JobBasicInfo';
import JobDescription from '../components/JobFormParts/JobDescription';
import JobOrganizationInfo from '../components/JobFormParts/JobOrganizationInfo';
import useFormJobs from './useForm.jobs';

const useTenantJobs = () => {
  const {
    control,
    errors,
    watch,
    setValue,
    handleJobFormSubmit,
    qualificationArray,
    setQualificationArray,
    keySkillArray,
    setKeySkillArray,
    isPreview,
    setIsPreview,
    modifiedJobFormValues,
    handleOnPreviewApproved,
    setApplicationStatus,
    isLoading,
    isJobPostLoading,
    isJobEditLoading,
    jobType,
    setJobType,
    touched,
    locationValue,
    setWorkExperienceValidation,
    workExperienceValidation,
    setEducationValidation,
    educationValidation,
  } = useFormJobs();

  const formParts = [
    {
      formSectionHeading: '1. Basic Info',
      childElement: (
        <JobBasicInfo
          qualificationArray={qualificationArray}
          setQualificationArray={setQualificationArray}
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          setJobType={setJobType}
          jobType={jobType}
          isLoading={isLoading}
          touched={touched}
          locationValue={locationValue}
          setWorkExperienceValidation={setWorkExperienceValidation}
          workExperienceValidation={workExperienceValidation}
          setEducationValidation={setEducationValidation}
          educationValidation={educationValidation}
        />
      ),
    },
    {
      formSectionHeading: '2. Job Description',
      childElement: (
        <JobDescription
          setKeySkillArray={setKeySkillArray}
          keySkillArray={keySkillArray}
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
      ),
    },
    {
      formSectionHeading: '3. Organization Info',
      childElement: (
        <JobOrganizationInfo setValue={setValue} control={control} errors={errors} watch={watch} />
      ),
    },
  ];

  return {
    isLoading,
    formParts,
    handleOnPreviewApproved,
    handleJobFormSubmit,
    isPreview,
    setIsPreview,
    modifiedJobFormValues,
    setApplicationStatus,
    isJobPostLoading,
    isJobEditLoading,
  };
};

export default useTenantJobs;
