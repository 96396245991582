import { useCallback } from 'react';
import { EDIT_ROUTE } from '../../../../routes/constant';
import { useNavigate } from 'react-router-dom';

export default function useNavigationActivityLog() {
  let navigate = useNavigate();

  const navigateToEdit = useCallback((id) => {
    navigate(`${id}/${EDIT_ROUTE}`);
  }, []);

  return {
    navigateToEdit,
  };
}
