import React from 'react';
import { NavLink } from 'react-router-dom';
import { DoctTypography } from '@doct-react/core';
import useSidebarData from '../hooks/useLayout.SidebarData.jsx';
import { useSelector } from 'react-redux';
import './Sidebar.scss';

function SidebarNav({ title, pathname, icon, isDisabled, onclickHandler, underReviewComponent }) {
  const {
    user: { tenant },
  } = useSelector((state) => state?.app);

  if (title === 'Billing & Subscriptions' && tenant?.status === 'Pending') {
    return null;
  }

  if (isDisabled) {
    return (
      <>
        <span className=" sidebar_nav sidebar_nav_disabled d-inline-flex align-items-center w-100">
          {icon}
          <DoctTypography variant="textLabel1">{title}</DoctTypography>
        </span>
      </>
    );
  }

  return (
    <NavLink
      onClick={() => {
        onclickHandler();
      }}
      to={isDisabled ? '' : pathname}
      className={({ isActive }) =>
        isActive
          ? `sidebar_nav sidebar_nav_active d-inline-flex align-items-center w-100`
          : `sidebar_nav align-items-center d-inline-flex w-100`
      }
    >
      {underReviewComponent}
      {icon}
      <DoctTypography variant="textLabel1">{title}</DoctTypography>
    </NavLink>
  );
}

export default function Sidebar({ className }) {
  const { sidebarData } = useSidebarData();

  return (
    <nav className={className ? `${className}` : ''}>
      {sidebarData
        .filter((item) => item.isAccessable != false)
        .map(({ title, pathname, icon, isDisabled, onclickHandler, underReviewComponent }, i) => {
          return (
            <SidebarNav
              key={i}
              title={title}
              pathname={pathname}
              icon={icon}
              isDisabled={isDisabled}
              underReviewComponent={underReviewComponent}
              onclickHandler={onclickHandler}
            />
          );
        })}
    </nav>
  );
}
