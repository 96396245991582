import React from 'react';
import style from './JobSubscriptionUsageCard.module.scss';
import { DoctIcon, DoctTypography } from '@doct-react/core';
import UpgradePlanWhiteCard from '../../../../../shared/ui/SubscriptionHoverCard/UpgradePlanWhiteCard';
import { useNavigate } from 'react-router';
import { DASHBOARD } from '../../../../../routes/constant';

export default function JobSubscriptionUsageCard({
  jobPostedCount,
  totalJobListingLimit,
  planName,
  pendingApprovalCounts,
}) {
  return (
    <>
      <div className={`${style['job-usage-card-border']} font-family-roboto mt-4`}>
        <DoctTypography variant="subtitle2" className="text-initial text-grey-400 m-2 text-center">
          {planName}
        </DoctTypography>
        <div className={`${style['job-usage-card-divider-line']}`} />
        <div className="d-flex justify-content-between align-items-center m-2 p-2">
          <DoctTypography variant="subtitle2" className="text-grey-600 m-0">
            Job Posting
          </DoctTypography>
          <DoctTypography variant="textLabel1" className="text-grey-600 m-0">
            {jobPostedCount}
            {totalJobListingLimit === null ? '' : '/'}
            {totalJobListingLimit}
          </DoctTypography>
        </div>
        {planName === 'Recruiter Free Plan' ? null : (
          <>
            <div className={`${style['job-usage-card-divider-line']}`} />
            <div className="d-flex justify-content-between align-items-center m-2 p-2">
              <DoctTypography variant="subtitle2" className="text-grey-600 m-0">
                Pending Approvals
              </DoctTypography>
              <DoctTypography variant="textLabel1" className="text-grey-600 m-0">
                {pendingApprovalCounts}
              </DoctTypography>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export const PlanLimitedUsed = ({
  jobPostedCount,
  totalJobListingLimit,
  pendingApprovalCounts,
  planName,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`${style['job-usage-card-border']} ${style['job-usage-card-bg']} font-family-roboto mt-4 pb-1`}
    >
      <DoctTypography
        variant="subtitle3"
        className={`${style['plan-limit-text']} d-flex justify-content-center mb-0`}
      >
        <DoctIcon name="warningOutline" width="18" height="18" fill="#C18700" className="mr-1" />
        PLAN LIMIT USED
      </DoctTypography>
      <DoctTypography
        variant="caption2"
        className="text-initial text-grey-400 m-2 d-flex justify-content-center align-items-center"
      >
        {planName}
      </DoctTypography>
      <div className={`${style['job-usage-card-divider-line']}`} />
      <div className="d-flex justify-content-between align-items-center m-2 p-2">
        <DoctTypography variant="subtitle2" className="text-grey-600 m-0">
          Job Posting
        </DoctTypography>
        <DoctTypography variant="textLabel1" className="text-grey-600 m-0">
          {jobPostedCount}/{totalJobListingLimit}
        </DoctTypography>
      </div>
      {planName === 'Recruiter Free Plan' ? null : (
        <>
          <div className={`${style['job-usage-card-divider-line']}`} />
          <div className="d-flex justify-content-between align-items-center m-2 p-2">
            <DoctTypography variant="subtitle2" className="text-grey-600 m-0">
              Pending Approvals
            </DoctTypography>
            <DoctTypography variant="textLabel1" className="text-grey-600 m-0">
              {pendingApprovalCounts}
            </DoctTypography>
          </div>
        </>
      )}
      <UpgradePlanWhiteCard
        PlanTextClassName={`${style['planTextWeight']}`}
        className="m-n1"
        noBorder
        navigateOn={() => {
          navigate(`/${DASHBOARD}/billing-subscription/subscription/all-packages/2`, {
            state: { backFromSubscription: window?.location?.pathname },
          });
        }}
        text="Upgrade plan to Post new Jobs!"
        plan="View Plans"
      />
    </div>
  );
};

export const FreeLimitUsed = ({ jobPostedCount, totalJobListingLimit }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`${style['job-usage-card-border']} ${style['job-usage-card-bg']} font-family-roboto mt-4 pb-1`}
    >
      <DoctTypography
        variant="subtitle3"
        className={`${style['plan-limit-text']} d-flex justify-content-center mb-0`}
      >
        <DoctIcon name="warningOutline" width="18" height="18" fill="#C18700" className="mr-1" />
        FREE LIMIT USED
      </DoctTypography>
      <DoctTypography
        variant="caption2"
        className="text-initial text-grey-400 m-2 d-flex justify-content-center align-items-center"
      >
        You have no active plan
      </DoctTypography>
      <div className={`${style['job-usage-card-divider-line']}`} />
      <div className="d-flex justify-content-between align-items-center m-2 p-2">
        <DoctTypography variant="subtitle2" className="text-grey-600 m-0">
          Job Posting
        </DoctTypography>
        <DoctTypography variant="textLabel1" className="text-grey-600 m-0">
          {jobPostedCount}/{totalJobListingLimit}
        </DoctTypography>
      </div>
      <UpgradePlanWhiteCard
        PlanTextClassName={`${style['planTextWeight']}`}
        className="m-n1"
        noBorder
        navigateOn={() => {
          navigate(`/${DASHBOARD}/billing-subscription/subscription/all-packages/2`, {
            state: { backFromSubscription: window?.location?.pathname },
          });
        }}
        text="To Buy Subscription"
        plan="View Plans"
      />
    </div>
  );
};

export const FreeJobGreenCard = ({ jobPostedCount, totalJobListingLimit }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`${style['job-usage-card-border']} ${style['job-usage-green-card']} font-family-roboto mt-4`}
    >
      <DoctTypography variant="textLabel2" className="text-success font-weight-bold text-center">
        POST FREE JOB
      </DoctTypography>
      <div className={`${style['job-usage-card-divider-line']}`} />
      <div className="d-flex justify-content-between align-items-center m-2 p-2">
        <DoctTypography variant="subtitle2" className="text-grey-600 m-0">
          Job Posting
        </DoctTypography>
        <DoctTypography variant="textLabel1" className="text-grey-600 m-0">
          {jobPostedCount}/{totalJobListingLimit}
        </DoctTypography>
      </div>
      <UpgradePlanWhiteCard
        PlanTextClassName={`${style['planTextWeight']}`}
        className="mx-n1 mb-1"
        noBorder
        navigateOn={() => {
          navigate(`/${DASHBOARD}/billing-subscription/subscription/all-packages/2`, {
            state: { backFromSubscription: window?.location?.pathname },
          });
        }}
        text="To Buy Subscription"
        plan="View Plans"
        noWhiteBG={`${style['view-plan-bg']}`}
      />
    </div>
  );
};
