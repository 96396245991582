export const transformStatus = {
  pending: 'created',
  created: 'Pending',
};

export const manageDirectoryStatus = {
  active: 'active',
  draft: 'draft',
  inactive: 'inactive',
  renewalpending: 'renewalpending',
};
