import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import fieldCommittee from '../components/Common/field.Committee';
import { setCommitteeMembers } from '../slice/committee.slice';

export function useResetDesignationForm() {
  const dispatch = useDispatch();
  const prepareApiToFormData = useCallback(async (apiData) => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(
          setCommitteeMembers(
            apiData[fieldCommittee.assignMembers.keyOfGetApi]?.map(
              ({ memberId: id, memberName: title }) => {
                return { title, id: Number(id) };
              },
            ),
          ),
        );
        resolve({
          ...apiData,
        });
      } catch (e) {
        reject(null);
      }
    });
  }, []);

  return { prepareApiToFormData };
}

export function useResetSubCommitteeAndArchiveForm() {
  const prepareApiToFormData = useCallback(async (apiData) => {
    return new Promise((resolve, reject) => {
      try {
        resolve({
          ...apiData,
        });
      } catch (e) {
        reject(null);
      }
    });
  }, []);

  return { prepareApiToFormData };
}
