import { DoctFreeSoloSearchInput } from '@doct-react/app';
import React, { useEffect, useState } from 'react';
import { useDebounce } from '../../../../hooks';

export default function SearchActivityLog({ setDebouncedSearchVal }) {
  const [searchVal, setSearchVal] = useState('');

  const prepareDebouncedVal = useDebounce(searchVal, 300);

  useEffect(() => {
    setDebouncedSearchVal(prepareDebouncedVal);
  }, [prepareDebouncedVal]);

  return (
    <DoctFreeSoloSearchInput
      name="searchText"
      noRadial
      placeholder="Search by Title"
      options={[]}
      onInputChangeHandler={(val) => {
        setSearchVal(val);
      }}
      onChangeHandler={() => {}}
      inputValue={searchVal}
      onClearHandler={() => {}}
    />
  );
}
