import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import AddEditDesignationWrapper from './AddEditDesignationWrapper';
import { useCommitteeMemberOptionEffect } from '../components/Common/Common.Committee';
import CommitteeModal from '../components/CommitteeModal';

import { useGetAllMembershipMembersQuery } from '../../ManageDirectory/services/members.services';
import {
  selectDesignationId,
  selectDesignationResponseById,
  selectLoaderInApiCall,
  selectIsAddOrUpdating,
} from '../slice/committee.slice';
import { useResetDesignationForm } from '../hooks/committeeResetHookForm';
import { useDispatch } from 'react-redux';
import { getDesignationById } from '../services/designation.services';

function EditDesignation({ id, primaryBtnText }) {
  const dispatch = useDispatch();
  const designationResponseById = useSelector(selectDesignationResponseById);
  const isLoading = useSelector(selectLoaderInApiCall);
  // const isAddOrUpdating = useSelector(selectIsAddOrUpdating);

  const { prepareApiToFormData } = useResetDesignationForm();

  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(getDesignationById(id));
    }
  }, [id]);

  useEffect(() => {
    if (!designationResponseById) return;

    const handleDefaultValue = async () => {
      const apiToFormData = await prepareApiToFormData(designationResponseById);
      setDefaultValues(apiToFormData);
    };
    handleDefaultValue();
  }, [designationResponseById]);

  if (!isLoading && defaultValues) {
    return (
      <AddEditDesignationWrapper
        modalContainer={CommitteeModal}
        isLoading={isLoading}
        defaultValues={defaultValues}
        id={id}
        primaryBtnText={primaryBtnText}
      />
    );
  }

  return (
    <CommitteeModal
      isLoading={isLoading}
      isOpen={true}
      isPrimaryBtnDisable={isLoading}
      primaryBtnText={primaryBtnText}
    />
  );
}

export default function AddEditDesignation() {
  const { data: allMembersData, isLoading } = useGetAllMembershipMembersQuery({
    disablePageSize: true,
  });
  const designationId = useSelector(selectDesignationId);

  const primaryBtnText = 'Save';

  const isAddOrUpdating = useSelector(selectIsAddOrUpdating);

  useCommitteeMemberOptionEffect({
    data: allMembersData || [],
    isLoading,
  });

  if (designationId) {
    return (
      <EditDesignation
        id={designationId}
        primaryBtnText={primaryBtnText}
        isAddOrUpdating={isAddOrUpdating}
      />
    );
  }

  return (
    <AddEditDesignationWrapper
      modalContainer={CommitteeModal}
      isLoading={isLoading}
      primaryBtnText={primaryBtnText}
    />
  );
}
