import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subscriptionForm: {},
};

const subscriptionFormSlice = createSlice({
  name: 'subscriptionForm',
  initialState,
  reducers: {
    setFormData: (state, action) => {
      Object.assign(state.subscriptionForm, action.payload);
    },
    resetForm: (state) => {
      state.subscriptionForm = initialState.subscriptionForm;
    },
  },
});

export const { setFormData, resetForm } = subscriptionFormSlice.actions;

export const selectFormData = (state) => state.subscriptionForm;

export default subscriptionFormSlice;
