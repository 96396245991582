import React from 'react';
import { useNavigate } from 'react-router';
import { useGetEcertificateAwardeesQuickAccessQuery } from '../../services/quickAccess.services';
import SmallQuickCardHighlighted from '../QuickCard/SmallQuickCardHighlighted';
import MembershipEmptyCard from './MembershipEmptyCard';
import MembershipsCard from './MembershipsCard';

function MembershipQuickAccessWrapper() {
  const { data: membershipData, isLoading } = useGetEcertificateAwardeesQuickAccessQuery('', {
    refetchOnMountOrArgChange: true,
  });

  const { totalMembers, totalRenewals } = membershipData || {};

  const navigate = useNavigate();

  return (
    <SmallQuickCardHighlighted title={'Memberships'}>
      {(totalMembers && !isLoading) || isLoading ? (
        <MembershipsCard
          totalRenewals={totalRenewals}
          totalMembers={totalMembers}
          navigate={navigate}
          isLoading={isLoading}
        />
      ) : (
        <MembershipEmptyCard navigate={navigate} />
      )}
    </SmallQuickCardHighlighted>
  );
}

export default MembershipQuickAccessWrapper;
