const useFocus = (errors) => {
  const onFocus = () => {
    if (Object?.keys(errors)?.length > 0) {
      const firstErrorKey = Object?.keys(errors)[0];
      const firstErrorElement = document?.getElementById(firstErrorKey);
      firstErrorElement?.focus();
    }
  };

  return onFocus;
};

export default useFocus;
