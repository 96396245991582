import React from 'react';
import { DoctButton, DoctTypography } from '@doct-react/core';

export default function EmptyStateCard({
  icon,
  title,
  btnText,
  onPressFunction,
  bgcolor,
  disabled = false,
}) {
  return (
    <div className="d-flex align-items-center flex-column h-100">
      {icon}
      <DoctTypography variant="textLabel1" className="text-grey-600 font-weight-medium mt-0 mb-3">
        {title}
      </DoctTypography>
      <DoctButton
        variant="contained"
        size="medium"
        text={btnText}
        disabled={disabled}
        icon="plus"
        onButtonClickHandler={onPressFunction}
        className={bgcolor}
      />
    </div>
  );
}
