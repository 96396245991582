import * as ROUTE from '../../../routes/constant';
// TODO:NOTE:- backend need full infor given below inorder to call post subscription API
export const prepObjectForFormData = {
  subscriberOrder: {
    subscriber: {
      userId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: 'string',
      applicationType: 'string',
      startDate: '2023-06-07T10:30:33.471Z',
      expiryDate: '2023-06-07T10:30:33.471Z',
      validity: 'string',
      planId: 0,
      orders: [null],
    },
    orderNumber: 'string',
    createdOn: '2023-06-07T10:30:33.471Z',
    orderStatus: 'string',
    cancellationReason: 'string',
    currency: 'string',
    type: 'Paid',
    mode: 'string',
    userId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    netAmount: 0,
    discountAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
    paymentMode: 'string',
    paymentReferenceNumber: 'string',
    paymentRemarks: 'string',
    subscriberOrderItems: [
      {
        id: 0,
        subscriberOrderId: 0,
        netAmount: 0,
        discountAmount: 0,
        taxAmount: 0,
        totalAmount: 0,
        currency: 'string',
      },
    ],
  },
  callbackUrl: `${ROUTE.ENTERPRISE_DOCTHUB_URL}/${ROUTE.DASHBOARD}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO}/${ROUTE.CONFIRMATION_ROUTE}`,
};
