import React from 'react';

import {
  AdminProfile,
  EmailPhoneProfile,
  LocationDetailsProfile,
  OrganizationProfile,
} from './components';

export default function Profile() {
  return (
    <>
      <AdminProfile />
      <EmailPhoneProfile />
      <OrganizationProfile />
      <LocationDetailsProfile />
    </>
  );
}
