import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CommitteeModal from '../components/CommitteeModal';
import AddEditArchiveWrapper from './AddEditArchiveWrapper';

import { useResetSubCommitteeAndArchiveForm } from '../hooks/committeeResetHookForm';

import {
  selectArchiveId,
  selectArchiveResponseById,
  selectLoaderInApiCall,
  selectIsAddOrUpdating,
} from '../slice/committee.slice';
import { getArchiveById } from '../services/archive.services';
import { useDispatch } from 'react-redux';

function EditArchive({ id, primaryBtnText }) {
  const dispatch = useDispatch();
  const archiveResponse = useSelector(selectArchiveResponseById);
  const isLoading = useSelector(selectLoaderInApiCall);

  const { prepareApiToFormData } = useResetSubCommitteeAndArchiveForm();

  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(getArchiveById(id));
    }
  }, [id]);

  useEffect(() => {
    if (!archiveResponse) return;

    const handleDefaultValue = async () => {
      const apiToFormData = await prepareApiToFormData(archiveResponse);
      setDefaultValues(apiToFormData);
    };
    handleDefaultValue();
  }, [archiveResponse]);

  if (!isLoading && defaultValues) {
    return (
      <AddEditArchiveWrapper
        modalContainer={CommitteeModal}
        defaultValues={defaultValues}
        id={id}
        primaryBtnText={primaryBtnText}
      />
    );
  }

  return (
    <CommitteeModal
      isLoading={isLoading}
      isOpen={true}
      isPrimaryBtnDisable={isLoading}
      primaryBtnText={primaryBtnText}
    />
  );
}

export default function AddEditArchive() {
  const archiveId = useSelector(selectArchiveId);

  const primaryBtnText = 'Archive';

  const isAddOrUpdating = useSelector(selectIsAddOrUpdating);

  if (archiveId) {
    return (
      <EditArchive
        id={archiveId}
        primaryBtnText={primaryBtnText}
        isAddOrUpdating={isAddOrUpdating}
      />
    );
  }
  return <AddEditArchiveWrapper modalContainer={CommitteeModal} primaryBtnText={primaryBtnText} />;
}
