import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as API_ENDPOINTS from '../../../../api/apiEndpoints';
import qs from 'qs';

export const courseApi = createApi({
  reducerPath: 'courseApi',

  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = getState().app.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Courses'],

  endpoints: (builder) => ({
    getCourses: builder.query({
      query: (queryParams) => {
        return `${process.env.REACT_APP_BASE_API_URL}/${API_ENDPOINTS.COURSE_CENTER}${
          API_ENDPOINTS.BUSINESS_BASE_API_ROUTE
        }/${API_ENDPOINTS.COURSES}${qs.stringify(queryParams, {
          addQueryPrefix: true,
          indices: false,
        })}`;
      },
      providesTags: ['Courses'],
    }),

    updateCourseStatus: builder.mutation({
      query: ({ id, status, unPublishedReason, unPublishedDate }) => ({
        url: `${process.env.REACT_APP_BASE_API_URL}/${API_ENDPOINTS.COURSE_CENTER}${API_ENDPOINTS.BUSINESS_BASE_API_ROUTE}/${API_ENDPOINTS.COURSES}/${id}/${API_ENDPOINTS.CHANGE_COURSE_STATUS}`,
        method: 'PUT',
        body: { status, unPublishedReason, unPublishedDate },
      }),
      invalidatesTags: ['Courses'],
    }),

    deleteCourse: builder.mutation({
      query: ({ id, deleteReason }) => ({
        url: `${process.env.REACT_APP_BASE_API_URL}/${API_ENDPOINTS.COURSE_CENTER}${API_ENDPOINTS.BUSINESS_BASE_API_ROUTE}/${API_ENDPOINTS.COURSES}/${id}`,
        headers: { 'Content-Type': 'application/json' },
        method: 'DELETE',
        body: deleteReason,
      }),
      invalidatesTags: ['Courses'],
    }),
  }),
});

export const { useGetCoursesQuery, useUpdateCourseStatusMutation, useDeleteCourseMutation } =
  courseApi;
