import { DoctButton } from '@doct-react/core';
import React from 'react';

const EditMemberFormFooter = ({ editMemberLoading }) => {
  return (
    <DoctButton
      className="float-right my-3 mx-3"
      size="medium"
      text="Submit"
      disabled={editMemberLoading}
    />
  );
};

export default EditMemberFormFooter;
