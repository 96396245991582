import React from 'react';
import { DoctTypography } from '@doct-react/core';

export default function UserAvatarAndInfo({ children, organization, admin, id }) {
  return (
    <div className="d-flex align-items-center border-radius py-20px px-2 flex-column text-center bg-white">
      {children}
      <DoctTypography variant="subtitle2" className="mb-0 mt-12px">
        {organization}
      </DoctTypography>
      <DoctTypography variant="caption2" className="text-grey-600 my-0">
        ID: {id}
      </DoctTypography>
      <DoctTypography variant="body3" className="mb-0 mt-6px text-grey-600" fontWeight="medium">
        Admin: {admin}
      </DoctTypography>
    </div>
  );
}
