import React from 'react';
import { DoctButton, DoctCol, DoctContainer, DoctRow, DoctTypography } from '@doct-react/core';
import PackageBox from './PackageBox';
import PackageBoxRecruiter from './PakageBoxRecruiter';
import useSendSubscription from '../hooks/useSendSubscription';
import NeedAssistance from './NeedAssistance';
import { useLocation, useParams } from 'react-router';
import { useGetPackagesPlansQuery } from '../API/subscriptionApi';
import { DoctAnimationLoading } from '@doct-react/app';
import FooterCopyRight from '../../../layout/components/Footer.CopyRight';
import style from '../SubscriptionModal.module.scss';
import { needAssitanceInfoInSubscription } from '../../../helper/helperFunction.js';
import {
  setEducationArray,
  setModifiedValues,
  setRawFormValues,
} from '../../RecruiterDashboard/ManageJob/slice/manageJobSlice.js';
import { useDispatch } from 'react-redux';
export default function AllPackages() {
  const { id } = useParams();
  const { navigate } = useSendSubscription();
  const { data: packagesPlans, isLoading: loadingPackagesPlans } = useGetPackagesPlansQuery(
    id || 1,
  );

  const dispatch = useDispatch();
  const { state } = useLocation();

  const extractNeedAssitanceInfo = needAssitanceInfoInSubscription(id);

  const getInfoMessage = (email) => {
    if (email === 'courses@docthub.com') {
      return 'For information about the subscription plan, connect with our support team.';
    } else if (email === 'jobs@docthub.com') {
      return 'Our support team is ready to help you understand subscription plans in more depth.';
    }
    return '';
  };

  if (loadingPackagesPlans) {
    return <DoctAnimationLoading />;
  }

  return (
    <>
      <DoctContainer>
        <DoctButton
          onButtonClickHandler={() => {
            dispatch(setModifiedValues({}));
            dispatch(setRawFormValues({}));
            dispatch(setEducationArray({}));

            navigate(state?.backFromSubscription || '/');
          }}
          icon="close"
          variant="text"
          width="28"
          className={`${style['close-icon-bg']} mt-2 d-flex`}
        />
        <DoctTypography
          variant="subtitle2"
          className={`${+id === 1 ? 'btn-text-positive' : 'btn-text-jobs'} text-center mb-1 mt-0`}
        >
          {packagesPlans[0]?.subscriptionPackage?.name
            ? `${packagesPlans[0]?.subscriptionPackage?.name}  Subscription`
            : ''}
        </DoctTypography>
        <DoctTypography variant="h3" className="text-center mb-4 pb-2">
          Choose a Plan
        </DoctTypography>

        <div className={`${style['subscription-card-body-height']}`}>
          <DoctRow>
            {packagesPlans &&
              [...packagesPlans] // Create a copy of the array
                .sort((a, b) => a.displayOrder - b.displayOrder) // Sort the copy based on displayOrder
                .map((choosePlan) => (
                  <DoctCol key={choosePlan.id} className="p-0 mx-2 mb-3">
                    {choosePlan.subscriptionPlanFeature === null ? (
                      <PackageBoxRecruiter
                        {...(choosePlan.subscriptionPlanFeature === null ? choosePlan : '')}
                      />
                    ) : (
                      <DoctCol xs={12} sm={6} md={4} lg={3} key={choosePlan.id} className="p-0">
                        <PackageBox {...choosePlan} />
                      </DoctCol>
                    )}
                  </DoctCol>
                ))}
          </DoctRow>
        </div>

        <div className={`${style['need-assistance-position']} w-100 container`}>
          <NeedAssistance
            Email={extractNeedAssitanceInfo.email}
            Phone={extractNeedAssitanceInfo.mobile}
            infoMessage={getInfoMessage(extractNeedAssitanceInfo.email)}
          />
        </div>
      </DoctContainer>
      <div className={`${style['subscription-modal-footer']} w-100`}>
        <FooterCopyRight />
      </div>
    </>
  );
}
