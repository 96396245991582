import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { serialize } from 'object-to-formdata';
import * as API_ENDPOINT from '../../../../api/apiEndpoints';

const abstractFileUpload = createAsyncThunk(`abstractFileUpload`, async (value, { dispatch }) => {
  const formData = serialize(
    {
      ...value,
    },
    {
      indices: true,
      dotsForObjectNotation: true,
      nullsAsUndefineds: true,
      allowEmptyArrays: true,
    },
  );
  const response = await axios.put(
    `${API_ENDPOINT.CREATE_EVENT_BASE_URL}/${value.id}/upload-abstract-file`,
    formData,
  );
  dispatch({
    type: `eventApi/invalidateTags`,
    payload: ['Events'],
  });
  return response.data;
});

export { abstractFileUpload };
