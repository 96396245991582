import React from 'react';
import style from '../ProfileDropdown.module.scss';

const LayoutProfileDropdown = React.forwardRef(({ children }, ref) => {
  return (
    <div
      ref={ref}
      className={`position-absolute border-radius pb-2 bg-grey-100 ${style['profile-dropdown']} ${style['profile-dropdown-box']}`}
    >
      {children}
    </div>
  );
});
export default LayoutProfileDropdown;
