import React, { useEffect, useState } from 'react';
import { DoctAutoComplete, DoctChip, DoctTextField } from '@doct-react/app';
// import { Controller } from 'react-hook-form';
import { DoctCol, DoctIconButton, DoctRow, DoctTypography } from '@doct-react/core';
import useFieldOptions from '../../../../../../hooks/useFieldOptions/useFieldOptions';
import arrowDropDown from '../../../../../../assets/icons/arrowDropDown.svg';
import {
  useGetCourseSpecialtyIdFromDiscipleIdQuery,
  // useGetCourseTitleFromSearchTextQuery,
} from '../../services/manageCourse.services';
// import { Autocomplete, TextField } from '@mui/material';

const BasicInfo = ({
  control,
  error,
  affiliations,
  setAffiliations,
  watch,
  setValue,
  courseIsFetching,
  defaultDisciplineValue,
}) => {
  // const [open, setOpen] = useState(false);
  const { optionsArray: courseTitleOptions } = useFieldOptions({
    courseCenterRoutes: '/coursetitles',
  });

  const { optionsArray: courseTypeOptions } = useFieldOptions({
    apiRoute: '/course-types/names',
  });

  const { optionsArray: disciplineOptions } = useFieldOptions({
    apiRoute: '/disciplines',
  });

  const { optionsArray: affiliationOptions } = useFieldOptions({
    courseCenterRoutes: '/affiliations',
  });

  const isEdit = window?.location?.href?.split('/')[6];

  const [selectCustomTitle, setSelectCustomTitle] = useState(false);
  const [selectCustomAffiliation, setSelectCustomAffiliation] = useState(false);
  const watchAffiliations = watch('selectAffiliation');
  const watchNewCourseTitle = watch('newCourseTitleName');
  const watchNewAffiliation = watch('newAffiliationName');
  const watchDisciplineId = watch('disciplineId');
  const [specialtyByDiscipline, setSpecialtyByDiscipline] = useState([]);

  const apiDisciplineId = disciplineOptions?.find(
    (element) => element?.label == watchDisciplineId?.label,
  );

  const {
    data: specialties,
    isSuccess,
    isError,
  } = useGetCourseSpecialtyIdFromDiscipleIdQuery({
    id: apiDisciplineId?.id,
  });

  useEffect(() => {
    if (isSuccess) {
      setSpecialtyByDiscipline([]);
      specialties?.map((item) => {
        setSpecialtyByDiscipline((prev) => [
          ...prev,
          { label: item?.name, value: item?.id, id: item?.id },
        ]);
      });
      return;
    }
    if (isError) {
      setSpecialtyByDiscipline([]);
      return;
    }
  }, [specialties, isSuccess, isError]);

  // specialties?.map((item) => {
  //   specialtyByDiscipline.push({ label: item?.name, value: item?.id, id: item?.id });
  // });

  useEffect(() => {
    if (watchDisciplineId?.label == defaultDisciplineValue) return;
    setValue('specialtyId', '');
  }, [watchDisciplineId]);

  const addAffiliationsToArr = () => {
    if (watchAffiliations && !affiliations?.includes(watchAffiliations)) {
      setAffiliations((prevState) => [...prevState, watchAffiliations]);
    }
  };

  const removeAffiliationFromArr = (affiliationToRemove) => {
    setAffiliations(
      affiliations?.filter(
        (existingAffiliation) => existingAffiliation?.label != affiliationToRemove?.label,
      ),
    );
  };

  useEffect(() => {
    if (watchNewCourseTitle) {
      setSelectCustomTitle(true);
    }
    if (watchNewAffiliation) {
      setSelectCustomAffiliation(true);
    }
  }, [watchNewCourseTitle, watchNewAffiliation]);

  const disableSelectedAffiliation = (affiliation) => {
    return !!affiliations.find((element) => element?.value === affiliation?.value);
  };

  useEffect(() => {
    if (courseIsFetching) {
      setValue('selectAffiliation', null);
    }
    if (watchAffiliations?.length) {
      setValue('selectAffiliation', null);
    }
  }, [courseIsFetching, watchAffiliations]);
  return (
    <>
      {!selectCustomTitle && (
        <div className="input-column">
          <div className="d-flex align-items-center justify-content-between">
            <DoctTypography variant="subtitle2">Course Title</DoctTypography>
            {isEdit === 'edit' ? (
              ''
            ) : (
              <span
                onClick={() => {
                  setSelectCustomTitle(true);
                }}
              >
                <DoctTypography variant="subtitle3" className="text-primary cursor-pointer">
                  Couldn&apos;t find?
                </DoctTypography>
              </span>
            )}
          </div>
          {/* NOTE:- Don't remove bellow comment until production deploy */}
          {/* <Controller
            name="courseTitleId"
            control={control}
            rules={{
              required: !selectCustomTitle ? "It's Required Field" : false,
            }}
            render={(render) => (
              <>
                <Autocomplete
                  disabled={isEdit === 'edit'}
                  id="courseTitleId"
                  disableClearable
                  options={courseTitleOptions || []}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  open={open}
                  onChange={(e, val) => {
                    setOpen(false);
                    render.onChange(val);
                  }}
                  onInputChange={(_, newValue) => {
                    setOpen(false);
                    setTimeout(() => {
                      setOpen(true);
                    }, 0);
                    render.onChange(newValue);
                  }}
                  value={render.value || null} // Ensure value is controlled
                  renderInput={(params) => (
                    <TextField
                      required
                      variant="standard"
                      {...params}
                      error={error?.courseTitleId}
                      helperText={error?.courseTitleId ? `It's Required Field` : ''}
                      placeholder="Search or Select course title"
                    />
                  )}
                />
              </>
            )}
          /> */}
          <DoctAutoComplete
            id={'courseTitleId'}
            name={'courseTitleId'}
            variant="standard"
            label={'Search or Select course title'}
            options={courseTitleOptions || []}
            disabled={isEdit === 'edit'}
            control={control}
            isErrors={error}
            validationRules={{
              required: "It's Required Field",
            }}
            onEndScroll={() => {}}
          />
        </div>
      )}

      {selectCustomTitle && (
        <>
          <div className="input-column">
            <DoctTypography variant="subtitle2">Course Title</DoctTypography>

            <div
              onClick={() => setSelectCustomTitle(false)}
              className="d-flex align-items-center justify-content-between bg-white px-2 box-shadow cursor-pointer"
            >
              <DoctTypography variant="textLabel1">Choose custom</DoctTypography>
              <img src={arrowDropDown} />
            </div>
          </div>

          <div className="input-column bg-primary-100 p-2 border-radius">
            <DoctTextField
              control={control}
              isErrors={error}
              id={'newCourseTitleName'}
              name={'newCourseTitleName'}
              label={'Enter course name/title'}
              options={[]}
              showStar={false}
              validationRules={{
                required: selectCustomTitle ? "It's Required Field" : false,
              }}
            />

            <DoctTypography variant="body3" className="my-2 p-0 text-grey-600">
              Docthub will verify this course title or will choose the right match for this course.
            </DoctTypography>
          </div>
        </>
      )}

      <DoctRow>
        <DoctCol sm={6}>
          <DoctTypography variant="subtitle2">Course Type</DoctTypography>
          <DoctAutoComplete
            control={control}
            isErrors={error}
            id={'courseTypeId'}
            name={'courseTypeId'}
            label={'Select'}
            options={courseTypeOptions || []}
            validationRules={{
              required: "It's Required Field",
            }}
            variant="standard"
            onEndScroll={() => {}}
          />
        </DoctCol>
        <DoctCol sm={6}>
          <DoctTypography variant="subtitle2">Stream</DoctTypography>
          <DoctAutoComplete
            control={control}
            isErrors={error}
            id={'disciplineId'}
            name={'disciplineId'}
            label={'Select'}
            options={disciplineOptions || []}
            validationRules={{
              required: "It's Required Field",
            }}
            variant="standard"
            onEndScroll={() => {}}
          />
        </DoctCol>
      </DoctRow>

      <div className="input-column">
        <DoctTypography variant="subtitle2">Specialty</DoctTypography>
        <DoctAutoComplete
          control={control}
          isErrors={error}
          id={'specialtyId'}
          name={'specialtyId'}
          label={'Select'}
          options={specialtyByDiscipline || []}
          validationRules={{
            required: "It's Required Field",
          }}
          variant="standard"
          onEndScroll={() => {}}
        />
      </div>

      {!selectCustomAffiliation && (
        <div className="input-column">
          <div className="d-flex align-items-center justify-content-between">
            <DoctTypography variant="subtitle2">Affiliation (optional)</DoctTypography>

            <span
              onClick={() => {
                setSelectCustomAffiliation(true);
              }}
            >
              <DoctTypography variant="subtitle3" className="text-primary cursor-pointer">
                Couldn&apos;t find?
              </DoctTypography>
            </span>
          </div>
          <DoctRow>
            <DoctCol sm={11}>
              <DoctAutoComplete
                control={control}
                isErrors={error}
                id={'selectAffiliation'}
                name={'selectAffiliation'}
                label={'Search or Select affiliation'}
                options={affiliationOptions || []}
                variant="standard"
                getOptionDisabled={(option) => {
                  return disableSelectedAffiliation(option);
                }}
                onEndScroll={() => {}}
              />
            </DoctCol>
            <DoctCol sm={1}>
              <DoctIconButton
                onButtonClickHandler={(e) => {
                  e.preventDefault();
                  addAffiliationsToArr();
                  setValue('selectAffiliation', '');
                }}
                icon="check"
                size="medium"
              />
            </DoctCol>
          </DoctRow>
        </div>
      )}

      {selectCustomAffiliation && (
        <>
          <div className="input-column">
            <DoctTypography variant="subtitle2">Affiliation (optional)</DoctTypography>

            <div
              onClick={() => setSelectCustomAffiliation(false)}
              className="d-flex align-items-center justify-content-between bg-white px-2 box-shadow cursor-pointer"
            >
              <DoctTypography variant="textLabel1">Choose custom</DoctTypography>
              <img src={arrowDropDown} />
            </div>
          </div>

          <div className="input-column bg-primary-100 p-2 border-radius">
            <DoctTextField
              control={control}
              isErrors={error}
              id={'newAffiliationName'}
              name={'newAffiliationName'}
              label={'Enter affiliation name'}
              options={[]}
              showStar={false}
              validationRules={{
                required: selectCustomAffiliation ? "It's Required Field" : false,
              }}
            />

            <DoctTypography variant="body3" className="my-2 p-0 text-grey-600">
              Docthub will verify this course title or will choose the right match for this course.
            </DoctTypography>
          </div>
        </>
      )}

      <DoctRow>
        <DoctCol sm={12}>
          {affiliations?.length > 0 &&
            affiliations?.map((affiliation) => (
              <div key={affiliation?.id} className="m-2">
                <DoctChip
                  title={affiliation?.label}
                  onCloseHandler={() => {
                    removeAffiliationFromArr(affiliation);
                  }}
                />
              </div>
            ))}
        </DoctCol>
      </DoctRow>
    </>
  );
};

export default BasicInfo;
