import React from 'react';
import * as ROUTE from '../../../routes/constant';
import AddAwardeeActionBtn from './shared/AddAwardeeActionBtn';

const allAwardeesListingActionBar = {
  [`/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_AWARDEES_LIST}`]: {
    pathname: `/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_AWARDEES_LIST}`,
    title: 'E-Certificates',
    navigateButtonLink: '/dashboard/e-certificate/all-awardees-list',
    buttonText: 'Add Awardee',
    buttonIcon: 'plus',
    isButtonDisabled: false,
    btnComponent: <AddAwardeeActionBtn />,
  },
};
export default allAwardeesListingActionBar;
