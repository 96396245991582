import { createSlice } from '@reduxjs/toolkit';
import {
  getDesignationById,
  postDesignation,
  putDesignation,
} from '../services/designation.services';
import {
  getSubCommitteeById,
  postSubCommittee,
  putSubCommittee,
} from '../services/subCommittee.services';
import { getArchiveById, postArchive, putArchive } from '../services/archive.services';

const initialState = {
  showDesignationModal: false,
  showSubCommitteeModal: false,
  showArchiveModal: false,
  committeeMemberOptions: null,
  committeeMembers: [],
  modalTitle: '',
  subCommitteeId: null,
  designationId: null,
  archiveId: null,
  loaderInApiCall: false,
  loaderInSubCommitteeApiCall: false,
  loaderInArchiveApiCall: false,
  subCommitteeResponseById: null,
  designationResponseById: null,
  archiveResponseById: null,
  isAddOrUpdating: false,
};

export const committeeSlice = createSlice({
  name: 'committee',
  initialState,
  reducers: {
    toggleDesignationModal: (state) => {
      state.showDesignationModal = !state.showDesignationModal;
    },
    toggleSubCommitteeModal: (state) => {
      state.showSubCommitteeModal = !state.showSubCommitteeModal;
    },
    toggleArchiveModal: (state) => {
      state.showArchiveModal = !state.showArchiveModal;
    },
    setCommitteeMemberOptions: (state, action) => {
      state.committeeMemberOptions = action.payload;
    },
    setCommitteeMembers: (state, action) => {
      state.committeeMembers = action.payload;
    },
    setModalTitle: (state, action) => {
      state.modalTitle = action.payload;
    },
    setSubCommitteeId: (state, action) => {
      state.subCommitteeId = action.payload;
    },
    setDesignationId: (state, action) => {
      state.designationId = action.payload;
    },
    setArchiveId: (state, action) => {
      state.archiveId = action.payload;
    },
    resetState: (state) => {
      state.subCommitteeResponseById = null;
      state.designationResponseById = null;
      state.archiveResponseById = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postDesignation.pending, (state) => {
      state.isAddOrUpdating = true;
    });
    builder.addCase(postDesignation.fulfilled, (state) => {
      state.isAddOrUpdating = false;
    });
    builder.addCase(postDesignation.rejected, (state) => {
      state.isAddOrUpdating = false;
    });

    builder.addCase(putDesignation.pending, (state) => {
      state.isAddOrUpdating = true;
    });
    builder.addCase(putDesignation.fulfilled, (state) => {
      state.isAddOrUpdating = false;
    });
    builder.addCase(putDesignation.rejected, (state) => {
      state.isAddOrUpdating = false;
    });

    builder.addCase(postSubCommittee.pending, (state) => {
      state.isAddOrUpdating = true;
    });
    builder.addCase(postSubCommittee.fulfilled, (state) => {
      state.isAddOrUpdating = false;
    });
    builder.addCase(postSubCommittee.rejected, (state) => {
      state.isAddOrUpdating = false;
    });

    builder.addCase(putSubCommittee.pending, (state) => {
      state.isAddOrUpdating = true;
    });
    builder.addCase(putSubCommittee.fulfilled, (state) => {
      state.isAddOrUpdating = false;
    });
    builder.addCase(putSubCommittee.rejected, (state) => {
      state.isAddOrUpdating = false;
    });

    builder.addCase(postArchive.pending, (state) => {
      state.isAddOrUpdating = true;
    });
    builder.addCase(postArchive.fulfilled, (state) => {
      state.isAddOrUpdating = false;
    });
    builder.addCase(postArchive.rejected, (state) => {
      state.isAddOrUpdating = false;
    });

    builder.addCase(putArchive.pending, (state) => {
      state.isAddOrUpdating = true;
    });
    builder.addCase(putArchive.fulfilled, (state) => {
      state.isAddOrUpdating = false;
    });
    builder.addCase(putArchive.rejected, (state) => {
      state.isAddOrUpdating = false;
    });

    builder.addCase(getSubCommitteeById.pending, (state) => {
      state.loaderInApiCall = true;
    });
    builder.addCase(getSubCommitteeById.fulfilled, (state, action) => {
      state.subCommitteeResponseById = action.payload;
      state.loaderInApiCall = false;
    });
    builder.addCase(getSubCommitteeById.rejected, (state) => {
      state.loaderInApiCall = false;
    });

    builder.addCase(getDesignationById.pending, (state) => {
      state.loaderInApiCall = true;
    });
    builder.addCase(getDesignationById.fulfilled, (state, action) => {
      state.designationResponseById = action.payload;
      state.loaderInApiCall = false;
    });
    builder.addCase(getDesignationById.rejected, (state) => {
      state.loaderInApiCall = false;
    });

    builder.addCase(getArchiveById.pending, (state) => {
      state.loaderInApiCall = true;
    });
    builder.addCase(getArchiveById.fulfilled, (state, action) => {
      state.archiveResponseById = action.payload;
      state.loaderInApiCall = false;
    });
    builder.addCase(getArchiveById.rejected, (state) => {
      state.loaderInApiCall = false;
    });
  },
});

export const {
  toggleDesignationModal,
  toggleSubCommitteeModal,
  toggleArchiveModal,
  setCommitteeMemberOptions,
  setCommitteeMembers,
  setModalTitle,
  setSubCommitteeId,
  setDesignationId,
  setArchiveId,
  resetState,
} = committeeSlice.actions;

export const selectShowDesignationModal = (state) => state['committee'].showDesignationModal;
export const selectSubCommitteeModal = (state) => state['committee'].showSubCommitteeModal;
export const selectArchiveModal = (state) => state['committee'].showArchiveModal;
export const selectCommitteeMemberOptions = (state) => state['committee'].committeeMemberOptions;
export const selectCommitteeMembers = (state) => state['committee'].committeeMembers;
export const selectModalTitle = (state) => state['committee'].modalTitle;
export const selectSubCommitteeId = (state) => state['committee'].subCommitteeId;
export const selectDesignationId = (state) => state['committee'].designationId;
export const selectLoaderInApiCall = (state) => state['committee'].loaderInApiCall;
export const selectArchiveId = (state) => state['committee'].archiveId;
export const selectSubCommitteeResponseById = (state) =>
  state['committee'].subCommitteeResponseById;
export const selectDesignationResponseById = (state) => state['committee'].designationResponseById;
export const selectArchiveResponseById = (state) => state['committee'].archiveResponseById;
export const selectIsAddOrUpdating = (state) => state['committee'].isAddOrUpdating;

export default committeeSlice;
