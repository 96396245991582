import { DoctTypography } from '@doct-react/core';
import React from 'react';
import style from '../SubscriptionModal.module.scss';

export default function UnlimitedChip({ blackChipText }) {
  if (blackChipText === 0) {
    return null;
  }
  return (
    <>
      {blackChipText && (
        <div className={`${style['black-chip']} bg-black text-white mr-1`}>
          <DoctTypography variant="subtitle2" className="m-0">
            {blackChipText}
          </DoctTypography>
        </div>
      )}
    </>
  );
}
