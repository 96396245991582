import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TOST_BOTTOM_CENTER } from '../../../../constants/toasterPosition.constants';
import { setShowToasterWithParams } from '../../../featuresSlice';

import { putDesignation, useDeleteDesignationMutation } from '../services/designation.services';

import CardCommittee from './Card.Committee/Card.Committee';
import DeleteCommittee from './Common/Delete.Committee';

function ListDesignation({ designations }) {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [designationIdAndName, setDesignationIdAndName] = useState({});
  const [deleteDesignation] = useDeleteDesignationMutation();

  const moveObject = (data, direction) => {
    const transformObj = { ...data };
    if (direction === 'Up') {
      transformObj.displayPosition = data.displayPosition - 1;
      transformObj.isDisplayPositionUpOrDown = direction;
    } else if (direction === 'Down') {
      transformObj.displayPosition = data.displayPosition + 1;
      transformObj.isDisplayPositionUpOrDown = direction;
    }
    dispatch(putDesignation({ id: data.id, data: transformObj }));
  };

  return (
    <div>
      {designations?.map((item, index) => {
        return (
          <CardCommittee
            designation={item}
            key={index}
            setIsModalOpen={setIsModalOpen}
            setDesignationIdAndName={setDesignationIdAndName}
            moveObject={moveObject}
            index={index}
            designationArray={designations}
          />
        );
      })}
      <DeleteCommittee
        open={isModalOpen}
        primaryBtnText={'Yes, Remove'}
        featureText={'Designation'}
        name={designationIdAndName?.name}
        handleClose={() => {
          setDesignationIdAndName({});
          setIsModalOpen(false);
        }}
        handlePrimaryButtonClick={() => {
          deleteDesignation({ id: designationIdAndName?.id });
          setIsModalOpen(false);
          setDesignationIdAndName({});
          dispatch(
            setShowToasterWithParams({
              variant: 'neutral',
              position: TOST_BOTTOM_CENTER,
              message: 'Designation Removed Successfully',
            }),
          );
        }}
      />
    </div>
  );
}

export default ListDesignation;
