import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetUserQuery } from '../../../../../components';
import { ProfileDataBox, ProfileDataBoxContentTitleDescription } from '../../../../../shared';
import ActionMenu from '../ActionMenu';

import { selectIsModalOpenAdminProfile, toggleAdminProfileModal } from '../../slice/profile.slice';

import EditAdminProfile from './EditAdminProfile';
import useHookDefaultValue from './useFormDefaultValue';
import EditModal from '../EditModal';

export default function AdminProfile() {
  const dispatch = useDispatch();

  const { data: userData } = useGetUserQuery('');
  const { name, professionalTitle } = userData || {};

  const { prepareFormValue } = useHookDefaultValue();

  const isModalOpen = useSelector(selectIsModalOpenAdminProfile);

  const menuAction = useCallback((item) => {
    if (item.title == 'Edit') {
      handleOpenModal();
    }
  }, []);

  const handleOpenModal = useCallback(() => {
    dispatch(
      toggleAdminProfileModal({
        modalTitle: 'Edit Enterprise Admin Info',
        isModalOpenAdminProfile: true,
      }),
    );
  }, []);

  const handleModalClose = useCallback(() => {
    dispatch(
      toggleAdminProfileModal({
        modalTitle: null,
        isModalOpenAdminProfile: false,
        ImageFileName: 'image',
        ResumeFileName: 'resume',
      }),
    );
  }, []);

  return (
    <>
      <ProfileDataBox
        heading={'Enterprise Admin'}
        actionMenu={
          <ActionMenu options={[{ title: 'Edit' }]} menuAction={(item) => menuAction(item)} />
        }
      >
        <ProfileDataBoxContentTitleDescription title={'Full Name:'} description={name || '-'} />
        <ProfileDataBoxContentTitleDescription
          title={'Designation:'}
          description={professionalTitle || '-'}
        />
      </ProfileDataBox>
      {isModalOpen && (
        <EditAdminProfile
          defaultValues={prepareFormValue(userData)}
          userData={userData}
          modalComponent={EditModal}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
}
