import React, { useEffect, useState } from 'react';
import { DoctChip, DoctRadioGroup, DoctFreeSoloSearchInput, DoctTextField } from '@doct-react/app';
import { DoctCol, DoctIcon, DoctRow, DoctTypography } from '@doct-react/core';
import { EMAIL_VALIDATION } from '../../../constants/constants';
import LocationField from '../LocationField';
import MobileWhatsAppForm from '../MobileWhatsAppForm/MobileWhatsAppForm';
import { FORM_NAMES_STEPS } from './attendeeForm.constants';
import { getSpecialtyNames } from '../../../features/ManageMemberships/AddNewMember/components/services/createNewMember.service';
import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function AttendeeForm({
  control,
  errors,
  touched,
  watch,
  setValue,
  savedValue,
  clearErrors,
  setSpecialityArray,
  specialityArray,
  isSpecialtyEmpty,
  setIsSpecialtyEmpty,
  register,
  specialtyVal,
  setSpecialtyVal,
}) {
  const [specialtyDataOption, setSpecialtyDataOption] = useState();
  const [specialtySearchText, setSpecialtySearchText] = useState();
  const accessToken = useSelector((state) => state?.app?.token);

  useEffect(() => {
    getSpecialtyNames(specialtySearchText).then((data) => {
      setSpecialtyDataOption(data);
    });
  }, [specialtySearchText]);

  const removeSpecialityChipHandler = (chip) => {
    setSpecialityArray(specialityArray.filter((list) => list != chip));
  };

  const handleSpecialtyVal = () => {
    if (!specialtyVal) return;

    if (specialityArray && !specialityArray?.includes(specialtyVal)) {
      setSpecialityArray([...specialityArray, specialtyVal]);
    }
    setSpecialtyVal('');
  };

  const [organizationsNamesArray, setOrganizationsNamesArray] = useState([]);
  const [organizationValue, setOrganizationValue] = useState('');
  const currentOrganization = watch(FORM_NAMES_STEPS.currentOrganization) || '';
  useEffect(() => {
    (async () => {
      if (organizationValue && organizationValue.length > 0) {
        try {
          const organizationNamesArray = [];
          const res = await axios.get(
            `${process.env.REACT_APP_BASE_API_URL}/contentcenter/organizations/names?searchText=${organizationValue}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
          res?.data?.map((item) => {
            organizationNamesArray.push({ title: item.name, id: item.id });
          });

          setOrganizationsNamesArray(organizationNamesArray);
        } catch (error) {
          console.error(error);
        }
      } else {
        setOrganizationsNamesArray([]);
      }
    })();
  }, [currentOrganization, organizationValue]);

  return (
    <DoctRow>
      <DoctCol xs={6} className="mx-auto">
        <DoctTypography variant="subtitle3" className="mb-3 mt-0">
          PERSONAL DETAILS
        </DoctTypography>
        <DoctTypography variant="subtitle2" className="my-0">
          Gender
        </DoctTypography>
        <div className="mx-n2 mb-2">
          <DoctRadioGroup
            name={FORM_NAMES_STEPS.gender}
            id="radio1"
            control={control}
            options={[
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
            ]}
            validationRules={{
              required: "It's Required Field",
            }}
            isErrors={errors}
          />
        </div>
        <DoctTextField
          showStar
          name={FORM_NAMES_STEPS.fullName}
          label="Certificate Name"
          id="fullName"
          pattern="\s*(\S\s*){3,}"
          control={control}
          isErrors={errors}
          defaultValue=""
          validationRules={{
            minLength: {
              value: 3,
              message: 'Name should be minimum 3 characters long',
            },
            required: "It's Required Field",
            pattern: {
              value: /^(?=.*[a-zA-Z])[a-zA-Z\s]*[a-zA-Z]{3,}[a-zA-Z\s]*\s*$/,
              message: 'Name should contain only letters',
            },
          }}
          touched={touched}
        />
        <DoctTypography variant="caption2" className="text-initial mb-3 d-block mt-1">
          This name will be shown in Event Certificate.
        </DoctTypography>
        <LocationField
          savedValue={savedValue}
          control={control}
          errors={errors}
          touched={touched}
          watch={watch}
          setValue={setValue}
          inputProps={{
            country: {
              disabled: true,
            },
          }}
        />
        <DoctTypography variant="subtitle3" className="registration-form-gap-top">
          CONTACT DETAILS
        </DoctTypography>
        <MobileWhatsAppForm
          control={control}
          errors={errors}
          touched={touched}
          watch={watch}
          setValue={setValue}
          clearErrors={clearErrors}
          className={'d-flex input-column mx-n2'}
        />
        <DoctTextField
          showStar
          name={FORM_NAMES_STEPS.email}
          label="Email address"
          id="email"
          control={control}
          isErrors={errors}
          touched={touched}
          validationRules={{
            required: "It's Required Field",
            pattern: {
              value: EMAIL_VALIDATION,
              message: 'Entered value does not match email format',
            },
          }}
        />
        <div className="d-flex w-100 mt-3">
          <div className="w-100">
            <DoctFreeSoloSearchInput
              control={control}
              placeholder="Select specialty*"
              id="specialtyIds"
              onChangeHandler={(val) => {
                if (val) {
                  setIsSpecialtyEmpty(false);
                }
                setSpecialtyVal(val);
                setValue(FORM_NAMES_STEPS.specialtyIds, specialityArray);
              }}
              onInputChangeHandler={(val) => {
                if (val) {
                  setIsSpecialtyEmpty(false);
                }
                setSpecialtySearchText(val);
                setValue(FORM_NAMES_STEPS.specialtyIds, specialityArray);
              }}
              name={FORM_NAMES_STEPS.specialtyIds}
              hideSearchIcon={true}
              value={specialtyVal}
              options={specialtyDataOption}
              isErrors={isSpecialtyEmpty}
              inputValue={specialtyVal?.value}
              validationRules={{
                required: "It's Required Field",
              }}
              onClearHandler={(e) => {
                if (specialityArray) {
                  e.preventDefault();
                  setSpecialtyVal();
                  setValue(FORM_NAMES_STEPS.specialtyIds, []);
                }
              }}
            />
          </div>
          <div
            className="px-2"
            onClick={(e) => {
              e.preventDefault();
              handleSpecialtyVal();
            }}
          >
            <DoctIcon className="cursor-pointer" name="success" width="40" fill="#00A0C0" />
          </div>
        </div>
        {isSpecialtyEmpty && specialityArray?.length <= 0 && (
          <p className="custom-validation-error">Its required</p>
        )}
        {specialityArray?.length != 0 && <div className="mt-1"></div>}
        <div className="mx-n1 d-flex flex-wrap">
          {specialityArray?.map((speciality, index) => (
            <span key={index} className="mx-1 mb-1 mt-1">
              <DoctChip
                title={speciality}
                onCloseHandler={() => {
                  removeSpecialityChipHandler(speciality);
                }}
              />
            </span>
          ))}
        </div>
        <DoctTextField
          name={FORM_NAMES_STEPS.currentDesignation}
          label="Designation"
          id="currentDesignation"
          control={control}
          isErrors={errors}
          touched={touched}
          validationRules={{ required: "It's Required Field" }}
          className="mt-3 mb-3"
        />

        <Autocomplete
          className="mb-3"
          value={currentOrganization || organizationValue || ''}
          onChange={(_, selectedValue) => {
            setOrganizationValue(selectedValue);
            setValue(FORM_NAMES_STEPS.currentOrganization, selectedValue, {
              shouldValidate: true,
            });
          }}
          onInputChange={(_, inputValue) => {
            setOrganizationValue(inputValue);
            setValue(FORM_NAMES_STEPS.currentOrganization, inputValue, {
              shouldValidate: true,
            });
          }}
          freeSolo
          options={organizationsNamesArray.map((option) => option.title) || []}
          renderInput={(params) => (
            <TextField
              {...params}
              {...register(FORM_NAMES_STEPS.currentOrganization, {
                required: "It's a Required Field",
                validate: (value) => {
                  if (value.trim() !== value) return 'Blank spaces are not allowed';
                  return true;
                },
              })}
              label="Organization *"
              error={!!errors[FORM_NAMES_STEPS.currentOrganization]}
              helperText={errors[FORM_NAMES_STEPS.currentOrganization]?.message}
              sx={{
                '.MuiFormLabel-root': {
                  fontSize: '14px',
                  color: '#717171 !important',
                  fontWeight: '400',
                  lineHeight: '16px',
                  zIndex: '1',
                  transition: 'all 0.2s ease-in-out',
                  pointerEvents: 'none',
                  '&.Mui-focused': {
                    transform: 'translate(12px, 9px) scale(0.75)',
                    fontSize: '14px !important',
                    color: 'rgb(0, 160, 192) !important',
                  },
                  '&.Mui-error': {
                    color: '#d32f2f !important',
                  },
                  ...(organizationValue && {
                    transform: 'translate(12px, 9px) scale(0.75)',
                    fontSize: '14px !important',
                    color: '#717171 !important',
                    '&.Mui-focused': {
                      color: 'rgb(0, 160, 192) !important',
                    },
                  }),
                },
                '.MuiInputBase-root': {
                  marginTop: '0',
                  padding: '13px 12px 5px !important',
                  backgroundColor: 'rgba(255, 255, 255, 0.6) !important',
                  height: '46px !important',
                  borderRadius: '0 !important',
                  position: 'relative',
                  transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1)',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    height: '1px',
                    backgroundColor: '#e3e3e3',
                    transition: 'background-color 0.2s ease-in-out, height 0.2s ease-in-out',
                  },
                  '&:hover::before': {
                    backgroundColor: 'rgb(0, 160, 192)',
                    height: '2px',
                  },
                  '&.Mui-focused::before': {
                    backgroundColor: 'rgb(0, 160, 192)',
                    height: '2px',
                  },
                  '&.Mui-error::before': {
                    backgroundColor: '#e3e3e3',
                    height: '1px',
                  },
                  '&.Mui-error:hover::before': {
                    backgroundColor: '#d32f2f',
                    height: '1px',
                  },
                  '.MuiInputBase-input': {
                    paddingLeft: '0 !important',
                    paddingRight: '30px !important',
                    paddingBottom: '0px !important',
                    fontSize: '14px !important',
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none !important',
                  },
                },
                '.MuiFormHelperText-root': {
                  fontSize: '11px',
                  lineHeight: '14px',
                  marginTop: '4px',
                },
              }}
            />
          )}
        />
        <DoctTypography variant="subtitle3" className="registration-form-gap-top">
          OTHER DETAILS (OPTIONAL)
        </DoctTypography>
        <DoctTextField
          name={FORM_NAMES_STEPS.practiceLicenseNumber}
          label="Practice License Number"
          id="practiceLicenseNumber"
          control={control}
          isErrors={errors}
          touched={touched}
          validationRules={{}}
          className="mb-3"
        />
        <DoctTextField
          name={FORM_NAMES_STEPS.membershipID}
          label="Membership ID"
          id="membershipID"
          control={control}
          isErrors={errors}
          touched={touched}
          validationRules={{}}
          className="mb-4"
        />
      </DoctCol>
    </DoctRow>
  );
}
