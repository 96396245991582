import React from 'react';
import { useDispatch } from 'react-redux';
import { DoctButton, DoctTypography } from '@doct-react/core';
import { DoctActionMenu } from '@doct-react/app';

import dayjs from 'dayjs';
import { setArchiveId, setModalTitle, toggleArchiveModal } from '../../slice/committee.slice';

function ArchiveCard({
  archive,
  options,
  setIsArchiveView,
  setStoreArchiveIdWithName,
  setIsModalOpen,
}) {
  const dispatch = useDispatch();

  return (
    <div className="d-flex archives-background-tab-color border-radius mt-3 justify-content-between align-items-center p-2">
      <div>
        <DoctTypography variant="subtitle2" fontWeight="medium" className="m-0 p-0 mb-1">
          {archive.title}
        </DoctTypography>
        <DoctTypography variant="textLabel2" fontWeight="medium" className="m-0 p-0 text-grey-400">
          {dayjs(archive.date).format('DD MMM YYYY')}
        </DoctTypography>
      </div>
      <div className="d-flex">
        <DoctButton
          text="View"
          type="inverse"
          variant="text"
          className="mr-sm-n3"
          onButtonClickHandler={() => {
            setStoreArchiveIdWithName({ id: archive.id, name: archive.title });
            setIsArchiveView(true);
          }}
        />
        <DoctActionMenu
          options={options}
          className="more_action_menu mr-1"
          onClick={(item) => {
            if (item.title == 'Rename Archive') {
              dispatch(setModalTitle('Rename Archive'));
              dispatch(setArchiveId(archive.id));
              dispatch(toggleArchiveModal());
            }
            if (item.title == 'Delete Archive') {
              setStoreArchiveIdWithName({ id: archive.id, name: archive.title });
              setIsModalOpen(true);
            }
          }}
        />
      </div>
    </div>
  );
}

export default ArchiveCard;
