import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  packages: [],
  plans: [],
  choosedPlan: {},
  isLoading: false,
  error: null,
  billingDetails: {},
  setSuccessFormDetails: {},
  amountDetails: {},
  prepData: {},
  tabValue: 0,
  pathNameOnBackOfBillingDetails: '',
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setChoosedPlan: (state, action) => {
      state.choosedPlan = action?.payload;
    },
    setBillingDetails: (state, action) => {
      state.billingDetails = action?.payload;
    },
    setPrepData: (state, action) => {
      state.prepData = action?.payload;
    },
    setSuccessFormDetails: (state, action) => {
      state.setSuccessFormDetails = action?.payload;
    },
    setAmountDetails: (state, action) => {
      state.amountDetails = action.payload;
    },
    resetBillingDetails: (state) => {
      state.billingDetails = {};
    },
    setTabValue: (state, action) => {
      state.tabValue = action.payload;
    },
    setPathNameOnBackOfBillingDetails: (state, action) => {
      state.pathNameOnBackOfBillingDetails = action.payload;
    },
  },
});

export const {
  setChoosedPlan,
  setBillingDetails,
  resetBillingDetails,
  setSuccessFormDetails,
  setAmountDetails,
  setPrepData,
  setTabValue,
  setPathNameOnBackOfBillingDetails,
} = subscriptionSlice.actions;

export default subscriptionSlice;
