import React from 'react';
import { useNavigate } from 'react-router';
import { useGetEventQuickAccessQuery } from '../../services/quickAccess.services';
import DefaultStateImage from '../../../../assets/images/create-event-placeholder.png';
import QuickCardHighted from '../QuickCard/QuickCardHighted';
import * as ROUTE from '../../../../routes/constant';
import EventsCard from './EventsCard';
import EmptyStateCard from '../QuickCard/EmptyStateCard';

function EventQuickAccessWrapper() {
  const { data: eventData, isLoading } = useGetEventQuickAccessQuery('', {
    refetchOnMountOrArgChange: true,
  });

  const { activeEventSummaryDto } = eventData || {};

  const navigate = useNavigate();

  return (
    <QuickCardHighted title="Events">
      {((activeEventSummaryDto?.length > 0 || eventData?.draftEvents) && !isLoading) ||
      isLoading ? (
        <EventsCard eventData={eventData} navigate={navigate} isLoading={isLoading} />
      ) : (
        <EmptyStateCard
          icon={<img src={DefaultStateImage} alt="Post your first Job Now!" />}
          title={'Create an Event Now!'}
          btnText="Create Event"
          bgcolor={'create-event-button-bgcolor'}
          onPressFunction={() => {
            navigate(`${ROUTE.PATH_CREATE_EVENT}`);
          }}
        />
      )}
    </QuickCardHighted>
  );
}

export default EventQuickAccessWrapper;
