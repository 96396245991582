import { DoctAnimationLoading, DoctTabContent, DoctTabWrapper } from '@doct-react/app';
import { DoctTypography } from '@doct-react/core';
import React, { useState } from 'react';
import usePrepareRecruiterApplicantTabList from '../../../../hooks/usePrepareApplicantStatus';
import JobApplicantListingRow from './JobApplicantListingRow';
import LoadMore from '../../../../JobListing/components/LoadMore';
import useQueryHooks from '../../../../../../hooks/useQueryHooks';
import qs from 'qs';
import { useEffect } from 'react';

const JobCustomTableMain = ({
  isLoading,
  selectedJobId,
  jobApplicantData,
  reviewedApplicantData,
  unreadApplicantData,
  shortlistedApplicantData,
  rejectedApplicantData,
  savedApplicantData,
  setValue,
  value = 0,
}) => {
  const tabOptionsArray = usePrepareRecruiterApplicantTabList();
  const [selectedId, setSelectedId] = useState();
  const [tableLoading, setTableLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [jobsData, setJobsData] = useState(0);
  const [jobsAllData, setJobsAllData] = useState(7);

  const query = useQueryHooks();

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setSelectedId(null);
  }, [qs.stringify(query)]);

  if (isLoading) {
    return (
      <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
        <DoctAnimationLoading />
      </div>
    );
  }

  return (
    <div className="content-with-animated-loader-area">
      <DoctTabWrapper value={value} handleChange={handleChange} tabOptions={tabOptionsArray} />
      <DoctTabContent value={value} index={0}>
        <DoctTypography variant="textLabel2" className="text-grey-600">
          {jobApplicantData?.totalRecords} APPLICANTS
        </DoctTypography>

        {jobApplicantData?.jobApplicants?.slice(jobsData, jobsAllData).map((applicantData, i) => (
          <>
            <JobApplicantListingRow
              setTableLoading={setTableLoading}
              jobId={selectedJobId}
              id={i}
              tableRowData={applicantData}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              key={i}
            />
          </>
        ))}
        <LoadMore
          allJobsData={jobApplicantData?.jobApplicants}
          setJobsAllData={setJobsAllData}
          jobsAllData={jobsAllData}
        />
      </DoctTabContent>

      <DoctTabContent value={value} index={1}>
        <DoctTypography variant="textLabel2" className="text-grey-600">
          {reviewedApplicantData?.totalRecords} APPLICANTS
        </DoctTypography>
        {reviewedApplicantData?.jobApplicants?.map((applicantData, i) => (
          <>
            <JobApplicantListingRow
              setTableLoading={setTableLoading}
              jobId={selectedJobId}
              id={i}
              tableRowData={applicantData}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              key={i}
            />
          </>
        ))}
      </DoctTabContent>

      <DoctTabContent value={value} index={2}>
        <DoctTypography variant="textLabel2" className="text-grey-600">
          {unreadApplicantData?.totalRecords} APPLICANTS
        </DoctTypography>
        {unreadApplicantData?.jobApplicants?.map((applicantData, i) => (
          <>
            <JobApplicantListingRow
              setTableLoading={setTableLoading}
              jobId={selectedJobId}
              id={i}
              tableRowData={applicantData}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              key={i}
            />
          </>
        ))}
      </DoctTabContent>

      <DoctTabContent value={value} index={3}>
        <DoctTypography variant="textLabel2" className="text-grey-600">
          {shortlistedApplicantData?.totalRecords} APPLICANTS
        </DoctTypography>
        {shortlistedApplicantData?.jobApplicants?.map((applicantData, i) => (
          <>
            <JobApplicantListingRow
              setTableLoading={setTableLoading}
              jobId={selectedJobId}
              id={i}
              tableRowData={applicantData}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              key={i}
            />
          </>
        ))}
      </DoctTabContent>

      <DoctTabContent value={value} index={4}>
        <DoctTypography variant="textLabel2" className="text-grey-600">
          {rejectedApplicantData?.totalRecords} APPLICANTS
        </DoctTypography>
        {rejectedApplicantData?.jobApplicants?.map((applicantData, i) => (
          <>
            <JobApplicantListingRow
              setTableLoading={setTableLoading}
              jobId={selectedJobId}
              id={i}
              tableRowData={applicantData}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              key={i}
            />
          </>
        ))}
      </DoctTabContent>

      <DoctTabContent value={value} index={5}>
        <DoctTypography variant="textLabel2" className="text-grey-600">
          {savedApplicantData?.totalRecords} APPLICANTS
        </DoctTypography>
        {savedApplicantData?.jobApplicants?.map((applicantData, i) => (
          <>
            <JobApplicantListingRow
              setTableLoading={setTableLoading}
              jobId={selectedJobId}
              id={i}
              tableRowData={applicantData}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              key={i}
            />
          </>
        ))}
      </DoctTabContent>
      <div
        className={`${
          isLoading || tableLoading ? 'd-block' : 'd-none'
        } content-animated-loader-row adjust-loader-for-tab-view`}
      >
        <DoctAnimationLoading />
      </div>
    </div>
  );
};

export default JobCustomTableMain;
