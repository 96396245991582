import { DoctTypography } from '@doct-react/core';
import React from 'react';
import rocketIllustration from '../../../../../../../assets/icons/boost-img.png';
import './CounsellingCta.scss';

const CounsellingCTA = () => {
  return (
    <div className="w-100 my-4">
      <div className="product_objective_text border-radius d-flex align-items-center p-2">
        <img
          src={rocketIllustration}
          alt="rocket_illustration"
          className="px-3"
          width={80}
          height={50}
        />

        <span className="mx-2">
          <DoctTypography variant="body1">
            <span className="font-weight-bold">
              For further assistance or information, you can contact us at{' '}
              <a href="tel:+919510202289">(+91) 95102 02289</a> or Email at{' '}
              <a href="mailto:courses@docthub.com">courses@docthub.com</a>
            </span>
          </DoctTypography>
        </span>
      </div>

      {/* <div className="d-flex w-100 bg-white p-5 border-radius-12 mx-auto mt-4 align-items-center">
        <div className="w-50 bussines_counselling_container">
          <DoctTypography variant="h4">
            <span className="text-primary-400">Counselling</span> for Business
          </DoctTypography>
          <DoctTypography variant="body2" className="text-grey-600">
            We can be your official career counselling partner!!!!
          </DoctTypography>
          <DoctTypography variant="body1" fontWeight="regular">
            Docthub is open to career counselling partnerships <br /> with Institutes, Schools &
            Coaching Classes.
          </DoctTypography>
          <DoctTypography variant="body2" className="text-grey-600">
            For More information reach us at, <br /> Email:
            <a href="mailto:counselling@docthub.com"> counselling@docthub.com </a>
            OR <br /> Call on: (+91) 8320876298
          </DoctTypography>
        </div>
  
        <img src={counsellingIllustration} />
      </div> */}
    </div>
  );
};

export default CounsellingCTA;
