import { DoctTypography } from '@doct-react/core';
import React from 'react';
import style from '../SubscriptionModal.module.scss';

export default function ContactDetailChip({ Email, Phone }) {
  return (
    <div
      className={`${style['contact-chip-body']} d-flex bg-white align-self-center ml-4 mr-4 align-items-center`}
    >
      <DoctTypography variant="subtitle2" className="m-0 text-grey-600">
        Email: <span className="text-grey-800 font-weight-bold"> {Email} </span>
      </DoctTypography>
      <span className="text-grey-200"> &nbsp; | &nbsp;</span>
      <DoctTypography variant="subtitle2" className="m-0 text-grey-600">
        Phone: <span className="text-grey-800 font-weight-bold"> {Phone} </span>
      </DoctTypography>
    </div>
  );
}
