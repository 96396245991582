import { DoctTypography } from '@doct-react/core';
import React from 'react';
import style from './CommonPackageDetailCard.module.scss';

export default function CommonPackageDetailCard({
  packageTitle,
  subscriptionPlanName,
  children,
  accordionBody,
  icon,
  isActive,
  status,
}) {
  return (
    <div>
      <DoctTypography
        variant="h6"
        className="d-flex align-items-center text-grey-800 mt-4 mb-3 font-weight-bold"
      >
        {icon}
        <div className="ml-1">{packageTitle}</div>
      </DoctTypography>
      <div className={`${style['package-details-card-body']} bg-white p-4 text-grey-800 mb-2 pb-1`}>
        <div className="d-flex align-items-center justify-content-between">
          <DoctTypography variant="subtitle3" className="m-0 mb-2 pb-1">
            PACKAGE DETAILS
          </DoctTypography>
          {isActive && (
            <div
              className={`${style['subscription-chip']} ${
                status === 'Expired' ? 'bg-grey-400' : 'bg-success'
              }  text-white font-weight-regular`}
            >
              <DoctTypography variant="subtitle3" className="m-0">
                {status.toUpperCase()}
              </DoctTypography>
            </div>
          )}
        </div>
        <DoctTypography variant="subtitle1" className="m-0 text-grey-800">
          {subscriptionPlanName}
        </DoctTypography>
        <div className="d-flex mb-3">{children}</div>
        {accordionBody}
      </div>
    </div>
  );
}
