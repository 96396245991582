import React from 'react';
import { DoctButton, DoctTypography } from '@doct-react/core';
import style from '../../../Security.module.scss';

export function TitleDescription({ title, description }) {
  return (
    <div className="d-flex">
      <DoctTypography
        variant="textLabel1"
        className={`text-grey-600 mr-4 ${style['security-action-row-title']}`}
      >
        {title}
      </DoctTypography>
      <DoctTypography variant="textLabel1">{description}</DoctTypography>
    </div>
  );
}

export function ActionButton({ onClick, btnText = 'CHANGE' }) {
  return (
    <DoctButton
      text={btnText}
      type="semantic-info"
      variant="outlined"
      size="small"
      onButtonClickHandler={(e) => {
        e.stopPropagation();
        onClick();
      }}
    />
  );
}
