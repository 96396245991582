import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { LayoutDashboard, MangeLayout } from '../layout';
import {
  QuickAccess,
  eventPageSidebar,
  Events,
  Registration,
  InvoicesSummary,
  ManageAttendees,
  ComplementaryRegistration,
  OfflineRegistration,
  CreateEvent,
  // ErrorInInquiry,
  // InquiryApplicationSentSuccessfully,
  Editmembership,
  institutePageSidebar,
  ManageCourseForm,
  InstituteCoursesListing,
  InstituteCourseApplicants,
  PreviewCourse,
  recruiterPageSidebar,
  RecruiterJobListing,
  RecruiterJobApplicant,
  RecruiterPostAJob,
  PreviewJob,
  ActivityLog,
  AddEditActivityLog,
  Profile,
  settingsSidebar,
  Security,
  Abstract,
  CommitteeContainer,
} from '../features';
import { AuthWrapper } from '../components';
import * as ROUTE from './constant';
import { AllMemberships, ManageDirectory, membersPageSidebar } from '../features/ManageMemberships';
import { NewMemberShipPlanCreationForm } from '../features/ManageMemberships/NewMembershipPlanCreation/NewMemberShipPlanCreationForm';
import { AddNewMemberForm } from '../features/ManageMemberships/AddNewMember';
import { EditMemberForm } from '../features/ManageMemberships/EditMember';
import useExcludeHeaderPathname from '../hooks/useExcludeHeaderPathname';
import CourseSuccessPage from '../features/InstituteDashboard/Courses/ManageCourse/components/onPostSuccess/CourseSuccessPage';
import CourseFailurePage from '../features/InstituteDashboard/Courses/ManageCourse/components/onPostFail/CourseFailurePage';
import JobPostSuccess from '../features/RecruiterDashboard/ManageJob/components/JobApplicationStatusPages/JobPostSuccess';
import JobPostFailure from '../features/RecruiterDashboard/ManageJob/components/JobApplicationStatusPages/JobPostFailure';
import billingSubscriptionSidebar from '../features/SubscriptionModal/billingSubscription.sidebar';
import BillingDetailsLayout from '../features/SubscriptionModal/BillingDetails/BillingDetailsLayout';
import { SubscriptionTabContent } from '../features/SubscriptionModal';
import AllPackages from '../features/SubscriptionModal/AllPackages/AllPackages';
import SubscriptionForm from '../features/SubscriptionModal/Form/Form';
import FormRecruiter from '../features/SubscriptionModal/Form/FormRecruiter.jsx';
import OrderScreen from '../features/SubscriptionModal/OrderScreen/OrderScreen';
import ConfirmationScreen from '../features/SubscriptionModal/ConfirmationScreen/ConfirmationScreen';
import CourseApplicantUsage from '../features/InstituteDashboard/Applicants/components/CourseApplicantUsage';
import TalentAcquisition from '../features/landingPages/TalentAcquisition';
import EventLandingPage from '../features/landingPages/EventLandingPage';
import LandingPageRecruiter from '../features/landingPages/LandingPageRecruiter';
import LandingPageInstitute from '../features/landingPages/LandingPageInstitute';
import RedirectInstitutePage from '../features/landingPages/RedirectInstitutePage';
import RedirectRecruiterPage from '../features/landingPages/RedirectRecruiterPage';
import BeforeLoginPage from '../features/BeforeLoginPage/BeforeLoginPage';
import LandingPageEnterpriseSolutions from '../features/landingPages/LandingPageEnterpriseSolutions';
import PrivacyPolicyEnterpriseFooter from '../EnterpriseFooterPolicies/PrivacyPolicyEnterpriseFooter';
import TermsAndConditionsEnterpriseFooter from '../EnterpriseFooterPolicies/TermsAndConditionsEnterpriseFooter';
import PaymentPolicyEnterpriseFooter from '../EnterpriseFooterPolicies/PaymentPolicyEnterpriseFooter';
import DisclaimerPolicyEnterpriseFooter from '../EnterpriseFooterPolicies/DisclaimerPolicyEnterpriseFooter';
import AllPackagesRecruiter from '../features/SubscriptionModal/AllPackages/AllPackagesRecruiter';
import { AllECertificate } from '../features/ECertificates/components/index';
import { AllAwardeesListingCard } from '../features/ECertificates/components/index';
import eCeritificatesSidebar from '../features/ECertificates/components/ECertificateSideBar.menuItem';
import JobApplicantUsage from '../features/RecruiterDashboard/JobApplicants/components/JobApplicantUsage.jsx';
import ProtectedRoute from './ProtectedRoute.js';

export default function AppRoutes() {
  const { excludeHeaderPathnames } = useExcludeHeaderPathname();

  const location = useLocation();

  const { pathname } = location;

  return (
    <Routes>
      <Route path={ROUTE.HOME} element={<BeforeLoginPage />} />

      <Route path={ROUTE.TALENT_ACQUISITION_SERVICES} element={<TalentAcquisition />} />
      <Route path={ROUTE.EVENT_LANDING_PAGE} element={<EventLandingPage />} />
      <Route path={ROUTE.LANDING_PAGE_RECRUITER} element={<LandingPageRecruiter />} />
      <Route path={ROUTE.LANDING_PAGE_INSTITUTE} element={<LandingPageInstitute />} />

      <Route path={ROUTE.LANDING_PAGE_RECRUITER_REDIRECT} element={<RedirectRecruiterPage />} />
      <Route path={ROUTE.LANDING_PAGE_INSTITUTE_REDIRECT} element={<RedirectInstitutePage />} />

      <Route
        path={ROUTE.LANDING_PAGE_ENTERPRISE_SOLUTIONS}
        element={<LandingPageEnterpriseSolutions />}
      />

      {/* <Route
        path={`${ROUTE.TALENT_ACQUISITION_SERVICES}/${ROUTE.TALENT_ACQUISITION_ERROR_IN_INQUIRY}`}
        element={<ErrorInInquiry />}
      /> */}

      <Route
        path={ROUTE.DASHBOARD}
        element={
          <AuthWrapper>
            <LayoutDashboard
              showHeader={excludeHeaderPathnames.includes(pathname) ? false : true}
            />
          </AuthWrapper>
        }
      >
        {/* {Add New Member start} */}
        <Route
          path={ROUTE.ADD_NEW_MEMBER}
          element={
            // <ModalWrapper>
            <AddNewMemberForm />
            // </ModalWrapper>
          }
        />
        {/* {Add New Member end} */}
        <Route
          path={ROUTE.EDIT_MEMBER}
          element={
            // <ModalWrapper>
            <EditMemberForm />
            // </ModalWrapper>
          }
        />
        <Route index element={<QuickAccess />} />
        {/* Event Dashboard Start*/}
        <Route
          path={`${ROUTE.EVENTS}`}
          element={<MangeLayout sideBar={eventPageSidebar(pathname)} />}
        >
          <Route path={`${ROUTE.EVENTS_ALL}`} element={<Events />} />
          <Route element={<ProtectedRoute roles={['CreateAnEvent']} />}>
            <Route path={`${ROUTE.MANAGE_REGISTRATION}`} element={<Registration />} />
            <Route path={`${ROUTE.INVOICES_SUMMARY}`} element={<InvoicesSummary />} />
            <Route path={`${ROUTE.MANAGE_ATTENDEES}`} element={<ManageAttendees />} />
            <Route path={`${ROUTE.ABSTRACTS}`} element={<Abstract />} />
          </Route>
        </Route>
        {/* Protected Route base on tenant type */}
        <Route element={<ProtectedRoute roles={['CreateAnEvent']} />}>
          <Route
            path={`${ROUTE.EVENTS}/${ROUTE.CREATE_EVENT}`}
            element={
              <AuthWrapper>
                <CreateEvent />
              </AuthWrapper>
            }
          />
          <Route
            path={`${ROUTE.EVENTS}/:id/edit`}
            element={
              <AuthWrapper>
                <CreateEvent />
              </AuthWrapper>
            }
          />
        </Route>
        {/* Event Dashboard End*/}
        <Route
          path={`${ROUTE.SETTINGS}`}
          element={<MangeLayout sideBar={settingsSidebar(pathname)} />}
        >
          <Route path={`${ROUTE.PROFILE}`} element={<Profile />} />
          <Route path={`${ROUTE.LOGIN_SECURITY}`} element={<Security />} />
        </Route>
        <Route
          path={`${ROUTE.MANAGE_MEMBERSHIPS}`}
          element={<MangeLayout sideBar={membersPageSidebar(pathname)} />}
        >
          <Route path={`${ROUTE.ALL_MEMBERSHIPS}`} element={<AllMemberships />} />
          {/* Protected Route base on tenant type */}
          <Route element={<ProtectedRoute roles={['ManageMemberships']} />}>
            <Route path={`${ROUTE.MANAGE_DIRECTORY}`} element={<ManageDirectory />} />

            <Route path={`${ROUTE.ACTIVITY_LOG}`} element={<ActivityLog />} />
            <Route path={`${ROUTE.COMMITTEE}`} element={<CommitteeContainer />} />
          </Route>
        </Route>
        {/* Protected Route base on tenant type */}
        <Route element={<ProtectedRoute roles={['ManageMemberships']} />}>
          <Route
            path={`${ROUTE.CREATE_NEW_MEMBERSHIP}`}
            element={<NewMemberShipPlanCreationForm />}
          />
          <Route path={`${ROUTE.EDIT_MEMBERSHIP}`} element={<Editmembership />} />
        </Route>
        {/* Recruiter Dashboard */}
        <Route
          path={`/${ROUTE.MANAGE_EVENTS_REGISTRATION_COMPLEMENTARY}`}
          element={
            <AuthWrapper>
              {/* <ModalWrapper> */}
              <ComplementaryRegistration />
              {/* </ModalWrapper> */}
            </AuthWrapper>
          }
        />
        <Route
          path={`/${ROUTE.MANAGE_EVENTS_REGISTRATION_OFFLINE}`}
          element={
            <AuthWrapper>
              {/* <ModalWrapper> */}
              <OfflineRegistration />
              {/* </ModalWrapper> */}
            </AuthWrapper>
          }
        />
        {/* INSTITUTE DASHBOARD ROUTES */}
        <Route
          path={ROUTE.INSTITUTE_DASHBOARD}
          element={
            <MangeLayout sideBar={institutePageSidebar(pathname)}>
              <CourseApplicantUsage />
            </MangeLayout>
          }
        >
          <Route path={ROUTE.INSTITUTE_COURSES} element={<InstituteCoursesListing />} />

          {/* Protected Route base on tenant type */}
          <Route element={<ProtectedRoute roles={['ListACourse']} />}>
            <Route path={ROUTE.INSTITUTE_APPLICANTS} element={<InstituteCourseApplicants />} />
          </Route>
        </Route>
        {/* Protected Route base on tenant type */}
        <Route element={<ProtectedRoute roles={['ListACourse']} />}>
          <Route
            path={`/${ROUTE.DASHBOARD}/${ROUTE.INSTITUTE}/${ROUTE.INSTITUTE_ADD_COURSE}`}
            element={<ManageCourseForm />}
          />
          <Route
            path={`/${ROUTE.DASHBOARD}/${ROUTE.INSTITUTE}/:id/${ROUTE.INSTITUTE_EDIT_COURSE}`}
            element={<ManageCourseForm />}
          />
          <Route
            path={`/${ROUTE.DASHBOARD}/${ROUTE.INSTITUTE}/:id/${ROUTE.PREVIEW_COURSE}`}
            element={<PreviewCourse showClosPreviewHeader />}
          />
        </Route>
        <Route
          path={`/${ROUTE.DASHBOARD}/${ROUTE.INSTITUTE}/${ROUTE.COURSE_ACTION_SUCCSSFUL}`}
          element={<CourseSuccessPage />}
        />
        <Route
          path={`/${ROUTE.DASHBOARD}/${ROUTE.INSTITUTE}/${ROUTE.ERROR_IN_COURSE_ACTION}`}
          element={<CourseFailurePage />}
        />
        {/* INSTITUTE DASHBOARD ROUTES END */}
        {/* RECRUITER DASHBOARD ROUTE START */}
        <Route
          path={ROUTE.RECRUITERS_DASHBOARD}
          element={
            <MangeLayout sideBar={recruiterPageSidebar(pathname)}>
              <JobApplicantUsage />
            </MangeLayout>
          }
        >
          <Route path={ROUTE.POSTING} element={<RecruiterJobListing />} />
          {/* Protected Route base on tenant type */}
          <Route element={<ProtectedRoute roles={['PostAJob']} />}>
            <Route path={ROUTE.JOB_APPLICANTS} element={<RecruiterJobApplicant />} />
          </Route>
        </Route>
        {/* Protected Route base on tenant type */}
        <Route element={<ProtectedRoute roles={['PostAJob']} />}>
          <Route
            path={`/${ROUTE.DASHBOARD}/${ROUTE.RECRUITER}/${ROUTE.POST_A_JOB}`}
            element={<RecruiterPostAJob />}
          />
          <Route
            path={`/${ROUTE.DASHBOARD}/${ROUTE.RECRUITER}/:id/${ROUTE.EDIT_JOB}`}
            element={<RecruiterPostAJob />}
          />
          <Route
            path={`/${ROUTE.DASHBOARD}/${ROUTE.RECRUITER}/${ROUTE.JOB_PREVIEW}/:id`}
            element={<PreviewJob showClosPreviewHeader />}
          />
          <Route
            path={`/${ROUTE.DASHBOARD}/${ROUTE.RECRUITER}/${ROUTE.JOB_SUBMITTED}/:id`}
            element={<JobPostSuccess />}
          />
          <Route
            path={`/${ROUTE.DASHBOARD}/${ROUTE.RECRUITER}/${ROUTE.JOB_FAILED}/:id`}
            element={<JobPostFailure />}
          />
        </Route>
        {/* Feat: E-certificates */} {/* Feat: awardee-list */}
        <Route
          path={`${ROUTE.E_CERTIFICATE}`}
          element={<MangeLayout sideBar={eCeritificatesSidebar(pathname)} />}
        >
          <Route path={`${ROUTE.ALL_E_CERTIFICATES}`} element={<AllECertificate />} />
          <Route path={`${ROUTE.ALL_AWARDEES_LIST}`} element={<AllAwardeesListingCard />} />
        </Route>
        {/* TODO: subscription */}
        <Route
          path={`${ROUTE.BILLING_SUBSCRIPTION}`}
          element={<MangeLayout sideBar={billingSubscriptionSidebar(pathname)} />}
        >
          <Route
            path={`${ROUTE.MANAGE_BILLING_SUBSCRIPTION}`}
            element={<SubscriptionTabContent />}
          />
          <Route path={`${ROUTE.MANAGE_BILLING_DETAILS}`} element={<BillingDetailsLayout />} />
        </Route>
        <Route
          path={`/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/:id?`}
          element={<AllPackages />}
        />
        <Route
          path={`/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES_RECRUITER}`}
          element={<AllPackagesRecruiter />}
        />
        <Route
          path={`/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO}/:id?`}
          element={<SubscriptionForm />}
        />
        <Route
          path={`/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO_RECRUITER}/:id?`}
          element={<FormRecruiter />}
        />
        <Route
          path={`/${ROUTE.DASHBOARD}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO}/${ROUTE.ORDER_SUMMARY}`}
          element={<OrderScreen />}
        />
        <Route
          path={`/${ROUTE.DASHBOARD}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO}/${ROUTE.CONFIRMATION_ROUTE}/:id`}
          element={<ConfirmationScreen />}
        />
        <Route
          path={`/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.SUBSCRIPTION_DETAILS}/:id`}
          element={<ConfirmationScreen />}
        />
      </Route>
      {/* Protected Route base on tenant type */}
      <Route element={<ProtectedRoute roles={['ManageMemberships']} />}>
        <Route
          path={`${ROUTE.DASHBOARD}/${ROUTE.MANAGE_MEMBERSHIPS}/${ROUTE.ACTIVITY_LOG}/${ROUTE.ADD_ROUTE}`}
          element={
            <AuthWrapper>
              <AddEditActivityLog />
            </AuthWrapper>
          }
        />
        <Route
          path={`${ROUTE.DASHBOARD}/${ROUTE.MANAGE_MEMBERSHIPS}/${ROUTE.ACTIVITY_LOG}/:id/${ROUTE.EDIT_ROUTE}`}
          element={
            <AuthWrapper>
              <AddEditActivityLog />
            </AuthWrapper>
          }
        />
      </Route>
      <Route
        path={`/${ROUTE.ENTERPRISE_PRIVACY_POLICY}`}
        element={<PrivacyPolicyEnterpriseFooter />}
      />
      <Route
        path={`/${ROUTE.ENTERPRISE_TERMS_CONDITIONS}`}
        element={<TermsAndConditionsEnterpriseFooter />}
      />
      <Route
        path={`/${ROUTE.ENTERPRISE_PAYMENT_POLICY}`}
        element={<PaymentPolicyEnterpriseFooter />}
      />
      <Route
        path={`/${ROUTE.ENTERPRISE_DISCLAIMER_POLICY}`}
        element={<DisclaimerPolicyEnterpriseFooter />}
      />
    </Routes>
  );
}
