import { useState } from 'react';
const useToggle = (initialValue = false) => {
  const [open, setOpen] = useState(initialValue);

  const toggle = () => setOpen((prevOpen) => !prevOpen);

  return [open, toggle];
};
export default useToggle;

const usePreviewToggle = (initialValue = false) => {
  const [isOpen, setOpen] = useState(initialValue);
  const toggle = () => setOpen((prevOpen) => !prevOpen);
  return [isOpen, toggle];
};

const useAwardeeToggle = (initialValue = false) => {
  const [isOpen, setOpen] = useState(initialValue);
  const toggle = () => setOpen((prevOpen) => !prevOpen);
  return [isOpen, toggle];
};

export { usePreviewToggle, useAwardeeToggle };
