export const feesOptions = [
  {
    label: 'Annual',
    value: 'Annual',
  },
  {
    label: 'Total',
    value: 'Total',
  },
  {
    label: 'Semester',
    value: 'Semester',
  },
];
