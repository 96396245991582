import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formValues: {},
  rawFormValues: {},
  affiliation: {},
  intakes: {},
  selectMinimumEducation: {},
  faculties: '',
  brochureUrl: {},
  idStoreAtTime: null,
  editTimeApplyType: null,
  allApplicants: [],
};

export const manageCourseSlice = createSlice({
  name: 'manageCourse',
  initialState,
  reducers: {
    setInstituteCourseFormValues: (state, action) => {
      state.formValues = action.payload;
    },
    setRawFormValues: (state, action) => {
      state.rawFormValues = action.payload;
    },
    setAffiliation: (state, action) => {
      state.affiliation = action.payload;
    },
    setIntakes: (state, action) => {
      state.intakes = action.payload;
    },
    setSelectMinimumEducation: (state, action) => {
      state.selectMinimumEducation = action.payload;
    },
    setFaculties: (state, action) => {
      state.faculties = action.payload;
    },
    setBrochureUrl: (state, action) => {
      state.brochureUrl = action.payload;
    },
    setIdStoreAtTime: (state, action) => {
      state.idStoreAtTime = action.payload;
    },
    setEditTimeApplyType: (state, action) => {
      state.editTimeApplyType = action.payload;
    },
    setAllApplicants: (state, action) => {
      state.allApplicants = action.payload;
    },
  },
});

export const {
  setRawFormValues,
  setInstituteCourseFormValues,
  setAffiliation,
  setIntakes,
  setSelectMinimumEducation,
  setFaculties,
  setBrochureUrl,
  setIdStoreAtTime,
  setEditTimeApplyType,
  setAllApplicants,
} = manageCourseSlice.actions;

export const selectCourseFormValues = (state) => state['manageCourse'].formValues;
export const selectRawFormValues = (state) => state['manageCourse'].rawFormValues;
export const selectRawAffiliation = (state) => state['manageCourse'].affiliation;
export const selectRawintakes = (state) => state['manageCourse'].intakes;
export const selectAllApplicants = (state) => state['manageCourse'].allApplicants;
export const selectRawSelectMinimumEducation = (state) =>
  state['manageCourse'].selectMinimumEducation;
export const selectRawFaculties = (state) => state['manageCourse'].faculties;
export const selectRawBrochureUrl = (state) => state['manageCourse'].brochureUrl;
export const selectIdStoreAtTime = (state) => state['manageCourse'].idStoreAtTime;
export const selectEditTimeApplyType = (state) => state['manageCourse'].editTimeApplyType;
