import React from 'react';
import { DoctTypography, DoctIcon, DoctButton, DoctContainer } from '@doct-react/core';

import '../../../styles/box-shadow.scss';
import style from './AddEditFormElement.module.scss';

const AddEditHeader = React.memo(function AddEditHeader({ onCloseIconPressed, title }) {
  return (
    <div className={`bg-grey-100 common-box-shadow ${style['add-edit-form-element-header']}`}>
      <div className="container">
        <div className="d-flex action-bar-height align-items-center">
          <span
            className="cursor-pointer mr-3 align-self-center line-height-0"
            onClick={onCloseIconPressed}
          >
            <DoctIcon name="close" fill="grey" width="24" />
          </span>
          <DoctTypography variant="subtitle2" className="my-0">
            {title}
          </DoctTypography>
        </div>
      </div>
    </div>
  );
});

const AddEditFooter = React.memo(function AddEditFooter({
  loading,
  btnText,
  onButtonClicked,
  btnClassName,
}) {
  return (
    <div className={`bg-white ${style['add-edit-form-element-footer']}`}>
      <DoctContainer>
        <div className="d-flex justify-content-end py-3">
          <DoctButton
            disabled={loading}
            text={btnText}
            className={btnClassName}
            onButtonClickHandler={() => {
              onButtonClicked();
            }}
          />
        </div>
      </DoctContainer>
    </div>
  );
});

function AddEditFormHeaderFooterMainArea({ onClose, children, footerProps, title }) {
  return (
    <div className="bg-grey-200">
      <AddEditHeader onCloseIconPressed={onClose} title={title} />
      <div className={`${style['add-edit-form-element-main-area']}`}>
        <DoctContainer>{children}</DoctContainer>
      </div>
      <AddEditFooter {...footerProps} />
    </div>
  );
}

export default React.memo(AddEditFormHeaderFooterMainArea);
