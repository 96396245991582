import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { DoctChip, DoctFreeSoloSearchInput } from '@doct-react/app';
import { DoctTypography } from '@doct-react/core';
import React, { useState, useEffect } from 'react';
import { TextEditor } from '../../../../../shared';
import { useGetKeySkillsQuery } from '../../services/manageJob.services';
const JobDescription = ({ setKeySkillArray, keySkillArray, control, errors, setValue }) => {
  const [keySkillsInputValue, setKeySkillInputValue] = useState('');
  const [keySkillsValue, setKeySkillValue] = useState(null);
  const [keySkillsArr, setKeySkillArr] = useState([]);
  const [transformSkillsData, setTransFormData] = useState([]);
  const [isDataFieldAgain, setIsDataFieldAgain] = useState(false);

  const { data: keySkillsData, isFetching } = useGetKeySkillsQuery();

  useEffect(() => {
    setKeySkillArr(keySkillsData);
  }, [isFetching]);

  useEffect(() => {
    if (keySkillsInputValue) {
      const filterKeySkillArray = keySkillsData?.filter((item) => {
        return item?.name.toLowerCase().includes(keySkillsInputValue.toLowerCase());
      });

      filterKeySkillArray?.length > 0 &&
        filterKeySkillArray?.map((item) => {
          const skillsObj = {
            title: item?.name,
            value: item?.name,
          };
          setTransFormData((prevState) => [...prevState, skillsObj]);
        });
    } else {
      setIsDataFieldAgain(false);
      setTransFormData([]);
      keySkillsArr?.length > 0 &&
        keySkillsArr?.map((item) => {
          const skillsObj = {
            title: item?.name,
            value: item?.name,
          };
          setTransFormData((prevState) => [...prevState, skillsObj]);
        });
    }
  }, [keySkillsData, keySkillsArr, keySkillsInputValue, isDataFieldAgain]);

  const validateLength = (value = '') => {
    const valueLength = value.toString().replace(/(<([^>]+)>)/gi, '')?.length;
    if (valueLength < 200) {
      return 'Your Job Description must contain at least 200 characters to meet our Job Posting Guidelines.';
    } else if (valueLength > 10000) {
      return 'This Job Description might be too long for the job to appear on Docthub.com';
    }
  };

  return (
    <>
      <DoctTypography variant="subtitle2">Overview</DoctTypography>
      <TextEditor
        control={control}
        isErrors={errors}
        name="description"
        placeholder="Describe here.."
        validationRules={{
          required: "It's Required Field",
          validate: validateLength,
        }}
        setValue={setValue}
      />
      <DoctTypography variant="subtitle2">Benefits (optional)</DoctTypography>
      <Controller
        rules={{}}
        render={(field) => {
          return (
            <TextField
              variant="standard"
              multiline
              rows={2}
              maxRows={4}
              {...field}
              label={'Describe here.'}
              className={`doct-input doct-text-area${field?.value ? ' doct-input-focused' : ''}`}
            />
          );
        }}
        name={'benefits'}
        control={control}
      />
      {/* <DoctTextArea
        name="benefits"
        label="Describe here.."
        className="p-2"
        id="benefits"
        showStar={false}
        control={control}
        isErrors={errors}
        validationRules={{
          maxLength: {
            value: 1000,
            message: 'Max 1000 Characters',
          },
        }}
      /> */}
      <DoctTypography variant="subtitle2">Key Skills (optional)</DoctTypography>
      <DoctTypography variant="body2" className="text-grey-600">
        You can add multiple key skills related to this job post.
      </DoctTypography>

      <DoctFreeSoloSearchInput
        inputValue={keySkillsInputValue}
        value={keySkillsValue}
        name="keySkills"
        id="keySkills"
        hideSearchIcon
        options={transformSkillsData ?? []}
        onInputChangeHandler={(val) => {
          setTransFormData([]);
          setKeySkillInputValue(val);
          setIsDataFieldAgain(true);
        }}
        placeholder="Type and Enter"
        onChangeHandler={(val) => {
          setKeySkillValue(val);
          !keySkillArray?.includes(val) && setKeySkillArray((prevState) => [...prevState, val]);
          setKeySkillInputValue('');
          setKeySkillValue(null);
        }}
        disableOnEnterFormSubmit
      />

      {keySkillArray?.length > 0 && (
        <>
          <div className="d-flex flex-sm-wrap">
            {keySkillArray?.map((skill, i) => (
              <div key={i} className="m-1 mt-2 mt-2">
                <DoctChip
                  title={skill}
                  onCloseHandler={() => {
                    setKeySkillArray(
                      keySkillArray?.filter((existingSkills) => existingSkills != skill),
                    );
                  }}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default JobDescription;
