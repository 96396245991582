import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useFormCommon from '../../../../shared/form/useForm.Common';

import {
  resetState,
  selectIsAddOrUpdating,
  selectSubCommitteeModal,
  setSubCommitteeId,
  toggleSubCommitteeModal,
} from '../slice/committee.slice';
import { postSubCommittee, putSubCommittee } from '../services/subCommittee.services';

import FormAddSubCommittee from './FormAddEditSubCommittee';

function AddEditSubCommitteeWrapper({
  modalContainer: ModalContainer,
  defaultValues = {},
  id,
  primaryBtnText,
}) {
  const dispatch = useDispatch();

  const {
    formProps: { handleSubmit, control, errors, setValue, watch, getValues },
  } = useFormCommon({ defaultValues });

  const formProps = { control, isErrors: errors, setValue, watch, getValues };

  const subCommitteeModal = useSelector(selectSubCommitteeModal);
  // const loaderForApiCall = useSelector(selectLoaderInApiCall);
  const isAddOrUpdating = useSelector(selectIsAddOrUpdating);

  const onSubmitForm = handleSubmit((values) => {
    if (id) {
      dispatch(putSubCommittee({ id, data: values, handleModalClose }));
    } else {
      dispatch(postSubCommittee({ data: values, handleModalClose }));
    }
  });

  const handleModalClose = useCallback(() => {
    if (subCommitteeModal) {
      dispatch(toggleSubCommitteeModal());
      dispatch(resetState());
      dispatch(setSubCommitteeId(null));
    }
  }, []);

  const modalProps = { onSubmitForm, handleModalClose, primaryBtnText };

  return (
    <ModalContainer
      {...modalProps}
      isOpen={subCommitteeModal}
      isPrimaryBtnDisable={isAddOrUpdating}
    >
      <FormAddSubCommittee formProps={formProps} />
    </ModalContainer>
  );
}

export default AddEditSubCommitteeWrapper;
