import React, { useEffect } from 'react';
import { DoctAnimationLoading } from '@doct-react/app';
import { useHardReloadForCachedApp } from '../../helper/helperFunction';
import { AppRoutes } from '../../routes';
import ToastWrapper from '../ToastWrapper';
import TrackApp from '../TrackApp';

function App() {
  useEffect(() => {
    useHardReloadForCachedApp();
  }, []);

  return (
    <TrackApp>
      <div id="loader" className="app-loader">
        <DoctAnimationLoading />
      </div>
      <AppRoutes />
      <ToastWrapper />
    </TrackApp>
  );
}

export default App;
