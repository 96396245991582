import React, { useState } from 'react';
import style from './SubscriptionUsageCard.module.scss';
import { DoctTypography } from '@doct-react/core';
import { useSelector } from 'react-redux';
import InfoModalWrapper from '../../../components/InfoModalWrapper';

export default function UpgradePlanWhiteCard({
  noWhiteBG,
  noBorder,
  text,
  plan,
  className,
  PlanTextClassName,
  navigateOn = () => {},
}) {
  const type = useSelector((state) => state?.app?.user?.tenant?.status);
  const isAccountPending = type === 'Pending';
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <>
      <div className={`${className} pl-1 pr-1 pb-1`}>
        <div
          className={`${!noBorder && style['upgrade-card-br']} ${
            noWhiteBG ? noWhiteBG : 'bg-white'
          } d-flex justify-content-between pl-3 pr-3 align-items-center`}
        >
          <DoctTypography
            variant="textLabel2"
            className={`${PlanTextClassName} text-grey-600 m-0 w-50 mt-2 pt-1 mb-2 pb-1`}
          >
            {text}
          </DoctTypography>
          <span
            onClick={() => {
              isAccountPending ? setIsOpenModal(true) : navigateOn();
            }}
            className="cursor-pointer"
          >
            <DoctTypography variant="textLabel1" className="text-info font-weight-bold m-0">
              {plan}
            </DoctTypography>
          </span>
        </div>
      </div>
      <InfoModalWrapper isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </>
  );
}
