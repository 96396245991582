import { useState, useEffect } from 'react';
import { contacts } from '../../utils/contact';
const useDetectRoutes = () => {
  const [result, setResult] = useState({});

  const location = window?.location?.href;
  const route = location?.split('/')[4];
  useEffect(() => {
    setResult(contacts[route]);
  }, [route]);

  return {
    result,
  };
};

export default useDetectRoutes;
