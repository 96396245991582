import { createAsyncThunk } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

import {
  BUSINESS_BASE_API_ROUTE,
  ENTERPRISE_BASE_API_ROUTE,
  SUBCOMMITTEES,
} from '../../../../api/apiEndpoints';
import { NETWORK_REQUEST_ERROR_MSG } from '../../../../constants/constants';
import { TOST_BOTTOM_CENTER } from '../../../../constants/toasterPosition.constants';

import { setShowToasterWithParams } from '../../../featuresSlice';

export const postSubCommittee = createAsyncThunk(
  'postSubCommittee',
  async ({ data, handleModalClose }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { app } = getState();
      const { token } = app;

      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${SUBCOMMITTEES}`,
        data,
        {
          Authorization: `Bearer ${token}`,
        },
      );
      dispatch({
        type: `designationApi/invalidateTags`,
        payload: ['Designation'],
      });
      dispatch(
        setShowToasterWithParams({
          variant: 'neutral',
          position: TOST_BOTTOM_CENTER,
          message: 'Sub-Committee Added Successfully',
        }),
      );
      handleModalClose();
    } catch (err) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_BOTTOM_CENTER,
          message: NETWORK_REQUEST_ERROR_MSG,
        }),
      );
      return rejectWithValue(err.response.data);
    }
  },
);

export const putSubCommittee = createAsyncThunk(
  `putSubCommittee`,
  async ({ id, data, handleModalClose }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { app } = getState();
      const { token } = app;

      await axios.put(
        `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${SUBCOMMITTEES}/${id}`,
        data,
        {
          Authorization: `Bearer ${token}`,
        },
      );
      dispatch({
        type: `designationApi/invalidateTags`,
        payload: ['Designation'],
      });
      dispatch(
        setShowToasterWithParams({
          variant: 'neutral',
          position: TOST_BOTTOM_CENTER,
          message: 'Sub-Committee Updated Successfully',
        }),
      );
      handleModalClose();
    } catch (err) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_BOTTOM_CENTER,
          message: NETWORK_REQUEST_ERROR_MSG,
        }),
      );
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSubCommitteeById = createAsyncThunk(`getSubCommitteeById`, async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${SUBCOMMITTEES}/${id}`,
  );
  return response.data;
});

export const deleteSubCommittee = createAsyncThunk(
  `deleteSubCommittee`,
  async (id, { dispatch }) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${SUBCOMMITTEES}/${id}`,
    );
    dispatch({
      type: `designationApi/invalidateTags`,
      payload: ['Designation'],
    });
    dispatch(
      setShowToasterWithParams({
        variant: 'neutral',
        position: TOST_BOTTOM_CENTER,
        message: 'Sub-Committee Deleted Successfully',
      }),
    );
    return response;
  },
);

export const subCommitteeApi = createApi({
  reducerPath: 'subCommitteeApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = getState().app.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['SubCommittee', 'Designation'],
  endpoints: (builder) => ({
    getSubCommittees: builder.query({
      query: () =>
        `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${SUBCOMMITTEES}`,
      providesTags: ['SubCommittee'],
    }),
  }),
});

export const { useGetSubCommitteesQuery } = subCommitteeApi;
