const fieldCommittee = {
  designationName: {
    name: 'name',
    label: 'Designation',
    keyOfGetApi: 'name',
    validationRules: {
      required: "It's Required Field",
    },
  },
  assignMembers: {
    label: 'Assign Members',
    keyOfGetApi: 'members',
  },
  subCommittee: {
    name: 'name',
    label: 'Sub-Committee Name',
    keyOfGetApi: 'name',
    validationRules: {
      required: "It's Required Field",
    },
  },
  archive: {
    name: 'title',
    label: 'Archive Title',
    keyOfGetApi: 'title',
    validationRules: {
      required: "It's Required Field",
    },
  },
};

export default fieldCommittee;
