import React from 'react';
import SubscriptionUsageCard from '../../../../shared/ui/SubscriptionHoverCard/SubscriptionUsageCard';
import { useGetApplicantUsageQuery } from '../services/courseApplicant.services';
import { userSelector } from '../../../../components';
import { useSelector } from 'react-redux';

const CourseApplicantUsage = () => {
  const { data: allApplicantUsage = {} } = useGetApplicantUsageQuery();
  const { applicantViewUsage, subscriberPlan, totalApplicants, applicantViews, isPremium } =
    allApplicantUsage || {};

  const user = useSelector(userSelector);
  const type = useSelector((state) => state?.app?.user?.tenant?.type);

  const isListCourseType = type === 'ListACourse';

  return (
    <div>
      {user?.tenant?.status === 'Pending' ? (
        ''
      ) : isListCourseType ? (
        <>
          <SubscriptionUsageCard
            totalUsage={applicantViewUsage}
            planName={subscriberPlan}
            limitForUsage={applicantViews}
            isFreePlan={subscriberPlan?.toLowerCase().includes('free') || subscriberPlan == null}
            totalApplicants={totalApplicants}
            isPremium={isPremium}
          />
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default CourseApplicantUsage;
