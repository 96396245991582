import React from 'react';
import { DoctTypography } from '@doct-react/core';
import clsx from 'clsx';
import style from '../SubscriptionModal.module.scss';

export default function SubscriptionChip({ subscriptionChipText, isExpired }) {
  const chipBackgroundClass = clsx({
    [style['subscription-chip']]: true,
    'bg-grey-400': isExpired,
    'bg-color-pending': !isExpired && subscriptionChipText === 'Pending',
    'bg-success': !isExpired && subscriptionChipText !== 'Pending',
  });

  return (
    <div className={`${chipBackgroundClass} text-white text-uppercase font-weight-regular`}>
      <DoctTypography variant="subtitle3" className="m-0">
        {subscriptionChipText}
      </DoctTypography>
    </div>
  );
}
