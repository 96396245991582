import React, { useEffect, useState } from 'react';
import QuickAcessIcon from '../../../../assets/icons/quick_access_icon.svg';
import JobsAppIcon from '../../../../assets/icons/Jobs-new-icon.svg';
import CourseAppIcon from '../../../../assets/icons/Course-new-icon.svg';
import EventAppIcon from '../../../../assets/icons/Events-new-icon.svg';
import ECertificateIcon from '../../../../assets/icons/ECertificateIcon.svg';
import SubscriptionIcon from '../../../../assets/icons/Subscription-icon.svg';
import { AccountUnderReview } from '../../../ManageMemberships';
import { useNavigate } from 'react-router-dom';
import { useUserApproval } from '../../../../hooks';
import { useDispatch } from 'react-redux';
import {
  setCourseListingsActiveTabIndex,
  setECertificatesActiveTabIndex,
  setJobPostActiveTabIndex,
  setManageEventsActiveTabIndex,
  setMembershipActiveTabIndex,
  setSubscriptionActiveTabIndex,
} from '../../../featuresSlice';
import { DoctIcon } from '@doct-react/core';
import axios from 'axios';

export default function useSidebarData() {
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    async function eCertificateData() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/enterprisecenter/tenant/ecertificates`,
        );
        setTotalRecords(res?.data?.totalRecords);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    eCertificateData();
  }, []);
  const allAccountTypesData = ['ManageMemberships', 'PostAJob', 'ListACourse', 'CreateAnEvent'];
  const { openModalPath } = useUserApproval({
    allAccountTypes: allAccountTypesData,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sidebarData = [
    {
      title: 'Quick Access',
      pathname: '/dashboard',
      icon: <img src={QuickAcessIcon} />,
    },

    {
      title: 'Recruiter Dashboard',
      pathname: '/dashboard/recruiter/posting',
      icon: <img src={JobsAppIcon} className="dashboard_sidebar_icons" />,
      isDisabled: false,
      onclickHandler: () => {
        dispatch(setJobPostActiveTabIndex(0));
      },
    },

    {
      title: 'Institute Dashboard',
      pathname: '/dashboard/institute/courses',
      icon: <img src={CourseAppIcon} className="dashboard_sidebar_icons" />,
      isDisabled: false,
      onclickHandler: () => {
        dispatch(setCourseListingsActiveTabIndex(0));
      },
    },

    {
      title: 'Events Dashboard',
      pathname: '/dashboard/events/all',
      icon: <img src={EventAppIcon} className="dashboard_sidebar_icons" />,
      onclickHandler: () => {
        dispatch(setManageEventsActiveTabIndex(0));
      },
    },

    {
      title: 'Memberships',
      pathname: `${
        openModalPath != undefined ? openModalPath : '/dashboard/manage-memberships/all-memberships'
      }`,
      icon: <DoctIcon name={'membershipOutline'} width="32" height="32" className="mr-2" />,
      isDisabled: false,
      onclickHandler: () => {
        dispatch(setMembershipActiveTabIndex(0));
      },
      // isAccessable: allAccessableFeaturesArr?.ManageMemberships,
      underReviewComponent: (
        <AccountUnderReview
          handelClose={() => {
            navigate(-1);
          }}
        />
      ),
    },
    {
      title: 'Billing & Subscriptions',
      pathname: '/dashboard/billing-subscription/subscription',
      icon: <img src={SubscriptionIcon} width="32" height="32" className="mr-2" />,
      onclickHandler: () => {
        dispatch(setSubscriptionActiveTabIndex(0));
      },
      isDisabled: false,
    },
    {
      title: 'E-Certificates',
      pathname:
        totalRecords === 0
          ? '/dashboard/e-certificate/all-e-certificates'
          : '/dashboard/e-certificate/all-e-certificates?=showAllCertificates',
      icon: <img src={ECertificateIcon} width="32" height="32" className="mr-2" />,
      onclickHandler: () => {
        dispatch(setECertificatesActiveTabIndex(0));
      },
      isDisabled: false,
    },
  ];

  return {
    sidebarData,
  };
}
