export const renewalPaymentTermsOption = [
  { label: 'Nil (No Renewal)', value: 'Nil (No Renewal)' },
  { label: 'Once in a Year', value: 'Once in a Year' },
  { label: 'Once in 2 Years', value: 'Once in 2 Years' },
  { label: 'Once in 3 Years', value: 'Once in 3 Years' },
  { label: 'Once in 18 Months', value: 'Once in 18 Months' },
  { label: 'Once in 6 Months', value: 'Once in 6 Months' },
  { label: 'Once in 3 Months', value: 'Once in 3 Months' },
  { label: 'Monthly', value: 'Monthly' },
];
