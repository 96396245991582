import React from 'react';
import { useGetJobQuickAccessQuery } from '../../services/quickAccess.services';
import QuickCardHighted from '../QuickCard/QuickCardHighted';
import * as ROUTE from '../../../../routes/constant';
import JobsEmptyStateImage from '../../../../assets/images/empty-state-jobs.png';
import { useNavigate } from 'react-router';
import UserAppCard from '../QuickCard/UserAppCard';
import { useState } from 'react';
import EmptyStateCard from '../QuickCard/EmptyStateCard';

function JobQuickAccessWrapper() {
  const { data: jobData, isLoading } = useGetJobQuickAccessQuery('', {
    refetchOnMountOrArgChange: true,
  });

  const { totalJobs, activeJobs, closedJobs, totalNewApplicants, newApplications } = jobData || {};

  const [loadRecord, setLoadRecord] = useState(1);

  const handleNextData = () => {
    setLoadRecord(loadRecord + 1);
  };

  const handlePreviewData = () => {
    setLoadRecord(loadRecord - 1);
  };

  const navigate = useNavigate();

  return (
    <QuickCardHighted title="Jobs">
      {(totalJobs && !isLoading) || isLoading ? (
        <UserAppCard
          status={'Jobs'}
          totalRecords={totalJobs}
          firstSubHeadingCount={activeJobs}
          thirdSubHeadingCount={closedJobs}
          newApplicantCount={totalNewApplicants}
          newApplicants={newApplications}
          navigate={navigate}
          handleNextData={handleNextData}
          handlePreviewData={handlePreviewData}
          loadRecord={loadRecord}
          isLoading={isLoading}
        />
      ) : (
        <EmptyStateCard
          icon={<img src={JobsEmptyStateImage} alt="Post your first Job Now!" />}
          title={'Post your first Job Now!'}
          btnText="Post a Job"
          bgcolor="jobs-btn-bgcolor"
          onPressFunction={() => {
            navigate(`/${ROUTE.DASHBOARD}/${ROUTE.RECRUITER}/${ROUTE.POST_A_JOB}`);
          }}
        />
      )}
    </QuickCardHighted>
  );
}

export default JobQuickAccessWrapper;
