import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ACTIVITY_LOG_PATH } from '../../../../routes/constant';
import { useResetForm } from '../hooks/useResetForm.ActivityLog';

import {
  resetValue,
  selectGetActivityByIdData,
  selectIsAddOrUpdating,
  selectLoading,
  selectOrganizingOptions,
} from '../slice/activityLog.slice';
import AddEditActivityLogWrapper from './AddEditActivityLogWrapper';
import { AddEditActivityLogLoader, useOrganizingTeamOptionEffect } from './components';

import { useGetAllMembershipMembersQuery } from '../../ManageDirectory/services/members.services';
import {
  getActivityById,
  postActivityLog,
  updateActivityLog,
} from '../services/activityLog.services';

function EditActivity({ id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector(selectLoading);
  const isAddOrUpdating = useSelector(selectIsAddOrUpdating);
  const data = useSelector(selectGetActivityByIdData);
  const organizingOptions = useSelector(selectOrganizingOptions);

  const { prepareApiToFormData } = useResetForm();
  const [defaultValues, setDefaultValues] = useState(null);

  useEffect(() => {
    dispatch(getActivityById({ id }));
  }, []);

  useEffect(async () => {
    if (!data) return;

    const handleDefaultValue = async () => {
      const apiToFormData = await prepareApiToFormData(data);
      setDefaultValues(apiToFormData);
    };
    handleDefaultValue();
  }, [data]);

  const callBackOnApiSuccess = () => {
    navigate(ACTIVITY_LOG_PATH);
  };

  const initPostActivityLog = (data) => {
    dispatch(updateActivityLog({ data, id, callBackOnApiSuccess }));
  };

  const title = 'Edit Activity';

  if (isLoading || !organizingOptions || !defaultValues)
    return <AddEditActivityLogLoader title={title} />;

  if (!isLoading && defaultValues && organizingOptions)
    return (
      <AddEditActivityLogWrapper
        onSubmit={initPostActivityLog}
        isAddOrUpdating={isAddOrUpdating}
        title={title}
        defaultValues={defaultValues}
        edit
      />
    );

  return <AddEditActivityLogLoader title={title} />;
}

function AddActivity() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAddOrUpdating = useSelector(selectIsAddOrUpdating);

  const callBackOnApiSuccess = () => {
    navigate(ACTIVITY_LOG_PATH);
  };

  const initPostActivityLog = (data) => {
    dispatch(postActivityLog({ data, callBackOnApiSuccess }));
  };

  return (
    <AddEditActivityLogWrapper
      onSubmit={initPostActivityLog}
      title="Add New Activity"
      isAddOrUpdating={isAddOrUpdating}
    />
  );
}

export default function AddEditActivityLog() {
  let { id } = useParams();
  const dispatch = useDispatch();

  const { data: allMembersData, isLoading } = useGetAllMembershipMembersQuery({
    disablePageSize: true,
  });

  useOrganizingTeamOptionEffect({
    data: allMembersData || [],
    isLoading,
  });

  useEffect(() => {
    return () => {
      dispatch(resetValue());
    };
  }, []);

  if (id) {
    return <EditActivity id={id} />;
  }

  return <AddActivity />;
}
