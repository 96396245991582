import { createAsyncThunk } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import qs from 'qs';
import dayjs from 'dayjs';
import { serialize } from 'object-to-formdata';

import {
  ACTIVITIES,
  BUSINESS_BASE_API_ROUTE,
  ENTERPRISE_BASE_API_ROUTE,
} from '../../../../api/apiEndpoints';
import { API_TIME_FORMAT, NETWORK_REQUEST_ERROR_MSG } from '../../../../constants/constants';
import fieldAddEditActivityLog from '../AddEditActivityLog/field.AddEditActivityLog';
import { resetValue } from '../slice/activityLog.slice';
import { setShowToasterWithParams } from '../../../featuresSlice';
import { TOST_BOTTOM_CENTER } from '../../../../constants/toasterPosition.constants';

function transformFormData(data) {
  return {
    ...data,
    [fieldAddEditActivityLog.startDate.name]: dayjs(
      data[fieldAddEditActivityLog.startDate.name],
    ).format(API_TIME_FORMAT),
    [fieldAddEditActivityLog.endDate.name]: dayjs(
      data[fieldAddEditActivityLog.endDate.name],
    ).format(API_TIME_FORMAT),
  };
}

function addUpdateDataActivityLog({ state, data }) {
  const {
    valueOrganizingTeam,
    valueSupportedBy,
    valueCoverImageUrl,
    valueCoverImage,
    valueDeleteCoverImage,
  } = state;

  return transformFormData({
    ...data,
    [fieldAddEditActivityLog.memberIds.name]: valueOrganizingTeam?.map(({ id }) => id),
    [fieldAddEditActivityLog.supportedBy.name]: valueSupportedBy,
    [fieldAddEditActivityLog.coverImage.name]: valueCoverImage,
    [fieldAddEditActivityLog.coverImageUrl.name]: valueCoverImageUrl,
    [fieldAddEditActivityLog.deleteCoverImage.name]: valueDeleteCoverImage,
  });
}

export const postActivityLog = createAsyncThunk(
  `postActivityLog`,
  async ({ data, callBackOnApiSuccess }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { activityLog, app } = getState();
      const { token } = app;
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${ACTIVITIES}`,
        serialize(addUpdateDataActivityLog({ state: activityLog, data }), {
          indices: true,
          nullsAsUndefineds: true,
        }),
        {
          Authorization: `Bearer ${token}`,
        },
      );
      dispatch({
        type: `activityLogApi/invalidateTags`,
        payload: ['ActivityLog'],
      });
      callBackOnApiSuccess();
      dispatch(
        setShowToasterWithParams({
          variant: 'neutral',
          position: TOST_BOTTOM_CENTER,
          message: 'Activity Added Successfully',
        }),
      );
      dispatch(resetValue());
    } catch (err) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_BOTTOM_CENTER,
          message: NETWORK_REQUEST_ERROR_MSG,
        }),
      );
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateActivityLog = createAsyncThunk(
  `updateActivityLog`,
  async ({ data, id, callBackOnApiSuccess }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { activityLog, app } = getState();
      const { token } = app;
      // eslint-disable-next-line no-unused-vars
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${ACTIVITIES}/${id}`,
        serialize(addUpdateDataActivityLog({ state: activityLog, data }), {
          indices: true,
          nullsAsUndefineds: true,
        }),
        {
          Authorization: `Bearer ${token}`,
        },
      );
      dispatch({
        type: `activityLogApi/invalidateTags`,
        payload: ['ActivityLog'],
      });
      callBackOnApiSuccess();
      dispatch(
        setShowToasterWithParams({
          variant: 'neutral',
          position: TOST_BOTTOM_CENTER,
          message: 'Activity Updated Successfully',
        }),
      );
      dispatch(resetValue());
    } catch (err) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_BOTTOM_CENTER,
          message: NETWORK_REQUEST_ERROR_MSG,
        }),
      );
      return rejectWithValue(err.response.data);
    }
  },
);

export const getActivityById = createAsyncThunk(
  `getActivityById`,
  async ({ id }, { getState, rejectWithValue }) => {
    try {
      const { app } = getState();
      const { token } = app;

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${ACTIVITIES}/${id}`,
        {
          Authorization: `Bearer ${token}`,
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const activityLogApi = createApi({
  reducerPath: 'activityLogApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = getState().app.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['ActivityLog'],
  endpoints: (builder) => ({
    getActivities: builder.query({
      query: ({ queryParams }) =>
        `${
          process.env.REACT_APP_BASE_API_URL
        }${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${ACTIVITIES}${qs.stringify(
          queryParams,
          {
            addQueryPrefix: true,
          },
        )}`,
      providesTags: ['ActivityLog'],
    }),

    deleteActivityLog: builder.mutation({
      query: ({ id }) => {
        return {
          url: `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${ACTIVITIES}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['ActivityLog'],
    }),
  }),
});

export const { useGetActivitiesQuery, useDeleteActivityLogMutation } = activityLogApi;
