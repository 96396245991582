import React from 'react';
import { DoctDatePickerV2, DoctTextField } from '@doct-react/app';

import SupportedByActivityLog from './components/SupportedBy.ActivityLog';
import { CoverImageActivityLog, OrganizingTeamActivityLog } from './components';
import { FormEl, FormElWithHeading, TextEditor } from '../../../../shared';
import fieldAddEditActivityLog from './field.AddEditActivityLog';

export default function FormAddEditActivityLog({ formProps, minDateOfEndDate }) {
  return (
    <>
      <FormEl>
        <CoverImageActivityLog />
      </FormEl>
      <FormEl>
        <FormElWithHeading heading={fieldAddEditActivityLog.title.label}>
          <DoctTextField
            {...formProps}
            name={fieldAddEditActivityLog.title.name}
            id={fieldAddEditActivityLog.title.name}
            label={fieldAddEditActivityLog.title.label}
            validationRules={fieldAddEditActivityLog.title.validationRules}
            showStar={false}
          />
        </FormElWithHeading>
        <FormElWithHeading heading={fieldAddEditActivityLog.startDate.label}>
          <div className="input-column input-column-half-size-row">
            <div className="input-column-half-size">
              <DoctDatePickerV2
                {...formProps}
                name={fieldAddEditActivityLog.startDate.name}
                withController={true}
                inputProps={{
                  label: 'Start Date',
                  id: fieldAddEditActivityLog.startDate.name,
                  dateFormat: 'dd MMM yyyy',
                  autoComplete: 'off',
                }}
                onChange={() => {
                  formProps.setValue(fieldAddEditActivityLog.endDate.name, null);
                }}
                validationRules={fieldAddEditActivityLog.startDate.validationRules}
              />
            </div>
            <div className="input-column-half-size">
              <DoctDatePickerV2
                name={fieldAddEditActivityLog.endDate.name}
                withController={true}
                {...formProps}
                inputProps={{
                  label: 'End Date',
                  id: fieldAddEditActivityLog.endDate.name,
                  dateFormat: 'dd MMM yyyy',
                  minDate: minDateOfEndDate,
                  autoComplete: 'off',
                }}
                onChange={() => {
                  formProps.setValue('endDate', null);
                }}
                validationRules={fieldAddEditActivityLog.endDate.validationRules}
              />
            </div>
          </div>
        </FormElWithHeading>
        <FormElWithHeading heading={fieldAddEditActivityLog.highlights.label}>
          <TextEditor
            {...formProps}
            name={fieldAddEditActivityLog.highlights.keyOfGetApi}
            placeholder="Start writing.."
          />
        </FormElWithHeading>
        <OrganizingTeamActivityLog />
        <SupportedByActivityLog />
      </FormEl>
    </>
  );
}
