import React from 'react';
import style from './SubscriptionModal.module.scss';
import { DoctButton, DoctIcon, DoctTypography } from '@doct-react/core';
import * as ROUTE from '../../routes/constant';
import useSendSubscription from './hooks/useSendSubscription';
import { DoctAnimationLoading } from '@doct-react/app';
import { useGetPackagesQuery } from './API/subscriptionApi';

export default function Subscriptions() {
  const { navigate } = useSendSubscription();

  const { data: packages, isLoading } = useGetPackagesQuery();

  if (isLoading) {
    return (
      <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
        <DoctAnimationLoading />
      </div>
    );
  }

  return (
    <>
      {packages?.map((subscriptionPlan) => {
        return (
          <div
            className={`${style['subscription-card-body']} bg-white d-flex align-items-center mt-3`}
            key={subscriptionPlan?.id}
          >
            <div>
              <div className="d-flex">
                {subscriptionPlan?.name === 'Institute Dashboard' && (
                  <div className={`${style['institute-icon-bg']} p-4 mr-4`}>
                    <DoctIcon
                      name="courseEnterpriseFilled"
                      width="44"
                      height="44"
                      className="mb-n1"
                    />
                  </div>
                )}
                {subscriptionPlan?.name === 'Recruiter Dashboard' && (
                  <div className={`${style['recruiter-icon-bg']} p-4 mr-4`}>
                    <DoctIcon
                      name="jobsEnterpriseFilled"
                      width="44"
                      height="44"
                      className="mb-n1"
                    />
                  </div>
                )}
                <div className="align-self-center">
                  <DoctTypography variant="h6" className="text-grey-800">
                    {subscriptionPlan?.name} Subscription
                  </DoctTypography>
                  {subscriptionPlan?.name === 'Institute Dashboard' && (
                    <DoctTypography variant="subtitle3" className="text-grey-600 mt-1 mb-2">
                      Increase potential admission leads, attract more students.
                    </DoctTypography>
                  )}
                  {subscriptionPlan?.name === 'Recruiter Dashboard' && (
                    <DoctTypography variant="subtitle3" className="text-grey-600 mt-1 mb-2">
                      Find healthcare candidates quickly, Full feel your openings now.
                    </DoctTypography>
                  )}

                  <DoctTypography variant="subtitle3" className="text-grey-600 m-0">
                    {subscriptionPlan?.plans} Plans
                  </DoctTypography>
                </div>
              </div>
            </div>
            <DoctButton
              text="View Plans"
              className="bg-active-green ml-auto"
              variant="contained"
              icon="right"
              iconPosition="right"
              onButtonClickHandler={() => {
                navigate(
                  `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${subscriptionPlan?.id}`,
                  {
                    state: { backFromSubscription: window?.location?.pathname },
                  },
                );
              }}
            />
          </div>
        );
      })}
    </>
  );
}
