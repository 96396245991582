import React from 'react';
import style from './CommonBillingInfoCard.module.scss';
import { DoctTypography } from '@doct-react/core';
import dayjs from 'dayjs';

export default function CommonBillingInfoCard({ statusSuccess, orderDetails = {} }) {
  const { amount, subscriberOrderDTO, subscriberPlanHistory } = orderDetails ?? {};
  const { subscriberBillingInformation } = subscriberOrderDTO ?? {};

  const createdOn = dayjs(subscriberOrderDTO?.createdOn); // Use dayjs to parse the date
  const formattedDate = createdOn.format('DD MMMM YYYY'); // Format the date using dayjs lib

  // const itemsToRemove = ['successDetails', 'amountDetails', 'prepDatas'];
  // const removeStorageData = () => {
  //   itemsToRemove?.forEach((item) => {
  //     localStorage?.removeItem(item);
  //   });
  // };

  return (
    <>
      <div className={`${style['billing-info-card-br']} p-4 mt-4 bg-white`}>
        {statusSuccess ? (
          <>
            <DoctTypography variant="subtitle3" className="text-grey-800 mb-1 m-0">
              ORDER INFORMATION
            </DoctTypography>
            <div className="d-flex align-items-center text-grey-600">
              <DoctTypography variant="subtitle3" className="m-0 mt-2">
                Order ID: {subscriberOrderDTO?.orderNumber}
              </DoctTypography>
              <DoctTypography variant="subtitle3" className="m-0">
                &nbsp; . &nbsp;
              </DoctTypography>
              <DoctTypography variant="subtitle3" className="m-0 mt-2">
                Order Date: {formattedDate}
              </DoctTypography>
            </div>
          </>
        ) : (
          // <div className="pt-1 pl-1 pr-1">
          //   <DoctTypography
          //     variant="subtitle2"
          //     className={`${style['failed-msg-padding']} m-0 text-danger ml-n4 mr-n4 mt-n4`}
          //   >
          //     Your transaction has failed, please click on “Retry Payment” to proceed it again.
          //   </DoctTypography>
          // </div>
          ''
        )}
        <>
          <DoctTypography variant="subtitle3" className="text-grey-800 m-0 pb-2 pt-4">
            BILLING INFORMATION
          </DoctTypography>
          <DoctTypography variant="subtitle3" className="m-0 mt-1 text-grey-600">
            Billing name: {subscriberBillingInformation?.billingName}
          </DoctTypography>
          <DoctTypography variant="subtitle3" className="m-0 mt-1 text-grey-600">
            PAN number:&nbsp;
            {subscriberBillingInformation?.taxId ? subscriberBillingInformation?.taxId : ' \u2014'}
          </DoctTypography>
          <DoctTypography variant="subtitle3" className="m-0 mt-1 text-grey-600">
            GSTIN:{' '}
            {subscriberBillingInformation?.gstNo ? subscriberBillingInformation?.gstNo : ' \u2014'}
          </DoctTypography>
          <DoctTypography variant="subtitle3" className="m-0 mt-1 text-grey-600">
            Address: {subscriberBillingInformation?.address}
          </DoctTypography>
          <DoctTypography variant="subtitle3" className="m-0 mt-1 text-grey-600">
            City: {subscriberBillingInformation?.city}
          </DoctTypography>
          <DoctTypography variant="subtitle3" className="m-0 mt-1 text-grey-600">
            State: {subscriberBillingInformation?.state}
          </DoctTypography>
          <DoctTypography variant="subtitle3" className="m-0 mt-1 text-grey-600">
            Country: {subscriberBillingInformation?.country}
          </DoctTypography>
          <DoctTypography variant="subtitle3" className="m-0 mt-1 text-grey-600">
            Postal Code:&nbsp;
            {subscriberBillingInformation?.pincode
              ? subscriberBillingInformation?.pincode
              : ' \u2014'}
          </DoctTypography>
        </>
        <>
          <DoctTypography variant="subtitle3" className="text-grey-800 m-0 pb-2 mb-1 pt-4">
            PAYMENT INFO
          </DoctTypography>
          <div className="d-flex justify-content-between text-grey-600">
            <DoctTypography variant="subtitle3" className={`${style['amount-text-padding']} m-0`}>
              PLAN PRICE{' '}
            </DoctTypography>
            <DoctTypography variant="subtitle3" className="m-0">
              INR {amount?.netAmount}
            </DoctTypography>
          </div>
          <div className="d-flex justify-content-between text-grey-600">
            {subscriberOrderDTO?.saveUpTo ? (
              <DoctTypography variant="subtitle3" className={`${style['amount-text-padding']} m-0`}>
                DISCOUNT
              </DoctTypography>
            ) : (
              <DoctTypography variant="subtitle3" className={`${style['amount-text-padding']} m-0`}>
                DISCOUNT{' '}
                {subscriberPlanHistory?.discountPercent &&
                  `(${subscriberPlanHistory?.discountPercent}%)`}
              </DoctTypography>
            )}
            <DoctTypography variant="subtitle3" className="m-0">
              - INR {amount?.discountAmount}
            </DoctTypography>
          </div>
          {/* {amount?.igst ? (
            <div className="d-flex justify-content-between text-grey-600">
              <DoctTypography variant="subtitle3" className={`${style['amount-text-padding']} m-0`}>
                IGST(18%)
              </DoctTypography>
              <DoctTypography variant="subtitle3" className="m-0">
                + INR {amount?.igst}
              </DoctTypography>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between text-grey-600">
                <DoctTypography
                  variant="subtitle3"
                  className={`${style['amount-text-padding']} m-0`}
                >
                  CGST(9%)
                </DoctTypography>
                <DoctTypography variant="subtitle3" className="m-0">
                  + INR {amount?.cgst}
                </DoctTypography>
              </div>
              <div className="d-flex justify-content-between text-grey-600">
                <DoctTypography
                  variant="subtitle3"
                  className={`${style['amount-text-padding']} m-0`}
                >
                  SGST(9%)
                </DoctTypography>
                <DoctTypography variant="subtitle3" className="m-0">
                  + INR {amount?.sgst}
                </DoctTypography>
              </div>
            </>
          )} */}
          {amount?.billingState === 'Gujarat' || amount?.gstNumber?.startsWith('24') ? (
            <>
              <div className="d-flex justify-content-between text-grey-600">
                <DoctTypography
                  variant="subtitle3"
                  className={`${style['amount-text-padding']} m-0`}
                >
                  CGST(9%)
                </DoctTypography>
                <DoctTypography variant="subtitle3" className="m-0">
                  + INR {amount?.cgst}
                </DoctTypography>
              </div>
              <div className={`${style['text-line-divider']}`} />
              <div className="d-flex justify-content-between text-grey-600">
                <DoctTypography
                  variant="subtitle3"
                  className={`${style['amount-text-padding']} m-0`}
                >
                  SGST(9%)
                </DoctTypography>
                <DoctTypography variant="subtitle3" className="m-0">
                  + INR {amount?.sgst}
                </DoctTypography>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-between text-grey-600">
              <DoctTypography variant="subtitle3" className={`${style['amount-text-padding']} m-0`}>
                IGST(18%)
              </DoctTypography>
              <DoctTypography variant="subtitle3" className="m-0">
                + INR {amount?.igst}
              </DoctTypography>
            </div>
          )}
          <div className={`${style['amount-text-line-divider']} bg-grey-200`} />
          <div
            className={`${style['total-amount-text-padding']} d-flex justify-content-between text-grey-800`}
          >
            <DoctTypography variant="subtitle2" className="m-0">
              Total Amount
            </DoctTypography>
            <DoctTypography variant="subtitle2" className="m-0">
              INR {amount?.totalAmount}
            </DoctTypography>
          </div>
          <div className={`${style['amount-text-line-divider']} bg-grey-200`} />

          <div className="d-flex justify-content-between mt-2 pt-1">
            <DoctTypography variant="subtitle3" className="text-grey-600 m-0">
              PAYMENT STATUS
            </DoctTypography>
            <DoctTypography
              variant="subtitle2"
              className={`${statusSuccess ? 'text-success' : 'text-danger'} m-0`}
            >
              {statusSuccess ? 'Confirmed' : 'Failure'}
            </DoctTypography>
          </div>
        </>
      </div>
      <div className="pt-2"></div>
    </>
  );
}
