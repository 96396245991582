import { DoctTypography } from '@doct-react/core';
import React from 'react';

const FormFooterPaymentHeading = ({ amount, gst, currency }) => {
  return (
    <>
      <DoctTypography variant="textLabel1" className="m-0 p-0">
        <b>Amount: </b> {currency} {amount}
      </DoctTypography>
      <DoctTypography className="m-0 p-0 mt-1 text-grey-400" variant="textLabel2">
        {gst}
      </DoctTypography>
    </>
  );
};

export default FormFooterPaymentHeading;
