import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOST_BOTTOM_CENTER } from '../../../../../constants/toasterPosition.constants';

import { ImageInput } from '../../../../../shared';
import { setShowToasterWithParams } from '../../../../featuresSlice';

import {
  selectValueCoverImageUrl,
  setIsFormDirty,
  setValueCoverImage,
  setValueCoverImageUrl,
} from '../../slice/activityLog.slice';

const MAX_FILE_SIZE_COVER_IMAGE = 10;
const ALLOWED_FILE_TYPE = ['.jpg', '.png', '.jpeg'];

export default function CoverImageActivityLog() {
  const dispatch = useDispatch();
  const valueCoverImageUrl = useSelector(selectValueCoverImageUrl);

  const setCoverImage = (file) => {
    dispatch(setValueCoverImage(file?.file));
    dispatch(setValueCoverImageUrl(''));
  };

  return (
    <ImageInput
      name={'coverImage'}
      onChange={(file) => {
        setCoverImage(file);
        dispatch(setIsFormDirty(true));
      }}
      accept={ALLOWED_FILE_TYPE.map((val) => val).join(', ')}
      maxFileSize={MAX_FILE_SIZE_COVER_IMAGE}
      imageUrl={valueCoverImageUrl}
      onMaxFileSizeExceed={() => {
        dispatch(
          setShowToasterWithParams({
            variant: 'danger',
            position: TOST_BOTTOM_CENTER,
            message: `Max File Size is ${MAX_FILE_SIZE_COVER_IMAGE} MB`,
          }),
        );
      }}
      onNotAllowedExtension={() => {
        dispatch(
          setShowToasterWithParams({
            variant: 'danger',
            position: TOST_BOTTOM_CENTER,
            message: `Allowed file type is ${ALLOWED_FILE_TYPE.map((val) => val).join(', ')}`,
          }),
        );
      }}
    />
  );
}
