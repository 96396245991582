import { DoctModal } from '@doct-react/app';
import { DoctIcon, DoctTypography } from '@doct-react/core';
import React from 'react';

export default function AssignCertificateViaDashboard({
  handlePrimaryButtonClick,
  children,
  openMenu,
  setOpenMenu,
  iconName,
  name,
}) {
  return (
    <DoctModal
      iconName={''}
      primaryBtnText="Generate Certificate"
      secondaryBtnText="Cancel"
      open={openMenu}
      handlePrimaryButtonClick={() => {
        handlePrimaryButtonClick();
        setOpenMenu(false);
      }}
      handleSecondaryButtonClick={() => {
        setOpenMenu(false);
      }}
      handleClose={() => setOpenMenu(false)}
      title="Assign Certificate via"
      handleIconButtonClick={() => {}}
      width={572}
      className="disabled_modal_outside_click assign-via-dashboard-modal"
    >
      <div className={`assign-dashboard-card p-2 d-flex align-items-center`}>
        <DoctIcon width="28" height="28" name={iconName} />
        <DoctTypography variant="textLabel1" className="text-grey-800 m-0 ml-2">
          {name}
        </DoctTypography>
      </div>
      {children}
    </DoctModal>
  );
}
