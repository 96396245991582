import React from 'react';
import { useDispatch } from 'react-redux';
import { DoctActionMenu } from '@doct-react/app';
import { DoctTypography } from '@doct-react/core';

import {
  setDesignationId,
  setModalTitle,
  toggleDesignationModal,
  setSubCommitteeId,
} from '../../slice/committee.slice';

export default function CardCommittee({
  designation,
  setIsModalOpen,
  setDesignationIdAndName,
  moveObject,
  index,
  designationArray,
}) {
  const dispatch = useDispatch();

  const options = [
    {
      title: 'Edit',
    },
    {
      title: 'Remove',
      className: 'text-danger',
    },
    {
      title: 'Move Up',
      className: index === 0 ? 'disable_color_text' : '',
    },
    {
      title: 'Move Down',
      className: index == designationArray.length - 1 ? 'disable_color_text' : '',
    },
  ];

  return (
    <>
      <div className="bg-grey-100 p-3 border-radius mb-2">
        <div className="d-flex justify-content-between">
          <DoctTypography
            variant="subtitle2"
            fontWeight="medium"
            className="mb-2 pb-1 mt-n2 m-0 text-grey-600"
          >
            {designation?.name}
          </DoctTypography>
          {!designation.archiveId && (
            <DoctActionMenu
              options={options}
              className="position-absolute add-committee-icon more_action_menu mr-12px mr-md-3 mt-lg-n3"
              onClick={(item) => {
                if (item.title === 'Edit') {
                  dispatch(setSubCommitteeId(designation.subCommitteeId));
                  dispatch(setDesignationId(designation.id));
                  dispatch(setModalTitle('Edit Designation'));
                  dispatch(toggleDesignationModal());
                }
                if (item.title === 'Remove') {
                  setDesignationIdAndName({ id: designation.id, name: designation?.name });
                  setIsModalOpen(true);
                }
                if (item.title === 'Move Up') {
                  moveObject(designation, 'Up');
                }
                if (item.title === 'Move Down') {
                  moveObject(designation, 'Down');
                }
              }}
            />
          )}
        </div>
        {designation?.members?.map((item, index) => {
          return (
            <span
              className="committee-member-box bg-white text-grey-800 text-label-1 mb-1 d-inline-flex mr-2"
              key={index}
            >
              {item?.memberName}
            </span>
          );
        })}
      </div>
    </>
  );
}
