import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@mui/material/Skeleton';
import SmallCardSkeletonView from './SmallCardSkeletonView';

function SkeletonCardView2({ smallCardSkeleton }) {
  if (smallCardSkeleton) {
    return <SmallCardSkeletonView />;
  }

  return (
    <>
      <Box
        sx={{ bgcolor: 'grey.200' }}
        variant="rect"
        width="99%"
        height={172}
        className="mt-1 border-radius"
      >
        <div className="d-flex justify-content-center">
          <Skeleton
            sx={{ bgcolor: 'grey.300' }}
            variant="h1"
            width="30%"
            height={50}
            className="mt-5 border-radius"
          />
          <div className="d-flex flex-column w-50 ml-4">
            <Skeleton
              sx={{ bgcolor: 'grey.300' }}
              variant="h1"
              width="100%"
              height={20}
              className="mt-5 border-radius"
            />
            <Skeleton
              sx={{ bgcolor: 'grey.300' }}
              variant="h1"
              width="100%"
              height={20}
              className="mt-2 border-radius"
            />
          </div>
        </div>
        <Skeleton
          sx={{ bgcolor: 'grey.300' }}
          variant="h1"
          width="90%"
          height={20}
          className="mt-4 border-radius ml-3"
        />
      </Box>
      <Skeleton
        sx={{ bgcolor: 'grey.300' }}
        variant="h1"
        width="99%"
        height={85}
        className="mt-1 border-radius"
      />
    </>
  );
}

export default SkeletonCardView2;
