export const PER_PAGE = 10;
export const PER_PAGE_TABLE = 10000; // TODO: temp fix to show all data
export const DEFAULT_PAGE_NUMBER = 1;

export const IS_PROD_ENV = process.env.REACT_APP_BUILD_ENV == 'production';

export const NETWORK_REQUEST_ERROR_MSG = 'Oops! something went wrong';
export const API_TIME_FORMAT = 'YYYY-MM-DDTh:mm:ss';
// Password must include one uppercase, special character and number and minimum 8 characters.
export const PASSWORD_VALIDATION =
  /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;

export const MIN_PASSWORD_LENGTH = 8;

export const PASSWORD_VALIDATION_TEXT =
  'Password must include a minimum of 8 characters with one uppercase, one lowercase character, one special character, and one number.';

export const CONTINUE_URL = 'continue_url';

export const EMAIL_VALIDATION = /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const NOT_CHECK_THIS_ROUTES_REGEX =
  /^\/dashboard(\/(recruiter\/(posting|job-applicant)|institute\/(courses|applicants\|*)|events\/(all|registration|invoice-summary|manage-attendees|abstracts|edit)|billing-subscription\/(subscription(\/(all-packages\/(\d|billing-info\/.*|billing-info-recruiter\/rBundleId\?))?|billing-details)|e-certificate\/all-e-certificates|manage-memberships\/(all-memberships|manage-directory|activity-log|committee))|manage-memberships\/(all-memberships|manage-directory|activity-log|committee))?)?$/;
