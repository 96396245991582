import * as ROUTE from '../../../routes/constant';

const securityActionBarData = {
  [`/${ROUTE.DASHBOARD}/${ROUTE.SETTINGS}/${ROUTE.LOGIN_SECURITY}`]: {
    pathname: `/${ROUTE.DASHBOARD}/${ROUTE.SETTINGS}/${ROUTE.LOGIN_SECURITY}`,
    title: 'Account Settings',
    navigateButtonLink: '/create',
    buttonIcon: 'plus',
    isButtonDisabled: true,
  },
};

export default securityActionBarData;
