import React from 'react';
import * as ROUTE from '../../../routes/constant';
import { ActionButton } from './components';

const committeeLogActionBar = {
  [`/${ROUTE.DASHBOARD}/${ROUTE.MANAGE_MEMBERSHIPS}/${ROUTE.COMMITTEE}`]: {
    pathname: `${ROUTE.ACTIVITY_LOG}/${ROUTE.ADD_ROUTE}`,
    title: 'Memberships Management',
    buttonText: 'New Activity',
    buttonIcon: 'plus',
    isButtonDisabled: false,
    btnComponent: <ActionButton />,
    // navigateButtonLink: `/${ROUTE.DASHBOARD}/${ROUTE.MANAGE_MEMBERSHIPS}/${ROUTE.ACTIVITY_LOG}/${ROUTE.ADD_ROUTE}`,
  },
};

export default committeeLogActionBar;
