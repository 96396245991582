import { DoctTextField } from '@doct-react/app';
import { DoctTypography } from '@doct-react/core';
import React, { useEffect } from 'react';
// import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../../../../../../../components';
import { selectEditTimeApplyType } from '../../../../../ManageCourseSlice';

const UsingApplyNow = ({ control, errors, watch, setValue, isEditTimeEmailValue }) => {
  const user = useSelector(userSelector);
  const applyType = useSelector(selectEditTimeApplyType);
  const watchEmailAddress = watch('email');

  useEffect(() => {
    if (user?.tenant?.email && !isEditTimeEmailValue) {
      setValue('email', user?.tenant?.email);
    }
  }, [user?.tenant?.email, isEditTimeEmailValue, applyType]);

  return (
    <div className="p-4">
      <DoctTypography variant="textLabel1" fontWeight="medium">
        Get Email notification to
      </DoctTypography>

      <DoctTextField
        name={'email'}
        id={'email'}
        label={'Email address'}
        showStar={false}
        control={control}
        isErrors={errors}
        validationRules={{
          required: !watchEmailAddress ? 'it is required' : false,
          pattern: {
            value: /^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Email is not valid',
          },
        }}
      />
    </div>
  );
};

export default UsingApplyNow;
