import { DoctTypography } from '@doct-react/core';
import React, { useState } from 'react';
import { DoctFileUpload, DoctModal, DoctTextField } from '@doct-react/app';
import FormGroup from '../../../../../../../shared/FormGroup';
import { selectTicketsDetails } from '../../../../createEvent.slice';
import { useDispatch, useSelector } from 'react-redux';
import UserLocation from '../../../../../../../shared/ui/UserLocation';
import { prepareUploadedFile } from '../../../../../../../helper/helperFunction';
import { EMAIL_VALIDATION } from '../../../../../../../constants/constants';
import { setShowToasterWithParams } from '../../../../../../featuresSlice';
import { TOST_TOP_RIGHT } from '../../../../../../../constants/toasterPosition.constants';

function InvoiceDetails({
  formName,
  touched,
  uploadRegistration,
  setUploadRegistration,
  uploadSignature,
  setUploadSignature,
  control,
  errors,
  watch,
  setValue,
  defaultStateValue,
  resetGSTRegistrationUploadUrl,
  setResetGSTRegistrationUploadUrl,
  setDeleteGSTRegistrationUploadUrl,
  resetSignatureUrl,
  setResetSignatureUrl,
  setDeleteSignatureUrl,
  isSignatureFileError,
  isGSTFileError,
}) {
  const dispatch = useDispatch();
  const gstRegistrationFromTicket = useSelector(selectTicketsDetails);
  const [isOpenModalOfFileLimit, setIsOpenModalOfFileLimit] = useState(false);

  return (
    <div>
      <DoctTypography variant="h6" className="text-grey-800">
        Invoice Details
      </DoctTypography>
      <DoctTypography variant="subtitle2" className="text-grey-600 mt-4">
        Fill all below fields for GST registration details.
      </DoctTypography>
      <DoctTextField
        {...formName.gstIn}
        showStar={false}
        className="mb-3"
        defaultValue=""
        validationRules={{
          required:
            gstRegistrationFromTicket?.invoiceDetail?.isGSTRegistration === 'true'
              ? "It's Required Field"
              : false,
        }}
        touched={touched}
        disabled={
          gstRegistrationFromTicket?.invoiceDetail?.isGSTRegistration === 'false' ? true : false
        }
      />
      <DoctTextField
        {...formName.registeredCompanyName}
        showStar={false}
        className="mb-3"
        defaultValue=""
        validationRules={{
          required: "It's Required Field",
        }}
        touched={touched}
      />
      <DoctTextField
        {...formName.registeredCompanyAddress}
        showStar={false}
        className="mb-3"
        defaultValue=""
        validationRules={{
          required: "It's Required Field",
        }}
        touched={touched}
      />
      <UserLocation
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        touched={touched}
        countryName="country"
        stateName="state"
        cityName="city"
        paymentStep={true}
        defaultStateVal={defaultStateValue}
      />
      <DoctTextField
        {...formName.contactNumber}
        showStar={false}
        className="mb-3"
        validationRules={{
          required: "It's required",
          maxLength: {
            value: 10,
            message: 'Number is not valid',
          },

          minLength: {
            value: 10,
            message: 'Number is not valid',
          },
          pattern: {
            value: /^(0|[1-9]\d*)(\.\d+)?$/,
            message: 'Mobile No is not valid',
          },
        }}
        defaultValue=""
        touched={touched}
      />
      <DoctTextField
        {...formName.emailAddress}
        showStar={false}
        className="mb-3"
        validationRules={{
          required: "It's Required Field",
          pattern: {
            value: EMAIL_VALIDATION,
            message: 'Email is not valid',
          },
        }}
        defaultValue=""
        touched={touched}
      />
      <DoctTextField
        {...formName.panCardNumber}
        showStar={false}
        className="mb-2"
        defaultValue=""
        validationRules={{
          required: "It's Required Field",
        }}
        touched={touched}
      />
      <div className="mb-5 py-2">
        {gstRegistrationFromTicket?.invoiceDetail?.isGSTRegistration === 'true' ? (
          <>
            <DoctTypography variant="subtitle2" className="text-grey-800">
              Upload GST Registration Certificate
            </DoctTypography>
            <DoctFileUpload
              uploadMaxFilesMessage="Upload document in JPG, JPEG, PNG, PDF, DOC formats up to 5 MB size."
              maxFiles={1}
              uploadedFiles={prepareUploadedFile(
                resetGSTRegistrationUploadUrl && resetGSTRegistrationUploadUrl,
                uploadRegistration && uploadRegistration,
              )}
              setUploadedFiles={(uploadedFile) => {
                if (!uploadedFile?.length) {
                  setResetGSTRegistrationUploadUrl(null);
                  setDeleteGSTRegistrationUploadUrl(true);
                  setUploadRegistration(null);
                } else {
                  const fileExtension = uploadedFile[0]?.name?.toString()?.split('.')[1];
                  if (
                    !['jpeg', 'jpg', 'png', 'pdf', 'docx'].includes(fileExtension.toLowerCase())
                  ) {
                    dispatch(
                      setShowToasterWithParams({
                        variant: 'danger',
                        position: TOST_TOP_RIGHT,
                        message: 'Invalid File Type!',
                      }),
                    );
                    return;
                  }
                  setUploadRegistration(uploadedFile);
                }
              }}
              accept=".jpeg, .jpg, .png, .pdf, .docx"
              maxFileSizeInMb={5}
              name={'gstRegistration'}
              id={'gstRegistration'}
            />
            {isGSTFileError && <p className="custom-validation-error">It&apos;s required</p>}
          </>
        ) : (
          ''
        )}
        <FormGroup>
          <DoctTypography variant="subtitle2" className="text-grey-800">
            Upload Signature
          </DoctTypography>
          <DoctFileUpload
            uploadTitle="You can upload signature here."
            uploadMaxFilesMessage="Upload document in JPG, JPEG, PNG, PDF, DOC formats up to 5 MB size."
            maxFiles={1}
            uploadedFiles={prepareUploadedFile(
              resetSignatureUrl && resetSignatureUrl,
              uploadSignature && uploadSignature,
            )}
            setUploadedFiles={(uploadedFile) => {
              if (!uploadedFile?.length) {
                setResetSignatureUrl(null);
                setDeleteSignatureUrl(true);
                setUploadSignature(null);
              } else {
                const fileExtension = uploadedFile[0]?.name?.toString()?.split('.')[1];
                if (!['jpeg', 'jpg', 'png', 'pdf', 'docx'].includes(fileExtension.toLowerCase())) {
                  dispatch(
                    setShowToasterWithParams({
                      variant: 'danger',
                      position: TOST_TOP_RIGHT,
                      message: 'Invalid File Type!',
                    }),
                  );
                  return;
                }
                setUploadSignature(uploadedFile);
              }
            }}
            accept=".jpeg, .jpg, .png, .pdf, .docx"
            maxFileSizeInMb={5}
            name={'signature'}
            id={'signature'}
          />
          {isSignatureFileError && <p className="custom-validation-error">It&apos;s required</p>}
        </FormGroup>
      </div>
      <DoctModal
        showFooter={false}
        open={isOpenModalOfFileLimit}
        handleClose={() => setIsOpenModalOfFileLimit(false)}
      >
        <div>You can upload 1 document only</div>
      </DoctModal>
    </div>
  );
}

export default InvoiceDetails;
