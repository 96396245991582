import { createAsyncThunk } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import {
  BUSINESS_BASE_API_ROUTE,
  CONTENT_CENTER,
  JOBS,
  JOB_CENTER,
  KEY_SKILLS,
} from '../../../../api/apiEndpoints';
import { TOST_TOP_RIGHT } from '../../../../constants/toasterPosition.constants';
import { NETWORK_REQUEST_ERROR_MSG } from '../../../../constants/constants';
import { setShowToasterWithParams } from '../../../featuresSlice';

export const postJobInquiry = createAsyncThunk(
  `manageJob/postJobInquiry`,
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_API_URL}/jobcenter/job-inquiries`,
        data,
      });
      return response.data;
    } catch (err) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_TOP_RIGHT,
          message: err?.response?.data?.Title || NETWORK_REQUEST_ERROR_MSG,
        }),
      );
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchJobById = createAsyncThunk(`manageJobsApi/fetchJobById`, async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/${JOB_CENTER}${BUSINESS_BASE_API_ROUTE}/${JOBS}/${id}`,
  );
  return response.data;
});

export const manageJobsApi = createApi({
  reducerPath: 'manageJobsApi',

  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = getState().app.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: ['manageJobsApi'],

  endpoints: (builder) => ({
    postJob: builder.mutation({
      query: ({ formValues }) => ({
        url: `${process.env.REACT_APP_BASE_API_URL}/${JOB_CENTER}${BUSINESS_BASE_API_ROUTE}/${JOBS}`,
        method: 'POST',
        body: formValues,
      }),
    }),

    getJobById: builder.query({
      query: ({ id }) =>
        id &&
        `${process.env.REACT_APP_BASE_API_URL}/${JOB_CENTER}${BUSINESS_BASE_API_ROUTE}/${JOBS}/${id}`,
    }),

    getKeySkills: builder.query({
      query: () => `${process.env.REACT_APP_BASE_API_URL}${CONTENT_CENTER}/${KEY_SKILLS}`,
    }),

    editJob: builder.mutation({
      query: ({ formValues, id }) => ({
        url: `${process.env.REACT_APP_BASE_API_URL}/${JOB_CENTER}${BUSINESS_BASE_API_ROUTE}/${JOBS}/${id}`,
        method: 'PUT',
        body: formValues,
      }),
    }),
  }),
});

export const { usePostJobMutation, useGetJobByIdQuery, useEditJobMutation, useGetKeySkillsQuery } =
  manageJobsApi;
