import React, { useEffect, useState } from 'react';
import { FilterDropdown } from '../../../../shared/ui/CustomTable/components';
import { DoctButton, DoctIcon, DoctIconButton, DoctTypography } from '@doct-react/core';
import { DoctFreeSoloSearchInput, DoctModal } from '@doct-react/app';
import AwardeesListingFilterPopUp from './PopUps/AwardeesListingFilterPopUp';
import { useLocation, useNavigate } from 'react-router';
import useCertificateData from '../../hooks/useGetCertificateData';
import useAwardeesListingFilterForm from '../../hooks/Form/useAwardeesListingFilterForm';
import APIAllPdfDownload from '../../APIs/APIAllPdfDownload.service';
import { Toaster } from '../../../../shared';
import { CircularProgress, Tooltip } from '@mui/material';

export default function AwardeesListing({
  filterProps,
  awardeeCount,
  showFilterIcon = true,
  filterData,
  searchText,
  setSearchText,
  newAwardee,
  setNewAwardee,
  certificateID,
  children,
}) {
  const [, setActiveRowIndex] = useState(null);
  const location = useLocation();
  // let certificateID = location?.search?.split('=')[1];
  const [filterCount] = useState(0);
  const certificateData = useCertificateData();
  const [certificateDatas, setCertificateData] = useState([]);
  const propsOfAwardeeFilters = useAwardeesListingFilterForm(certificateID);
  const [pdfClicked, setPdfClicked] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for controlling DoctModal visibility
  const [toastOpen, setToastOpen] = useState(false);
  const [filterSubmit, setFilterSubmit] = useState({});
  const [FilterClicked, setFilterClicked] = useState(false);

  const navigate = useNavigate();
  // const search = useDebounce(searchText, 500);

  const individualCertificateID = location?.search?.includes('id')
    ? location?.search?.split('=')[1]
    : certificateID;

  useEffect(() => {
    const fullPath = location.pathname + location.search;
    const isAwardeeListPage = fullPath?.includes('/dashboard/e-certificate/all-awardees-list');

    if (isAwardeeListPage) {
      if (awardeeCount > 0) {
        setNewAwardee(false);
      } else {
        setNewAwardee(true);
      }
    }
    if (searchText || Object.keys(propsOfAwardeeFilters.filterProps ?? {})?.length > 0) {
      setNewAwardee(false);
    }
    if (FilterClicked) {
      setNewAwardee(false);
    }
  }, [location, awardeeCount, setNewAwardee, propsOfAwardeeFilters.filterProps]);

  useEffect(() => {
    setCertificateData(certificateData);
  }, [certificateData?.length]);
  useEffect(() => {
    if (propsOfAwardeeFilters.filterData) {
      setFilterSubmit(propsOfAwardeeFilters.filterData);
    }
  }, [propsOfAwardeeFilters.filterData]);

  let filterValues = {
    ...filterProps,
    ['searchText']: searchText,
  };

  const downloadAllPDF = () => {
    setPdfClicked(true);
    const response = APIAllPdfDownload(
      individualCertificateID?.length ? individualCertificateID : String(certificateData[0]?.id),
      searchText,
      filterValues,
    );

    response.then((res) => {
      if (res?.status) {
        setPdfClicked(false);
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      propsOfAwardeeFilters.setDateError(false);
    }, 3000);
  }, [propsOfAwardeeFilters.dateError]);

  const closeToast = () => {
    setToastOpen(false);
  };

  useEffect(() => {
    // Show modal if date error is true, otherwise hide it
    if (propsOfAwardeeFilters.dateError) {
      setShowModal(true);
    }
    setToastOpen(propsOfAwardeeFilters.dateError);
  }, [propsOfAwardeeFilters.dateError]);

  const clearAllData = () => {
    propsOfAwardeeFilters.reset({
      emailSent: '',
      fromDate: '',
      toDate: '',
      emailPending: '',
    });
  };
  return (
    <>
      {toastOpen && (
        <div className="position-fixed tost-container-top-right">
          <Toaster variant={'error'} text={'Date is Required'} onPressedClose={closeToast} />
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between">
        <FilterDropdown
          dropdownMenuItems={certificateDatas ?? []}
          dropdownMenuDefaultSelected={
            individualCertificateID ? individualCertificateID : String(certificateData[0]?.id)
          }
          setActiveRowIndex={setActiveRowIndex}
          certificateID={individualCertificateID}
          propsOfAwardeeFilters={propsOfAwardeeFilters}
          setFilterSubmit={setFilterSubmit}
        />
        <div className="d-flex align-items-center w-50 mr-sm-n3 ml-auto">
          <Tooltip
            title="Download All in One"
            arrow
            className={`${
              newAwardee || pdfClicked ? 'pointer-event-none d-flex align-items-center' : ''
            }`}
          >
            <div>
              {pdfClicked && (
                <CircularProgress size={20} color="inherit" className="mr-2 text-grey-400" />
              )}
              <DoctButton
                disabled={newAwardee || pdfClicked || awardeeCount === 0}
                text="PDF"
                icon="downloadOne"
                type="inverse"
                size="medium"
                variant="text"
                className="ml-sm-n4 mr-2 ml-lg-auto p-0"
                onButtonClickHandler={downloadAllPDF}
              />
            </div>
          </Tooltip>
          <span className="d-sm-none ml-auto">
            <DoctIcon width="20" height="20" name="search" className="text-grey-500" />
          </span>
          <div
            className={`${newAwardee && 'disable-sidebar-link-item'} applicants-search-bar`}
            style={{ width: '298px' }}
          >
            <DoctFreeSoloSearchInput
              name="searchAwardees"
              onInputChangeHandler={(val) => {
                setSearchText(val);
              }}
              value={searchText}
              placeholder="Search awardees name"
              onChangeHandler={() => {}}
            />
          </div>
          <span className="ml-3 position-relative">
            {filterCount > 0 && (
              <span className="custom-table-result-filter-counter position-absolute d-flex align-items-center justify-content-center">
                {filterCount}
              </span>
            )}
            {showFilterIcon && (
              <DoctIconButton
                variant="text"
                type="secondary"
                icon="filter"
                size="medium"
                disabled={newAwardee ? true : false}
                onButtonClickHandler={() => {
                  propsOfAwardeeFilters.reset(filterSubmit);
                  setShowModal(true);
                }}
              />
            )}
          </span>
        </div>
      </div>
      <DoctTypography variant="subtitle3" className="text-grey-600">
        {awardeeCount} AWARDEES
      </DoctTypography>
      <DoctModal
        iconName={''}
        primaryBtnText="Apply"
        secondaryBtnText="Clear"
        open={showModal} // Use showModal state here
        handlePrimaryButtonClick={() => {
          if (!propsOfAwardeeFilters.dateError) {
            propsOfAwardeeFilters.handleSubmit();
            setFilterClicked(true);
            setShowModal(false);
          }
        }}
        handleSecondaryButtonClick={() => {
          propsOfAwardeeFilters.setFilterData({});
          propsOfAwardeeFilters.setFilterProps({});
          setFilterSubmit({});
          clearAllData();
          setShowModal(true);
        }}
        handleClose={() => {
          navigate(location?.pathname + '?id=' + certificateID);
          propsOfAwardeeFilters.reset({});
          propsOfAwardeeFilters.setFilterData({});
          setShowModal(false);
        }}
        title={'Filter'}
        handleIconButtonClick={() => {}}
        width={360}
        className="disabled_modal_outside_click"
      >
        {filterData ? filterData : <AwardeesListingFilterPopUp {...propsOfAwardeeFilters} />}
      </DoctModal>
      {children}
    </>
  );
}
