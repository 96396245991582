import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import fieldAddEditActivityLog from '../AddEditActivityLog/field.AddEditActivityLog';
import {
  setValueCoverImageUrl,
  setValueOrganizingTeam,
  setValueSupportedBy,
} from '../slice/activityLog.slice';

export function useResetForm() {
  const dispatch = useDispatch();
  const prepareApiToFormData = useCallback(async (apiData) => {
    return new Promise((resolve, reject) => {
      try {
        if (apiData[fieldAddEditActivityLog.coverImageUrl.keyOfGetApi]) {
          dispatch(
            setValueCoverImageUrl(apiData[fieldAddEditActivityLog.coverImageUrl.keyOfGetApi]),
          );
        }
        dispatch(
          setValueOrganizingTeam(
            apiData[fieldAddEditActivityLog.organizingTeam.keyOfGetApi]?.map(
              ({ memberId: id, memberName: title }) => {
                return { title, id: Number(id) };
              },
            ),
          ),
        );
        dispatch(
          setValueSupportedBy(apiData[fieldAddEditActivityLog.supportedBy.keyOfGetApi] || []),
        );
        resolve({
          ...apiData,
          [fieldAddEditActivityLog.title.name]: apiData[fieldAddEditActivityLog.title.keyOfGetApi],
          [fieldAddEditActivityLog.startDate.name]: new Date(
            apiData[fieldAddEditActivityLog.startDate.keyOfGetApi],
          ),
          [fieldAddEditActivityLog.endDate.name]: new Date(
            apiData[fieldAddEditActivityLog.endDate.keyOfGetApi],
          ),
          [fieldAddEditActivityLog.highlights.name]:
            apiData[fieldAddEditActivityLog.highlights.keyOfGetApi],
        });
      } catch (e) {
        reject(null);
      }
    });
  }, []);

  return { prepareApiToFormData };
}
