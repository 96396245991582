import React from 'react';
import { DoctTypography } from '@doct-react/core';

export default function QuickCardHighted({ title, children }) {
  return (
    <div className="bg-white quick_card_height border-radius p-1 mb-auto">
      <div className="d-flex justify-content-between align-items-center mb-n2 mt-n2">
        <DoctTypography variant="subtitle1" fontWeight="medium" className="text-grey-800 px-2">
          {title}
        </DoctTypography>
      </div>
      {children}
    </div>
  );
}
