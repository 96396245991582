import { DoctCol, DoctIcon, DoctIconButton, DoctRow, DoctTypography } from '@doct-react/core';
import React from 'react';
import { calculateTotalExperience } from '../../../helper/calculateTotalExperience';
import dayjs from 'dayjs';

const JobApplicantCollapsibleEl = ({ tableCollapsibleDetailEl }) => {
  const {
    currentSalary,
    noticePeriod,
    educations = [],
    workExperiences = [],
  } = tableCollapsibleDetailEl;

  function calculateExperience(data = []) {
    if (!Array.isArray(data)) return { years: null, months: null };
    let totalMonth = 0;
    const dateToMonths = function (date = new Date()) {
      return date.getFullYear() * 12 + date.getMonth();
    };

    let totalExperience = [];
    data.forEach((experience) => {
      const experienceStartDate = new Date(experience.startDate);
      const experienceEndDate = experience.endDate ? new Date(experience.endDate) : new Date();
      const experienceStartDateInMonths = dateToMonths(experienceStartDate);
      const experienceEndDateInMonths = dateToMonths(experienceEndDate);
      const experienceDurationInMonth = experienceEndDateInMonths - experienceStartDateInMonths;
      totalMonth = experienceDurationInMonth;
      let storeExp = {};
      storeExp.years = Math.floor(totalMonth / 12);
      storeExp.months = totalMonth % 12;
      totalExperience.push(storeExp);
    });
    return totalExperience;
  }

  const totalExperience = calculateExperience(workExperiences);

  return (
    <>
      <DoctRow>
        {(totalExperience[0]?.years > 0 ||
          totalExperience[0]?.months > 0 ||
          currentSalary > 0 ||
          noticePeriod) && (
          <DoctCol sm={4}>
            <div>
              <DoctIcon name="infoOutline" fill="grey" width="16" />

              {(totalExperience[0]?.years > 0 || totalExperience[0]?.months > 0) && (
                <DoctTypography variant="textLabel2" className="text-grey-600">
                  Total Experience: {calculateTotalExperience(workExperiences).years} years{' '}
                  {calculateTotalExperience(workExperiences).months} months
                </DoctTypography>
              )}

              {currentSalary > 0 && (
                <DoctTypography variant="textLabel2" className="text-grey-600 m-0">
                  Current Salary: {currentSalary} per month
                </DoctTypography>
              )}
              {noticePeriod && (
                <DoctTypography variant="textLabel2" className="text-grey-600 m-0">
                  Notice Period: {noticePeriod}
                </DoctTypography>
              )}
            </div>
          </DoctCol>
        )}
        {educations?.length > 0 && (
          <DoctCol sm={4}>
            <div
              className={workExperiences?.length > 0 && 'collapsible_content_container px-3 h-100'}
            >
              <DoctIcon name="degreeCollegeOutline" fill="grey" width="16" height="16" />

              {educations?.map((education) => (
                <>
                  <div className="my-3">
                    <DoctTypography variant="textLabel2" className="text-grey-600 my-1">
                      {education?.organization}
                    </DoctTypography>

                    <DoctTypography variant="textLabel2" className="text-grey-600 my-1">
                      {education?.degree}
                    </DoctTypography>
                    <DoctTypography variant="textLabel2" className="text-grey-600 m-0">
                      {education?.startYear} -{' '}
                      {education?.endYear ? education?.endYear : 'Till Date'}
                    </DoctTypography>
                  </div>
                </>
              ))}
            </div>
          </DoctCol>
        )}
        {workExperiences?.length > 0 && (
          <DoctCol sm={4}>
            <div className={'collapsible_content_container px-3'}>
              <DoctIcon name="workOutline" fill="grey" width="16" />

              {workExperiences?.map((experience, index) => (
                <>
                  <div className="my-3">
                    <DoctTypography variant="textLabel2" className="text-grey-600 my-1">
                      {experience?.company}
                    </DoctTypography>

                    <DoctTypography variant="textLabel2" className="text-grey-600 my-1">
                      {experience?.designation}
                    </DoctTypography>
                    <DoctTypography variant="textLabel2" className="text-grey-600 m-0">
                      {`${
                        experience?.startDate && dayjs(experience?.startDate).format('MMMM YYYY')
                      } - ${
                        experience?.endDate
                          ? dayjs(experience?.endDate).format('MMMM YYYY')
                          : 'Present'
                      } | ${totalExperience[index]?.years} yr ${
                        totalExperience[index]?.months
                      } mos`}
                    </DoctTypography>
                  </div>
                </>
              ))}
            </div>
          </DoctCol>
        )}
      </DoctRow>
      <div className="d-flex justify-content-between align-items-center">
        {(noticePeriod ||
          currentSalary ||
          (educations?.length == 0 && workExperiences?.length == 0)) && (
          <DoctTypography variant="subtitle2" className="font-weight-bold">
            Details are Unavailable.
          </DoctTypography>
        )}

        <div className="text-right ml-auto">
          <DoctIconButton variant="outlined" icon="up" />
        </div>
      </div>
    </>
  );
};

export default JobApplicantCollapsibleEl;
