import React from 'react';
import defaultProfilePhoto from '../../../../assets/images/settings/placeholder/default-profile.png';
import style from '../ProfileDropdown.module.scss';

export default function AvatarProfileDropdown({ src }) {
  return (
    <div className={style['profile-dropdown-avatar']}>
      <img src={src || defaultProfilePhoto} alt="profile photo" className="object-fit-cover" />
    </div>
  );
}
