import React from 'react';
import { DoctTypography } from '@doct-react/core';

const NoSearchFound = ({ positionClassName, description }) => {
  return (
    <div
      className={`text-grey-600 text-center no-data-found-screen d-flex align-items-center justify-content-center ${positionClassName}`}
    >
      <div>
        <DoctTypography variant="body1" fontWeight="bold" className="my-2">
          No data found!
        </DoctTypography>
        <br className="bg-danger" />
        <DoctTypography variant="body2" className="my-0 p-0">
          {description}
        </DoctTypography>
      </div>
    </div>
  );
};

export default NoSearchFound;
