import { createSlice } from '@reduxjs/toolkit';

const SLICE_NAME = 'profile';

export const profileSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    loading: false,
    isModalOpenAdminProfile: false,
    isModalOpenOrganization: false,
    modalTitle: null,
  },
  reducers: {
    toggleAdminProfileModal: (state, action) => {
      state.isModalOpenAdminProfile = action.payload.isModalOpenAdminProfile;
      state.modalTitle = action.payload.modalTitle;
    },
    toggleOrganizationModal: (state, action) => {
      state.isModalOpenOrganization = action.payload.isModalOpenOrganization;
      state.modalTitle = action.payload.modalTitle;
    },
  },
});

export const { toggleAdminProfileModal, toggleOrganizationModal } = profileSlice.actions;

export const selectIsModalOpenAdminProfile = (state) => state[SLICE_NAME].isModalOpenAdminProfile;
export const selectIsModalOpenOrganization = (state) => state[SLICE_NAME].isModalOpenOrganization;
export const selectModalTitle = (state) => state[SLICE_NAME].modalTitle;
// export const selectDownloadUrl = (state) => state[SLICE_NAME].downloadUrl;
// export const selectEventsIdCode = (state) => state[SLICE_NAME].selectedEvents;

export default profileSlice;
