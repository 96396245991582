import { DoctTypography } from '@doct-react/core';
import React from 'react';
import style from '../SubscriptionModal.module.scss';
import SubscriptionChip from '../CommonChip/SubscriptionChip';

export default function INRAmount({
  subscriptionChipText,
  MainAmount,
  DiscountedAmount,
  textGrey,
  className,
  isGSTApplicable,
}) {
  return (
    <>
      <div className={`${className} align-items-center`}>
        {subscriptionChipText && <SubscriptionChip subscriptionChipText={subscriptionChipText} />}
        {MainAmount && (
          <DoctTypography
            variant="subtitle2"
            className={`${style['inr-number-text']} m-0 ml-2 ${
              textGrey ? 'text-grey-400' : style['inr-text-color']
            }`}
          >
            INR {MainAmount}
          </DoctTypography>
        )}
      </div>
      <div className="d-flex align-items-center pb-4 mb-n1">
        <DoctTypography
          variant="h6"
          className={`m-0 font-weight-bold mr-1 ${textGrey ? 'text-grey-800' : 'text-white'}`}
        >
          INR {DiscountedAmount}
        </DoctTypography>
        {isGSTApplicable && (
          <DoctTypography
            variant="subtitle3"
            fontWeight="regular"
            className={`m-0 ${textGrey ? 'text-grey-400' : style['inr-text-color']}`}
          >
            +GST
          </DoctTypography>
        )}
      </div>
    </>
  );
}
