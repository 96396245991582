import React from 'react';
import { DoctTypography, DoctIcon } from '@doct-react/core';

import * as ROUTE from '../../../../routes/constant';
import SmallCardSkeletonView from '../../../../shared/ui/SkeletonCard/SmallCardSkeletonView';
import { DoctAnimationLoading } from '@doct-react/app';

const ECertificateCard = ({
  totalECertificates,
  totalUploaded,
  totalDraft,
  totalCreated,
  navigate,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <>
        <SmallCardSkeletonView />
        <DoctAnimationLoading />
      </>
    );
  }

  return (
    <>
      <span
        onClick={() => {
          navigate(
            `/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_E_CERTIFICATES}?=showAllCertificates`,
          );
        }}
        className="cursor-pointer"
      >
        <DoctIcon
          name="plus"
          width="24"
          height="24"
          fill="#00A0C0"
          className="mr-4 position-absolute right-0 negative-margin-for-btn"
        />
      </span>
      <div className="justify-content-around d-flex text-center align-items-center mx-2 mt-3">
        <div>
          <DoctTypography variant="h3" className="text-grey-800 font-weight-medium">
            {totalECertificates}
          </DoctTypography>
          <DoctTypography variant="textLabel2" className="text-grey-400 m-0 mt-1">
            Certificates
          </DoctTypography>
        </div>
        <div>
          <div className="bg-normal-shade-primary d-flex border-radius-6px mb-1 justify-content-between px-2">
            <DoctTypography variant="textLabel2" className="text-grey-800 m-0 p-1">
              Created
            </DoctTypography>
            <span className="text-grey-800 m-0 p-1 text-label-2">{totalCreated}</span>{' '}
          </div>{' '}
          <div className="bg-normal-shade-primary d-flex border-radius-6px mb-1 justify-content-between px-2">
            <DoctTypography variant="textLabel2" className="text-grey-800 m-0 p-1">
              Uploaded
            </DoctTypography>
            <span className="text-grey-800 m-0 p-1 text-label-2">{totalUploaded}</span>{' '}
          </div>{' '}
          <div className="d-flex mb-1 justify-content-between px-2">
            <DoctTypography variant="textLabel2" className="text-grey-500 m-0 p-1">
              Draft
            </DoctTypography>
            <span className="text-grey-800 m-0 p-1 text-label-2">{totalDraft}</span>
          </div>
        </div>
      </div>{' '}
    </>
  );
};

export default ECertificateCard;
