import { createSlice } from '@reduxjs/toolkit';
import {
  getActivityById,
  postActivityLog,
  updateActivityLog,
} from '../services/activityLog.services';

const initialState = {
  loading: false,
  isAddOrUpdating: false,
  getActivityByIdData: null,
  organizingOptions: null,
  valueOrganizingTeam: [],
  valueSupportedBy: [],
  valueCoverImageUrl: '',
  valueCoverImage: null,
  valueDeleteCoverImage: false,
  isFormDirty: false,
};

export const activityLogSlice = createSlice({
  name: 'activityLog',
  initialState,
  reducers: {
    setOptionsOrganizingOptions: (state, action) => {
      state.organizingOptions = action.payload;
    },
    setSelectedOptionsOrganizations: (state, action) => {
      state.organizingOptions = action.payload;
    },
    setValueOrganizingTeam: (state, action) => {
      state.valueOrganizingTeam = action.payload;
    },
    setValueSupportedBy: (state, action) => {
      state.valueSupportedBy = action.payload;
    },
    setValueCoverImageUrl: (state, action) => {
      if (state.valueCoverImageUrl) {
        if (action.payload) {
          state.valueDeleteCoverImage = false;
        } else {
          state.valueDeleteCoverImage = true;
        }
      }
      state.valueCoverImageUrl = action.payload;
    },
    setValueCoverImage: (state, action) => {
      state.valueCoverImage = action.payload;
    },
    setIsFormDirty: (state, action) => {
      state.isFormDirty = action.payload;
    },
    resetValue: (state) => {
      state.getActivityByIdData = null;
      state.organizingOptions = null;
      state.valueOrganizingTeam = [];
      state.valueSupportedBy = [];
      state.valueCoverImageUrl = '';
      state.valueCoverImage = null;
      state.valueDeleteCoverImage = false;
      state.isFormDirty = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postActivityLog.pending, (state) => {
      state.isAddOrUpdating = true;
    });
    builder.addCase(postActivityLog.fulfilled, (state) => {
      state.isAddOrUpdating = false;
    });
    builder.addCase(postActivityLog.rejected, (state) => {
      state.isAddOrUpdating = false;
    });
    builder.addCase(updateActivityLog.pending, (state) => {
      state.isAddOrUpdating = true;
    });
    builder.addCase(updateActivityLog.fulfilled, (state) => {
      state.isAddOrUpdating = false;
    });
    builder.addCase(updateActivityLog.rejected, (state) => {
      state.isAddOrUpdating = false;
    });
    builder.addCase(getActivityById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActivityById.fulfilled, (state, action) => {
      state.loading = false;
      state.getActivityByIdData = action.payload;
    });
    builder.addCase(getActivityById.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setOptionsOrganizingOptions,
  setValueOrganizingTeam,
  setValueSupportedBy,
  setValueCoverImageUrl,
  setValueCoverImage,
  resetValue,
  setIsFormDirty,
} = activityLogSlice.actions;

export const selectLoading = (state) => state['activityLog'].loading;
export const selectIsAddOrUpdating = (state) => state['activityLog'].isAddOrUpdating;
export const selectGetActivityByIdData = (state) => state['activityLog'].getActivityByIdData;

export const selectOrganizingOptions = (state) => state['activityLog'].organizingOptions;

export const selectValueOrganizingTeam = (state) => state['activityLog'].valueOrganizingTeam;
export const selectValueSupportedBy = (state) => state['activityLog'].valueSupportedBy;

export const selectValueDeleteCoverImage = (state) => state['activityLog'].valueDeleteCoverImage;
export const selectValueCoverImageUrl = (state) => state['activityLog'].valueCoverImageUrl;
export const selectValueCoverImage = (state) => state['activityLog'].valueCoverImage;

export const selectIsFormDirty = (state) => state['activityLog'].isFormDirty;

export default activityLogSlice;
