import { createAsyncThunk } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

import {
  ARCHIVES,
  BUSINESS_BASE_API_ROUTE,
  ENTERPRISE_BASE_API_ROUTE,
} from '../../../../api/apiEndpoints';
import { NETWORK_REQUEST_ERROR_MSG } from '../../../../constants/constants';
import { TOST_BOTTOM_CENTER } from '../../../../constants/toasterPosition.constants';

import { setShowToasterWithParams } from '../../../featuresSlice';

export const postArchive = createAsyncThunk(
  'postArchive',
  async ({ data, handleModalClose }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { app } = getState();
      const { token } = app;
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${ARCHIVES}`,
        data,
        {
          Authorization: `Bearer ${token}`,
        },
      );
      dispatch({
        type: `designationApi/invalidateTags`,
        payload: ['Designation'],
      });
      dispatch(
        setShowToasterWithParams({
          variant: 'neutral',
          position: TOST_BOTTOM_CENTER,
          message: 'Archived Added Successfully',
        }),
      );
      handleModalClose();
    } catch (err) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_BOTTOM_CENTER,
          message: NETWORK_REQUEST_ERROR_MSG,
        }),
      );
      return rejectWithValue(err.response.data);
    }
  },
);

export const putArchive = createAsyncThunk(
  'putArchive',
  async ({ id, data, handleModalClose }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { app } = getState();
      const { token } = app;
      // eslint-disable-next-line no-unused-vars
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${ARCHIVES}/${id}`,
        data,
        {
          Authorization: `Bearer ${token}`,
        },
      );
      dispatch({
        type: `archiveApi/invalidateTags`,
        payload: ['Archive'],
      });
      dispatch(
        setShowToasterWithParams({
          variant: 'neutral',
          position: TOST_BOTTOM_CENTER,
          message: 'Archived Updated Successfully',
        }),
      );
      handleModalClose();
    } catch (err) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_BOTTOM_CENTER,
          message: NETWORK_REQUEST_ERROR_MSG,
        }),
      );
      return rejectWithValue(err.response.data);
    }
  },
);

export const getArchiveById = createAsyncThunk(`getArchiveById`, async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${ARCHIVES}/${id}`,
  );
  return response.data;
});

export const archiveApi = createApi({
  reducerPath: 'archiveApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = getState().app.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Archive'],
  endpoints: (builder) => ({
    getArchives: builder.query({
      query: () =>
        `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${ARCHIVES}`,
      providesTags: ['Archive'],
    }),
    deleteArchive: builder.mutation({
      query: ({ id }) => {
        return {
          url: `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${ARCHIVES}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Archive'],
    }),
  }),
});

export const { useGetArchivesQuery, useDeleteArchiveMutation } = archiveApi;
