export const HOME = '/';

export const EVENT_LANDING_PAGE = 'create-an-event';
export const LANDING_PAGE_RECRUITER = 'post-job';
export const LANDING_PAGE_INSTITUTE = 'publish-course';
export const TALENT_ACQUISITION_SERVICES = 'talent-acquisition-services';
export const LANDING_PAGE_ENTERPRISE_SOLUTIONS = 'enterprise-solutions';

export const LANDING_PAGE_RECRUITER_REDIRECT = 'recruiter';
export const LANDING_PAGE_INSTITUTE_REDIRECT = 'institutes';

export const ADD_ROUTE = 'add';
export const EDIT_ROUTE = 'edit';

export const DASHBOARD = 'dashboard';
export const INSTITIUTE = 'institute';

// Branding/ Promotional page

export const QUICK_ACCESS = 'quick-access';

// Event Dashboard
export const EVENTS = 'events';
export const EVENT_DASHBOARD = `${EVENTS}`;
export const EVENTS_ALL = 'all';
export const MANAGE_REGISTRATION = 'manage-registration';
export const INVOICES_SUMMARY = 'invoice-summary';
export const MANAGE_ATTENDEES = 'manage-attendees';
export const COMPLEMENTARY = 'register/complementary';
export const OFFLINE_REGISTRATION = 'register/offline';
export const CREATE_EVENT = 'new';
export const ABSTRACTS = 'abstracts';

export const ROUTE_CALLBACK_PAY = 'pay';

export const MANAGE_MEMBERSHIPS = 'manage-memberships';
export const CREATE_NEW_MEMBERSHIP = `new-memberships`;
export const EDIT_MEMBERSHIP = 'edit-membership';
export const ALL_MEMBERSHIPS = 'all-memberships';
export const MANAGE_DIRECTORY = 'manage-directory';
export const ACTIVITY_LOG = 'activity-log';
export const COMMITTEE = 'committee';

export const TALENT_ACQUISITION_INQUIRY_APPLICATION_SENT_SUCCESSFULLY =
  'inquiry-application-sent-successfully';
export const TALENT_ACQUISITION_ERROR_IN_INQUIRY = 'error-in-inquiry';

export const MANAGE_EVENTS = `/${DASHBOARD}/${EVENTS}/${EVENTS_ALL}`;
export const MANAGE_EVENTS_REGISTRATION = `/${DASHBOARD}/${EVENTS}/${MANAGE_REGISTRATION}`;
export const MANAGE_MEMBERSHIPS_ALL_MEMBERSHIP = `/${DASHBOARD}/${MANAGE_MEMBERSHIPS}/${ALL_MEMBERSHIPS}`;
export const MANAGE_MEMBERSHIPS_MANAGE_DIRECTORY = `/${DASHBOARD}/${MANAGE_MEMBERSHIPS}/${MANAGE_DIRECTORY}`;

export const MANAGE_EVENTS_REGISTRATION_COMPLEMENTARY = `${DASHBOARD}/${EVENTS}/${MANAGE_REGISTRATION}/:code/${COMPLEMENTARY}`;
export const MANAGE_EVENTS_REGISTRATION_OFFLINE = `${DASHBOARD}/${EVENTS}/${MANAGE_REGISTRATION}/:code/${OFFLINE_REGISTRATION}`;

export const MANAGE_MANAGE_ATTENDEES = `/${DASHBOARD}/${EVENTS}/${MANAGE_ATTENDEES}`;
export const MANAGE_EVENTS_ABSTRACTS = `/${DASHBOARD}/${EVENTS}/${ABSTRACTS}`;
export const MANAGE_INVOICE_SUMMARY = `/${DASHBOARD}/${EVENTS}/${INVOICES_SUMMARY}`;
export const PATH_CREATE_EVENT = `/${DASHBOARD}/${EVENTS}/${CREATE_EVENT}`;

// export const MANAGE_BILLING_SUBSCRIPTION = `/${DASHBOARD}/${BILLING_SUBSCRIPTION}/${BILLING_SUBSCRIPTION}`;
// export const MANAGE_BILLING = `/${DASHBOARD}/${BILLING_SUBSCRIPTION}`;
// export const MANAGE_BILLING_DETAILS = `/${DASHBOARD}/${BILLING_SUBSCRIPTION}/${BILLING_DETAILS}`;
export const ADD_NEW_MEMBER = 'add-member';

export const EDIT_MEMBER = 'edit-member';

// INSTITUTE DASHBOARD ROUTES

export const INSTITUTE = 'institute';
export const INSTITUTE_DASHBOARD = `${INSTITUTE}`;
export const INSTITUTE_COURSES = 'courses';
export const INSTITUTE_APPLICANTS = 'applicants';
export const INSTITUTE_ADD_COURSE = 'add-course';
export const INSTITUTE_EDIT_COURSE = 'edit';
export const PREVIEW_COURSE = 'preview-course';
export const COURSE_ACTION_SUCCSSFUL = 'course-posted-successfully';
export const ERROR_IN_COURSE_ACTION = 'error-while-posting';

// RECRUITER'S DASHBOARD ROUTE

export const RECRUITER = 'recruiter';
export const POSTING = 'posting';
export const RECRUITERS_DASHBOARD = `${RECRUITER}`;
export const JOB_APPLICANTS = 'job-applicant';
export const POST_A_JOB = 'post-a-job';
export const EDIT_JOB = 'edit';
export const JOB_PREVIEW = 'job-preview';
export const JOB_SUBMITTED = 'job-submitted';
export const JOB_FAILED = 'job-failed';

//Subscription
export const BILLING_SUBSCRIPTION = 'billing-subscription';
export const MANAGE_BILLING_SUBSCRIPTION = 'subscription';
export const MANAGE_BILLING_DETAILS = 'billing-details';
export const ALL_PACKAGES = 'all-packages';
export const ALL_PACKAGES_RECRUITER = 'all-packages-recruiter';
export const BILLING_INFO = 'billing-info';
export const BILLING_INFO_RECRUITER = 'billing-info-recruiter';
export const CONFIRMATION_ROUTE = 'confirmation';
export const ORDER_SUMMARY = 'order-summary';
export const SUBSCRIPTION_DETAILS = 'subscription-details';

// Settings
export const SETTINGS = 'settings'; // common slug for settings route
export const PROFILE = 'profile';
export const LOGIN_SECURITY = 'login-security';

export const RECRUITERS_DASHBOARD_PATH = `/${DASHBOARD}/${RECRUITER}/${POSTING}`;
export const ACTIVITY_LOG_PATH = `/${DASHBOARD}/${MANAGE_MEMBERSHIPS}/${ACTIVITY_LOG}`;

//Footer Policies Route

export const ENTERPRISE_PRIVACY_POLICY = 'enterprise-privacy-policy';
export const ENTERPRISE_TERMS_CONDITIONS = 'enterprise-terms-conditions';
export const ENTERPRISE_PAYMENT_POLICY = 'enterprise-payment-policy';
export const ENTERPRISE_DISCLAIMER_POLICY = 'enterprise-disclaimer-policy';

// ENTERPRISE_DOCTHUB_URL
export const ENTERPRISE_DOCTHUB_URL = process.env.REACT_APP_ENTERPRISE_DOCTHUB_URL;

// E Certificates Routes
export const ALL_E_CERTIFICATES = 'all-e-certificates';
export const ALL_AWARDEES_LIST = 'all-awardees-list';
export const E_CERTIFICATE = 'e-certificate';
export const SHOW_ALL_E_CERTIFICATES = 'showAllCertificates';
