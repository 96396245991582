import React from 'react';
import { useNavigate } from 'react-router';
import SmallQuickCardHighlighted from '../QuickCard/SmallQuickCardHighlighted';
// import MembershipEmptyCard from './ECertificateQuickAccess';
import { useGetEcertificateAwardeesQuickAccessQuery } from '../../services/quickAccess.services';
import ECertificateCard from './ECertificateCard';
import ECertificateEmptyQuickAccess from './ECertificateEmptyQuickAccess';

function ECertificateQuickAccessWrapper() {
  const { data: eCertificatesData, isLoading } = useGetEcertificateAwardeesQuickAccessQuery('', {
    refetchOnMountOrArgChange: true,
  });

  const { totalCreated, totalDraft, totalUploaded, totalECertificates } = eCertificatesData || {};

  const navigate = useNavigate();

  return (
    <SmallQuickCardHighlighted title={'E-Certificates'}>
      {(totalECertificates && !isLoading) || isLoading ? (
        <ECertificateCard
          totalCreated={totalCreated}
          totalDraft={totalDraft}
          totalUploaded={totalUploaded}
          totalECertificates={totalECertificates}
          navigate={navigate}
          isLoading={isLoading}
        />
      ) : (
        <ECertificateEmptyQuickAccess navigate={navigate} />
      )}
    </SmallQuickCardHighlighted>
  );
}

export default ECertificateQuickAccessWrapper;
