import {
  DoctFreeSoloSearchInput,
  DoctModal,
  DoctTabContent,
  DoctTabWrapper,
} from '@doct-react/app';
import { DoctAnimationLoading } from '@doct-react/app';

import { DoctButton, DoctTypography } from '@doct-react/core';
import React, { useEffect, useReducer, useState } from 'react';
import NoDataFound from '../../../shared/ui/NoDataFound';
import usePrepareRecruiterTabList from '../hooks/usePrepareRecruiterTabList';
import JobListingCard from './components/JobListingCard';
import OnNoJobAdded from './components/OnNoJobAdded';
import {
  useChangeJobStatusMutation,
  useDeleteJobPostMutation,
  useGetJobsQuery,
} from './services/jobs.services';
import './recruiterJobListing.styles.scss';
import CustomToaster from '../../../shared/ui/CustomToaster/CurstomToaster';
import LoadMore from './components/LoadMore';
import NoSearchFound from '../../../shared/components/NoSearchFound';
import { useDispatch, useSelector } from 'react-redux';
import { jobPostActiveTabIndexState, setJobPostActiveTabIndex } from '../../featuresSlice';
import useDebounce from './hooks/useDebounce';
import { getJobUsageDetails } from '../../SubscriptionModal/helper/getJobUsage';
import skeletonOnly from '../../../assets/images/recruiter-dashboard/skeletonOnly.png';
import linesBgPattern from '../../../assets/images/recruiter-dashboard/linesBgPattern.png';
import { DASHBOARD } from '../../../routes/constant';
import { useNavigate } from 'react-router';
import InfoModalWrapper from '../../../components/InfoModalWrapper';

const initialStateJobListing = {
  id: null,
  showCancelledJobModal: false,
  showDeleteJobModal: false,
  canShowTostNotification: false,
  showTost: false,
  cancelledTostMsg: null,
};

function reducerJobListing(state, action) {
  switch (action.type) {
    case 'SET_CANCELLED_JOB_MODAL_VISIBILITY':
      return {
        ...state,
        showCancelledJobModal: action.payload.showCancelledJobModal,
        canShowTostNotification: action.payload.canShowTostNotification,
        id: action.payload.id,
      };

    case 'SET_SHOW_JOB_DELETE_MODAL':
      return {
        ...state,
        id: action.payload?.id,
        showDeleteJobModal: action.payload?.showDeleteJobModal,
      };

    case 'SET_SHOW_TOST':
      return {
        ...state,
        showTost: action.payload.showTost,
        cancelledTostMsg: action.payload.cancelledTostMsg,
        canShowTostNotification: action.payload.canShowTostNotification,
      };

    case 'CAN_SHOW_TOST':
      return {
        ...state,
        canShowTostNotification: action.payload.canShowTostNotification,
      };

    default:
      throw new Error('Out of reducer methods in reducerJobListing');
  }
}

const RecruiterJobListing = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.app?.token);
  const type = useSelector((state) => state?.app?.user?.tenant?.status);
  const isAccountPending = type === 'Pending';
  const tabOptionsArray = usePrepareRecruiterTabList();

  const [searchText, setSearchText] = useState(null);
  const [, setDisable] = useState(false);
  const navigate = useNavigate();

  const search = useDebounce(searchText, 500);

  const {
    data: allJobsDataFromApi,
    isLoading: allJobsDataFromApiFetching,
    isFetching: allJobsFetching,
    refetch,
  } = useGetJobsQuery({
    searchText: search,
  });

  const [
    changeStatus,
    { isLoading: isChangingStatus, isSuccess: isChangeStatusSuccess, isError: isChangeStatusError },
  ] = useChangeJobStatusMutation();

  const [
    deleteJobPost,
    {
      isLoading: isDeletingPost,
      // isFetching: isDeletingFetching,
      isSuccess: isDeletePostSuccess,
      isError: isDeletingPostError,
    },
  ] = useDeleteJobPostMutation();

  const [allJobsData, setAllJobsData] = useState();
  const [rejectionReason, setRejectionReason] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [jobsData, setJobsData] = useState(0);
  const [jobsAllData, setJobsAllData] = useState(7);
  const [cancelStatus, setCancelStatus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const rejectionReasonOne = String(rejectionReason)?.split(',')[0];
  const rejectionReasonTwo = String(rejectionReason)?.split(',')[1];

  const [stateJobListing, dispatchJobListing] = useReducer(
    reducerJobListing,
    initialStateJobListing,
  );

  const {
    showCancelledJobModal,
    id: idOfJob,
    showTost,
    cancelledTostMsg,
    canShowTostNotification,
    showDeleteJobModal,
  } = stateJobListing;

  useEffect(() => {
    refetch();
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);

  useEffect(() => {
    return setAllJobsData(allJobsDataFromApi?.tenantJobs);
  }, [allJobsDataFromApi]);

  useEffect(() => {
    if (showTost) {
      setTimeout(() => {
        dispatchJobListing({
          type: 'SET_SHOW_TOST',
          payload: { showTost: false, canShowTostNotification: false },
        });
      }, 3000);
    }
  }, [showTost]);

  useEffect(() => {
    if (!canShowTostNotification) return;

    const handleCancelMsg = () => {
      if (cancelStatus) {
        return 'Your job post cancelled successfully!';
      }
      if (deleteStatus) {
        return 'Your job post deleted successfully!';
      }
    };

    if (isChangeStatusSuccess || isDeletePostSuccess) {
      if (isChangeStatusSuccess) {
        canceledJobPostingHandler({ showCancelledJobModal: false });
      }
      if (isDeletePostSuccess) {
        confirmationModalForDeleteHandler();
      }
      dispatchJobListing({
        type: 'SET_SHOW_TOST',
        payload: {
          showTost: true,
          cancelledTostMsg: handleCancelMsg(),
        },
      });
      return;
    }
  }, [cancelStatus, deleteStatus, isChangeStatusError]);

  useEffect(() => {
    if (isChangeStatusError || isDeletingPostError) {
      dispatchJobListing({
        type: 'SET_SHOW_TOST',
        payload: { showTost: true, cancelledTostMsg: 'Oops! something went wrong' },
      });
    }
  }, [isChangeStatusError, isDeletingPostError]);

  const jobPostActiveTabIndex = useSelector(jobPostActiveTabIndexState);

  const handleChange = (_, newValue) => {
    dispatch(setJobPostActiveTabIndex(newValue));
  };

  const onLearnWhyButtonPressed = ({ rejectionReason }) => {
    setRejectionReason(rejectionReason);
  };

  const canceledJobPostingHandler = (payload) => {
    dispatchJobListing({ type: 'SET_CANCELLED_JOB_MODAL_VISIBILITY', payload });
  };

  const canceledJobStatusChange = () => {
    changeStatus({ id: idOfJob, status: 'Cancelled' })
      .then((res) => {
        if (Object.prototype.hasOwnProperty.call(res, 'data')) {
          setCancelStatus(true);
        }
      })
      .finally(() => setCancelStatus(false));
    canceledJobPostingHandler({ canShowTostNotification: true, showCancelledJobModal: true });
  };

  const confirmationModalForDeleteHandler = (payload) => {
    dispatchJobListing({ type: 'SET_SHOW_JOB_DELETE_MODAL', payload });
  };

  const deleteJobHandler = () => {
    deleteJobPost({ id: idOfJob })
      .then((res) => {
        if (Object.prototype.hasOwnProperty.call(res, 'data')) {
          setDeleteStatus(true);
        }
      })
      .finally(() => {
        setDeleteStatus(false);
      });
    dispatchJobListing({
      type: 'CAN_SHOW_TOST',
      payload: { canShowTostNotification: true },
    });
  };
  const [jobApplicantData, setJobApplicantData] = useState();
  useEffect(() => {
    const response = getJobUsageDetails(token);
    response.then((data) => {
      localStorage.setItem('jobUsage', JSON.stringify(data?.data ?? {}));
      setJobApplicantData(data?.data);
    });
  }, [token]);
  const totalActiveJobs =
    allJobsData?.length && allJobsData?.filter((item) => item.status === 'Active');

  useEffect(() => {
    localStorage.setItem('totalActiveJobs', totalActiveJobs?.length);
  }, [totalActiveJobs?.length]);

  return (
    <>
      <DoctFreeSoloSearchInput
        name="searchJobs"
        onInputChangeHandler={(val) => {
          setSearchText(val);
        }}
        value={searchText}
        placeholder="Job Title, City, State"
      />
      <div className="content-with-animated-loader-area">
        <div
          hidden={!allJobsDataFromApiFetching}
          className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader"
        >
          {allJobsDataFromApiFetching ? <DoctAnimationLoading /> : null}
        </div>
        <DoctTabWrapper
          value={jobPostActiveTabIndex}
          handleChange={handleChange}
          tabOptions={tabOptionsArray}
        />
        <DoctTabContent value={jobPostActiveTabIndex} index={0}>
          <DoctTypography variant="textLabel2" className="text-grey-500">
            {allJobsDataFromApi?.totalRecords > 0
              ? `${allJobsDataFromApi?.totalRecords} POSTS`
              : 'NO POSTS YET'}
          </DoctTypography>

          {jobApplicantData?.planName === 'Recruiter Free Plan' &&
            jobApplicantData?.pendingApprovalCounts === 0 &&
            !jobApplicantData?.isLimitExceed && (
              <div className="skeleton-job-listing-card d-flex justify-content-center position-relative">
                <img src={skeletonOnly} alt="skeletonOnly" className="p-2" />
                <div
                  className={`d-flex flex-column position-absolute skeleton-over-text align-items-center`}
                  style={{ zIndex: 999 }}
                >
                  <a href={`/dashboard/recruiter/post-a-job`}>
                    <DoctButton
                      className={`free-job-btn-width bg-active-green`}
                      text="Post a FREE Job"
                      variant="contained"
                      size="medium"
                    />
                  </a>
                </div>
              </div>
            )}
          {jobApplicantData?.planName === 'Recruiter Free Plan' &&
            jobApplicantData?.isLimitExceed === true && (
              <div className="skeleton-job-listing-card d-flex justify-content-center">
                <div
                  className="px-2 pt-2 position-relative d-flex flex-column align-items-center"
                  style={{ position: 'relative' }}
                >
                  <img src={skeletonOnly} alt="skeletonOnly" />
                  <div
                    className={`d-flex position-absolute align-items-center upgrade-plan-button-center`}
                    style={{ zIndex: 999 }}
                  >
                    <DoctTypography
                      variant="subtitle1"
                      className="text-grey-800 m-0 mr-3"
                      fontWeight="bold"
                    >
                      Post More Jobs
                    </DoctTypography>

                    <DoctButton
                      className={`select-file-button`}
                      text="Upgrade Plan"
                      variant="contained"
                      size="medium"
                      onButtonClickHandler={() => {
                        isAccountPending
                          ? setIsOpenModal(true)
                          : navigate(
                              `/${DASHBOARD}/billing-subscription/subscription/all-packages/2`,
                              {
                                state: { backFromSubscription: window?.location?.pathname },
                              },
                            );
                      }}
                    />
                  </div>
                  <div className="position-relative">
                    <img src={linesBgPattern} alt="linesBgPattern" />
                  </div>
                </div>
              </div>
            )}
          {allJobsData?.length ? (
            allJobsData?.slice(jobsData, jobsAllData).map((jobDetail, index) => {
              return (
                <JobListingCard
                  key={index}
                  jobDetail={jobDetail}
                  onLearnWhyButtonPressed={onLearnWhyButtonPressed}
                  canceledJobPostingHandler={canceledJobPostingHandler}
                  confirmationModalForDeleteHandler={confirmationModalForDeleteHandler}
                  allJobsFetching={allJobsFetching}
                />
              );
            })
          ) : searchText && !allJobsData?.length && !allJobsDataFromApiFetching ? (
            <NoSearchFound
              positionClassName="not_found_postion"
              description="No jobs found to show here."
            />
          ) : allJobsDataFromApiFetching ? null : (
            <OnNoJobAdded />
          )}
          <LoadMore
            allJobsData={allJobsData}
            setJobsAllData={setJobsAllData}
            jobsAllData={jobsAllData}
          />
        </DoctTabContent>

        {/* ) : (
        <div className="page-content-loader">
          <DoctPageLoading />
        </div>
      )} */}

        {/* {!activeJobsDataFromApiFetching ? ( */}
        <DoctTabContent value={jobPostActiveTabIndex} index={1}>
          <DoctTypography variant="textLabel2" className="text-grey-500">
            {allJobsData?.filter((item) => item?.status === 'Active')?.length > 0 &&
              `${allJobsData?.filter((item) => item?.status === 'Active')?.length} ${
                allJobsData?.filter((item) => item?.status === 'Active')?.length > 0
                  ? ' POSTS'
                  : 'POST'
              }`}
          </DoctTypography>

          {allJobsData?.filter((item) => item?.status === 'Active')?.length ? (
            allJobsData
              ?.filter((item) => item?.status === 'Active')
              ?.map((jobDetail, index) => {
                return (
                  <JobListingCard
                    key={index}
                    jobDetail={jobDetail}
                    canceledJobPostingHandler={canceledJobPostingHandler}
                    allJobsFetching={allJobsFetching}
                  />
                );
              })
          ) : !allJobsData?.length && allJobsDataFromApi?.totalRecords <= 0 ? (
            searchText && !allJobsData?.length && !allJobsDataFromApiFetching ? (
              <NoSearchFound
                positionClassName="not_found_postion"
                description="No jobs found to show here."
              />
            ) : (
              <OnNoJobAdded />
            )
          ) : (
            <NoDataFound />
          )}
        </DoctTabContent>
        <DoctTabContent value={jobPostActiveTabIndex} index={2}>
          <DoctTypography variant="textLabel2" className="text-grey-500">
            {allJobsData?.filter((item) => item?.status === 'ActivationRequested')?.length > 0 &&
              `${allJobsData?.filter((item) => item?.status === 'ActivationRequested')?.length} ${
                allJobsData?.filter((item) => item?.status === 'ActivationRequested')?.length > 0
                  ? 'POSTS'
                  : 'POST'
              }`}
          </DoctTypography>

          {allJobsData?.filter((item) => item?.status === 'ActivationRequested')?.length ? (
            allJobsData
              ?.filter((item) => item?.status === 'ActivationRequested')
              ?.map((jobDetail, index) => {
                return (
                  <JobListingCard
                    key={index}
                    jobDetail={jobDetail}
                    canceledJobPostingHandler={canceledJobPostingHandler}
                    allJobsFetching={allJobsFetching}
                  />
                );
              })
          ) : !allJobsData?.length && allJobsDataFromApi?.totalRecords <= 0 ? (
            searchText && !allJobsData?.length && !allJobsDataFromApiFetching ? (
              <NoSearchFound
                positionClassName="not_found_postion"
                description="No jobs found to show here."
              />
            ) : (
              <OnNoJobAdded />
            )
          ) : (
            <NoDataFound />
          )}
        </DoctTabContent>
        <DoctTabContent value={jobPostActiveTabIndex} index={3}>
          {/* <DoctTypography
            variant="textLabel2"
            className={`${
              searchText && !allJobsData?.length && !allJobsDataFromApiFetching
                ? ''
                : allJobsDataFromApi?.totalRecords > 1 &&
                  'bg-grey-300 py-2 border-radius d-flex align-items-center'
            }`}
            fontWeight="regular"
          >
            {searchText && !allJobsData?.length && !allJobsDataFromApiFetching
              ? ''
              : allJobsDataFromApi?.totalRecords > 0 && (
                  <DoctIcon name="infoOutline" width="15" className="mx-2 p-0" />
                )}
            {searchText && !allJobsData?.length && !allJobsDataFromApiFetching
              ? ''
              : allJobsDataFromApi?.totalRecords > 0 &&
                'Closed job posts will stay here for 6 months, then deleted automatically.'}
          </DoctTypography> */}

          <DoctTypography variant="textLabel2" className="text-grey-500">
            {allJobsData?.filter((item) => item?.status === 'Closed')?.length > 0 &&
              `${allJobsData?.filter((item) => item?.status === 'Closed')?.length}  ${
                allJobsData?.filter((item) => item?.status === 'Closed')?.length > 0
                  ? ' POSTS'
                  : 'POST'
              }`}
          </DoctTypography>

          {allJobsData?.filter((item) => item?.status === 'Closed')?.length ? (
            allJobsData
              ?.filter((item) => item?.status === 'Closed')
              .map((jobDetail, index) => {
                return (
                  <JobListingCard
                    key={index}
                    jobDetail={jobDetail}
                    canceledJobPostingHandler={canceledJobPostingHandler}
                    allJobsFetching={allJobsFetching}
                  />
                );
              })
          ) : !allJobsData?.length && allJobsDataFromApi?.totalRecords <= 0 ? (
            searchText && !allJobsData?.length && !allJobsDataFromApiFetching ? (
              <NoSearchFound
                positionClassName="not_found_postion"
                description="No jobs found to show here."
              />
            ) : (
              <OnNoJobAdded />
            )
          ) : (
            <NoDataFound />
          )}
        </DoctTabContent>
        <DoctTabContent value={jobPostActiveTabIndex} index={4}>
          <DoctTypography variant="textLabel2" className="text-grey-500">
            {allJobsData?.filter((item) => item?.status === 'Draft')?.length > 0 &&
              `${allJobsData?.filter((item) => item?.status === 'Draft')?.length} ${
                allJobsData?.filter((item) => item?.status === 'Draft')?.length > 1
                  ? 'POSTS'
                  : 'POST'
              } `}
          </DoctTypography>

          {allJobsData?.filter((item) => item?.status === 'Draft')?.length ? (
            allJobsData
              ?.filter((item) => item?.status === 'Draft')
              ?.map((jobDetail, index) => {
                return (
                  <JobListingCard
                    key={index}
                    jobDetail={jobDetail}
                    canceledJobPostingHandler={canceledJobPostingHandler}
                    confirmationModalForDeleteHandler={confirmationModalForDeleteHandler}
                    allJobsFetching={allJobsFetching}
                  />
                );
              })
          ) : !allJobsData?.length && allJobsDataFromApi?.totalRecords <= 0 ? (
            searchText && !allJobsData?.length && !allJobsDataFromApiFetching ? (
              <NoSearchFound
                positionClassName="not_found_postion"
                description="No jobs found to show here."
              />
            ) : (
              <OnNoJobAdded />
            )
          ) : (
            <NoDataFound />
          )}
        </DoctTabContent>
        <DoctTabContent value={jobPostActiveTabIndex} index={5}>
          {/* <DoctTypography
            variant="textLabel2"
            className={`${
              searchText && !allJobsData?.length && !allJobsDataFromApiFetching
                ? ''
                : allJobsDataFromApi?.totalRecords > 0 &&
                  'bg-grey-300 py-2 border-radius d-flex align-items-center'
            }`}
            fontWeight="regular"
          >
            {searchText && !allJobsData?.length && !allJobsDataFromApiFetching
              ? ''
              : allJobsDataFromApi?.totalRecords > 0 && (
                  <DoctIcon name="infoOutline" width="15" className="mx-2 p-0" />
                )}
            {searchText && !allJobsData?.length && !allJobsDataFromApiFetching
              ? ''
              : allJobsDataFromApi?.totalRecords > 0 &&
                'Rejected job posts will stay here for 1 month, then deleted automatically.'}
          </DoctTypography> */}

          <DoctTypography variant="textLabel2" className="text-grey-500">
            {allJobsData?.filter((item) => item?.status === 'Rejected')?.length > 0 &&
              `${allJobsData?.filter((item) => item?.status === 'Rejected')?.length}  ${
                allJobsData?.filter((item) => item?.status === 'Rejected') > 0 ? ' POSTS' : 'POST'
              }`}
          </DoctTypography>

          {allJobsData?.filter((item) => item?.status === 'Rejected')?.length ? (
            allJobsData
              ?.filter((item) => item?.status === 'Rejected')
              ?.map((jobDetail, index) => {
                return (
                  <JobListingCard
                    key={index}
                    jobDetail={jobDetail}
                    canceledJobPostingHandler={canceledJobPostingHandler}
                    confirmationModalForDeleteHandler={confirmationModalForDeleteHandler}
                    onLearnWhyButtonPressed={onLearnWhyButtonPressed}
                    allJobsFetching={allJobsFetching}
                  />
                );
              })
          ) : !allJobsData?.length && allJobsDataFromApi?.totalRecords <= 0 ? (
            searchText && !allJobsData?.length && !allJobsDataFromApiFetching ? (
              <NoSearchFound
                positionClassName="not_found_postion"
                description="No jobs found to show here."
              />
            ) : (
              <OnNoJobAdded />
            )
          ) : (
            <NoDataFound />
          )}
        </DoctTabContent>

        <DoctTabContent value={jobPostActiveTabIndex} index={6}>
          <DoctTypography variant="textLabel2" className="text-grey-500">
            {allJobsDataFromApi?.tenantJobs?.filter((item) => item?.status === 'Cancelled')
              ?.length > 0 &&
              `${
                allJobsDataFromApi?.tenantJobs?.filter((item) => item?.status === 'Cancelled')
                  ?.length
              }  ${allJobsDataFromApi?.totalRecords > 1 ? ' POSTS' : 'POST'}`}
          </DoctTypography>

          {allJobsDataFromApi?.tenantJobs?.filter((item) => item?.status === 'Cancelled').length ? (
            allJobsDataFromApi?.tenantJobs
              ?.filter((item) => item?.status === 'Cancelled')
              ?.map((jobDetail, index) => {
                return (
                  <JobListingCard
                    key={index}
                    jobDetail={jobDetail}
                    canceledJobPostingHandler={canceledJobPostingHandler}
                    confirmationModalForDeleteHandler={confirmationModalForDeleteHandler}
                    allJobsFetching={allJobsFetching}
                  />
                );
              })
          ) : !allJobsDataFromApi?.tenantJobs?.length && allJobsDataFromApi?.totalRecords <= 0 ? (
            searchText && !allJobsData?.length && !allJobsDataFromApiFetching ? (
              <NoSearchFound
                positionClassName="not_found_postion"
                description="No jobs found to show here."
              />
            ) : (
              <OnNoJobAdded />
            )
          ) : (
            <NoDataFound />
          )}
        </DoctTabContent>
      </div>

      {!!Object.keys(rejectionReason || {})?.length && (
        <DoctModal
          open={open}
          handleClose={() => {
            setRejectionReason(null);
          }}
          iconName=""
          showFooter={false}
          className="hide-modal-header white-body-modal"
        >
          <div className="m-2">
            <DoctTypography variant="h6" className="text-danger mt-0 mb-12px">
              Why rejection?
            </DoctTypography>
            <DoctTypography variant="body2">
              {rejectionReasonOne?.length && rejectionReasonOne}
            </DoctTypography>
            <DoctTypography variant="body2">
              {rejectionReasonTwo?.length && rejectionReasonTwo}
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600">
              For queries or help contact at:{' '}
              <a href="mailto:jobs@docthub.com" className="text-info">
                jobs@docthub.com
              </a>
            </DoctTypography>

            <div className="d-flex justify-content-end">
              <DoctButton
                variant="contained"
                type="semantic-danger"
                text="Ok, Got it"
                size="medium"
                onButtonClickHandler={() => setRejectionReason(null)}
              ></DoctButton>
            </div>
          </div>
        </DoctModal>
      )}
      {showCancelledJobModal && (
        <DoctModal
          open={open}
          handleClose={() => {
            canceledJobPostingHandler(false);
          }}
          iconName=""
          showFooter={false}
          className="hide-modal-header white-body-modal"
        >
          <div className="m-2">
            <DoctTypography variant="h6" className="mt-0 mb-12px">
              Cancel job post?
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 mt-0 mb-4">
              This job post will be cancelled and removed immediately.
            </DoctTypography>

            <div className="d-flex justify-content-end">
              <DoctButton
                variant="contained"
                type="secondary"
                text="Go back"
                size="medium"
                className="mr-2"
                onButtonClickHandler={() => canceledJobPostingHandler(false)}
              />
              <DoctButton
                variant="contained"
                type="semantic-danger"
                text="Yes, Cancel"
                size="medium"
                disabled={isChangingStatus}
                onButtonClickHandler={() => {
                  canceledJobStatusChange();
                  setDisable(true);
                }}
              />
            </div>
          </div>
        </DoctModal>
      )}
      {showTost && (
        <CustomToaster
          text={cancelledTostMsg}
          handleRemoval={() => {
            dispatchJobListing({
              type: 'SET_SHOW_TOST',
              payload: { showTost: false },
            });
          }}
          bgColor={'#4B4B4B'}
          top="80%"
          right={'calc(50% - 180px)'}
        />
      )}
      {showDeleteJobModal && (
        <DoctModal
          open
          handleClose={() => {
            canceledJobPostingHandler(false);
          }}
          iconName=""
          showFooter={false}
          className="hide-modal-header white-body-modal"
        >
          <div className="m-2">
            <DoctTypography variant="h6" className="mt-0 mb-12px">
              Delete job post?
            </DoctTypography>
            <DoctTypography variant="body2" className="text-grey-600 mt-0 mb-4">
              This job post will be deleted and removed immediately.
            </DoctTypography>

            <div className="d-flex justify-content-end">
              <DoctButton
                variant="contained"
                type="secondary"
                text="Go back"
                size="medium"
                className="mr-2"
                onButtonClickHandler={() => confirmationModalForDeleteHandler()}
              />
              <DoctButton
                variant="contained"
                type="semantic-danger"
                text="Yes, Delete"
                size="medium"
                disabled={isDeletingPost}
                onButtonClickHandler={() => deleteJobHandler()}
              />
            </div>
          </div>
        </DoctModal>
      )}
      <InfoModalWrapper isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </>
  );
};

export default RecruiterJobListing;
