// import { useState } from 'react';
// import * as pdfjsLib from 'pdfjs-dist/build/pdf';
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
// import { useDispatch } from 'react-redux';
// import { setShowToasterWithParams } from '../../featuresSlice';
// import { TOST_TOP_RIGHT } from '../../../constants/toasterPosition.constants';

// pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// const MM_TO_INCH = 0.0393701; // 1mm = 0.0393701 inches
// const A4_WIDTH_MM = 210;
// const A4_HEIGHT_MM = 297;
// const DPI = 96; // Dots per inch for screens (standard DPI)

// // Convert millimeters to pixels at the specified DPI
// const mmToPixels = (mm, dpi) => (mm * dpi) / 25.4;

// const usePdfToImageConverter = () => {
//   const dispatch = useDispatch();
//   const [images, setImages] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');

//   const convertPdfToImages = async (file) => {
//     setLoading(true);
//     if (file.type === 'application/pdf') {
//       try {
//         const fileReader = new FileReader();
//         fileReader.readAsArrayBuffer(file);
//         fileReader.onload = async (event) => {
//           const typedArray = new Uint8Array(event.target.result);
//           const pdfDocument = await pdfjsLib.getDocument({ data: typedArray }).promise;
//           const pagesPromises = [];

//           if (pdfDocument.numPages > 1) {
//             dispatch(
//               setShowToasterWithParams({
//                 variant: 'error',
//                 position: TOST_TOP_RIGHT,
//                 message: 'PDF has more than one page.',
//               }),
//             );

//             setLoading(false);
//             return;
//           }

//           for (let i = 1; i <= pdfDocument.numPages; i++) {
//             pagesPromises.push(pdfDocument.getPage(i));
//           }

//           const pages = await Promise.all(pagesPromises);
//           const imagesPromises = pages.map((page) => {
//             const viewport = page.getViewport({ scale: 1 });
//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');

//             const widthScale = mmToPixels(A4_WIDTH_MM, DPI) / viewport.width;
//             const heightScale = mmToPixels(A4_HEIGHT_MM, DPI) / viewport.height;
//             const scale = Math.min(widthScale, heightScale);

//             canvas.width = mmToPixels(A4_WIDTH_MM, DPI);
//             canvas.height = mmToPixels(A4_HEIGHT_MM, DPI);

//             return page
//               .render({
//                 canvasContext: context,
//                 viewport: page.getViewport({ scale }),
//               })
//               .promise.then(() => canvas.toDataURL());
//           });

//           setImages(await Promise.all(imagesPromises));
//           setLoading(false);
//         };
//       } catch (error) {
//         console.error('Error converting PDF:', error);
//         setLoading(false);
//         setError(error.message);
//       }
//     } else if (file.type.startsWith('image/')) {
//       try {
//         const fileReader = new FileReader();
//         fileReader.readAsDataURL(file);
//         fileReader.onload = (event) => {
//           const base64URL = event.target.result;
//           setImages([base64URL]);
//           setLoading(false);
//         };
//       } catch (error) {
//         console.error('Error converting image to base64:', error);
//         setLoading(false);
//         setError(error.message);
//       }
//     }
//   };
//   return { images, setImages, loading, error, convertPdfToImages };
// };

// export default usePdfToImageConverter;

// Note :- Above commemnted code remain untile e-certificate in QA.

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDocument } from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.entry';
import { setShowToasterWithParams } from '../../featuresSlice';
import { TOST_TOP_RIGHT } from '../../../constants/toasterPosition.constants';
import { getMimeType, readFileAsArrayBuffer } from '../../../helper/helperFunction';

const DPI = 96;

const usePdfToImageConverter = () => {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const convertPdfToImages = async (file) => {
    const arrayBuffer = await readFileAsArrayBuffer(file);
    const uint8Array = new Uint8Array(arrayBuffer);
    const mimeType = getMimeType(uint8Array);

    if (mimeType !== 'application/pdf') {
      dispatch(
        setShowToasterWithParams({
          variant: 'error',
          position: TOST_TOP_RIGHT,
          message: 'Invalid file type. Only PDF files are supported.',
        }),
      );
      setError('');
      setLoading(false);
      const body = document.getElementsByTagName('body')[0];
      body.style.pointerEvents = 'none';
      setTimeout(() => {
        window.location.reload(window.location.href);
      }, 3000);
      return;
    }
    setLoading(true);
    if (file.type === 'application/pdf') {
      try {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = async (event) => {
          const typedArray = new Uint8Array(event.target.result);
          const pdfDocument = await getDocument({ data: typedArray }).promise;

          if (pdfDocument.numPages > 1) {
            dispatch(
              setShowToasterWithParams({
                variant: 'error',
                position: TOST_TOP_RIGHT,
                message: 'PDF has more than one page.',
              }),
            );
            setLoading(false);
            return;
          }

          const page = await pdfDocument.getPage(1);
          const viewport = page.getViewport({ scale: 1 });

          const width = viewport.width; // in points
          const height = viewport.height; // in points

          // Conversion factor for points to pixels (assuming 96 DPI)
          const POINTS_PER_INCH = 72;
          const widthPx = await Math.floor((width / POINTS_PER_INCH) * DPI);
          const heightPx = await Math.floor((height / POINTS_PER_INCH) * DPI);

          const isA4Landscape = widthPx === 1122 && heightPx === 793;
          const isA4Portrait = widthPx === 793 && heightPx === 1122;

          if (!isA4Landscape && !isA4Portrait) {
            dispatch(
              setShowToasterWithParams({
                variant: 'error',
                position: TOST_TOP_RIGHT,
                message: 'PDF does not match Indian A4 size.',
              }),
            );
            setLoading(false);
            return;
          }

          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          const widthScale = widthPx / width;
          const heightScale = heightPx / height;

          const scale = Math.min(widthScale, heightScale);

          canvas.width = widthPx;
          canvas.height = heightPx;

          await page.render({
            canvasContext: context,
            viewport: page.getViewport({ scale }),
          }).promise;

          setImages([canvas.toDataURL()]);
          setLoading(false);
        };
      } catch (error) {
        console.error('Error converting PDF:', error);
        setLoading(false);
        setError(error.message);
      }
    } else if (file.type.startsWith('image/')) {
      try {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = (event) => {
          const base64URL = event.target.result;
          setImages([base64URL]);
          setLoading(false);
        };
      } catch (error) {
        console.error('Error converting image to base64:', error);
        setLoading(false);
        setError(error.message);
      }
    }
  };

  return { convertPdfToImages, images, setImages, loading, error };
};

export default usePdfToImageConverter;
