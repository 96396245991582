import { createAsyncThunk } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

import {
  BUSINESS_BASE_API_ROUTE,
  DESIGNATIONS,
  ENTERPRISE_BASE_API_ROUTE,
} from '../../../../api/apiEndpoints';
import { NETWORK_REQUEST_ERROR_MSG } from '../../../../constants/constants';
import { TOST_BOTTOM_CENTER } from '../../../../constants/toasterPosition.constants';

import { setShowToasterWithParams } from '../../../featuresSlice';

export const postDesignation = createAsyncThunk(
  `postDesignation`,
  async ({ data, handleModalClose }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { app } = getState();
      const { token } = app;
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${DESIGNATIONS}`,
        data,
        {
          Authorization: `Bearer ${token}`,
        },
      );
      dispatch({
        type: `designationApi/invalidateTags`,
        payload: ['Designation'],
      });
      dispatch(
        setShowToasterWithParams({
          variant: 'neutral',
          position: TOST_BOTTOM_CENTER,
          message: 'Designation Added Successfully',
        }),
      );
      handleModalClose();
    } catch (err) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_BOTTOM_CENTER,
          message: NETWORK_REQUEST_ERROR_MSG,
        }),
      );
      return rejectWithValue(err.response.data);
    }
  },
);

export const putDesignation = createAsyncThunk(
  `putDesignation`,
  async ({ id, data, handleModalClose = () => {} }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { app } = getState();
      const { token } = app;
      // eslint-disable-next-line no-unused-vars
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${DESIGNATIONS}/${id}`,
        data,
        {
          Authorization: `Bearer ${token}`,
        },
      );
      dispatch({
        type: `designationApi/invalidateTags`,
        payload: ['Designation'],
      });
      dispatch(
        setShowToasterWithParams({
          variant: 'neutral',
          position: TOST_BOTTOM_CENTER,
          message: 'Designation Updated Successfully',
        }),
      );
      handleModalClose();
    } catch (err) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_BOTTOM_CENTER,
          message: NETWORK_REQUEST_ERROR_MSG,
        }),
      );
      return rejectWithValue(err.response.data);
    }
  },
);

export const getDesignationById = createAsyncThunk(`getDesignationById`, async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${DESIGNATIONS}/${id}`,
  );
  return response.data;
});

export const designationApi = createApi({
  reducerPath: 'designationApi',

  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = getState().app.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: ['Designation'],

  endpoints: (builder) => ({
    getDesignations: builder.query({
      query: ({ archiveId }) =>
        `${
          process.env.REACT_APP_BASE_API_URL
        }${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${DESIGNATIONS}${
          archiveId && `?archiveId=${archiveId}`
        }`,
      providesTags: ['Designation'],
    }),
    deleteDesignation: builder.mutation({
      query: ({ id }) => {
        return {
          url: `${process.env.REACT_APP_BASE_API_URL}${ENTERPRISE_BASE_API_ROUTE}${BUSINESS_BASE_API_ROUTE}/${DESIGNATIONS}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Designation'],
    }),
  }),
});

export const { useGetDesignationsQuery, useDeleteDesignationMutation } = designationApi;
