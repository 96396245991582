import React, { useState } from 'react';
import { DoctButton, DoctCol, DoctTypography } from '@doct-react/core';
import UpgradePlanWhiteCard from './UpgradePlanWhiteCard';
import { useNavigate } from 'react-router';
import { DASHBOARD } from '../../../routes/constant';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setAllApplicants } from '../../../features/InstituteDashboard/Courses/ManageCourse/ManageCourseSlice';
import style from './SubscriptionUsageCard.module.scss';
import InfoModalWrapper from '../../../components/InfoModalWrapper';

export default function SubscriptionUsageCard({
  totalUsage = 0,
  planName = 'Free',
  isFreePlan = false,
  limitForUsage,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organizationId = useSelector((state) => state.app.user.tenant?.organizationId);
  if (!organizationId) {
    return null;
  }
  const upgradePlan = limitForUsage && totalUsage >= limitForUsage && totalUsage !== 0;

  const type = useSelector((state) => state?.app?.user?.tenant?.status);
  const isAccountPending = type === 'Pending';

  const [isOpenModal, setIsOpenModal] = useState(false);

  const isUrlIncludesCourses = location.pathname?.includes('/courses');

  const handleButtonClick = async () => {
    //always pass 0 as courseId, so removed courseId, and added 0.
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/courses/0/applicants?IsViewedApplicants=true`,
      );
      navigate(`/dashboard/institute/applicants?id=0`);
      dispatch(setAllApplicants(res?.data));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  if (isFreePlan) {
    return (
      <div className={`${style['usage-card-body']} ${style['free-plan-card-bg']} mt-3`}>
        <div className="pl-3 pr-3">
          <div className="d-flex justify-content-between mt-2 pt-1 mb-2 pb-1">
            <DoctTypography variant="textLabel1" className="text-grey-600 m-0">
              Applicant view usage
            </DoctTypography>
          </div>
        </div>
        <UpgradePlanWhiteCard
          text="No active Subscription"
          plan="View Plans"
          navigateOn={() => {
            if (isAccountPending) {
              setIsOpenModal(true);
            } else {
              navigate(`/${DASHBOARD}/billing-subscription/subscription/all-packages`, {
                state: { backFromSubscription: window?.location?.pathname },
              });
            }
          }}
        />

        <div className="text-center mt-2 mb-2 pb-1">
          <DoctTypography variant="body3" className="text-grey-600 m-0">
            Applicants Waiting for Responses!
          </DoctTypography>
        </div>
      </div>
    );
  }
  return (
    <DoctCol xs={3} className="pl-0 pr-0 mt-4">
      <div className={`${style['usage-card-body']} pl-3 pr-3`}>
        <div className="d-flex justify-content-between mt-2 pt-1 mb-2 pb-1">
          <DoctTypography variant="textLabel1" className="text-grey-600 m-0">
            Applicant view usage
          </DoctTypography>

          <div className="d-flex align-items-center my-n2">
            {limitForUsage ? (
              <>
                <DoctButton
                  text={`${totalUsage}`}
                  onButtonClickHandler={handleButtonClick}
                  disabled={isUrlIncludesCourses ? true : false}
                  variant="text"
                  size="medium"
                  className={`p-0 text-grey-600 m-0 ${style['total-usage-btn-bg']} ${
                    isUrlIncludesCourses
                      ? style['total-usage-normal-btn']
                      : style['total-usage-active-btn']
                  }`}
                />
                <DoctTypography variant="textLabel1" className="text-grey-600 m-0">
                  / {limitForUsage}
                </DoctTypography>
              </>
            ) : (
              <DoctButton
                text={`${totalUsage}`}
                onButtonClickHandler={handleButtonClick}
                disabled={isUrlIncludesCourses ? true : false}
                variant="text"
                size="medium"
                className={`p-0 text-grey-600 m-0 ${style['total-usage-btn-bg']} ${
                  isUrlIncludesCourses
                    ? style['total-usage-normal-btn']
                    : style['total-usage-active-btn']
                }`}
              />
            )}
          </div>
        </div>
        <div className={`${style['usage-card-line-divider']} ml-n3 mr-n3`} />
        <DoctTypography
          variant="caption2"
          className="d-flex justify-content-center text-lowercase text-grey-400 mt-2 mb-2"
        >
          {` plan usage data / ${planName}`}
        </DoctTypography>

        {upgradePlan ? (
          <div className="bg-white d-flex justify-content-between mb-1 py-1 border-radius ml-n2 mr-n2">
            <DoctTypography variant="textLabel2" className="text-grey-600 ml-12px">
              Usage limit <br /> exceeded.
            </DoctTypography>
            <span
              onClick={() => {
                if (isAccountPending) {
                  setIsOpenModal(true);
                } else {
                  navigate(`/${DASHBOARD}/billing-subscription/subscription/all-packages`, {
                    state: { backFromSubscription: window?.location?.pathname },
                  });
                }
              }}
            >
              <DoctTypography
                variant="textLabel1"
                className="text-info font-weight-bold mr-12px mt-3 cursor-pointer"
              >
                Upgrade Plan
              </DoctTypography>
            </span>
          </div>
        ) : (
          ''
        )}
        <InfoModalWrapper isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
      </div>
    </DoctCol>
  );
}
